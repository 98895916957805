import {Component} from "@angular/core";
import {UserSettingsStateService} from "@shared";

@Component({
  templateUrl: "./login-page.component.html"
})
export class LoginPageComponent {

  constructor(readonly userSettingsService: UserSettingsStateService) {
  }
}
