import {Component, EventEmitter, Input, OnInit} from "@angular/core";
import {BusinessEntityIdWithType, None, required} from "@utils";
import {
  BusinessEntityVariable,
  BusinessEntityVariableType,
  BusinessVariable,
  VariablePath
} from "@shared-model";
import {
  BusinessEntityQueryService
} from "../../modules/designer.module/services/business-entity/business-entity.query-service";
import {BusinessEntitySearchQuery} from "../../modules/designer.module/services/business-entity/business-entity.model";

export class BusinessEntityVariableInputViewModel {

  previewText: string|null = null;
  internalModel: BusinessEntityIdWithType|null = null;

  constructor(readonly path: VariablePath,
              public value: BusinessEntityVariable|null,
              public readOnly: boolean,
              public preview: boolean,
              readonly change: EventEmitter<{value: BusinessVariable|null, path: VariablePath}>,
              private readonly businessEntityQueryService: BusinessEntityQueryService) {
    this.updatePreviewText();
    this.initInternalModel();
  }

  private updatePreviewText() {

    const value = this.value;
    if(value) {
      this.businessEntityQueryService.loadEntitySummaryWithFields(value.value, None(), [], [], (entity) => {
        if(entity.isDefined()) {
          this.previewText = entity.get().code+" "+entity.get().nameUnwrapped().map(n => n.valueToSimpleString()).join(" ");
        } else {
          this.previewText = "BusinessEntity#"+value.value.typeId+"#"+value.value.id;
        }
      });
    } else {
      this.previewText = this.previewText && this.readOnly ? "-" : "-- select --";
    }

  }

  initInternalModel() {
    if (this.value == null) {
      this.internalModel = null;
    } else {
      this.internalModel = this.value.value;
    }
  }

  changeVariableValue(value: BusinessEntityVariable|null) {
    this.value = value;
    this.updatePreviewText();
    this.initInternalModel();
    this.change.emit({value: value, path: this.path});
  }


  updateValue(value: BusinessVariable|null) {
    if(value === null || value instanceof BusinessEntityVariable) {
      this.value = value;
      this.initInternalModel();
    } else {
      throw new Error("Incorrect variable type, expected 'BusinessEntity' but was '"+value.simpleValueType()+"'");
    }
  }

  updateMode(readOnly: boolean, preview: boolean) {
    this.readOnly = readOnly;
    this.preview = preview;
  }

}


class EntitySummaryViewModel {
 constructor(readonly id: BusinessEntityIdWithType,
             readonly code: string,
             readonly name: string,
             readonly description: string) {}
}

  @Component({
    selector: "my-business-entity-variable-input",
    templateUrl: "./business-entity-variable-input.component.html"
  })
  export class BusinessEntityVariableInputComponent implements OnInit {
    private _value!: BusinessVariable|null;
    get value(): BusinessVariable|null {return this._value;}
    @Input() set value(value: BusinessVariable|null) {this._value = value;this.onValueChanged();}

    @Input({required: true}) valueType?: BusinessEntityVariableType;

    private _readOnly: boolean = false;
    get readOnly(): boolean {return this._readOnly;}
    @Input() set readOnly(readOnly: boolean) {this._readOnly = readOnly;this.onModeChanged();}

    private _preview: boolean = false;
    get preview(): boolean {return this._preview;}
    @Input() set preview(preview: boolean) {this._preview = preview;this.onModeChanged();}

    @Input() path!: VariablePath;
    @Input() change = new EventEmitter<{value: BusinessVariable|null, path: VariablePath}>();

    viewModel!: BusinessEntityVariableInputViewModel;
    selectorVisible: boolean = false;
    query: string = "";
    foundEntities: Array<EntitySummaryViewModel> = [];

    constructor(private readonly businessEntityQueryService: BusinessEntityQueryService) {
    }

    private onValueChanged() {
      if(this.viewModel) { // might be undefined during initialization
        this.viewModel.updateValue(this._value);
      }
    }

    private onModeChanged() {
      if(this.viewModel) { // might be undefined during initialization
        this.viewModel.updateMode(this.readOnly, this.preview);
      }
    }

    ngOnInit(): void {
      this.viewModel = new BusinessEntityVariableInputViewModel(this.path, this._value, this._readOnly, this._preview, this.change, this.businessEntityQueryService);
    }


    lastSearch: number = -1;

    queryChanged() {

      if(this.lastSearch < Date.now() - 500) {
        setTimeout(() => {
          if(this.selectorVisible) {
            this.search(() => {
            });
          }
        }, 500);
        this.lastSearch = Date.now();
      }

    }

    search(onSuccess: () => void) {
      this.businessEntityQueryService.findEntitiesSummaries(BusinessEntitySearchQuery.textOnly(this.query), required(this.valueType, "valueType").businessVariableTypeId, [], [], (entities, moreAvailable) => {
        this.foundEntities = entities.map(e => new EntitySummaryViewModel(e.id, e.code,
          e.nameUnwrapped().map(e => e.valueToSimpleString()).join(" "),
          e.descriptionUnwrapped().map(e => e.valueToSimpleString()).join(" ")));
        onSuccess();
      });
    }

    selectSingle() {


    }

    showSelector() {
      this.query = "";
      this.search(() => {
        this.selectorVisible = true;
      });
    }

    selectEntity(entity: EntitySummaryViewModel) {
      this.viewModel.changeVariableValue(new BusinessEntityVariable(entity.id));
      this.selectorVisible = false;
    }
  }
