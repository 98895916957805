import {BusinessVariable, ObjectVariable} from "@shared-model";

export class SelectComponentOption {
  constructor(public id: string,
              public selected: boolean,
              public label: string,
              readonly value: BusinessVariable | undefined) {}

  static of(index: number, value: BusinessVariable) {
    if(value instanceof ObjectVariable) {
      if(value.valueFor("label").isDefined() && value.valueFor("value").isDefined()) {
        return new SelectComponentOption(index+"", false, value.valueFor("label").get().valueToSimpleString(), value.valueFor("value").getOrError("no value"));
      } else {
        throw new Error("Objects in options must have fields 'value' and 'label'");
      }
    } else {
      return new SelectComponentOption(index+"", false, value.valueToSimpleString(), value);
    }
  }

  static EMPTY(placeholder: string) {
    return new SelectComponentOption("xnp7Y6uPONKZiKEr", false, placeholder, undefined);
  }
}
