import {Component, Input, OnInit} from "@angular/core";
import {None, toastr, global, __} from "@utils";
import {
  I18nService,
  NavigationService,
  PasswordValidatorService,
  SessionServiceProvider,
  UserSettingsStateService
} from "@shared";
import {UserRegistrationService} from "@shared";
import {ActivatedRoute} from "@angular/router";

class SetPasswordForm {
  newPassword: string = "";
  newRepeatPassword: string = "";

  clear() {
    this.newPassword = "";
    this.newRepeatPassword = "";
  }

  isValid() {
    return this.newPassword.length > 0 && this.newRepeatPassword.length > 0 && this.newPassword == this.newRepeatPassword;
  }
}

@Component({
  selector: 'my-password-reset-form',
  templateUrl: './password-reset-form.component.html'
})
export class PasswordResetFormComponent implements OnInit {

  readonly activationCode!: string | null;
  readonly userId!: string | null;

  public form: SetPasswordForm = new SetPasswordForm();
  public organizationUrl: boolean = false;
  public finished: boolean = false;
  public invalidLink: boolean = false;
  public passwordExceptions: Array<string> = [];
  public repeatPasswordExceptions: Array<string> = [];


  constructor(readonly sessionServiceProvider: SessionServiceProvider,
              readonly userSettingsService: UserSettingsStateService,
              private activatedRoute: ActivatedRoute,
              private readonly i18nService: I18nService,
              private readonly userRegistrationService: UserRegistrationService,
              private readonly navigationService: NavigationService,
              readonly passwordValidatorService: PasswordValidatorService) {

    this.activationCode = activatedRoute.snapshot.paramMap.get("activationCode");
    this.userId = activatedRoute.snapshot.paramMap.get("userId");
  }

  @Input() onSuccessfulLogin: () => void = () => {

    this.sessionServiceProvider.getSessionService(sessionService => {
      if (sessionService.lastRequestedUrl.getOrElse("").indexOf("/admin") >= 0) {
        throw new Error("Logging to admin not yet implemented");
        // this.navigationService.navigateToLastRequestedPageOrMainPage();
        // $location.url("/admin/organizations");
      } else {
        this.navigationService.navigateToLastRequestedPageOrMainPage("/password-reset");
        sessionService.lastRequestedUrl = None();
      }
    });

  };

  ngOnInit() {
    this.organizationUrl = global.config.organizationId.isDefined();
    this.form = new SetPasswordForm();

    if(this.activationCode && this.userId) {
      this.userRegistrationService.verifyCode(this.activationCode, this.userId, () => {
        this.invalidLink = false;
      }, () => {
        this.invalidLink = true;
      });
    }
  }

  validatePassword() {

    this.passwordValidatorService.validatePassword(this.form.newPassword.trim(), () => {
      this.passwordExceptions = [];
      this.repeatPasswordExceptions = [];
    }, (passwordExceptions: Array<string>, repeatPasswordExceptions: Array<string>) => {
      this.passwordExceptions = passwordExceptions;
      this.repeatPasswordExceptions = repeatPasswordExceptions;
    });

  };

  validatePasswords() {

    this.passwordValidatorService.validatePasswords(this.form.newPassword.trim(), this.form.newRepeatPassword.trim(), () => {
      this.passwordExceptions = [];
      this.repeatPasswordExceptions = [];
    }, (passwordExceptions: Array<string>, repeatPasswordExceptions: Array<string>) => {
      this.passwordExceptions = passwordExceptions;
      this.repeatPasswordExceptions = repeatPasswordExceptions;
    });

  };

  validatePasswordPolicy = (onResult: (result: boolean) => void) => {
    this.passwordValidatorService.validatePassword(this.form.newPassword.trim(), () => {
      onResult(true);
    }, (passwordExceptions: Array<string>, repeatPasswordExceptions: Array<string>) => {
      onResult(__(passwordExceptions).isEmpty());
    });
  };

  checkIfPasswordsMatchNow() {
    if (this.repeatPasswordExceptions.length > 0) {
      this.validatePasswords();
    }
  };

  resetPassword() {

      this.passwordValidatorService.validatePasswords(this.form.newPassword.trim(), this.form.newRepeatPassword.trim(), () => {
        this.passwordExceptions = [];
        this.repeatPasswordExceptions = [];

        if (this.userId && this.activationCode) {

          this.userRegistrationService.resetPassword(
            this.userId, this.activationCode, this.form.newPassword, this.form.newRepeatPassword,
            () => {
              this.finished = true;
              this.form.clear();
            },
            (exceptions: Array<string>) => {
              exceptions.forEach(exception => toastr.error(exception))
            },
            () => {
              toastr.error("Couldn't send password reset to server, try again later...");
            });
        }

      }, (passwordExceptions: Array<string>, repeatPasswordExceptions: Array<string>) => {
        this.passwordExceptions = passwordExceptions;
        this.repeatPasswordExceptions = repeatPasswordExceptions;
      });

  }

}
