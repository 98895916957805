import {VariableId} from "@utils";
import {ScreenComponentViewModel, ScreenContainerViewModel, ScreenWrapperViewModel} from "../screen-component.view-model";
import {HtmlComponentDefinition, HtmlComponentRef} from "@screen-common";
import {ComponentErrorInfo, ScreenSharedViewModel} from "../..";
import {HtmlComponentRefState, HtmlComponentState} from "./HtmlComponentState";
import {ScreenInstanceServerModel} from "../../screen-instance.server-model";

export class HtmlComponentViewModel extends ScreenComponentViewModel {

  override typeName = "Html";


  public html: string = "";
  public css: string = "";

  override errors: Array<ComponentErrorInfo> = [];

  constructor(
    override readonly shared: ScreenSharedViewModel,
    override readonly parent: ScreenContainerViewModel | ScreenWrapperViewModel,
    readonly context: VariableId,
    override readonly definition: HtmlComponentDefinition,
    override readonly componentScreenId: string,
    readonly ref: HtmlComponentRef,
    override readonly refScreenId: string,
    readonly componentState: HtmlComponentState,
    readonly refState: HtmlComponentRefState,
    readonly serverModel: ScreenInstanceServerModel
  ) {
    super(parent, componentState, refState, definition, shared);
    this.update();
  }

  updateComponent(deep: boolean): void {

    this.css = "";
    this.html = this.definition.html.currentValue(() => this.componentState.html).valueOrDefault("");
    super.updatePosition();
  }

}
