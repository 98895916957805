import {Injectable} from '@angular/core';
import {
  AggregateId,
  AggregateVersion,
  ApplicationId, FileUri,
  GroupId,
  I18nText, LocalDate, LocalDateTime, None,
  Option,
  OrganizationNodeId,
  PersonId, restUrl, Some,
  Typed
} from "@utils";
import {
  asSuccess,
  AuthenticatedHttp, BasicPersonInfo,
  CommandResponse, customCommandResponseHandler, CustomSuccessResponse,
  isSuccess,
  MultiFactorAuthenticationMode,
  nonSuccessMessages, PersonsSharedService, TokenResponse
} from "@shared-model";
import {AddGroupAuthorization, AddGroupToGroup, ChangeGroupInfo, CreateGroup, DeleteGroup, RemoveGroupAuthorization, RemoveGroupFromGroup} from "../commands/GroupCommands";
import {
  AddPersonToGroup, AnonymizePerson, ApiPersonInfo, ChangePersonParent, CreateApiPerson,
  CreatePerson, DeleteAPIToken,
  DeletePerson, GenerateApiKey,
  RegularPersonInfo,
  RemovePersonFromGroup, RestorePerson, UpdateAPITokenValidUntil,
  UpdatePersonInfo
} from "../commands/PersonCommands";
import {AddDepartmentToGroup, RemoveDepartmentFromGroup} from "../commands/DepartmentCommands";
import {EmailConfirmResponse, LoginLink, RecoverPasswordByAdminUI, RecoverPasswordByAdminUIResponse} from "@shared";

@Injectable({
  providedIn: 'root',
})
export class PersonsCommandService {

  constructor(readonly authenticatedHttp: AuthenticatedHttp) {}

  createPerson(firstName: string, lastName: string, email: string, position: string, parentNodeId: OrganizationNodeId, supervisorId: Option<PersonId>, avatar: Option<FileUri>, phone: string, internalAuthenticationEnabled: boolean, externalAuthenticationEnabled: boolean, mfa: MultiFactorAuthenticationMode,
               onSuccess:(aggregateId: AggregateId, aggregateVersion: AggregateVersion) => void,
               onFailure: (exceptions: Array<string>) => void) {
    const command = new CreatePerson(firstName, lastName, email, position, parentNodeId, supervisorId, avatar, phone, internalAuthenticationEnabled, externalAuthenticationEnabled, mfa);
    this.authenticatedHttp.post("organization-structure/person/create", command,
      (data: Typed<CommandResponse>) => {
        if (isSuccess(data)) {
          const success = asSuccess(data);
          onSuccess(success.aggregateId, success.aggregateVersion);
        } else {
          onFailure([nonSuccessMessages(data)]);
        }
      }
    );
  }

  createApiPerson(name: string, parentNodeId: OrganizationNodeId,
               onSuccess:(aggregateId: AggregateId, aggregateVersion: AggregateVersion) => void,
               onFailure: (exceptions: Array<string>) => void) {
    const command = new CreateApiPerson(name, parentNodeId);
    this.authenticatedHttp.post("organization-structure/person/create-service-account", command,
      (data: Typed<CommandResponse>) => {
        if (isSuccess(data)) {
          const success = asSuccess(data);
          onSuccess(success.aggregateId, success.aggregateVersion);
        } else {
          onFailure([nonSuccessMessages(data)]);
        }
      }
    );
  }

  generateApiToken(aggregateId: AggregateId, expectedVersion: AggregateVersion,
                  onSuccess:(id: AggregateId, version: AggregateVersion, tokenId: number, token: string, tokenShort: string) => void,
                  onFailure: (exceptions: Array<string>) => void) {
    const command = new GenerateApiKey(aggregateId, expectedVersion);

    this.authenticatedHttp.post("organization-structure/person/generate-api-token", command,
      (data: Typed<CustomSuccessResponse<TokenResponse>>) => {
        customCommandResponseHandler<Typed<TokenResponse>>(data)
          .onSuccess((id: AggregateId, version: AggregateVersion, responseData: Typed<TokenResponse>) => {
            const info = Typed.value(responseData);
            onSuccess(id, version, info.id, info.token, info.tokenShort);
          })
          .onFailure((exceptions: Array<string>) => onFailure(exceptions)).handle();
      }
    );
  }

  deleteAPIToken(aggregateId: AggregateId, version: AggregateVersion, tokenId: number,
                 onSuccess: (id: AggregateId, version: AggregateVersion) => void,
                 onFailure: (exceptions: Array<string>) => void) {

    const command = new DeleteAPIToken(aggregateId, version, tokenId);
    this.authenticatedHttp.post("organization-structure/person/delete-api-token", command,
      (data: Typed<CommandResponse>) => {
        if (isSuccess(data)) {
          const success = asSuccess(data);
          onSuccess(success.aggregateId, success.aggregateVersion);
        } else {
          onFailure([nonSuccessMessages(data)]);
        }
      }
    );
  }

  updateAPITokenValidUntil(aggregateId: AggregateId, version: AggregateVersion, tokenId: number, validTo: Option<LocalDateTime>,
                 onSuccess: (id: AggregateId, version: AggregateVersion) => void,
                 onFailure: (exceptions: Array<string>) => void) {

    const command = new UpdateAPITokenValidUntil(aggregateId, version, tokenId, validTo);
    this.authenticatedHttp.post("organization-structure/person/update-api-token-valid", command,
      (data: Typed<CommandResponse>) => {
        if (isSuccess(data)) {
          const success = asSuccess(data);
          onSuccess(success.aggregateId, success.aggregateVersion);
        } else {
          onFailure([nonSuccessMessages(data)]);
        }
      }
    );
  }

  updateApiPersonInfo(aggregateId: AggregateId, expectedVersion: AggregateVersion, active: boolean, name: string, parentNodeId: OrganizationNodeId,
                   onSuccess: (aggregateId: AggregateId, aggregateVersion: AggregateVersion) => void,
                   onFailure: (exceptions: Array<string>) => void) {
    const info = new ApiPersonInfo(name);
    const regularInfo = new RegularPersonInfo("", "", "", "", "", true, false);
    const command = new UpdatePersonInfo(aggregateId, expectedVersion, parentNodeId, None(), active, None(), Some(regularInfo), Some(info), MultiFactorAuthenticationMode.DEFAULT);
    this.authenticatedHttp.post("organization-structure/person/update-info", command,
      (data: Typed<CommandResponse>) => {
        if (isSuccess(data)) {
          const success = asSuccess(data);
          onSuccess(success.aggregateId, success.aggregateVersion);
        } else {
          onFailure([nonSuccessMessages(data)]);
        }
      }
    );
  }

  updatePersonInfo(aggregateId: AggregateId, expectedVersion: AggregateVersion, active: boolean, firstName: string, lastName: string, email: string, position: string, parentNodeId: OrganizationNodeId, supervisorId: Option<PersonId>, avatar: Option<FileUri>, apiInfo: Option<ApiPersonInfo>, phone: string, internalAuthenticationEnabled: boolean, externalAuthenticationEnabled: boolean, mfa: MultiFactorAuthenticationMode,
                   onSuccess: (aggregateVersion: AggregateVersion) => void,
                   onFailure: (exceptions: Array<string>) => void) {
    const regularInfo = new RegularPersonInfo(firstName, lastName, position, email, phone, internalAuthenticationEnabled, externalAuthenticationEnabled)
    const command = new UpdatePersonInfo(aggregateId, expectedVersion, parentNodeId, supervisorId, active, avatar, Some(regularInfo), None(), mfa);
    this.authenticatedHttp.post("organization-structure/person/update-info", command,
      (data: Typed<CommandResponse>) => {
        if (isSuccess(data)) {
          const success = asSuccess(data);
          onSuccess(success.aggregateVersion);
        } else {
          onFailure([nonSuccessMessages(data)]);
        }
      }
    );
  }

  changePersonParent(aggregateId: AggregateId, expectedVersion: AggregateVersion, parentNodeId: OrganizationNodeId,
                   onSuccess: (aggregateVersion: AggregateVersion) => void,
                   onFailure: (exceptions: Array<string>) => void) {
    const command = new ChangePersonParent(aggregateId, expectedVersion, parentNodeId);
    this.authenticatedHttp.post("organization-structure/person/change-parent", command,
      (data: Typed<CommandResponse>) => {
        if (isSuccess(data)) {
          const success = asSuccess(data);
          onSuccess(success.aggregateVersion);
        } else {
          onFailure([nonSuccessMessages(data)]);
        }
      }
    );
  }

  addPersonToGroup(aggregateId: AggregateId, version: AggregateVersion,
                   groupId: GroupId,
                   onSuccess: (version: AggregateVersion) => void,
                   onFailure: (exceptions: Array<string>) => void) {
    const command = new AddPersonToGroup(aggregateId, version, groupId);
    this.authenticatedHttp.post("organization-structure/person/add-person-to-group", command,
      (data: Typed<CommandResponse>) => {
        if (isSuccess(data)) {
          const success = asSuccess(data);
          onSuccess(success.aggregateVersion);
        } else {
          onFailure([nonSuccessMessages(data)]);
        }
      }
    );
  }

  removePersonFromGroup(aggregateId: AggregateId, version: AggregateVersion,
                        groupId: GroupId,
                        onSuccess: (version: AggregateVersion) => void,
                        onFailure: (exceptions: Array<string>) => void) {

    const command = new RemovePersonFromGroup(aggregateId, version, groupId);
    this.authenticatedHttp.post("organization-structure/person/remove-person-from-group", command,
      (data: Typed<CommandResponse>) => {
        if (isSuccess(data)) {
          const success = asSuccess(data);
          onSuccess(success.aggregateVersion);
        } else {
          onFailure([nonSuccessMessages(data)]);
        }
      }
    );
  }

  deletePerson(aggregateId: AggregateId, expectedVersion: AggregateVersion,
               onSuccess: (aggregateId: AggregateId, aggregateVersion: AggregateVersion) => void,
               onFailure: (exceptions: Array<string>) => void) {
    const command = new DeletePerson(aggregateId, expectedVersion);
    this.authenticatedHttp.post("organization-structure/person/delete", command,
      (data: Typed<CommandResponse>) => {
        if (isSuccess(data)) {
          const success = asSuccess(data);
          onSuccess(success.aggregateId, success.aggregateVersion);
        } else {
          onFailure([nonSuccessMessages(data)]);
        }
      }
    );
  }

  restorePerson(aggregateId: AggregateId, expectedVersion: AggregateVersion,
               onSuccess: (aggregateId: AggregateId, aggregateVersion: AggregateVersion) => void,
               onFailure: (exceptions: Array<string>) => void) {
    const command = new RestorePerson(aggregateId, expectedVersion);
    this.authenticatedHttp.post("organization-structure/person/restore", command,
      (data: Typed<CommandResponse>) => {
        if (isSuccess(data)) {
          const success = asSuccess(data);
          onSuccess(success.aggregateId, success.aggregateVersion);
        } else {
          onFailure([nonSuccessMessages(data)]);
        }
      }
    );
  }

  anonymizePerson(aggregateId: AggregateId, expectedVersion: AggregateVersion,
                  onSuccess: (aggregateId: AggregateId, aggregateVersion: AggregateVersion) => void,
                  onFailure: (exceptions: Array<string>) => void) {
    const command = new AnonymizePerson(aggregateId, expectedVersion);
    this.authenticatedHttp.post("organization-structure/person/anonymize", command,
      (data: Typed<CommandResponse>) => {
        if (isSuccess(data)) {
          const success = asSuccess(data);
          onSuccess(success.aggregateId, success.aggregateVersion);
        } else {
          onFailure([nonSuccessMessages(data)]);
        }
      }
    );
  }

}
