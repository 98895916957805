import {__, i18n, None, NoneSingleton, Option, Some, VariableId} from "@utils";
import {DropListComponentRefState, DropListComponentState} from "./DropListComponentState";
import {SelectComponentOption} from "./select-common";
import {
  ComponentViewModelUtils,
  ComponentViewModelWithLabel,
  ScreenContainerViewModel, ScreenWrapperViewModel
} from "../screen-component.view-model";
import {ScreenSharedViewModel} from "../..";
import {ComponentsCommon} from "../ComponentsModel";
import {ScreenInstanceServerModel} from "../../screen-instance.server-model";
import {Search} from "@utils";
import {
  CssBuilder,
  DropListComponentDefinition,
  DropListComponentRef
} from "@screen-common";

export class DropListComponentViewModel extends ComponentViewModelWithLabel {

  override typeName = "DropList";

  public notSelected: boolean = true;
  public valueId: string|undefined;
  public valueLabel: string|undefined;
  public valueDefined: boolean = false;
  public tooltip: Option<string> = NoneSingleton;
  public placeholder: string = "";
  public required: boolean = false

  public options!: Array<SelectComponentOption>;
  public filterVisible: boolean = false;

  // Temporary state
  public filter: string = "";
  public visibleOptions!: Array<SelectComponentOption>;
  public activeIndex = 0;

  public outerCss = "";
  public outerCssClasses = "";

  public css = "";
  public cssClasses: string = "";


  constructor(override readonly shared: ScreenSharedViewModel,
              override readonly parent: ScreenContainerViewModel | ScreenWrapperViewModel,
              readonly context: VariableId,
              override readonly definition: DropListComponentDefinition,
              override readonly componentScreenId: string,
              readonly ref: DropListComponentRef,
              override readonly refScreenId: string,
              override readonly componentState: DropListComponentState,
              readonly refState: DropListComponentRefState,
              readonly serverModel: ScreenInstanceServerModel
  ) {
    super(parent, definition, componentState, refState, shared);
    this.update();
  }


  updateComponent(deep: boolean): void {

    const cssBuilder = new CssBuilder();
    const outerCssBuilder = new CssBuilder();

    ComponentViewModelUtils.toPaddingsCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.paddingsProperties, this.componentState.paddingsState);
    ComponentViewModelUtils.toBorderCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.bordersProperties, this.componentState.bordersState);
    ComponentViewModelUtils.toBackgroundCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.backgroundsProperties, this.componentState.backgroundsState);
    ComponentViewModelUtils.toTextCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.textProperties, this.componentState.textState);

    this.placeholder = this.definition.placeholder.currentValue(() => this.componentState.placeholder).valueOrDefault(None()).map(p => p.getCurrentWithFallback()).getOrElse("-- "+i18n("screen_select_select")+" --");

    this.options = [SelectComponentOption.EMPTY(this.placeholder)].concat(this.definition.options.currentValue(() => this.componentState.options).valueOrDefault([]).map((s, index) => SelectComponentOption.of(index, s)));
    this.filterVisible = this.options.length > 5;

    this.prepareValue();
    this.tooltip = this.definition.tooltip.currentValue(() => this.componentState.tooltip).valueOrDefault(None()).map(t => t.getCurrentWithFallback());
    this.required = this.ref.required.currentValue(() => this.refState.required).valueOrDefault(false);

    ComponentViewModelUtils.toOuterShadowCss(outerCssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.bordersProperties, this.componentState.bordersState);
    const innerShadow = this.definition.innerShadow(this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider).currentValue(() => this.componentState.innerShadow).valueOrDefault(None());
    ComponentsCommon.innerShadowCss(cssBuilder, innerShadow);

    this.updateTemporaryState();
    super.updatePosition();

    this.css = cssBuilder.toCss() + this.sizeCss;
    this.cssClasses = cssBuilder.toCssClasses();

    this.outerCss = outerCssBuilder.toCss();
    this.outerCssClasses = outerCssBuilder.toCssClasses();
  }

  updateTemporaryState() {
    this.visibleOptions = Search.filter(this.options, o => o.label, this.filter);
    this.updateActiveIndexToSelected();
  }

  updateActiveIndexToSelected() {
    this.activeIndex = __(this.visibleOptions).findIndexOf(o => o.id == this.valueId).getOrElse(0);
  }

  activeNext(){
    this.activeIndex++;
    if(this.activeIndex >= this.visibleOptions.length){
      this.activeIndex = 0;
    }
  }

  activePrevious(){
    this.activeIndex--;
    if(this.activeIndex < 0){
      this.activeIndex = this.visibleOptions.length - 1;
    }
  }

  selectActive(){
    if(this.visibleOptions.length > this.activeIndex) {
      this.optionSelected(this.visibleOptions[this.activeIndex]);
    }
  }

  filterUpdated() {
    this.updateTemporaryState();
  }

  clearFilter() {
    this.filter = "";
    this.filterUpdated();
  }

  prepareValue(): void {
    const valueOption = this.componentState.model.valueOrDefault(None());
    this.notSelected = valueOption.isEmpty();
    const value = valueOption.getOrUndefined();
    const existing = __(this.options).find(o => o.value == undefined && value == undefined || o.value != undefined && o.value.isEqual(value));

    if(existing.isDefined()) {
      this.valueId = existing.get().id;
      this.valueLabel = existing.get().label;
    } else {
      this.valueId = "-0"; // TODO handle missing option
      this.valueLabel = "???";
    }
    this.valueDefined = valueOption.isDefined();
  }



  optionSelected(option: SelectComponentOption) {
    this.valueId = option.id;
    this.valueLabel = option.label;

    if (option.value === undefined || option.value === null) {
      this.notSelected = true;
      this.componentState.updateModel(DropListComponentDefinition.MODEL, None());
      this.serverModel.clearModelWithAction(this.componentRefPath(), DropListComponentDefinition.MODEL, DropListComponentDefinition.ON_CHANGE);
    } else {
      this.notSelected = false;
      this.componentState.updateModel(DropListComponentDefinition.MODEL, Some(option.value));
      this.serverModel.changeModelWithAction(this.componentRefPath(), DropListComponentDefinition.MODEL, option.value, DropListComponentDefinition.ON_CHANGE);
    }

  }

}
