import {
  ComponentViewModelUtils,
  ComponentViewModelWithLabel,
  ScreenContainerViewModel,
  ScreenInstanceServerModel,
  ScreenSharedViewModel, ScreenWrapperViewModel
} from "../..";
import {__, arrayAppend, clearArray, None, NoneSingleton, Option, Some, Typed, VariableId} from "@utils";
import {MultiCheckboxComponentRefState, MultiCheckboxComponentState} from "./MultiCheckboxComponentState";
import {SelectComponentOption} from "./select-common";
import {ArrayVariable, BusinessVariable} from "@shared-model";
import {
  CssBuilder,
  MultiCheckboxComponentDefinition, MultiCheckboxComponentRef, SelectEntriesLayoutType
} from "@screen-common";

export class MultiCheckboxComponentViewModel extends ComponentViewModelWithLabel {

  override typeName = "MultiCheckbox";


  public value: Array<string> = [];
  public tooltip: Option<string> = NoneSingleton;
  public css: string = "";
  public cssClasses: string = "";
  public required: boolean = false;
  public invalid: boolean = false; //TODO
  public classes = "";

  public iconBackgroundColorCss: string|null = null;

  public options: Array<SelectComponentOption> = [];

  constructor(override readonly shared: ScreenSharedViewModel,
              override readonly parent: ScreenContainerViewModel | ScreenWrapperViewModel,
              readonly context: VariableId,
              override readonly definition: MultiCheckboxComponentDefinition,
              override readonly componentScreenId: string,
              readonly ref: MultiCheckboxComponentRef,
              override readonly refScreenId: string,
              override readonly componentState: MultiCheckboxComponentState,
              readonly refState: MultiCheckboxComponentRefState,
              readonly serverModel: ScreenInstanceServerModel
  ) {
    super(parent, definition, componentState, refState, shared);
    this.update();
  }

  onSelect(changed: SelectComponentOption) {
    if(this.uncoveredAndVisible && !this.disabled) {

      changed.selected = !changed.selected;
      if (changed.selected) {
        this.value.push(changed.id);
      } else {
        this.value.splice(this.value.indexOf(changed.id), 1);
      }

      if (this.value.length === 0) {
        this.componentState.updateModel(MultiCheckboxComponentDefinition.MODEL, None());
        this.serverModel.changeModelWithAction(this.componentRefPath(), MultiCheckboxComponentDefinition.MODEL, new ArrayVariable([]), MultiCheckboxComponentDefinition.ON_CHANGE);
      } else {
        const value: Array<BusinessVariable> = this.value.map(v => __(this.options).find(o => o.id === v).getOrError("No value in checkbox found")).map(v => v.value!);
        this.componentState.updateModel(MultiCheckboxComponentDefinition.MODEL, Some(new ArrayVariable(value.map(v => Typed.of(v)))));
        this.serverModel.changeModelWithAction(this.componentRefPath(), MultiCheckboxComponentDefinition.MODEL, new ArrayVariable(value.map(v => Typed.of(v))), MultiCheckboxComponentDefinition.ON_CHANGE);
      }
    }
  }

  updateComponent(deep: boolean): void {

    const cssBuilder = new CssBuilder();

    ComponentViewModelUtils.toPaddingsCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.paddingsProperties, this.componentState.paddingsState);
    ComponentViewModelUtils.toBorderCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.bordersProperties, this.componentState.bordersState);
    ComponentViewModelUtils.toBackgroundCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.backgroundsProperties, this.componentState.backgroundsState);
    ComponentViewModelUtils.toTextCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.textProperties, this.componentState.textState);

    const newOptions = this.definition.options.currentValue(() => this.componentState.options).valueOrDefault([]).map((s, index) => SelectComponentOption.of(index, s));
    const oldOptions = this.options.slice();
    clearArray(this.options);
    newOptions.forEach(o => {

      const existing = oldOptions.find(oo => oo.value === o.value || oo.value !== undefined && o.value !== undefined && oo.value.isEqual(o.value));

      if(existing) {
        existing.selected = o.selected;
        existing.label = o.label;
        existing.id = o.id;
        this.options.push(existing);
      } else {
        this.options.push(o);
      }
    });


    this.value = this.prepareValue();
    this.tooltip = this.definition.tooltip.currentValue(() => this.componentState.tooltip).valueOrDefault(None()).map(t => t.getCurrentWithFallback());
    this.required = this.ref.required.currentValue(() => this.refState.required).valueOrDefault(false);

    const entriesLayoutCssClass = SelectEntriesLayoutType.of(this.definition.entriesLayout.currentValue(() => this.componentState.entriesLayout).valueOrDefault(SelectEntriesLayoutType.vertical.name)).toCssClass();
    this.classes = (this.customCssClass+" "+entriesLayoutCssClass).trim()+` MultiCheckboxComponent_${this.id}`;

    this.iconBackgroundColorCss = this.definition.iconBackgroundColor(this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider).currentValue(() => this.componentState.iconBackgroundColor).valueOrDefault(None()).getOrNull();

    super.updatePosition();

    this.css = cssBuilder.toString();
    this.cssClasses = cssBuilder.toCssClasses();
  }

  prepareValue(): Array<string> {
    const value = this.componentState.model.valueOrDefault(None()).getOrElse(new ArrayVariable([])).unwrappedValue();
    this.options.forEach(o => o.selected = false);
    const existing = __(this.options).filter(o => __(value).exists(v => v.isEqual(o.value)));
    existing.forEach(o => o.selected = true);
    return existing.map(e => e.id);
  }

}
