<div class="fileSelectorBackgroud">
  <div class="fileSelector">
    <button class="closeButton" (click)="viewModel.close()"><i class="mi-delete"></i></button>
    <div class="fileSelectorTitle">
      {{'documentsRepository_select_from_repository' | i18n}}
    </div>
    <div class="directoryPath">
      <button class="upButton" (click)="viewModel.navigateUpClicked()"><i class="mi-up"></i></button>
      <span class="pathText">{{viewModel.path}}</span>
    </div>
    <div class="directoryContent">
      <div class="directoryEntry" *ngFor="let directory of viewModel.directories">
        <my-checkbox [(checked)]="directory.selected" *ngIf="!viewModel.singleFileMode" />
        <a href="#" onclick="return false;" (click)="viewModel.directoryClicked(directory)">
          <i class="mi-folder"></i>
          <span class="directoryName">{{directory.name}}</span>
        </a>
      </div>
      <div class="fileEntry" *ngFor="let file of viewModel.files">
        <my-checkbox [(checked)]="file.selected" *ngIf="!viewModel.singleFileMode" />
        <a href="#" onclick="return false;" (click)="viewModel.fileClicked(file)">
          <i class="mi-file"></i>
          <span class="fileName">{{file.name}}</span>
        </a>
        <span class="fileSize">{{file.size | myFileSizeFormat}}</span>
      </div>
      <div class="emptyInfo" *ngIf="viewModel.directoryIsEmpty()">
        [ {{'documentsRepository_directory_empty' | i18n}} ]
      </div>
    </div>
    <div class="fileSelectorButtons">
      <button class="cancelButton" (click)="viewModel.close()">Cancel</button>
<!--      <button class="okButton" (click)="viewModel.copy()">Copy documents</button>-->
      <button class="okButton" (click)="viewModel.pin()" *ngIf="!viewModel.singleFileMode">Pin documents</button>
    </div>

  </div>
</div>
