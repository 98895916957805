import {arraysEqualBy, BusinessEntityTypeId, I18nText, None, Option, Typed} from "@utils";

export interface MultiTypeInput {
    className(): string;

    isEmpty(): boolean;
    isEmptyVariable(): boolean;
    searchableText(): string;

    equals(other: MultiTypeInput): boolean;
  }

  export class TextInputType implements MultiTypeInput {
    static className = "TextInputType";
    className(): string {
      return TextInputType.className;
    }

    constructor(readonly value: string) {}

    isEmpty(): boolean {
      return this.value.length === 0;
    }

    isEmptyVariable(): boolean {
      return false;
    }

    static empty() {
      return new TextInputType("");
    }

    static label = "multiTypeInput_text"

    searchableText(): string {
      return this.value;
    }

    static copy(other: TextInputType): TextInputType {
      return new TextInputType(other.value);
    }

    equals(other: MultiTypeInput): boolean {
      return other instanceof TextInputType && other.value === this.value;
    }

    static of(text: string) {
      return new TextInputType(text);
    }
  }


export class NumberInputType implements MultiTypeInput {
  static className = "NumberInputType";
  className(): string {
    return NumberInputType.className;
  }

  constructor(readonly value: Option<number>) {}

  isEmpty(): boolean {
    return this.value.isEmpty();
  }

  isEmptyVariable(): boolean {
    return this.value.isEmpty();
  }

  static empty() {
    return new NumberInputType(None());
  }

  static label = "multiTypeInput_number"

  searchableText(): string {
    return "";
  }

  static copy(other: NumberInputType): NumberInputType {
    return new NumberInputType(Option.copy(other.value));
  }

  equals(other: MultiTypeInput): boolean {
    return other instanceof NumberInputType && other.value.equals(this.value);
  }
}

export class BooleanInputType implements MultiTypeInput {
  static className = "BooleanInputType";
  className(): string {
    return BooleanInputType.className;
  }

  constructor(readonly value: boolean) {}

  isEmpty(): boolean {
    return false;
  }

  isEmptyVariable(): boolean {
    return false;
  }

  static empty() {
    return new BooleanInputType(false);
  }

  static label = "multiTypeInput_boolean"

  searchableText(): string {
    return "";
  }

  static copy(other: BooleanInputType): BooleanInputType {
    return new BooleanInputType(other.value);
  }

  equals(other: MultiTypeInput): boolean {
    return other instanceof BooleanInputType && other.value === this.value;
  }
}


export class IdentifierInputType implements MultiTypeInput {
  static className = "IdentifierInputType";
  className(): string {
    return IdentifierInputType.className;
  }

  constructor(readonly value: string) {}

  isEmpty(): boolean {
    return this.value.length === 0;
  }

  isEmptyVariable(): boolean {
    return false;
  }

  static empty() {
    return new IdentifierInputType("");
  }

  static label = "multiTypeInput_identifier"

  searchableText(): string {
    return this.value;
  }

  static copy(other: IdentifierInputType): IdentifierInputType {
    return new IdentifierInputType(other.value);
  }

  equals(other: MultiTypeInput): boolean {
    return other instanceof IdentifierInputType && other.value === this.value;
  }
}

export class I18nInputType implements MultiTypeInput {
  static className = "I18nInputType";
  className(): string {
    return I18nInputType.className;
  }

  constructor(readonly value: I18nText) {}

  isEmpty(): boolean {
    return this.value.isEmpty();
  }

  isEmptyVariable(): boolean {
    return false;
  }

  static empty() {
    return new I18nInputType(I18nText.empty());
  }

  static label = "multiTypeInput_text"

  searchableText(): string {
    return this.value.getCurrentWithFallback();
  }

  static copy(other: I18nInputType): I18nInputType {
    return new I18nInputType(I18nText.copy(other.value));
  }

  equals(other: MultiTypeInput): boolean {
    return other instanceof I18nInputType && other.value.isEqual(this.value);
  }
}

  export class ScriptInputType implements MultiTypeInput {
    static className = "ScriptInputType";
    className(): string {
      return ScriptInputType.className;
    }

    constructor(readonly value: string) {}

    isEmpty(): boolean {
      return this.value.length === 0;
    }

    isEmptyVariable(): boolean {
      return false;
    }

    static label = "multiTypeInput_expression"

    searchableText(): string {
      return this.value;
    }

    static copy(other: ScriptInputType): ScriptInputType {
      return new ScriptInputType(other.value);
    }

    equals(other: MultiTypeInput): boolean {
      return other instanceof ScriptInputType && other.value === this.value;
    }
  }


export class VariableInputType implements MultiTypeInput {
  static className = "VariableInputType";
  className(): string {
    return VariableInputType.className;
  }

  constructor(readonly value: string) {}

  isEmpty(): boolean {
    return this.value.length === 0;
  }

  isEmptyVariable(): boolean {
    return this.value.trim().length === 0;
  }

  static label = "multiTypeInput_variable"

  static empty = new VariableInputType("");

  searchableText(): string {
    return this.value;
  }
  static copy(other: VariableInputType): VariableInputType {
    return new VariableInputType(other.value);
  }

  equals(other: MultiTypeInput): boolean {
    return other instanceof VariableInputType && other.value === this.value;
  }
}

  export class RoleInputType implements MultiTypeInput {
    static className = "RoleInputType";
    className(): string {
      return RoleInputType.className;
    }

    constructor(readonly value: string) {}

    isEmpty(): boolean {
      return this.value.length === 0;
    }

    isEmptyVariable(): boolean {
      return false;
    }

    static empty() {
      return new RoleInputType("");
    }

    static label = "multiTypeInput_role"

    searchableText(): string {
      return this.value;
    }

    static copy(other: RoleInputType): RoleInputType {
      return new RoleInputType(other.value);
    }

    equals(other: MultiTypeInput): boolean {
      return other instanceof RoleInputType && other.value === this.value;
    }
  }


  export class NamedValuesInputType implements MultiTypeInput {
    static className = "NamedValuesInputType";
    className(): string {
      return NamedValuesInputType.className;
    }

    constructor(readonly value: Array<[string, Typed<MultiTypeInput>]>) {}

    static of(value: Array<[string, MultiTypeInput]>) {
      return new NamedValuesInputType(value.map(v => <[string, Typed<MultiTypeInput>]>[v[0], Typed.of(v[1])]));
    }

    isEmpty(): boolean {
      return this.value.length === 0;
    }

    isEmptyVariable(): boolean {
      return false;
    }

    valueUnwrapped(): Array<[string, MultiTypeInput]> {
      return this.value.map(v => [v[0], Typed.value(v[1])]);
    }

    static empty = new NamedValuesInputType([]);

    static label = "multiTypeInput_named_valued"

    searchableText(): string {
      return this.value.map(v => Typed.value(v[1]).searchableText()).join(" ");
    }

    static copy(other: NamedValuesInputType) {
      return new NamedValuesInputType(other.value.map(v => [v[0], MultiTypeInputFactory.copyTyped(v[1])]));
    }

    equals(other: MultiTypeInput): boolean {
      return other instanceof NamedValuesInputType &&
        arraysEqualBy(other.value, this.value, (a, b) => a[0] === b[0] && Typed.value(a[1]).equals(Typed.value(b[1])));
    }
  }

  export class BusinessEntityTypeInputType implements MultiTypeInput {
    static className = "BusinessEntityTypeInputType";
    className(): string {
      return BusinessEntityTypeInputType.className;
    }

    constructor(readonly id: Option<BusinessEntityTypeId>) {}

    isEmpty(): boolean {
      return this.id.isEmpty();
    }

    isEmptyVariable(): boolean {
      return false;
    }

    static empty = new BusinessEntityTypeInputType(None());

    static label = "multiTypeInput_named_valued"

    searchableText(): string {
      return "";
    }

    static copy(other: BusinessEntityTypeInputType) {
      return new BusinessEntityTypeInputType(Option.copy(other.id, BusinessEntityTypeId.copy));
    }

    equals(other: MultiTypeInput): boolean {
      return other instanceof BusinessEntityTypeInputType && other.id.equals(this.id);
    }
  }

  export class MultiTypeInputFactory {
    static copy(input: MultiTypeInput): MultiTypeInput {
      return MultiTypeInputFactory.copyByType(input, input.className());
    }

    static copyTyped(variable: Typed<MultiTypeInput>): Typed<MultiTypeInput> {
      return Typed.of(MultiTypeInputFactory.copyByType(Typed.value(variable), Typed.className(variable)));
    }

    static copyByType(input: MultiTypeInput, className: string): MultiTypeInput {
      switch (className) {
        case TextInputType.className: return TextInputType.copy(<TextInputType>input);
        case NumberInputType.className: return NumberInputType.copy(<NumberInputType>input);
        case BooleanInputType.className: return BooleanInputType.copy(<BooleanInputType>input);
        case IdentifierInputType.className: return IdentifierInputType.copy(<IdentifierInputType>input);
        case I18nInputType.className: return I18nInputType.copy(<I18nInputType>input);
        case ScriptInputType.className: return ScriptInputType.copy(<ScriptInputType>input);
        case VariableInputType.className: return VariableInputType.copy(<VariableInputType>input);
        case RoleInputType.className: return RoleInputType.copy(<RoleInputType>input);
        case NamedValuesInputType.className: return NamedValuesInputType.copy(<NamedValuesInputType>input);
        case BusinessEntityTypeInputType.className: return BusinessEntityTypeInputType.copy(<BusinessEntityTypeInputType>input);

        default:
          throw new Error("Unsupported variable class: " + className + ", variable: " + JSON.stringify(input));
      }
    }
  }
