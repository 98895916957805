import {Component, EventEmitter, Input, Output, TrackByFunction} from "@angular/core";

export interface DropDownSelectorOption<T = any> {
  name: string;
  nameOnList?: string;
  value: T;
}

@Component({
  selector: 'my-drop-down-selector',
  templateUrl: './drop-down-selector.component.html'
})
export class DropDownSelectorComponent {
  dropDownMenuVisible: boolean = false;
  searchValue: string|undefined;
  searchMode: boolean = false;
  _options: ReadonlyArray<DropDownSelectorOption> = [];
  dropDownMenuOptions: ReadonlyArray<DropDownSelectorOption> = [];

  @Input({required: true}) set options(values: ReadonlyArray<DropDownSelectorOption>) {
    this._options = values;
    this.dropDownMenuOptions = values;
    if(this._value !== undefined) {
      this._selectedOption = this._options.find(option => option.value === this._value);
    }
  };

  private _value: any|undefined;
  @Input() set value(value: any|undefined) {
    this._value = value;
    if(value !== undefined) {
      this._selectedOption = this._options.find(option => option.value === value);
    }
  }
  get value(): any|undefined {
    return this._value;
  }

  private _selectedOption: DropDownSelectorOption|undefined;
  /**
   * @deprecated Use value instead
   */
  @Input() set selectedOption(value: DropDownSelectorOption|undefined) {
    this._selectedOption = value;
    if(value !== undefined) {
      this._value = value.value;
    }
  }
  get selectedOption(): DropDownSelectorOption|undefined {
    return this._selectedOption;
  }
  @Input() disabled: boolean = false;
  @Input() placeholder: string = "";
  @Input() searchable: boolean = false; // TODO
  @Input() minimal: boolean = false;
  @Input() searchPlaceholder: string|undefined;
  @Input() emptyPlaceholder: string|undefined;
  @Input() withIcon: boolean = true;

  /**
   * @deprecated Use valueChange instead
   */
  @Output() selectedOptionChange = new EventEmitter<DropDownSelectorOption>();
  @Output() valueChange = new EventEmitter<any>();

  optionsContentSizeChanged: EventEmitter<void> = new EventEmitter<void>();

  trackByIndex: TrackByFunction<DropDownSelectorOption> = (index: number, option: DropDownSelectorOption) => index;
  @Input() allowUnset: boolean = false;

  toggleDropDownMenuVisible() {
    this.optionsContentSizeChanged.emit();
    this.dropDownMenuVisible = !this.dropDownMenuVisible;
  }

  setValue(option: DropDownSelectorOption|undefined) {
    if(option) {
      this._selectedOption = option;
      this._value = option.value;
      this.valueChange.emit(option.value);
      this.selectedOptionChange.emit(option);
    } else {
      this._selectedOption = undefined;
      this._value = undefined;
      this.valueChange.emit(undefined);
      this.selectedOptionChange.emit(undefined);
    }
    this.toggleDropDownMenuVisible();
  }

  handleSearch() {
    if(this.searchValue && this.searchValue.trim().length > 0) {
      this.searchMode = true;
      this.dropDownMenuOptions = this._options.filter(option => this.searchValue && option.name.toLowerCase().includes(this.searchValue.toLowerCase()));
    } else {
      this.searchMode = false;
      this.dropDownMenuOptions = this._options;
    }
    this.optionsContentSizeChanged.emit();
  }

  clearSearch() {
    this.searchValue = undefined;
    this.handleSearch();
  }

}
