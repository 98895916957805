import {
  __,
  businessEntityDownloadUrl,
  BusinessEntityId,
  documentRepositoryUri,
  FileUri,
  None,
  Option, required,
  Some,
  Typed
} from "@utils";
import {
  ArrayAttributeTypeViewModel,
  ArrayVariable, AttributeTypeViewModel,
  BusinessVariable, BusinessVariableType, BusinessVariableTypeFactory, BusinessVariableTypeName, DepartmentVariable,
  EmailEventBus,
  EmailVariable,
  FileVariableV2, GroupVariable, NamedAttributeViewModel, ObjectAttributeTypeViewModel,
  ObjectVariable, ObjectVariableType, PersonVariable,
  VariablePath
} from "@shared-model";
import {ViewableFile, ViewableFileUrl} from "@shared";
import {EventEmitter} from "@angular/core";
import {BusinessVariableInputServerModel} from "../variables/BusinessVariableInputServerModel";

export class MetadataEntryViewModel {

  public subEntries: Array<MetadataEntryViewModel> = [];

  visibleArraySubEntries: Array<MetadataEntryViewModel> = [];
  visibleArraySubEntryIndex: number = 0;
  collapsed: boolean = false;
  arrayEntriesSingleMode: boolean = true;
  subEntriesCount: number = 0;

  expanded: boolean = true;
  fieldName: string;
  type: BusinessVariableType;
  typeName: string;
  nonEditable: boolean;

  selected: boolean = false;

  constructor(readonly parent: MetadataEntryViewModel|null,
              readonly path: VariablePath,
              readonly index: number,
              fieldName: string,
              readonly codeName: string,
              readonly fieldTypeName: string,
              readonly value: BusinessVariable|null,
              readonly previewValue: string,
              readonly notFromType: boolean,
              readonly incorrectType: boolean,
              readonly dataType: BusinessVariableType|undefined,
              readonly dataTypeName: string,
              readonly expectedType: BusinessVariableType|undefined,
              readonly expectedTypeName: string,
              readonly objectType: boolean,
              readonly arrayType: boolean,
              readonly simpleType: boolean,
              readonly level: number) {

    this.fieldName = fieldName.length > 0 ? fieldName : codeName;
    this.typeName = dataTypeName.length > 0 ? dataTypeName : expectedTypeName;
    this.type = required(dataType ? dataType : (expectedType ? expectedType : undefined), "dataType or expectedType");
    this.nonEditable = codeName.startsWith("_"); // this prevents _id to be edited
  }

  setSubEntries(subEntries: Array<MetadataEntryViewModel>) {
    this.subEntries = subEntries;
    if(subEntries.length > 1 && __(subEntries).exists(e => e.arrayType || e.objectType)) {
      this.arrayEntriesSingleMode = true;
    }

    this.subEntriesCount = this.subEntries.length;

    this.updateVisibleSubEntries();
    this.expanded = this.subEntries.length > 0;
  }

  previousSubEntry() {
    this.visibleArraySubEntryIndex--;
    this.updateVisibleSubEntries();
  }

  nextSubEntry() {
    this.visibleArraySubEntryIndex++;
    this.updateVisibleSubEntries();
  }

  private updateVisibleSubEntries() {
    if(this.arrayType && this.subEntries.length > 0) {
      if(this.arrayEntriesSingleMode) {
        this.visibleArraySubEntryIndex = Math.min(Math.max(0, this.visibleArraySubEntryIndex), this.subEntries.length - 1);
        this.visibleArraySubEntries = this.subEntries.slice(this.visibleArraySubEntryIndex, this.visibleArraySubEntryIndex + 1);
        this.visibleArraySubEntries = __(this.visibleArraySubEntries).sortBy(s => s.codeName);
      } else {
        this.visibleArraySubEntries = this.subEntries;
        this.visibleArraySubEntries = __(this.visibleArraySubEntries).sortBy(s => s.codeName);
      }
    } else if(this.arrayType && this.subEntries.length === 0) {
      this.visibleArraySubEntryIndex = -1;
      this.visibleArraySubEntries = [];
    }
  }

  toggleSingleMode() {
    this.arrayEntriesSingleMode = !this.arrayEntriesSingleMode;
    this.updateVisibleSubEntries();
  }

  toggleExpanded() {
    this.expanded = !this.expanded;
  }

  sort(sortType: "codeName"|"label") {
    if(sortType == "codeName") {
      __(this.subEntries).sortByAlphanumericInPlace(s => s.codeName);
    } else if(sortType == "label") {
      __(this.subEntries).sortByAlphanumericInPlace(s => s.fieldName.length > 0 ? s.fieldName : s.codeName);
    }
    this.updateVisibleSubEntries();

    this.subEntries.forEach(e => e.sort(sortType));
  }
}

export class MetadataFormViewModel {

  public editMode: boolean = false;
  public mainEntry: Array<MetadataEntryViewModel> = [];

  viewableFileIndex = 0;
  fileViewerVisible = false;
  viewableFiles: Array<ViewableFile> = [];

  emailViewerVisible = false;
  viewableEmail: FileUri|null = null;
  emailEventBus: EmailEventBus = new EmailEventBus();


  constructor(readonly businessVariableInputServerModel: BusinessVariableInputServerModel,
              readonly businessEntityId: Option<BusinessEntityId>,
              readonly validateTypes: boolean,
              readonly downloadableFileUri: (fileUri: FileUri) => FileUri,
              readonly change: EventEmitter<{value: BusinessVariable|null, path: VariablePath}>,
              readonly sortMainAttributes: boolean) {}

  init(editMode: boolean, data: BusinessVariable, attributes: Array<NamedAttributeViewModel>) {

    this.editMode = editMode;

    const emptyPath = VariablePath.empty();
    if(data instanceof ObjectVariable) {

      const newEntry = new MetadataEntryViewModel(null, emptyPath, 0, "", "object", "Object", null, "", false, false, BusinessVariableTypeFactory.createFromVariable(data), "Object", BusinessVariableTypeFactory.createFromVariable(data), "Object", true, false, false, 0);
      newEntry.setSubEntries(this.initObjectAttributeEntries(newEntry, emptyPath, data, attributes, true, false));
      this.mainEntry = [newEntry];
    } else if (data instanceof ArrayVariable) {
      const newEntry = new MetadataEntryViewModel(null, emptyPath, 0, "", "array", "Array", null, "", false, false, BusinessVariableTypeFactory.createFromVariable(data), "Array", BusinessVariableTypeFactory.createFromVariable(data), "Array", false, true, false, 0);
      newEntry.setSubEntries(this.initArrayAttributeEntries(newEntry, emptyPath, <ArrayVariable<BusinessVariable>>data, None(), true));
      this.mainEntry = [newEntry];
    } else {
      this.mainEntry = [new MetadataEntryViewModel(null, emptyPath, 0, "", "", data.simpleValueType(), data, data.valueToSimpleString(), false, false, BusinessVariableTypeFactory.createFromVariable(data), data.simpleValueType(), BusinessVariableTypeFactory.createFromVariable(data), data.simpleValueType(), false, false, true, 0)];
    }
  }

  initArrayAttributeEntries(arrayEntry: MetadataEntryViewModel, path: VariablePath, data: ArrayVariable<BusinessVariable>, subType: Option<AttributeTypeViewModel>, validateSubTypes: boolean) {

    const entries = data.unwrappedValue().map((fieldValue, index) => {
      const fieldName = "";
      const codeName = "";

      const isObject = fieldValue instanceof ObjectVariable;
      const isArray = fieldValue instanceof ArrayVariable
      const isSimple = !isObject && !isArray;
      const matchingType = !this.validateTypes || subType.isEmpty() || subType.isDefined() && fieldValue.simpleValueType() === subType.get().getTypeName();

      const entryPath = path.concat(VariablePath.rootIndex(index));

      if(isObject) {
        const objectVariable = <ObjectVariable>fieldValue;
        const variableType = BusinessVariableTypeFactory.createFromVariable(objectVariable);
        const correctType = matchingType;
        const newEntry = new MetadataEntryViewModel(arrayEntry, entryPath, index + 1, fieldName, codeName, "Object", null, "", false, validateSubTypes && !matchingType, variableType, "Object", subType.map(s => s.getType()).getOrUndefined(), subType.map(s => s.getTypeName()).getOrElse("-"), isObject, isArray, isSimple, arrayEntry.level + 1);
        newEntry.setSubEntries(this.initObjectAttributeEntries(newEntry, entryPath, objectVariable, (this.validateTypes && matchingType && subType.isDefined()) ? (<ObjectAttributeTypeViewModel>subType.get()).subAttributes : [], validateSubTypes && correctType, true));
        return newEntry;
      } else if(isArray) {
        const arrayVariable = <ArrayVariable<BusinessVariable>>fieldValue;
        const correctType = matchingType;
        const variableType = BusinessVariableTypeFactory.createFromVariable(arrayVariable);
        const newEntry = new MetadataEntryViewModel(arrayEntry, entryPath, index + 1, fieldName, codeName, "Array", null, "", false, validateSubTypes && !matchingType, variableType, "Array", subType.map(s => s.getType()).getOrUndefined(), subType.map(s => s.getTypeName()).getOrElse("-"), isObject, isArray, isSimple, arrayEntry.level + 1);
        newEntry.setSubEntries(this.initArrayAttributeEntries(newEntry, entryPath, <ArrayVariable<BusinessVariable>>fieldValue, (this.validateTypes && matchingType) ? Some((<ArrayAttributeTypeViewModel>subType.get()).subType) : None(), validateSubTypes && correctType));
        return newEntry;
      } else {
        return new MetadataEntryViewModel(arrayEntry, entryPath, index + 1, fieldName, codeName, fieldValue.simpleValueType(), fieldValue, fieldValue.valueToSimpleString(), false, validateSubTypes && !matchingType, BusinessVariableTypeFactory.createFromVariable(fieldValue), fieldValue.simpleValueType(), subType.map(s => s.getType()).getOrUndefined(), subType.map(s => s.getTypeName()).getOrElse("-"), isObject, isArray,isSimple, arrayEntry.level + 1);
      }

    });




    return entries;
  }

  initObjectAttributeEntries(objectEntry: MetadataEntryViewModel, path: VariablePath, data: ObjectVariable, subAttributes: Array<NamedAttributeViewModel>,
                             validateSubTypes: boolean, sortAttributes: boolean): Array<MetadataEntryViewModel> {

    const sorted = sortAttributes
      ? __(subAttributes).sortBy(a => a.codeName)
      : subAttributes;

    const matchingEntries: Array<MetadataEntryViewModel> = sorted.map(attribute => {

      const fieldName = attribute.name.getCurrentWithFallback();
      const codeName = attribute.codeName;
      const fieldValue: Option<BusinessVariable> = data.valueFor(codeName);

      const hasValue = fieldValue.isDefined();
      const isObject = hasValue && fieldValue.get().simpleValueType() == "Object" || fieldValue.isEmpty() && attribute.isObject();
      const isArray = hasValue && fieldValue.get().simpleValueType() == "Array" || fieldValue.isEmpty() && attribute.isArray();
      const isSimple = !isObject && !isArray;
      const matchingType = !this.validateTypes || fieldValue.isEmpty() || this.valueMatchesAttributeType(fieldValue.get(), attribute);


      const attributePath = path.concat(VariablePath.root(codeName));

      if(isObject) {
        const correctType = matchingType;
        const newEntry = new MetadataEntryViewModel(objectEntry, attributePath, 0, fieldName, codeName, "Object", null, "", false, validateSubTypes && !matchingType, fieldValue.map(BusinessVariableTypeFactory.createFromVariable).getOrUndefined(), fieldValue.map(v => v.simpleValueType()).getOrElse(""), attribute.getVariableType(), attribute.getTypeName(), isObject, isArray, isSimple, objectEntry.level + 1);
        newEntry.setSubEntries(this.initObjectAttributeEntries(newEntry, attributePath, <ObjectVariable>fieldValue.getOrElse(new ObjectVariable({})),
          (this.validateTypes && matchingType) ? (<ObjectAttributeTypeViewModel>attribute.attributeType).subAttributes : [],
          validateSubTypes && correctType, true));
        return newEntry;
      } else if(isArray) {
        const correctType = matchingType;
        const newEntry = new MetadataEntryViewModel(objectEntry, attributePath, 0,  fieldName, codeName, "Array", null, "", false, validateSubTypes && !matchingType, fieldValue.map(BusinessVariableTypeFactory.createFromVariable).getOrUndefined(), fieldValue.map(v => v.simpleValueType()).getOrElse(""), attribute.getVariableType(), attribute.getTypeName(), isObject, isArray, isSimple, objectEntry.level + 1);
        newEntry.setSubEntries(this.initArrayAttributeEntries(newEntry, attributePath, <ArrayVariable<BusinessVariable>>fieldValue.getOrElse(new ArrayVariable([])), (this.validateTypes && matchingType) ? Some((<ArrayAttributeTypeViewModel>attribute.attributeType).subType) : None(), validateSubTypes && correctType));
        return newEntry;
      } else {
        return new MetadataEntryViewModel(objectEntry, attributePath, 0, fieldName, codeName,
          fieldValue.isEmpty() ? "-" : fieldValue.get().simpleValueType(),  fieldValue.getOrNull(),
          hasValue ? fieldValue.get().valueToSimpleString() : "-", false,
          validateSubTypes && !matchingType,
          fieldValue.map(BusinessVariableTypeFactory.createFromVariable).getOrUndefined(),
          fieldValue.map(v => v.simpleValueType()).getOrElse(""),
          attribute.getVariableType(), attribute.getSimpleTypeName(),
          isObject, isArray,isSimple, objectEntry.level + 1);
      }

    });

    const nonMatchingEntries: Array<MetadataEntryViewModel> = __(data.value).flatMap((entry: [string, Typed<BusinessVariable>]) => {

      const attribute = __(sorted).find(a => a.codeName === entry[0]);

      if(attribute.isEmpty()) {

        const fieldName = entry[0];
        const codeName = entry[0];
        const fieldValue: BusinessVariable = Typed.value(entry[1]);

        const isObject = fieldValue.simpleValueType() == "Object";
        const isArray = fieldValue.simpleValueType() == "Array";
        const isSimple = !isObject && !isArray;

        const codeNamePathSuffix = VariablePath.root(codeName);

        if(isObject) {
          const correctType = !this.validateTypes;
          const newEntry = new MetadataEntryViewModel(objectEntry, path.concat(codeNamePathSuffix), 0, fieldName, codeName, "Object", null, "", validateSubTypes && this.validateTypes, false, BusinessVariableTypeFactory.createFromVariable(fieldValue), fieldValue.simpleValueType(), BusinessVariableTypeFactory.createFromVariable(fieldValue), fieldValue.simpleValueType(), isObject, isArray, isSimple, objectEntry.level + 1);
          newEntry.setSubEntries(this.initObjectAttributeEntries(newEntry, path.concat(codeNamePathSuffix), <ObjectVariable>fieldValue, [], validateSubTypes && correctType, true));
          return [newEntry];
        } else if(isArray) {
          const correctType = !this.validateTypes;
          const newEntry = new MetadataEntryViewModel(objectEntry, path.concat(codeNamePathSuffix), 0, fieldName, codeName, "Array", null, "", validateSubTypes && this.validateTypes, false, BusinessVariableTypeFactory.createFromVariable(fieldValue), fieldValue.simpleValueType(), BusinessVariableTypeFactory.createFromVariable(fieldValue), fieldValue.simpleValueType(), isObject, isArray, isSimple, objectEntry.level + 1);
          newEntry.setSubEntries(this.initArrayAttributeEntries(newEntry, path.concat(codeNamePathSuffix), <ArrayVariable<BusinessVariable>>fieldValue, None(), validateSubTypes && correctType));
          return [newEntry];
        } else {
          return [new MetadataEntryViewModel(objectEntry, path.concat(codeNamePathSuffix), 0, fieldName, codeName, fieldValue.simpleValueType(),  fieldValue, fieldValue.valueToSimpleString(), validateSubTypes && this.validateTypes, false, BusinessVariableTypeFactory.createFromVariable(fieldValue), fieldValue.simpleValueType(), BusinessVariableTypeFactory.createFromVariable(fieldValue), fieldValue.simpleValueType(), isObject, isArray,isSimple, objectEntry.level + 1)];
        }

      } else {
        return [];
      }
    });

    return matchingEntries.concat(__(nonMatchingEntries).sortByAlphanumeric(e => e.codeName));
  }

  private valueMatchesAttributeType(fieldValue: BusinessVariable, attribute: NamedAttributeViewModel) {
    if(attribute.isOrganizationNode()) {
      return fieldValue instanceof PersonVariable || fieldValue instanceof GroupVariable || fieldValue instanceof DepartmentVariable;
    } else if(attribute.isObject()) {
      return fieldValue instanceof ObjectVariable;
    } else if(attribute.isArray()) {
      return fieldValue instanceof ArrayVariable;
    } else {
      return fieldValue.simpleValueType() === attribute.getSimpleTypeName();
    }
  }

  entryValueUpdated = (event: {value: BusinessVariable|null, path: VariablePath}) => {
    this.change.emit({value: event.value, path: event.path});
  }

  changeEditMode(editMode: boolean) {
    this.editMode = editMode;
  }

  onCloseFileViewer() {
   this.fileViewerVisible = false;
   this.viewableFiles = [];
  }



  variablePreviewRequested = (value: BusinessVariable) => {

    if(value.className() === FileVariableV2.className) {
      this.businessVariableInputServerModel.loadFilesInfo((<FileVariableV2>value).value, (info) => {
        this.fileViewerVisible = true;
        this.viewableFiles = [new ViewableFile(info.name, info.size, info.version > 1 ? Some(info.version) : None(),
          new ViewableFileUrl(documentRepositoryUri(this.downloadableFileUri(info.uri))),
          false, info.modified, info.uri, false, None(), None(), None(), None(), !info.exists)];
      });
    } else if(value.className() === EmailVariable.className) {
      this.emailViewerVisible = true;
      this.viewableEmail = (<EmailVariable>value).value;
        // this.businessVariableInputServerModel.loadEmailInfo((<EmailVariable>value).value, (emailsInfo: EmailBasicInfo) => {
        // toastr.info("Email info loaded " + JSON.stringify(emailsInfo));

        // this.fileViewerVisible = true;
        // this.viewableFiles = [new ViewableFile(info.name, info.size, info.version > 1 ? Some(info.version) : None(),
        //   new ViewableFileUrl(attachments.businessEntityDownloadUrl(this.businessEntityId.getOrError("No business entity defined"), info.uri)),
        //   false, info.modified, info.uri, false, None(), None(), None(), None(), !info.exists)];
      // });
    } else {
      throw new Error("Only file and email preview supported");
    }
  };

  onEmailCloseRequested() {
    this.emailViewerVisible = false;
    this.viewableEmail = null;
  }

  sort(byName: boolean) {
    if(this.sortMainAttributes) {
      this.mainEntry.forEach(s => {
        byName ? s.sort("label") : s.sort("codeName");
      });
    }
  }

  getSelected(): Array<VariablePath> {
    const acc: Array<VariablePath> = [];
    this.fillSelectedAcc(VariablePath.empty(), this.mainEntry[0], acc);
    return acc;
  }

  private fillSelectedAcc(path: VariablePath, value: MetadataEntryViewModel, acc: Array<VariablePath> = []) {
    if(value.objectType) {
      value.subEntries.forEach(e => this.fillSelectedAcc(path.concat(VariablePath.parse(e.codeName)), e, acc));
    } else if(value.arrayType) {
      value.subEntries.forEach(e => this.fillSelectedAcc(path.concat(VariablePath.rootIndex(e.index - 1)), e, acc));
    } else {
      if(value.selected) {
        acc.push(path);
      }
    }
  }
}
