import {
  AutomaticActionId,
  AutomaticActionRefId,
  BusinessEntityTypeInputType,
  Either,
  i18n,
  MultiTypeInput,
  MultiTypeInputFactory,
  NamedValuesInputType,
  Option,
  removeFromArrayBy,
  Typed,
  VariableInputType
} from "@utils";
import {ApplicationComponentRef} from "@shared";
import {VariablePath} from "@shared-model";
import {VariableContext} from "./screen-properties.model";
export class ActionType {

  constructor(readonly name: string) {}

  static readonly script = new ActionType("script");
  static readonly setValue = new ActionType("setValue");
  static readonly clearValue = new ActionType("clearValue");
  static readonly email = new ActionType("email");
  static readonly notification = new ActionType("notification");
  static readonly sms = new ActionType("sms");

  // Business objects

  static readonly createBusinessObject = new ActionType("createBusinessObject");
  static readonly findBusinessObject = new ActionType("findBusinessObject");
  static readonly updateBusinessObject = new ActionType("updateBusinessObject");
  static readonly deleteBusinessObject = new ActionType("deleteBusinessObject");

}


export class AutomaticActionRefWithSchedule {
    constructor(readonly actionRef: AutomaticActionRef,
                readonly delaySeconds: number,
                readonly intervalSeconds: number) {
    }

    static copy(other: AutomaticActionRefWithSchedule) {
      return new AutomaticActionRefWithSchedule(AutomaticActionRef.copy(other.actionRef), other.delaySeconds, other.intervalSeconds);
    }
  }

  export class AutomaticActionRef {
    constructor(readonly id: AutomaticActionRefId,
                readonly applicationComponent: Option<ApplicationComponentRef>,
                readonly actionId: Either<AutomaticActionId, string>) {
    }

    static copy(other: AutomaticActionRef) {
      return new AutomaticActionRef(AutomaticActionRefId.copy(other.id),
        Option.copy(other.applicationComponent, s => ApplicationComponentRef.copy(s)),
        Either.copy(other.actionId, AutomaticActionId.copy, s => s));
    }

    updateIds(actionRefId: AutomaticActionRefId, actionId: AutomaticActionId): AutomaticActionRef {
      return new AutomaticActionRef(actionRefId,
        Option.copy(this.applicationComponent, s => ApplicationComponentRef.copy(s)),
        Either.copy(this.actionId, id => actionId, s => s));
    }
  }


export class GenericAutomaticActionType {

  constructor(readonly name: string) {}

  static readonly script = new GenericAutomaticActionType("script");
  static readonly setValue = new GenericAutomaticActionType("setValue");
  static readonly clearValue = new GenericAutomaticActionType("clearValue");
  static readonly email = new GenericAutomaticActionType("email");
  static readonly notification = new GenericAutomaticActionType("notification");
  static readonly sms = new GenericAutomaticActionType("sms");
  static readonly existingAction = new GenericAutomaticActionType("existingAction");
}




export class ResultVariablePath {
  constructor(readonly screenContext: VariableContext,
              readonly path: VariablePath) {}

  static copy(other: ResultVariablePath) {
    return new ResultVariablePath(
      VariableContext.copy(other.screenContext),
      VariablePath.copy(other.path)
    );
  }
}



// Must be Immutable
export abstract class AutomaticAction {
  abstract id: AutomaticActionId;
  abstract className(): string;
  abstract async: boolean;
  abstract name: Option<string>;
  abstract identifier: Option<string>;
  abstract conditional: boolean;
  abstract conditionExpression: string;
  abstract saveTo: Array<ResultVariablePath>;
  abstract properties: Array<[string, Typed<MultiTypeInput>]>;
  abstract searchableText(): string;
  abstract updateId(actionId: AutomaticActionId): AutomaticAction;

  static copyProperties(properties: Array<[string, Typed<MultiTypeInput>]>): Array<[string, Typed<MultiTypeInput>]> {
    return properties.map(p => [p[0], MultiTypeInputFactory.copyTyped(p[1])]);
  }

  propertyOrDefault(property: string, defaultProperty: MultiTypeInput) {
    const found = this.properties.find(p => p[0] === property);
    if(found) {
      return Typed.value(found[1]);
    } else {
      return defaultProperty;
    }
  }

  setProperty(name: string, value: MultiTypeInput) {
    removeFromArrayBy(this.properties, e => e[0] === name);
    this.properties.push([name, Typed.of(value)]);
  }
}


export class ScriptAction extends AutomaticAction {

  static className = "ScriptAction";
  className(): string {
    return ScriptAction.className;
  }

  constructor(readonly id: AutomaticActionId,
              readonly async: boolean,
              readonly name: Option<string>,
              readonly identifier: Option<string>,
              readonly conditional: boolean,
              readonly conditionExpression: string,
              readonly script: string,
              readonly saveTo: Array<ResultVariablePath>,
              readonly properties: Array<[string, Typed<MultiTypeInput>]>) {
    super();
  }

  static copy(other: ScriptAction, newId?: AutomaticActionId) {
    return new ScriptAction(
      newId ? newId : AutomaticActionId.copy(other.id),
      other.async,
      Option.copy(other.name),
      Option.copy(other.identifier),
      other.conditional,
      other.conditionExpression,
      other.script,
      other.saveTo.map(ResultVariablePath.copy),
      AutomaticAction.copyProperties(other.properties)
    );
  }

  searchableText() {
    return this.name.getOrElse("")+" "+(this.conditional ? this.conditionExpression : "")+ " "+this.script+" "+this.saveTo.map(s => s.path).join(" ");
  }

  updateId(actionId: AutomaticActionId): ScriptAction {
    return ScriptAction.copy(this, actionId)
  }

}


export class SetValueAction extends AutomaticAction {

  static className = "SetValueAction";
  className(): string {
    return SetValueAction.className;
  }

  constructor(readonly id: AutomaticActionId,
              readonly async: boolean,
              readonly name: Option<string>,
              readonly identifier: Option<string>,
              readonly conditional: boolean,
              readonly conditionExpression: string,
              readonly expression: string,
              readonly saveTo: Array<ResultVariablePath>,
              readonly properties: Array<[string, Typed<MultiTypeInput>]>) {
    super();
  }

  static copy(other: SetValueAction, newId?: AutomaticActionId) {
    return new SetValueAction(
      newId ? newId : AutomaticActionId.copy(other.id),
      other.async,
      Option.copy(other.name),
      Option.copy(other.identifier),
      other.conditional,
      other.conditionExpression,
      other.expression,
      other.saveTo.map(ResultVariablePath.copy),
      AutomaticAction.copyProperties(other.properties)
    );
  }

  value() {
    return this.propertyOrDefault("value", VariableInputType.empty);
  }

  searchableText() {
    return this.name.getOrElse("")+" "+(this.conditional ? this.conditionExpression : "")+ " "+this.expression+" "+this.saveTo.map(s => s.path).join(" ");
  }

  updateId(actionId: AutomaticActionId): SetValueAction {
    return SetValueAction.copy(this, actionId)
  }

  withValue(value: MultiTypeInput) {
    this.setProperty("value", value);
    return this;
  }
}


export class ClearValueAction extends AutomaticAction {

  static className = "ClearValueAction";
  className(): string {
    return ClearValueAction.className;
  }

  constructor(readonly id: AutomaticActionId,
              readonly async: boolean,
              readonly name: Option<string>,
              readonly identifier: Option<string>,
              readonly conditional: boolean,
              readonly conditionExpression: string,
              readonly saveTo: Array<ResultVariablePath>,
              readonly properties: Array<[string, Typed<MultiTypeInput>]>) {
    super();
  }

  static copy(other: ClearValueAction, newId?: AutomaticActionId) {
    return new ClearValueAction(
      newId ? newId : AutomaticActionId.copy(other.id),
      other.async,
      Option.copy(other.name),
      Option.copy(other.identifier),
      other.conditional,
      other.conditionExpression,
      other.saveTo.map(ResultVariablePath.copy),
      AutomaticAction.copyProperties(other.properties)
    );
  }

  searchableText() {
    return this.name.getOrElse("")+" "+(this.conditional ? this.conditionExpression : "")+" "+this.saveTo.map(s => s.path).join(" ");
  }

  updateId(actionId: AutomaticActionId): ClearValueAction {
    return ClearValueAction.copy(this, actionId)
  }
}

export class SendEmailAction extends AutomaticAction {

  static className = "SendEmailAction";
  className(): string {
    return SendEmailAction.className;
  }

  readonly defaultVisibleLabel = i18n("scriptActions_Email");

  constructor(readonly id: AutomaticActionId,
              readonly async: boolean,
              readonly name: Option<string>,
              readonly identifier: Option<string>,
              readonly conditional: boolean,
              readonly conditionExpression: string,
              readonly emailRecipients: Array<Typed<MultiTypeInput>>,
              readonly emailSubject: Typed<MultiTypeInput>,
              readonly emailBody: Typed<MultiTypeInput>,
              readonly emailAttachments: Typed<MultiTypeInput>,
              readonly disableResponse: boolean,
              readonly saveTo: Array<ResultVariablePath>,
              readonly properties: Array<[string, Typed<MultiTypeInput>]>) {
    super();
  }

  static copy(other: SendEmailAction, newId?: AutomaticActionId) {
    return new SendEmailAction(
      newId ? newId : AutomaticActionId.copy(other.id),
      other.async,
      Option.copy(other.name),
      Option.copy(other.identifier),
      other.conditional,
      other.conditionExpression,
      other.emailRecipients.map(MultiTypeInputFactory.copyTyped),
      MultiTypeInputFactory.copyTyped(other.emailSubject),
      MultiTypeInputFactory.copyTyped(other.emailBody),
      MultiTypeInputFactory.copyTyped(other.emailAttachments),
      other.disableResponse,
      other.saveTo.map(ResultVariablePath.copy),
      AutomaticAction.copyProperties(other.properties)
    );
  }

  emailRecipientsUnwrapped(): Array<MultiTypeInput> {
    return this.emailRecipients.map(v => Typed.value(v));
  }
  emailSubjectUnwrapped() {
    return Typed.value(this.emailSubject);
  }
  emailBodyUnwrapped() {
    return Typed.value(this.emailBody);
  }
  emailAttachmentsUnwrapped() {
    return Typed.value(this.emailAttachments);
  }

  searchableText() {
    return this.name.getOrElse("")+" "+(this.conditional ? this.conditionExpression : "")+ " "+
      this.emailRecipientsUnwrapped().map(v => v.searchableText())+" "+this.emailSubjectUnwrapped().searchableText()+" "+
      this.emailBodyUnwrapped().searchableText() + this.emailAttachmentsUnwrapped().searchableText();
  }

  updateId(actionId: AutomaticActionId): SendEmailAction {
    return SendEmailAction.copy(this, actionId)
  }
}


export class SendNotificationAction extends AutomaticAction {

  static className = "SendNotificationAction";
  className(): string {
    return SendNotificationAction.className;
  }

  constructor(readonly id: AutomaticActionId,
              readonly async: boolean,
              readonly name: Option<string>,
              readonly identifier: Option<string>,
              readonly conditional: boolean,
              readonly conditionExpression: string,
              readonly notificationRecipients: Array<Typed<MultiTypeInput>>,
              readonly notificationSubject: Typed<MultiTypeInput>,
              readonly notificationMessage: Typed<MultiTypeInput>,
              readonly saveTo: Array<ResultVariablePath>,
              readonly properties: Array<[string, Typed<MultiTypeInput>]>) {
    super();
  }

  static copy(other: SendNotificationAction, newId?: AutomaticActionId) {
    return new SendNotificationAction(
      newId ? newId : AutomaticActionId.copy(other.id),
      other.async,
      Option.copy(other.name),
      Option.copy(other.identifier),
      other.conditional,
      other.conditionExpression,
      other.notificationRecipients.map(MultiTypeInputFactory.copyTyped),
      MultiTypeInputFactory.copyTyped(other.notificationSubject),
      MultiTypeInputFactory.copyTyped(other.notificationMessage),
      other.saveTo.map(ResultVariablePath.copy),
      AutomaticAction.copyProperties(other.properties)
    );
  }

  notificationRecipientsUnwrapped(): Array<MultiTypeInput> {
    return this.notificationRecipients.map(v => Typed.value(v));
  }
  notificationSubjectUnwrapped() {
    return Typed.value(this.notificationSubject);
  }
  notificationMessageUnwrapped() {
    return Typed.value(this.notificationMessage);
  }

  searchableText() {
    return this.name.getOrElse("")+" "+(this.conditional ? this.conditionExpression : "")+ " "+
      this.notificationRecipientsUnwrapped().map(v => v.searchableText())+" "+this.notificationSubjectUnwrapped().searchableText()+" "+
      this.notificationMessageUnwrapped().searchableText();
  }

  updateId(actionId: AutomaticActionId): SendNotificationAction {
    return SendNotificationAction.copy(this, actionId)
  }

}


export class SendSMSAction extends AutomaticAction {
  static className = "SendSMSAction";
  className(): string {
    return SendSMSAction.className;
  }

  constructor(readonly id: AutomaticActionId,
              readonly async: boolean,
              readonly name: Option<string>,
              readonly identifier: Option<string>,
              readonly conditional: boolean,
              readonly conditionExpression: string,
              readonly phoneNumbers: Array<Typed<MultiTypeInput>>,
              readonly smsMessage: Typed<MultiTypeInput>,
              readonly test: boolean,
              readonly saveTo: Array<ResultVariablePath>,
              readonly properties: Array<[string, Typed<MultiTypeInput>]>) {
    super();
  }


  static copy(other: SendSMSAction, newId?: AutomaticActionId) {
    return new SendSMSAction(
      newId ? newId : AutomaticActionId.copy(other.id),
      other.async,
      Option.copy(other.name),
      Option.copy(other.identifier),
      other.conditional,
      other.conditionExpression,
      other.phoneNumbers.map(MultiTypeInputFactory.copyTyped),
      MultiTypeInputFactory.copyTyped(other.smsMessage),
      other.test,
      other.saveTo.map(ResultVariablePath.copy),
      AutomaticAction.copyProperties(other.properties)
    );
  }


  phoneNumbersUnwrapped(): Array<MultiTypeInput> {
    return this.phoneNumbers.map(v => Typed.value(v));
  }
  smsMessageUnwrapped() {
    return Typed.value(this.smsMessage);
  }

  searchableText() {
    return this.name.getOrElse("")+" "+(this.conditional ? this.conditionExpression : "")+ " "+
      this.phoneNumbersUnwrapped().map(v => v.searchableText())+" "+this.smsMessageUnwrapped().searchableText();
  }

  updateId(actionId: AutomaticActionId): SendSMSAction {
    return SendSMSAction.copy(this, actionId)
  }
}


export class CreateBusinessObjectAction extends AutomaticAction {

  static className = "CreateBusinessObjectAction";
  className(): string {
    return CreateBusinessObjectAction.className;
  }

  constructor(readonly id: AutomaticActionId,
              readonly async: boolean,
              readonly name: Option<string>,
              readonly identifier: Option<string>,
              readonly conditional: boolean,
              readonly conditionExpression: string,
              readonly saveTo: Array<ResultVariablePath>,
              readonly properties: Array<[string, Typed<MultiTypeInput>]>) {
    super();
  }

  static copy(other: CreateBusinessObjectAction, newId?: AutomaticActionId) {

    return new CreateBusinessObjectAction(
      newId ? newId : AutomaticActionId.copy(other.id),
      other.async,
      Option.copy(other.name),
      Option.copy(other.identifier),
      other.conditional,
      other.conditionExpression,
      other.saveTo.map(ResultVariablePath.copy),
      AutomaticAction.copyProperties(other.properties)
    );
  }

  searchableText() {
    return this.name.getOrElse("")+" "+(this.conditional ? this.conditionExpression : "")+ " "+this.saveTo.map(s => s.path).join(" ")+" "+this.initialValue().searchableText();
  }

  updateId(actionId: AutomaticActionId): CreateBusinessObjectAction {
    return CreateBusinessObjectAction.copy(this, actionId)
  }

  entityType() {
    return this.propertyOrDefault("entityType", BusinessEntityTypeInputType.empty);
  }

  initialValue() {
    return this.propertyOrDefault("initialValue", NamedValuesInputType.empty);
  }

  withEntityType(entityType: MultiTypeInput) {
    this.setProperty("entityType", entityType);
    return this;
  }

  withInitialValue(value: MultiTypeInput) {
    this.setProperty("initialValue", value);
    return this;
  }
}

export class UpdateBusinessObjectAction extends AutomaticAction {

  static className = "UpdateBusinessObjectAction";
  className(): string {
    return UpdateBusinessObjectAction.className;
  }

  constructor(readonly id: AutomaticActionId,
              readonly async: boolean,
              readonly name: Option<string>,
              readonly identifier: Option<string>,
              readonly conditional: boolean,
              readonly conditionExpression: string,
              readonly saveTo: Array<ResultVariablePath>,
              readonly properties: Array<[string, Typed<MultiTypeInput>]>) {
    super();
  }

  entity() {
    return this.propertyOrDefault("entity", VariableInputType.empty);
  }

  value() {
    return this.propertyOrDefault("value", NamedValuesInputType.empty);
  }

  static copy(other: UpdateBusinessObjectAction, newId?: AutomaticActionId) {

    return new UpdateBusinessObjectAction(
      newId ? newId : AutomaticActionId.copy(other.id),
      other.async,
      Option.copy(other.name),
      Option.copy(other.identifier),
      other.conditional,
      other.conditionExpression,
      other.saveTo.map(ResultVariablePath.copy),
      AutomaticAction.copyProperties(other.properties)
    );
  }

  searchableText() {
    return this.name.getOrElse("")+" "+(this.conditional ? this.conditionExpression : "")+ " "+this.saveTo.map(s => s.path).join(" ")+" "+this.value().searchableText()+" "+this.entity().searchableText();
  }

  updateId(actionId: AutomaticActionId): UpdateBusinessObjectAction {
    return UpdateBusinessObjectAction.copy(this, actionId)
  }

  withEntity(entity: MultiTypeInput) {
    this.setProperty("entity", entity);
    return this;
  }

  withValue(value: MultiTypeInput) {
    this.setProperty("value", value);
    return this;
  }
}

export class DeleteBusinessObjectAction extends AutomaticAction {

  static className = "DeleteBusinessObjectAction";
  className(): string {
    return DeleteBusinessObjectAction.className;
  }

  constructor(readonly id: AutomaticActionId,
              readonly async: boolean,
              readonly name: Option<string>,
              readonly identifier: Option<string>,
              readonly conditional: boolean,
              readonly conditionExpression: string,
              readonly saveTo: Array<ResultVariablePath>,
              readonly properties: Array<[string, Typed<MultiTypeInput>]>) {
    super();
  }

  entity() {
    return this.propertyOrDefault("entity", VariableInputType.empty);
  }

  static copy(other: DeleteBusinessObjectAction, newId?: AutomaticActionId) {

    return new DeleteBusinessObjectAction(
      newId ? newId : AutomaticActionId.copy(other.id),
      other.async,
      Option.copy(other.name),
      Option.copy(other.identifier),
      other.conditional,
      other.conditionExpression,
      other.saveTo.map(ResultVariablePath.copy),
      AutomaticAction.copyProperties(other.properties)
    );
  }

  searchableText() {
    return this.name.getOrElse("")+" "+(this.conditional ? this.conditionExpression : "")+ " "+this.saveTo.map(s => s.path).join(" ")+" "+this.entity().searchableText();
  }

  updateId(actionId: AutomaticActionId): DeleteBusinessObjectAction {
    return DeleteBusinessObjectAction.copy(this, actionId)
  }

  withEntity(entity: MultiTypeInput) {
    this.setProperty("entity", entity);
    return this;
  }
}


export class AutomaticActionFactory {
  static copy(input: AutomaticAction): AutomaticAction {
    return AutomaticActionFactory.copyByType(input, input.className());
  }

  static copyTyped(action: Typed<AutomaticAction>): Typed<AutomaticAction> {
    return Typed.of(AutomaticActionFactory.copyByType(Typed.value(action), Typed.className(action)));
  }

  static copyByType(action: AutomaticAction, className: string): AutomaticAction {
    switch (className) {
      case ScriptAction.className: return ScriptAction.copy(<ScriptAction>action);
      case SetValueAction.className: return SetValueAction.copy(<SetValueAction>action);
      case ClearValueAction.className: return ClearValueAction.copy(<ClearValueAction>action);
      case SendEmailAction.className: return SendEmailAction.copy(<SendEmailAction>action);
      case SendNotificationAction.className: return SendNotificationAction.copy(<SendNotificationAction>action);
      case SendSMSAction.className: return SendSMSAction.copy(<SendSMSAction>action);
      case CreateBusinessObjectAction.className: return CreateBusinessObjectAction.copy(<CreateBusinessObjectAction>action);
      case UpdateBusinessObjectAction.className: return UpdateBusinessObjectAction.copy(<UpdateBusinessObjectAction>action);
      case DeleteBusinessObjectAction.className: return DeleteBusinessObjectAction.copy(<DeleteBusinessObjectAction>action);
      default: throw new Error("Unsupported action class: " + className + ", action: " + JSON.stringify(action));
    }
  }
}

