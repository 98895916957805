<select class="operationType" [(ngModel)]="conditionRule.operationType" (ngModelChange)="operationTypeChanged($event)">
  <option [ngValue]="'rule'">{{'process_condition_condition' | i18n}}</option>
  <option [ngValue]="'expression'">{{'process_condition_expression' | i18n}}</option>
  <option [ngValue]="'always'">{{'process_condition_always' | i18n}}</option>
  <option [ngValue]="'never'">{{'process_condition_never' | i18n}}</option>
</select>

<ng-container *ngIf="conditionRule.operationType == 'rule'">
  <my-logic-rule [rule]="conditionRule.condition"
                 [suggestionsProvider]="suggestionsProvider"
                 [logicSuggestionsProvider]="logicSuggestionsProvider" [viewModel]="viewModel" [nodeId]="nodeId" [edgeId]="edgeId" [parentIndexPath]="parentIndexPath" />
</ng-container>
<my-expression-editor *ngIf="conditionRule.operationType == 'expression'"
                      [suggestionsProvider]="suggestionsProvider"
                      [(expression)]="conditionRule.expression" (expressionChangeOnBlur)="operationExpressionChanged($event)"/>
