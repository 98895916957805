import {toFixedLengthString, toNineDigits, toTwoDigits} from "../Strings";
import {Failure, Success, Try} from "./Try";
import {i18n} from "../I18n";
import {daysInMonth} from "../Dates";
import {global} from "../global";


function toDayLightSavingAwareTime(dateTime: LocalDateTime): {format: (name: string) => string} {
  throw new Error("Not implemented");
  // return moment(dateTime.asMillis()).tz(TimezoneManager.getTimezone());
}

export class Duration {
  static ZERO: Duration = new Duration(0,0);
  constructor(readonly seconds: number, readonly nanos: number) {}

  static copy(other: Duration) {
    return new Duration(other.seconds, other.nanos);
  }

  isEqual(other: Duration) {
    return this.seconds === other.seconds && this.nanos === other.nanos;
  }

  compare(other: Duration) {
    if(this.seconds > other.seconds) {
      return 1;
    } else if(this.seconds < other.seconds) {
      return -1;
    } else if(this.nanos > other.nanos) {
      return 1;
    } else if (this.nanos < other.nanos) {
      return -1;
    } else {
      return 0;
    }
  }

  toMilliseconds(): number {
    return this.seconds * 1000 + Math.round(this.nanos / 1000000);
  }

  static ofHoursMinutesSeconds(hours: number, minutes: number, seconds: number) {
    return new Duration(hours * 3600 + minutes * 60 + seconds, 0);
  }

  static ofMilliseconds(milliseconds: number) {
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    seconds = seconds % 60;
    minutes = minutes % 60;
    return this.ofHoursMinutesSeconds(hours, minutes, seconds);
  }

  format(daysMarker:string, hoursMarker:ReadonlyArray<string>, minutesMarker:string, secondsMarker:string, separator:string, hoursInDay:number) {
    const formatted: Array<string> = [];
    const pad = (n: number) => n < 10 ? '0' + n : n.toString();
    const days = Math.floor(this.seconds / (3600 * hoursInDay));
    const hours = Math.floor(this.seconds % (3600 * hoursInDay) / 3600);
    const minutes = Math.floor(this.seconds % 3600 / 60);
    const seconds = Math.floor(this.seconds % 60);

    if(days > 0) { formatted.push(days + daysMarker); }
    if(hours > 0) { formatted.push(hours + hoursMarker[0]); }
    if(minutes > 0) { formatted.push(pad(minutes) + minutesMarker); }
    if(seconds > 0) { formatted.push(pad(seconds) + secondsMarker); }

    return (formatted.length === 0) ? '0' + minutesMarker : formatted.join(separator).trim();
  }

  formatted24() {
    let out = "";

    const hours = Math.floor(this.seconds / 3600);
    const minutes = Math.floor((this.seconds - hours * 3600) / 60);
    const seconds = Math.floor(this.seconds % 60);
    if(hours > 0) {
      out += hours + "h";
    }
    if(minutes > 0) {
      out += " " + minutes + "m";
    }
    if(seconds > 0) {
      out += " " + seconds + "s";
    }
    if(out.length == 0) {
      return "0m";
    } else {
      return out.trim();
    }
  }

  getFullHours() {
    return Math.floor(this.seconds / 3600);
  }

  getFullMinutes() {
    return Math.floor(this.seconds / 60);
  }
}

export class LocalTime {
  static MIDNIGHT: LocalTime = new LocalTime(0,0,0,0);
  static SECOND_AFTER_MIDNIGHT: LocalTime = new LocalTime(0,0,1,1);
  static MAX: LocalTime = new LocalTime(23,59,59,999999);

  constructor(readonly hour: number, readonly minute: number, readonly second: number, readonly nano: number) {}

  static copy(other: LocalTime) {
    return new LocalTime(other.hour, other.minute, other.second, other.nano);
  }
  formatted(): string {
    return toTwoDigits(this.hour)+":"+toTwoDigits(this.minute)+(
      (this.second > 0 || this.nano > 0)
        ? (":"+ toTwoDigits(this.second) + (this.nano > 0 ? ("."+toNineDigits(this.nano)) : ""))
        : ("")
    );
  }

  formattedToMinutes(): string {
    return toFixedLengthString(this.hour, 2)+":"+toFixedLengthString(this.minute, 2);
  }

  sortable(): string {
    return toFixedLengthString(this.hour, 2)+":"+toFixedLengthString(this.minute, 2)+":"+toFixedLengthString(this.second, 2)+"."+toFixedLengthString(this.nano, 6);
  }

  static fromDate(date: Date): LocalTime {
    return new LocalTime(date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds(), 0);
  }

  static fromDateLocal(date: Date): LocalTime {
    return new LocalTime(date.getHours(), date.getMinutes(), date.getSeconds(), 0);
  }

  static parseOrZero(input: string) {
    const result = parseInt(input);
    if (!result) {
      return 0;
    } else {
      return result;
    }
  }

  static now(){
    return LocalTime.fromDateLocal(new Date());
  }

  static of(timeString: string|undefined): Try<LocalTime> {
    if(timeString) {
      const trimmed = timeString.trim();
      if(trimmed.match(/[0-9]{1,2}:[0-9]{2}/g) || trimmed.match(/[0-9]{1,2}:[0-9]{2}:[0-9]{2}/g) || trimmed.match(/[0-9]{1,2}:[0-9]{2}:[0-9]{2}:[0-9]{1-6}/g)) {
        const parts = timeString.trim().split(':');
        const hour = LocalTime.parseOrZero(parts[0]);
        const minute = LocalTime.parseOrZero(parts[1]);
        const second = LocalTime.parseOrZero(parts[2]);
        const nano = LocalTime.parseOrZero(parts[3]);

        if(hour >= 0 && hour <= 23 && minute >= 0 && minute <= 59 && second >= 0 && second <= 59 && nano >= 0 && nano < 1000000) {
          return Success(new LocalTime(hour, minute, second, nano));
        } else {
          return Failure("Incorrect time format");
        }

      } else {
        return Failure("Incorrect time format");
      }
    } else {
      return Failure("Incorrect time format");
    }
  }


  static ofMinutes(timeString: string): LocalTime {
    const parts = timeString.split(':');
    return new LocalTime(LocalTime.parseOrZero(parts[0]), LocalTime.parseOrZero(parts[1]), 0, 0);
  }

  isValid(): boolean {
    return (this.hour !== null && this.minute !== null && this.second !== null && this.nano !== null)
      && !(isNaN(this.hour) || isNaN(this.minute) || isNaN(this.second) || isNaN(this.nano));
  }

  isEqual(other: LocalTime) {
    return this.hour === other.hour &&
      this.minute === other.minute &&
      this.second === other.second &&
      this.nano === other.nano;
  }

  isNotEqual(other: LocalTime) {
    return !this.isEqual(other);
  }
  addMinutes(n: number): LocalTime {
    let totalMinutes = this.minute + n;

    let hoursFromMinutesOverflow = 0;

    if(totalMinutes >= 60) {
      hoursFromMinutesOverflow = Math.floor(totalMinutes / 60);
      totalMinutes %= 60;
    }

    while(totalMinutes < 0) {
      totalMinutes += 60;
      hoursFromMinutesOverflow--;
    }

    while(this.hour + hoursFromMinutesOverflow < 0) {
      hoursFromMinutesOverflow += 24;
    }

    while(this.hour + hoursFromMinutesOverflow > 23) {
      hoursFromMinutesOverflow -= 24;
    }


    return new LocalTime(this.hour + hoursFromMinutesOverflow, totalMinutes, this.second, this.nano);
  }


  isBefore(other: LocalTime): boolean {
    return this.hour < other.hour ||
      this.hour === other.hour && this.minute < other.minute ||
      this.hour === other.hour && this.minute === other.minute && this.second < other.second ||
      this.hour === other.hour && this.minute === other.minute && this.second === other.second && this.nano < other.nano;
  }

  isAfter(other: LocalTime): boolean {
    return this.hour > other.hour ||
      this.hour === other.hour && this.minute > other.minute ||
      this.hour === other.hour && this.minute === other.minute && this.second > other.second ||
      this.hour === other.hour && this.minute === other.minute && this.second === other.second && this.nano > other.nano;
  }

  cacheKey(): number {
    return this.hour*3600+this.minute*60+this.second;
  }

  isZero() {
    return this.hour === 0 && this.minute === 0 && this.second === 0 && this.nano === 0;
  }
}

export class LocalDate {
  static ZERO: LocalDate = new LocalDate(0,1,1);

  private static millisCache: {[key:number]: number} = {};

  constructor(readonly year: number,
              /**Counted from 1*/ readonly month: number,
              readonly day: number) {}

  static copy(other: LocalDate) {
    return new LocalDate(other.year, other.month, other.day);
  }

  cacheKey(): number {
    return this.year*384 + this.month * 32 + this.day;
  }


  asMillisBeginningOfDay(): number {
    const cacheKey = this.cacheKey();
    let fromCache = LocalDate.millisCache[cacheKey];
    if(!fromCache) {
      fromCache = Date.UTC(this.year, this.month - 1, this.day, 0, 0, 0, 0);
      LocalDate.millisCache[cacheKey] = fromCache;
    }
    return fromCache;
  }

  static of(dateString: string|undefined): Try<LocalDate> {
    if(dateString) {
      const parts = dateString.split('-');
      const valid = parts.length === 3 && parts.filter(part => isNaN(parseInt(part))).length === 0;
      if (valid) {
        try {
          const year = parts[2].length == 4 ? parseInt(parts[2]) : parseInt(parts[0]);
          const month = parseInt(parts[1]);
          const day = parts[0].length == 2 ? parseInt(parts[0]) : parseInt(parts[2]);
          if(month > 0 && month <= 12 && day > 0 && day <= 31) {
            return Success(new LocalDate(year, month, day));
          } else {
            return Failure("Invalid date [" + dateString + "]");
          }
        } catch (e) {
          if (typeof e === "string") {
            return Failure(e)
          } else if (e instanceof Error) {
            return Failure(e.message);
          } else {
            return Failure("Unknown error while using 'of' with parameter " + dateString)
          }

        }
      } else {
        return Failure("Invalid date string [" + dateString + "]");
      }
    } else {
      return Failure("Date is undefined");
    }
  }

  static fromDate(date: Date): LocalDate {
    return new LocalDate(date.getUTCFullYear(), date.getUTCMonth() + 1, date.getUTCDate());
  }

  static fromLocalDate(date: Date): LocalDate {
    return new LocalDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
  }


  isEqual(other: LocalDate) {
    return this.year === other.year &&
      this.month === other.month &&
      this.day === other.day;
  }

  nonEqual(other: LocalDate) {
    return !this.isEqual(other);
  }

  /**
   * 0 is sunday
   */
  weekDay(): number {
    return this.toDate().getDay() % 7; // 0 is sunday in JS, 7
  }

  /**
   * 7 is sunday
   */
  weekDaySunday7(): number {
    const weekDay = this.toDate().getDay() % 7;
    if(weekDay === 0) { // 0 is sunday in JS, 7
      return 7;
    } else {
      return weekDay;
    }
  }

  shortDayOfWeekName(): string {
    return i18n("date_weekday_short_"+this.toDate().getDay()); // 0 is sunday in JS, 7
  }

  formatted(): string {
    return this.formattedWithoutYear()+"-"+toFixedLengthString(this.year, 4);
  }

  isoFormatted(): string {
    return toFixedLengthString(this.year, 4) +"-"+toFixedLengthString(this.month, 2)+"-"+toFixedLengthString(this.day, 2);
  }

  /** 01 mar 2022*/
  formattedShortWords(): string {
    return this.day + " " + i18n("date_month_short_part_"+this.month) + " " + toFixedLengthString(this.year, 4);
  }

  formattedWords(): string {
    return this.day + " " + i18n("date_month_part_"+this.month) + " " + toFixedLengthString(this.year, 4);
  }

  formattedFromNow(): string {
    const now = LocalDate.nowDate();
    if (this.isEqual(now)) {
      return i18n("common_days_today");
    } else if (this.isEqual(now.plusDays(1))) {
      return i18n("common_days_tomorrow");
    } else if (this.isEqual(now.minusDays(1))) {
      return i18n("common_days_yesterday");
    } else if (this.isEqual(now.plusDays(2))) {
      return i18n("common_day_after_tomorrow");
    } else if (this.isEqual(now.plusDays(7))) {
      return i18n("common_in_a_week");
    } else if (this.isEqual(now.plusDays(14))) {
      return i18n("common_days_in_two_weeks");
    } else if (this.isEqual(now.plusDays(28))) {
      return i18n("common_days_in_four_weeks");
    } else {
      return this.day + " " + i18n("date_month_short_part_" + this.month) + " " + toFixedLengthString(this.year, 4);
    }
  }

  formattedWordsDayMonthOnly(): string {
    return this.day + " " + i18n("date_month_part_"+this.month);
  }

  formattedShortWordsWithoutYear(): string {
    return this.day + " " + i18n("date_month_short_part_"+this.month);
  }

  formattedWordsWithoutYear(): string {
    return this.day + " " + i18n("date_month_part_"+this.month);
  }

  formattedWithoutYear(): string {
    return toFixedLengthString(this.day, 2)+"-"+toFixedLengthString(this.month, 2);
  }

  formattedWithoutDay(): string {
    return toFixedLengthString(this.month, 2)+"-"+ toFixedLengthString(this.year, 4);
  }

  sortable(): string {
    return toFixedLengthString(this.year, 4)+"-"+toFixedLengthString(this.month, 2)+"-"+toFixedLengthString(this.day, 2);
  }

  isValid(): boolean {
    return (this.year !== null && this.month !== null && this.day !== null) && !(isNaN(this.year) || isNaN(this.month) || isNaN(this.day));
  }

  isBeforeEqual(other: LocalDate): boolean {
    return !this.isAfter(other);
  }

  isBefore(other: LocalDate): boolean {
    return this.year < other.year || this.year === other.year && this.month < other.month ||
      this.year === other.year && this.month === other.month && this.day < other.day;
  }

  isAfterEqual(other: LocalDate): boolean {
    return !this.isBefore(other);
  }

  isAfter(other: LocalDate): boolean {
    return this.year > other.year || this.year === other.year && this.month > other.month ||
      this.year === other.year && this.month === other.month && this.day > other.day;
  }

  toDate() {
    return new Date(Date.UTC(this.year, this.month - 1, this.day));
  }

  plusDays(days: number): LocalDate {
    const date = this.toDate();
    date.setUTCDate(this.day + days);
    return LocalDate.fromDate(date);
  }

  minusDays(days: number): LocalDate {
    return this.plusDays(-days);
  }

  plusWeeks(weeks: number): LocalDate {
    return this.plusDays(weeks * 7);
  }

  minusWeeks(weeks: number): LocalDate {
    return this.minusDays(weeks * 7);
  }

  plusMonths(months: number): LocalDate {
    const date = this.toDate();
    const day = date.getUTCDate();
    date.setDate(1);
    date.setUTCMonth(date.getUTCMonth() + months);
    date.setDate(Math.min(day, daysInMonth(LocalDate.fromDate(date))));
    return LocalDate.fromDate(date);
  }

  minusMonths(months: number): LocalDate {
    return this.plusMonths(-months);
  }

  plusQuarters(quarters: number): LocalDate {
    return this.plusMonths(quarters * 3);
  }

  minusQuarters(quarters: number): LocalDate {
    return this.plusQuarters(-quarters);
  }

  plusYears(years: number): LocalDate {
    const date = this.toDate();
    date.setUTCFullYear(date.getUTCFullYear() + years);
    return LocalDate.fromDate(date);
  }

  minusYears(years: number): LocalDate {
    return this.plusYears(-years);
  }

  daysBetween(localDate: LocalDate): number {
    return this.daysBetweenRec(this, localDate, 0);
  }

  private daysBetweenRec(localDateFrom: LocalDate, localDateTo: LocalDate, counter: number): number {
    if(localDateFrom.isBefore(localDateTo)) {
      return this.daysBetweenRec(localDateFrom.plusDays(1), localDateTo, counter + 1);
    } else if(localDateFrom.isAfter(localDateTo)) {
      return this.daysBetweenRec(localDateFrom.plusDays(-1), localDateTo, counter - 1);
    } else {
      return counter;
    }
  }

  /**
   * 0 is Sunday
   */
  static getDayOfWeek(date: LocalDate) {
    return date.toDate().getDay();
  }

  static getWeekNumber(date: LocalDate) {
    const weekDay = (date.weekDay() - date.dayOfYear() % 7 + 7) % 7;
    return Math.ceil((date.dayOfYear() - weekDay) / 7);
  }

  static daysInMonth(date: LocalDate) {
    return new Date(date.year, date.month, 0).getDate();
  }

  static formatSpan(from: LocalDate, to: LocalDate): string {

    if(from.isEqual(to)) {
      return from.day+" "+i18n("date_month_part_"+from.month)+" "+from.year;
    } if(from.year === to.year && from.month === to.month) {
      return from.day+" - "+to.day+" "+i18n("date_month_part_"+from.month)+" "+from.year;
    } else if(from.year === to.year) {
      return from.day+" "+i18n("date_month_part_"+from.month)+" - "+to.day+" "+i18n("date_month_part_"+to.month)+" "+from.year;
    } else {
      return from.day+" "+i18n("date_month_part_"+from.month)+" "+from.year+" - "+to.day+" "+i18n("date_month_part_"+to.month)+" "+to.year;
    }

  }


  static nowDate(): LocalDate {
    return LocalDateTime.now().date;
  }

  static localNowDate(): LocalDate {
    return LocalDateTime.localNow().date;
  }

  dayOfYear() {
    return Math.floor((this.toDate().getTime() - new Date(this.year, 0, 0).getTime()) / 1000 / 60 / 60 / 24);
  }
}


class FormatCacheEntry {
  constructor(readonly timestamp: number, readonly formatted: string) {}
}

export class TimezonedLocalDateTime {
  constructor(readonly date: LocalDate,
              readonly time: LocalTime,
              readonly timezoneOffeset: string) {}

  /** Without adjusting to local time zone, 12-09-2018 12:34 */
  formattedToMinutes(): string {
    return this.date.formatted()+" "+this.time.formattedToMinutes();
  }

  /** Without adjusting to local time zone, 12-09-2018 12:34 */
  isoSimpleFormattedToMinutes(): string {
    return this.date.isoFormatted()+" "+this.time.formattedToMinutes();
  }

  toDate() {
    const isoText = this.date.year+"-"+toTwoDigits(this.date.month)+"-"+toTwoDigits(this.date.day)+"T"+toTwoDigits(this.time.hour)+":"+toTwoDigits(this.time.minute)+":"+ toTwoDigits(this.time.second)+"."+this.time.nano+this.timezoneOffeset;
    return new Date(isoText);
  }

  isEqual(lastValue: TimezonedLocalDateTime) {
    return this.date.isEqual(lastValue.date) && this.time.isEqual(lastValue.time);
  }
}

export class LocalDateTime {

  constructor(readonly date: LocalDate, readonly time: LocalTime) {}

  static ZERO = new LocalDateTime(new LocalDate(0, 1, 1), LocalTime.MIDNIGHT);

  private static millisCache: {[key:number]: number} = {};
  private static timezonedCache: {[key:number]: LocalDateTime} = {};
  private static formattedCache: {[key:number]: FormatCacheEntry} = {};

  static copy(other: LocalDateTime) {
    return new LocalDateTime(LocalDate.copy(other.date), LocalTime.copy(other.time));
  }

  private cacheKey(): number {
    return this.date.cacheKey()*86400+this.time.cacheKey();
  }


  /** Without adjusting to local time zone, 2018-12-09 12:34 */
  static ofUTC(dateTimeString: string|undefined, dateTimeSeparator: string = " "): Try<LocalDateTime> {
    if(dateTimeString) {
      const parts = dateTimeString.trim().split(dateTimeSeparator);
      if (parts.length === 2) {
        try {
          return Success(new LocalDateTime(LocalDate.of(parts[0]).result, LocalTime.of(parts[1]).result));
        } catch (e) {
          return Failure(new Error(e+""));
        }
      } else {
        return Failure("Incorrect date time string [" + dateTimeString + "]");
      }
    } else {
      return Failure("Date time is undefined")
    }
  }

  static compare(a: LocalDateTime, b: LocalDateTime): number {
    return a.isBefore(b) ? -1 : (a.isAfter(b) ? 1 : 0);
  }

  isEqual(other: LocalDateTime) {
    if(other) {
      return this.date.isEqual(other.date) && this.time.isEqual(other.time);
    } else {
      return false;
    }
  }

  isValid(): boolean {
    return this.date.isValid() && this.time.isValid();
  }

  /** Without adjusting to local time zone, 12-09-2018 12:34 */
  formattedToMinutes(): string {
    return this.date.formatted()+" "+this.time.formattedToMinutes();
  }

  /** Without adjusting to local time zone, 12-09-2018 12:34 */
  isoSimpleFormattedToMinutes(): string {
    return this.date.isoFormatted()+" "+this.time.formattedToMinutes();
  }

  /** Without adjusting to local time zone, 12-09-2018 12:34 */
  isoSimpleFormatted(): string {
    return this.date.isoFormatted()+" "+this.time.formatted();
  }

  formattedWords() {
    return this.date.formattedWords() + " " + this.time.formattedToMinutes();
  }

  formattedShortWords() {
    return this.date.formattedShortWords() + " " + this.time.formattedToMinutes();
  }

  formattedFromNow(): string {

    const units: {[key: string]: number} = {
      "year"  : 24 * 60 * 60 * 1000 * 365,
      "month" : 24 * 60 * 60 * 1000 * 365/12,
      "day"   : 24 * 60 * 60 * 1000,
      "hour"  : 60 * 60 * 1000,
      "minute": 60 * 1000,
      "second": 1000
    };

    const elapsed = this.asMillis() - Date.now();

    const locale = global.i18nService.currentLocale();

    for (let u in units) {
      if (Math.abs(elapsed) > units[u] || u == 'second') {
        return new Intl.RelativeTimeFormat(locale, {numeric: "auto"}).format(Math.round(elapsed / units[u]), <any>u);
      }
    }

    return "Unit not found";


  }

  asUTCDate(): Date {
    return new Date(Date.UTC(this.date.year, this.date.month-1, this.date.day, this.time.hour, this.time.minute, this.time.second, this.time.nano / 1000000));
  }

  asMillis(): number {
    const cacheKey = this.cacheKey();
    let fromCache = LocalDateTime.millisCache[cacheKey];
    if(!fromCache) {
      const millis = Math.round(this.time.nano/1000000); // 1000000 - nanosInMilli
      fromCache = Date.UTC(this.date.year, this.date.month - 1, this.date.day, this.time.hour, this.time.minute, this.time.second, millis);
      LocalDateTime.millisCache[cacheKey] = fromCache;
    }
    return fromCache;
  }

  sortable(): string {
    return this.date.sortable()+" "+this.time.sortable();
  }

  static fromDate(date: Date): LocalDateTime {
    return new LocalDateTime(LocalDate.fromDate(date), LocalTime.fromDate(date));
  }

  static fromLocalDate(date: Date): LocalDateTime {
    return new LocalDateTime(LocalDate.fromLocalDate(date), LocalTime.fromDateLocal(date));
  }

  differenceMillis(other: LocalDateTime): number {
    return this.asMillis() - other.asMillis();
  }

  plusMillis(millis: number): LocalDateTime {
    return LocalDateTime.fromDate(new Date(this.asMillis() + millis));
  }

  minusMillis(millis: number): LocalDateTime {
    return this.plusMillis(- millis);
  }

  plusSeconds(seconds: number): LocalDateTime {
    return this.plusMillis(seconds * 1000);
  }

  plusDays(days: number): LocalDateTime {
    const date = this.asUTCDate();
    date.setUTCDate(this.date.day + days);
    return LocalDateTime.fromDate(date);
  }

  minusDays(days: number): LocalDateTime {
    return this.plusDays(-days);
  }

  plusWeeks(weeks: number): LocalDateTime {
    return this.plusDays(weeks * 7);
  }

  minusWeeks(weeks: number): LocalDateTime {
    return this.minusDays(weeks * 7);
  }

  plusMonths(months: number): LocalDateTime {
    const date = this.asUTCDate();
    const day = date.getUTCDate();
    date.setDate(1);
    date.setUTCMonth(date.getUTCMonth() + months);
    date.setDate(Math.min(day, daysInMonth(LocalDateTime.fromDate(date))));
    return LocalDateTime.fromDate(date);
  }

  minusMonths(months: number): LocalDateTime {
    return this.plusMonths(-months);
  }

  plusQuarters(quarters: number): LocalDateTime {
    return this.plusMonths(quarters * 3);
  }

  minusQuarters(quarters: number): LocalDateTime {
    return this.plusQuarters(-quarters);
  }

  plusYears(years: number): LocalDateTime {
    const date = this.asUTCDate();
    date.setUTCFullYear(date.getUTCFullYear() + years);
    return LocalDateTime.fromDate(date);
  }

  minusYears(years: number): LocalDateTime {
    return this.plusYears(-years);
  }

  isBefore(other: LocalDateTime): boolean {
    return this.date.isBefore(other.date) ||
      this.date.isEqual(other.date) && this.time.isBefore(other.time);
  }

  isBeforeOrEqual(other: LocalDateTime): boolean {
    return this.date.isBefore(other.date) ||
      this.date.isEqual(other.date) && !this.time.isAfter(other.time);
  }

  isAfter(other: LocalDateTime): boolean {
    return this.date.isAfter(other.date) ||
      this.date.isEqual(other.date) && this.time.isAfter(other.time);
  }

  isAfterOrEqual(other: LocalDateTime): boolean {
    return this.date.isAfter(other.date) ||
      this.date.isEqual(other.date) && !this.time.isBefore(other.time);
  }

  isInFuture() {
    return this.isAfter(LocalDateTime.now());
  }

  static now() {
    return LocalDateTime.fromDate(new Date());
  }

  static localNow() {
    return LocalDateTime.fromLocalDate(new Date());
  }

  // toUTC(): LocalDateTime {
  //   return toUTCFromTimezone(this, TimezoneManager.getTimezone());
  // }

  // toTimezonedDate(): Date {
  //   const localTimezone = this.toTimezonedLocalDateTime();
  //   return new Date(localTimezone.date.year, localTimezone.date.month - 1, localTimezone.date.day,
  //     localTimezone.time.hour, localTimezone.time.minute, localTimezone.time.second, localTimezone.time.nano / 1000);
  // }
  static fromLocalDateBegin(deadlineTo: LocalDate) {
    return new LocalDateTime(deadlineTo, LocalTime.MIDNIGHT);
  }

  static inTimezone(timezonedLocalDateTime: TimezonedLocalDateTime) {
    return new LocalDateTime(timezonedLocalDateTime.date, timezonedLocalDateTime.time);
  }

  static fromLocalDateEnd(deadlineTo: LocalDate) {
    return new LocalDateTime(deadlineTo.plusDays(1), LocalTime.MIDNIGHT);
  }
}


export function now() {
  return LocalDateTime.now();
}
