import {I18nText, None, VariableId} from "@utils";
import {ButtonComponentRefState, ButtonComponentState} from "./ButtonComponentState";
import {
  ScreenSharedViewModel,
  ScreenWrapperViewModel,
} from "../..";
import {ComponentViewModelUtils, ScreenComponentViewModel, ScreenContainerViewModel} from "../screen-component.view-model";
import {ScreenInstanceServerModel} from "../../screen-instance.server-model";
import {
  BordersProperties,
  ButtonComponentDefinition, ButtonComponentRef, CssBuilder,
  PaddingsProperties,
  TextsProperties
} from "@screen-common";

export class ComponentIcons {
  static iconOptions: {[identifier: string]: string} = {
    "confirm": "mi-check",
    "cancel": "mi-delete",
    "delete": "mi-trash",
    "add": "mi-plus",
    "clock": "mi-plus",
    "calendar": "mi-calendar",
    "chat": "mi-chat",
    "at": "mi-at",
    "attach": "mi-attach",
    "alarm-clock": "mi-alarm-clock",
    "fingerprint": "mi-fingerprint",
    "gears": "mi-gears",
    "hashtag": "mi-hashtag",
    "key": "mi-key",
    "location": "mi-location",
    "mail": "mi-mail",
    "search": "mi-search",
    "settings-sliders": "mi-settings-sliders",
    "edit": "mi-edit",
    "barchart": "mi-barchart",
    "globe": "mi-globe",
    "money": "mi-money",
    "phone": "mi-phone-office",
    "shopping-cart": "mi-shopping-cart",
    "star": "mi-star",
    "refresh": "mi-refresh",
    "backward": "mi-navigate-left",
    "forward": "mi-navigate-right",
    "rewind": "mi-rewind",
  }

  static getIcon(identifier: string): string|undefined {
    return ComponentIcons.iconOptions[identifier];
  }
}


export class ButtonComponentViewModel extends ScreenComponentViewModel {

  override typeName = "Button";

    public text: string = "";
    public tooltipText: string = "";
    public tooltipEnabled: boolean = false;
    public css: string = "";
    public cssClasses: string = "";
    public positioningCss: string = "";
    public override inProgress = false;
    public lastClick = -1;

    static MINIMUM_CLICK_INTERVAL = 500;

    get paddingsProperties(): PaddingsProperties {return new PaddingsProperties("", this.definition.properties)};
    get bordersProperties(): BordersProperties {return new BordersProperties(this.definition.properties)};
    get textProperties(): TextsProperties {return new TextsProperties(this.definition.properties)};

    startIcon: string|undefined = undefined;
    centerIcon: string|undefined = undefined;
    endIcon: string|undefined = undefined;

    constructor(override readonly shared: ScreenSharedViewModel,
                override readonly parent: ScreenContainerViewModel | ScreenWrapperViewModel,
                readonly context: VariableId,
                override readonly definition: ButtonComponentDefinition,
                override readonly componentScreenId: string,
                readonly ref: ButtonComponentRef,
                override readonly refScreenId: string,
                readonly componentState: ButtonComponentState,
                readonly refState: ButtonComponentRefState,
                readonly serverModel: ScreenInstanceServerModel,
                ) {
      super(parent, componentState, refState, definition, shared);
      this.update();
    }

    onClick() {
      const now = new Date().getTime();
      if(!this.inProgress && now - this.lastClick > ButtonComponentViewModel.MINIMUM_CLICK_INTERVAL) {
        this.inProgress = true;
        this.lastClick = now;
        this.serverModel.executeAction(this.componentRefPath(), "onClick")
      }
    }

    updateComponent(deep: boolean) {

      const cssBuilder: CssBuilder = new CssBuilder();

      ComponentViewModelUtils.toPaddingsCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.paddingsProperties, this.componentState.paddingsState);
      ComponentViewModelUtils.toBorderCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.bordersProperties, this.componentState.bordersState);
      ComponentViewModelUtils.toBackgroundCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.backgroundsProperties, this.componentState.backgroundsState);
      ComponentViewModelUtils.toTextCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider,this.textProperties, this.componentState.textState);
      ComponentViewModelUtils.toOuterShadowCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.bordersProperties, this.componentState.bordersState);

      this.text = this.definition.text.currentValue(() => this.componentState.text).valueOrDefault(I18nText.empty()).getCurrentWithFallback();
      const tooltip = this.definition.tooltip.currentValue(() => this.componentState.tooltip).valueOrDefault(None()).map(t => t.getCurrentWithFallback());

      this.tooltipEnabled = tooltip.exists(t => t.trim().length > 0);
      this.tooltipText = tooltip.getOrElse("");

      this.inProgress = this.componentState.properties.isActionInProgress("onClick");
      super.updatePosition();


      const icon = this.definition.icon.currentValue(() => this.componentState.icon).valueOrDefault(None()).map(t => ComponentIcons.getIcon(t)).getOrElse(undefined);
      const iconPosition: string = this.definition.iconPosition.currentValue(() => this.componentState.iconPosition).valueOrDefault("center");

      // if no icon is defined, use the startIcon deprecated property
      this.startIcon = icon !== undefined && iconPosition === "start" ? icon : (
        this.definition.startIcon.currentValue(() => this.componentState.startIcon).valueOrDefault(None()).map(t => ComponentIcons.getIcon(t)).getOrElse(undefined)
      );

      // if no icon is defined, use the endIcon deprecated property
      this.endIcon = icon !== undefined && iconPosition === "end" ? icon : (
        this.definition.endIcon.currentValue(() => this.componentState.endIcon).valueOrDefault(None()).map(t => ComponentIcons.getIcon(t)).getOrElse(undefined)
      );


      this.centerIcon = icon !== undefined && iconPosition === "center" ? icon : undefined;

      this.css = cssBuilder.toCss() + this.sizeCss;
      this.cssClasses = cssBuilder.toCssClasses();
    }

  }
