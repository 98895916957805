<div class="mainMenuGlass" [class.enabled]="visible" (click)="hide()"></div>
<div class="mainMenu" [class.expanded]="expanded" [class.visible]="visible">
  <div class="usagePart">
    <a class="logoEntry" [class.active]="mainDashboardActive" routerLink="/tasks" (contextmenu)="toggleApplicationsMenu(); false;">
      <div class="content">
        <div #ApplicationsAnchor>
          <my-draw-in-logo />
        </div>
<!--        <img class="logo" src="images/logo-modern.svg" alt="neula logo"/>-->
      </div>
    </a>
    <hr class="separator" />
<!--    <a class="entry" [class.active]="mainDashboardActive" routerLink="/">-->
<!--      <div class="content"><i class="icon mi-home"></i><span class="label">{{'main_menu_dashboard' | i18n}}</span></div>-->
<!--    </a>-->
    <a class="entry" [class.active]="mainTasksAndProcessesActive" routerLink="/tasks" [my-tooltip]="{i18n: 'main_menu_tasks_and_processes', position: 'right', enabled: !expanded}">
      <div class="content"><i class="icon mi-document-end-task"></i></div>
      <span class="label">{{'main_menu_tasks_and_processes' | i18n}}</span>
    </a>
    <a class="entry"        routerLink="/applications"
       [class.active]="applicationsActive"
       [my-tooltip]="{i18n: 'main_menu_applications', position: 'right', enabled: !expanded && !applicationsMenuVisible}">
      <div class="content"><i class="icon mi-apps"></i></div>
      <span class="label">{{'main_menu_applications' | i18n}}</span>
    </a>
    <a class="entry" [class.active]="documentsActive" routerLink="/documents/."
       [my-tooltip]="{i18n: 'main_menu_documents', position: 'right', enabled: !expanded}">
      <div class="content"><i class="icon mi-folder"></i></div>
      <span class="label">{{'main_menu_documents' | i18n}}</span>
    </a>
    <hr class="separator" *ngIf="applications.length > 0"/>
    <a class="entry" *ngFor="let app of applications"
              [class.active]="app.active"
       [routerLink]="app.applicationRouterLink"
       [my-tooltip]="{i18n: app.name, position: 'right', enabled: !expanded && !applicationContextMenuVisible}"
       (contextmenu)="toggleApplicationContextMenuPoint($event, app); $event.stopPropagation(); false;">
      <div class="content"><i class="icon" [class]="app.applicationIcon.icon"></i></div>
      <span class="label">{{app.name}}</span>
    </a>
  </div>
  <div class="adminPart">
    <a class="entry" [class.active]="designerActive" routerLink="/designer" (click)="goToDesigner()" [my-tooltip]="{i18n: 'main_menu_designer', position: 'right', enabled: !expanded}">
      <div class="content"><i class="icon mi-apps-add"></i></div>
      <span class="label">{{'main_menu_designer' | i18n}}</span>
    </a>
    <a class="entry" [class.active]="adminActive" routerLink="/admin/organization" [my-tooltip]="{i18n: 'main_menu_admin', position: 'right', enabled: !expanded}">
      <div class="content"><i class="icon mi-browser-admin"></i></div>
      <span class="label">{{'main_menu_admin' | i18n}}</span>
    </a>
    <a class="entry" [class.active]="helpActive" routerLink="/help" [my-tooltip]="{i18n: 'main_menu_help', position: 'right', enabled: !expanded}">
      <div class="content"><i class="icon mi-help"></i></div>
      <span class="label">{{'main_menu_help' | i18n}}</span>
    </a>
    <hr class="separator" />
    <button class="entry mobileEntry" (click)="logout()">
      <div class="content"><i class="icon mi-log-out"></i></div>
      <span class="label">{{'main_menu_logout' | i18n}}</span>
    </button>
    <button class="entry lastEntry desktopEntry" (click)="toggleNotificationsPanel()" [class.active]="userSettingsActive" [my-tooltip]="{i18n: 'main_menu_user', position: 'right', enabled: !expanded}">
      <my-avatar [person]="userAvatar"></my-avatar>
      <span class="label">{{'main_menu_user' | i18n}}</span>
    </button>
    <a class="entry lastEntry mobileEntry" [class.active]="userSettingsActive" routerLink="/user" [my-tooltip]="{i18n: 'main_menu_user', position: 'right', enabled: !expanded}">
      <my-avatar [person]="userAvatar"></my-avatar>
      <span class="label">{{'main_menu_user' | i18n}}</span>
    </a>
  </div>
</div>

<my-drop-menu cssClass="applicationsMenu" [(visible)]="applicationsMenuVisible" [anchor]="ApplicationsAnchor" position="end" [backgroundVisible]="false"
              [contentAvailableNotifier]="applicationsMenuContentAvailableNotifier">
  <my-applications-menu (applicationSelected)="onApplicationSelected()" *ngIf="applicationsMenuVisible"
  [contentAvailableNotifier]="applicationsMenuContentAvailableNotifier" />
</my-drop-menu>

<my-drop-menu cssClass="applicationContextMenu theme-drop-menu"
              [anchor]="applicationContextMenuAnchor"
              [pointAnchor]="applicationContextMenuPointAnchor"
              [(visible)]="applicationContextMenuVisible">
  <ng-container *ngIf="applicationContextMenuVisible && applicationContextMenuApp">
    <button class="theme-drop-menu-entry" (click)="onUnpinPinToMenu(applicationContextMenuApp)">
      <i class="mi-thumbtack"></i>
      {{'Odepnij od menu głównego' | i18n}}
    </button>
  </ng-container>
</my-drop-menu>
