<div *ngIf="viewModel.activeView"
     class="childrenPlain layout_{{viewModel.activeView.layoutClass}} layoutAlign_{{viewModel.activeView.layoutAlignClass}} layoutStretch_{{viewModel.activeView.layoutStretchClass}} layoutWrap_{{viewModel.activeView.layoutWrapClass}} {{viewModel.activeView.childrenPlainCssClasses}}"
     [style]="viewModel.activeView.childrenPlainCss">

  <ng-container *ngFor="let component of viewModel.activeView.children">
    <div *ngIf="component.uncoveredAndVisible" class="ScreenComponent ScreenComponent{{component.id}} layout_{{component.layoutClass}} {{component.errorClass}}"
         [class.nonVisibleComponent]="component.nonVisibleComponent"
         [style]="component.positionCss">
      <div class="componentBodyContainer labelPosition_{{component.label?.positionClass}}">
        <!--              SHARED -->
        <component-label *ngIf="component.label && component.label.visible" [viewModel]="component.label" />
        <ng-container [ngSwitch]="component.typeName">
          <my-section-container *ngSwitchCase="'Section'" [viewModel]="$any(component)" />
          <my-modal-container *ngSwitchCase="'Modal'" [viewModel]="$any(component)" />
          <my-repeatable-container *ngSwitchCase="'Repeatable'" [viewModel]="$any(component)" />
          <my-tabs-container *ngSwitchCase="'Tabs'" [viewModel]="$any(component)" />
          <my-view-switcher-container *ngSwitchCase="'ViewSwitcher'" [viewModel]="$any(component)" />
          <my-table-container *ngSwitchCase="'Table'" [viewModel]="$any(component)" />

          <my-single-attachment-input-component *ngSwitchCase="'SingleAttachmentInput'" [viewModel]="$any(component)" />
          <my-multi-attachment-input-component *ngSwitchCase="'MultiAttachmentInput'" [viewModel]="$any(component)" />

          <label-component *ngSwitchCase="'Label'" [viewModel]="$any(component)" />
          <a link-component *ngSwitchCase="'Link'" [viewModel]="$any(component)"></a>
          <button button-component *ngSwitchCase="'Button'" [viewModel]="$any(component)"></button>
          <img image-component *ngSwitchCase="'Image'" [viewModel]="$any(component)" />
          <my-map-component *ngSwitchCase="'Map'" [viewModel]="$any(component)" />
          <my-calendar-component *ngSwitchCase="'Calendar'" [viewModel]="$any(component)" />
          <my-html-component *ngSwitchCase="'Html'" [viewModel]="$any(component)" />

          <my-date-input-component *ngSwitchCase="'DateInput'" [viewModel]="$any(component)" />
          <my-time-input-component *ngSwitchCase="'TimeInput'" [viewModel]="$any(component)" />
          <my-date-time-input-component *ngSwitchCase="'DateTimeInput'" [viewModel]="$any(component)" />
          <my-duration-input-component *ngSwitchCase="'DurationInput'" [viewModel]="$any(component)" />

          <my-single-checkbox-component *ngSwitchCase="'SingleCheckbox'" [viewModel]="$any(component)" />
          <my-multi-checkbox-component *ngSwitchCase="'MultiCheckbox'" [viewModel]="$any(component)" />
          <my-radio-button-component *ngSwitchCase="'RadioButton'" [viewModel]="$any(component)" />
          <my-drop-list-component *ngSwitchCase="'DropList'" [viewModel]="$any(component)" />
          <my-person-select-component *ngSwitchCase="'PersonSelect'" [viewModel]="$any(component)" />

          <my-text-input-component *ngSwitchCase="'TextInput'" [viewModel]="$any(component)" />
          <my-number-input-component *ngSwitchCase="'NumberInput'" [viewModel]="$any(component)" />
          <my-password-input-component *ngSwitchCase="'PasswordInput'" [viewModel]="$any(component)" />

          <switch-component *ngSwitchCase="'Switch'" [viewModel]="$any(component)" />

          <div *ngSwitchDefault>
            Unsupported Component {{component.typeName}}
          </div>
        </ng-container>
        <div class="errorsInfo" *ngIf="component.error">
          <i class="errorsIcon mi-sensor-alert" [my-tooltip]="{text: component.errorText, position: 'top'}"></i>
        </div>

        <div class="inProgressGlass" *ngIf="component.inProgress"></div>
        <!--              SHARED -->
      </div>
    </div>
  </ng-container>
</div>
