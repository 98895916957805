  <div class="businessEntityVariablePreview" *ngIf="viewModel.readOnly && viewModel.preview">{{viewModel.previewText}}</div>

  <ng-container *ngIf="(!viewModel.readOnly || !viewModel.preview)">
  <button #Button class="businessEntityVariableEdition" (click)="showSelector()" >{{viewModel.previewText}}</button>

<!--  -->
<!--  <div class="numberPreview" *ngIf="viewModel.readOnly && viewModel.preview">{{viewModel.previewText}}</div>-->

<!--  <input *ngIf="(!viewModel.readOnly || !viewModel.preview)"-->
<!--         type="number"-->
<!--         class="form-control"-->
<!--         ng-model="viewModel.internalModel"-->
<!--         ng-blur="viewModel.onInternalChange()"-->
<!--         my-on-enter="viewModel.onInternalChange()"-->
<!--         [disabled]="viewModel.readOnly" />-->

  <my-drop-menu [anchor]="Button" [(visible)]="selectorVisible" cssClass="theme-drop-menu businessEntitySelector">
    <ng-container *ngIf="selectorVisible">
      <div class="theme-drop-menu-filter">
        <input type="text" [(ngModel)]="query" [focus-on-show]="true" (ngModelChange)="queryChanged()"
               (keydown.enter)="selectSingle()" [placeholder]="'Search...' | i18n"/>
        <i class="mi-search"></i>
      </div>

      <div class="theme-entries-scroll">
        <button class="theme-drop-menu-entry entity" *ngFor="let entity of foundEntities" (click)="selectEntity(entity)"><span class="code">{{entity.code}}</span><span class="name">{{entity.name}}</span></button>
      </div>
    </ng-container>
  </my-drop-menu>
  </ng-container>
