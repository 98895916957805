import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {__, ApplicationId, i18n, None, Option, OrganizationNodeId} from "@utils";
import {BasicOrganizationNodeInfo} from "@shared-model";
import {Observable, Subject} from "rxjs";

@Component({
  selector: 'my-organization-nodes-list',
  templateUrl: './organization-nodes-list.component.html'
})
export class AuthorizationListComponent implements OnInit {
  @Input() authorizations: Array<OrganizationNodeId> = [];
  selectorVisible: boolean = false;
  @Output() added = new EventEmitter<OrganizationNodeId>();
  @Output() removed = new EventEmitter<OrganizationNodeId>();

  @Input() disabled = false;

  visibleAuthorizations: Array<OrganizationNodeId> = [];
  contentChangeNotifier = new Subject<void>;
  resultsAvailable: boolean = false;
  @Input() applicationId: ApplicationId|null = null;

  @Input() addButtonLabel = "";

  @Input() persons: boolean = false;
  @Input() includeDeletedNodes: boolean = false;
  @Input() departments: boolean = false;
  @Input() groups: boolean = false;
  @Input() services: boolean = false;
  @Input() processes: boolean = false;
  @Input() reports: boolean = false;
  @Input() applications: boolean = false;
  @Input() screens: boolean = false;
  @Input() functions: boolean = false;
  @Input() anonymous: boolean = false;
  @Input() alwaysShowResults: boolean = false;
  @Input() inAllApplications: boolean = false;

  @Input() hideNode: OrganizationNodeId|null = null;


  removeAuthorization(node: OrganizationNodeId) {
    __(this.authorizations).findLastIndexOf(e => e.equals(node)).forEach(i => {
      this.authorizations.splice(i, 1);
    });
    this.removed.emit(node);
    this.updateVisibleAuthorizations();
  }

  toggleAddAuthorization() {
    this.selectorVisible = !this.selectorVisible;
    this.resultsAvailable = false;
  }

  newAuthorizationSelected(node: BasicOrganizationNodeInfo) {
    if(!__(this.authorizations).exists(e => e.equals(node.organizationNodeId))) {
      this.authorizations.push(node.organizationNodeId);
      this.added.emit(node.organizationNodeId);
    }
    this.updateVisibleAuthorizations();
    this.selectorVisible = false;
  }

  private updateVisibleAuthorizations() {
    this.visibleAuthorizations = __(this.authorizations).sortBy(s => s.nodeType.name);
  }

  ngOnInit(): void {
    // if not only person
    if(this.addButtonLabel.length === 0) {

      if(
        this.departments ||
        this.groups ||
        this.services ||
        this.processes ||
        this.reports ||
        this.applications ||
        this.screens ||
        this.functions) {
        this.addButtonLabel = i18n('common_assign');
      } else{
        this.addButtonLabel = i18n('common_assign_person');
      }

    }


    this.updateVisibleAuthorizations();
  }

  onResultsChanged() {
    this.resultsAvailable = true;
    this.contentChangeNotifier.next();
  }
}
