import {
  AnyVariablePath, BusinessVariable, BusinessVariableType,
  EdgeType, ExpressionWithAst,
  NodeMode,
  NodeType,
  ProcessAnnotationId,
  ProcessEdgeId,
  ProcessNodeId,
  ProcessPhaseId,
  ProcessPreviewFormId,
  ProcessRoleId, VariablePath
} from "@shared-model";
import {
  AutomaticActionRefId,
  Duration,
  GridXY,
  GridXYPath,
  I18nText, MultiTypeInput,
  Option,
  ProcessId, ProcessStart,
  ShiftXY,
  Trilean
} from "@utils";
import {AutomaticAction, AutomaticActionRef} from "@screen-common";
import {
  CloseParallelType,
  ConditionType, DurationMethod, ErrorHandlingType,
  NodeAssignment,
  OpenType, ProcessServiceId,
  TaskDistribution, TasksVisibility,
} from "../../../process-common.module";
import {LogicOperationValueViewModel, LogicRuleViewModel} from "./condition-rule/condition-rule.component";
import {AutomaticVariableModel} from "./map-canvas-editor-view.model";

export interface ProcessMapEditorUIEvent {
  className(): string; // used for debugging
  readonly temporary: boolean;
}


export class NodeAdded implements ProcessMapEditorUIEvent {
  className(): string {
    return "NodeAdded";
  }

  constructor(readonly nodeType: NodeType,
              readonly gridX: number,
              readonly gridY: number,
              readonly label: I18nText,
              readonly identifier: string,
              // other edges
              readonly newEdgesPaths: Map<ProcessEdgeId, GridXYPath>,
              readonly temporary: boolean) {}
}


export class AnnotationAdded implements  ProcessMapEditorUIEvent {
  className(): string {
    return "AnnotationAdded";
  }

  constructor(
    readonly gridXY: GridXY,
    readonly gridShiftXY: ShiftXY,
    readonly text: I18nText,
    readonly nodes: Array<ProcessNodeId>,
    readonly edges: Array<ProcessEdgeId>,
    readonly temporary: boolean
  ) {}

}

export class AnnotationMoved implements ProcessMapEditorUIEvent {

  className(): string {
    return "AnnotationMoved";
  }
  constructor(readonly id: ProcessAnnotationId,
              readonly gridXY: GridXY,
              readonly gridShiftXY: ShiftXY,
              readonly temporary: boolean) {}

}

export class AnnotationTextChanged implements ProcessMapEditorUIEvent {

  className(): string {
    return "AnnotationTextChanged";
  }
  constructor(readonly id: ProcessAnnotationId,
              readonly text: I18nText,
              readonly temporary: boolean) {}

}

export class AnnotationDeleted implements ProcessMapEditorUIEvent {

  className(): string {
    return "AnnotationDeleted";
  }
  constructor(readonly id: ProcessAnnotationId,
              readonly temporary: boolean) {}

}

export class NodeAddedWithEdge implements ProcessMapEditorUIEvent {
  className(): string {
    return "NodeAdded";
  }

  constructor(// node
              readonly nodeType: NodeType,
              readonly gridX: number,
              readonly gridY: number,
              readonly label: I18nText,
              readonly identifier: string,
              // edge
              readonly edgeType: EdgeType,
              readonly fromNodeId: ProcessNodeId|undefined,
              readonly toNodeId: ProcessNodeId|undefined,
              readonly edgePath: GridXYPath,
              // other edges
              readonly newEdgesPaths: Map<ProcessEdgeId, GridXYPath>,
              // nodes
              readonly conditionTypeChanges: Array<{nodeId: ProcessNodeId, conditionType: ConditionType}>,
              // temp
              readonly temporary: boolean) {}
}


export class NodeAddedWithEdgeMoved implements ProcessMapEditorUIEvent {
  className(): string {
    return "NodeAdded";
  }

  constructor(// node
    readonly nodeType: NodeType,
    readonly gridX: number,
    readonly gridY: number,
    readonly label: I18nText,
    readonly identifier: string,
    // edge
    readonly edgeId: ProcessEdgeId,
    readonly fromNodeId: ProcessNodeId|undefined,
    readonly toNodeId: ProcessNodeId|undefined,
    // other edges
    readonly newEdgesPaths: Map<ProcessEdgeId, GridXYPath>,
    // temp
    readonly temporary: boolean) {}
}

export class NodeMoved implements ProcessMapEditorUIEvent {
  className(): string {
    return "NodeMoved";
  }

  constructor(readonly nodeId: ProcessNodeId,
              readonly gridX: number,
              readonly gridY: number,
              readonly newEdgesPaths: Map<ProcessEdgeId, GridXYPath>,
              readonly temporary: boolean) {}
}

export class NodeLabelChanged implements ProcessMapEditorUIEvent {
  className(): string {
    return "NodeLabelChanged";
  }

  constructor(readonly nodeId: ProcessNodeId,
              readonly label: I18nText,
              readonly temporary: boolean) {}

}

export class NodeIdentifierChanged implements ProcessMapEditorUIEvent {
  className(): string {
    return "NodeIdentifierChanged";
  }

  constructor(readonly nodeId: ProcessNodeId,
              readonly identifier: string,
              readonly temporary: boolean) {}

}

export class NodeDescriptionChanged implements ProcessMapEditorUIEvent {
  className(): string {
    return "NodeDescriptionChanged";
  }

  constructor(readonly nodeId: ProcessNodeId,
              readonly description: I18nText,
              readonly temporary: boolean) {}

}



export class NodeInstructionChanged implements ProcessMapEditorUIEvent {
  className(): string {
    return "NodeInstructionChanged";
  }

  constructor(readonly nodeId: ProcessNodeId,
              readonly instruction: I18nText,
              readonly temporary: boolean) {}

}


export class NodeStartLabelChanged implements ProcessMapEditorUIEvent {
  className(): string {
    return "NodeStartLabelChanged";
  }

  constructor(readonly nodeId: ProcessNodeId,
              readonly startLabel: I18nText,
              readonly temporary: boolean) {}

}

export class NodeMetadataAverageRealizationDurationChanged implements ProcessMapEditorUIEvent {
  className(): string {
    return "NodeMetadataAverageRealizationDurationChanged";
  }

  constructor(readonly nodeId: ProcessNodeId,
              readonly averageRealizationDuration: Duration,
              readonly temporary: boolean) {}


}

export class NodeMetadataExpectedMaximumDurationChanged implements ProcessMapEditorUIEvent {
  className(): string {
    return "NodeMetadataExpectedMaximumDurationChanged";
  }

  constructor(readonly nodeId: ProcessNodeId,
              readonly method: DurationMethod,
              readonly duration: Duration|null,
              readonly expression: string|null,
              readonly field: string|null,
              readonly editableForCaseRole: Array<number>,
              readonly editableForInstanceRole: Array<number>,
              readonly temporary: boolean) {}
}




export class NodeTerminatingChanged implements ProcessMapEditorUIEvent {
  className(): string {
    return "NodeTerminatingChanged";
  }

  constructor(readonly nodeId: ProcessNodeId,
              readonly terminating: boolean,
              readonly temporary: boolean) {}

}

export class NodeModeChanged implements ProcessMapEditorUIEvent {
  className(): string {
    return "NodeModeChanged";
  }

  constructor(readonly nodeId: ProcessNodeId,
              readonly nodeMode: NodeMode,
              readonly temporary: boolean) {}
}

export class NodeTypeChanged implements ProcessMapEditorUIEvent {
  className(): string {
    return "NodeTypeChanged";
  }

  constructor(readonly nodeId: ProcessNodeId,
              readonly nodeType: NodeType,
              readonly temporary: boolean) {}
}



export class NodeConditionTypeChanged implements ProcessMapEditorUIEvent {
  className(): string {
    return "NodeConditionTypeChanged";
  }

  constructor(readonly nodeId: ProcessNodeId,
              readonly conditionType: ConditionType|undefined,
              readonly openType: OpenType|undefined,
              readonly closeType: CloseParallelType|undefined,
              readonly temporary: boolean) {}
}

export class NodeConditionDefaultEdgeChanged implements ProcessMapEditorUIEvent {

  className(): string {
    return "NodeConditionDefaultEdgeChanged";
  }

  constructor(readonly nodeId: ProcessNodeId,
              readonly edgeId: ProcessEdgeId|null,
              readonly temporary: boolean) {}
}

export class EdgeConditionInNodeMoved implements ProcessMapEditorUIEvent {
  className(): string {
    return "EdgeConditionInNodeMoved";
  }
  constructor(readonly nodeId: ProcessNodeId,
              readonly edgeId: ProcessEdgeId,
              readonly toIndex: number,
              readonly temporary: boolean) {}
}

export class EdgeConditionOperationTypeChanged implements ProcessMapEditorUIEvent {
  className(): string {
    return "EdgeConditionOperationTypeChanged";
  }

  constructor(readonly nodeId: ProcessNodeId,
              readonly edgeId: ProcessEdgeId,
              readonly operationType: "always" | "never" | "rule" | "expression",
              readonly temporary: boolean) {}
}

export class EdgeConditionExpressionChanged implements ProcessMapEditorUIEvent {
  className(): string {
    return "EdgeConditionExpressionChanged";
  }

  constructor(readonly nodeId: ProcessNodeId,
              readonly edgeId: ProcessEdgeId,
              readonly expression: string,
              readonly temporary: boolean) {}
}


export class EdgeConditionLogicOperationChanged implements ProcessMapEditorUIEvent {
  className(): string {
    return "EdgeConditionLogicOperationChanged";
  }
  constructor(readonly nodeId: ProcessNodeId,
              readonly edgeId: ProcessEdgeId,
              readonly indexPath: ReadonlyArray<number>,
              readonly left: Option<LogicOperationValueViewModel> | undefined,
              readonly operator: "==" | "!=" | "<" | "<=" | ">" | ">=" | "contains" | "!contains" | undefined,
              readonly right: Option<LogicOperationValueViewModel> | undefined,
              readonly temporary: boolean) {}
}

export class EdgeConditionSubRuleAdded implements ProcessMapEditorUIEvent {
  className(): string {
    return "EdgeConditionSubRuleAdded";
  }
  constructor(
    readonly nodeId: ProcessNodeId,
    readonly edgeId: ProcessEdgeId,
    readonly parentIndexPath: ReadonlyArray<number>,
    readonly atIndex: number,
    readonly operator: "and" | "or",
    readonly temporary: boolean) {
  }
}
export class EdgeConditionSubRuleDeleted implements ProcessMapEditorUIEvent {
  className(): string {
    return "DeleteEdgeConditionSubRuleDeleted";
  }
  constructor(
    readonly nodeId: ProcessNodeId,
    readonly edgeId: ProcessEdgeId,
    readonly parentIndexPath: ReadonlyArray<number>,
    readonly index: number,
    readonly temporary: boolean) {
  }
}
export class EdgeConditionSubRuleOperatorChanged implements ProcessMapEditorUIEvent {
  className(): string {
    return "EdgeConditionSubRuleOperatorChanged";
  }
  constructor(
    readonly nodeId: ProcessNodeId,
    readonly edgeId: ProcessEdgeId,
    readonly indexPath: ReadonlyArray<number>,
    readonly operator: "and" | "or",
    readonly temporary: boolean) {
  }
}





export class EdgeMoved implements ProcessMapEditorUIEvent {
  className(): string {
    return "EdgeMoved";
  }

  constructor(readonly edgeId: ProcessEdgeId,
              readonly fromNodeId: ProcessNodeId,
              readonly toNodeId: ProcessNodeId,
              readonly newPath: GridXYPath,
              // nodes
              readonly conditionTypeChanges: Array<{nodeId: ProcessNodeId, conditionType: ConditionType}>,
              readonly temporary: boolean) {}
}

export class EdgesPathsChanged implements ProcessMapEditorUIEvent {
  className(): string {
    return "EdgesPathsChanged";
  }

  constructor(readonly edgesPaths: Map<ProcessEdgeId, GridXYPath>,
              readonly temporary: boolean) {}

}

export class EdgeNameChanged implements ProcessMapEditorUIEvent {
  className(): string {
    return "EdgeNameChanged";
  }

  constructor(readonly edgeId: ProcessEdgeId,
              readonly name: I18nText,
              readonly temporary: boolean) {}

}

export class EdgePropertiesChanged implements ProcessMapEditorUIEvent {
  className(): string {
    return "EdgePropertiesChanged";
  }

  constructor(readonly edgeId: ProcessEdgeId,
              readonly outAllowed: Trilean,
              readonly outAllowedExpression: string,
              readonly inAllowed: Trilean,
              readonly inAllowedExpression: string,
              readonly rolesAllowed: Array<ProcessRoleId>,
              readonly terminating: boolean,
              readonly temporary: boolean) {}

}

export class EdgeTypeChanged implements ProcessMapEditorUIEvent {
  className(): string {
    return "EdgeTypeChanged";
  }

  constructor(readonly edgeId: ProcessEdgeId,
              readonly edgeType: EdgeType,
              readonly temporary: boolean) {}
}


export class NodeDeleted implements ProcessMapEditorUIEvent {
  className(): string {
    return "NodeDeleted";
  }

  constructor(readonly nodeId: ProcessNodeId,
              readonly temporary: boolean) {}
}

export class EdgeDeleted implements ProcessMapEditorUIEvent {
  className(): string {
    return "EdgeDeleted";
  }

  constructor(readonly edgeId: ProcessEdgeId,
              readonly temporary: boolean) {}
}

export class EdgeAdded implements ProcessMapEditorUIEvent {
  className(): string {
    return "EdgeAdded";
  }

  constructor(readonly edgeType: EdgeType,
              readonly fromNodeId: ProcessNodeId,
              readonly toNodeId: ProcessNodeId,
              readonly path: GridXYPath,
              // nodes
              readonly conditionTypeChanges: Array<{nodeId: ProcessNodeId, conditionType: ConditionType}>,
              readonly temporary: boolean) {}

}


export class RoleAdded implements ProcessMapEditorUIEvent {
  className(): string {
    return "RoleAdded";
  }
  constructor(readonly gridY: number,
              readonly roleName: I18nText,
              readonly identifier: string,
              readonly temporary: boolean) {}
}

export class RoleNameChanged implements ProcessMapEditorUIEvent {
  className(): string {
    return "RoleNameChanged";
  }
  constructor(readonly roleId: ProcessRoleId,
              readonly name: I18nText,
              readonly temporary: boolean) {}
}

export class RoleIdentifierChanged implements ProcessMapEditorUIEvent {
  className(): string {
    return "RoleIdentifierChanged";
  }
  constructor(readonly roleId: ProcessRoleId,
              readonly identifier: string,
              readonly temporary: boolean) {}
}

export class RoleDescriptionChanged implements ProcessMapEditorUIEvent {
  className(): string {
    return "RoleDescriptionChanged";
  }
  constructor(readonly roleId: ProcessRoleId,
              readonly description: I18nText,
              readonly temporary: boolean) {}
}

export class RoleNodeAssignmentChanged implements ProcessMapEditorUIEvent {
  className(): string {
    return "RoleNodeAssignmentChanged";
  }
  constructor(readonly roleId: ProcessRoleId,
              readonly nodeAssignment: NodeAssignment,
              readonly temporary: boolean) {}
}

export class RoleTaskDistributionChanged implements ProcessMapEditorUIEvent {
  className(): string {
    return "RoleTaskDistributionChanged";
  }
  constructor(readonly roleId: ProcessRoleId,
              readonly taskDistribution: TaskDistribution,
              readonly temporary: boolean) {}
}

export class RoleMembersCanChangeTaskAssignmentChanged implements ProcessMapEditorUIEvent {
  className(): string {
    return "RoleMembersCanChangeTaskAssignmentChanged";
  }
  constructor(readonly roleId: ProcessRoleId,
              readonly roleMembersCanChangeTaskAssignment: Trilean,
              readonly roleMembersCanChangeTaskAssignmentExpression: string,
              readonly temporary: boolean) {}
}

export class RoleIncompatibleRolesChanged implements ProcessMapEditorUIEvent {
  className(): string {
    return "RoleIncompatibleRolesChanged";
  }
  constructor(readonly roleId: ProcessRoleId,
              readonly incompatibleRoles: Array<number>,
              readonly temporary: boolean) {}
}

export class RoleTasksVisibilityChanged implements ProcessMapEditorUIEvent {
  className(): string {
    return "RoleTasksVisibilityChanged";
  }
  constructor(readonly roleId: ProcessRoleId,
              readonly tasksVisibility: TasksVisibility,
              readonly temporary: boolean) {}
}

export class RoleAssigneeLimitChanged implements ProcessMapEditorUIEvent {
  className(): string {
    return "RoleAssigneeLimitChanged";
  }
  constructor(readonly roleId: ProcessRoleId,
              readonly assigneeLimit: number,
              readonly temporary: boolean) {}
}

export class RoleCanChangeCaseImportanceChanged implements ProcessMapEditorUIEvent {
  className(): string {
    return "RoleCanChangeCaseImportanceChanged";
  }
  constructor(readonly roleId: ProcessRoleId,
              readonly canChangeCaseImportance: Trilean,
              readonly canChangeCaseImportanceExpression: string,
              readonly temporary: boolean) {}
}

export class RoleCanChangeCaseUrgencyChanged implements ProcessMapEditorUIEvent {
  className(): string {
    return "RoleCanChangeCaseUrgencyChanged";
  }
  constructor(readonly roleId: ProcessRoleId,
              readonly canChangeCaseUrgency: Trilean,
              readonly canChangeCaseUrgencyExpression: string,
              readonly temporary: boolean) {}
}

export class RoleCanChangeCaseLabelsChanged implements ProcessMapEditorUIEvent {
  className(): string {
    return "RoleCanChangeCaseLabelsChanged";
  }
  constructor(readonly roleId: ProcessRoleId,
              readonly canChangeCaseLabels: Trilean,
              readonly canChangeCaseLabelsExpression: string,
              readonly temporary: boolean) {}
}

export class RoleCommentsAccessChanged implements ProcessMapEditorUIEvent {
  className(): string {
    return "RoleCommentsAccessChanged";
  }
  constructor(readonly roleId: ProcessRoleId,
              readonly commentsAccess: boolean,
              readonly temporary: boolean) {}
}

export class RoleDeleted implements ProcessMapEditorUIEvent {
  className(): string {
    return "RoleDeleted";
  }
  constructor(readonly roleId: ProcessRoleId,
              readonly temporary: boolean) {}
}


export class NodeMove {
  constructor(readonly id: ProcessNodeId,
              readonly gridX: number,
              readonly gridY: number) {}
}

export class AnnotationMove {
  constructor(readonly id: ProcessAnnotationId,
              readonly gridXY: GridXY,
              readonly gridShiftXY: ShiftXY) {}
}

export class PhaseMove {
  constructor(readonly roleId: ProcessRoleId|undefined,
              readonly id: ProcessPhaseId,
              readonly gridX: number,
              readonly columns: number) {}
}

export class CommentMove {
  constructor(readonly id: ProcessAnnotationId,
              readonly gridXY: GridXY,
              readonly gridShiftXY: ShiftXY) {}
}

export class RoleMove {
  constructor(readonly id: ProcessRoleId,
              readonly gridY: number,
              readonly rows: number) {}
}

export class ElementsMove implements ProcessMapEditorUIEvent {
  className(): string {
    return "ElementsMove";
  }
  constructor(readonly rolesMove: Array<RoleMove>,
              readonly nodesMove: Array<NodeMove>,
              readonly annotationsMove: Array<AnnotationMove>,
              readonly commentsMove: Array<CommentMove>,
              readonly phasesMove: Array<PhaseMove>,
              readonly newEdgesPaths: Map<ProcessEdgeId, GridXYPath>,
              readonly temporary: boolean) {}
}

export class PhasesDefinitionAdded implements ProcessMapEditorUIEvent {
  className(): string {
    return "PhasesDefinitionAdded";
  }
  constructor(readonly roleId: ProcessRoleId,
              readonly temporary: boolean) {}
}

export class PhasesDefinitionDeleted implements ProcessMapEditorUIEvent {
  className(): string {
    return "PhasesDefinitionDeleted";
  }
  constructor(readonly roleId: ProcessRoleId,
              readonly temporary: boolean) {}

}

export class PhaseAdded implements ProcessMapEditorUIEvent {
  className(): string {
    return "PhaseAdded";
  }
  constructor(readonly roleId: ProcessRoleId|undefined,
              readonly gridX: number,
              readonly columns: number,
              readonly label: I18nText,
              readonly identifier: string,
              readonly temporary: boolean) {}
}

export class PhasesMoved implements ProcessMapEditorUIEvent {
  className(): string {
    return "PhasesAdded";
  }
  constructor(readonly phasesMoves: Array<PhaseMove>,
              readonly temporary: boolean) {}
}

export class PhaseNameChanged implements ProcessMapEditorUIEvent {
  className(): string {
    return "PhaseLabelChanged";
  }

  constructor(readonly roleId: ProcessRoleId|undefined,
              readonly id: ProcessPhaseId,
              readonly name: I18nText,
              readonly temporary: boolean) {}
}

export class PhaseDeleted implements ProcessMapEditorUIEvent {
  className(): string {
    return "PhaseDeleted";
  }
  constructor(readonly roleId: ProcessRoleId|undefined,
              readonly id: ProcessPhaseId,
              readonly temporary: boolean) {}
}

export class RowMove {
  constructor(readonly gridY: number,
              readonly newGridY: number) {}
}


export class DescriptionChanged implements ProcessMapEditorUIEvent {
  className(): string {
    return "DescriptionChanged";
  }
  constructor(readonly description: I18nText,
              readonly temporary: boolean) {}

}


export class FlowDescriptionExpressionChanged implements ProcessMapEditorUIEvent {
  className(): string {
    return "FlowDescriptionExpressionChanged";
  }
  constructor(readonly expression: string,
              readonly temporary: boolean) {}

}

export class FlowImportanceExpressionChanged implements ProcessMapEditorUIEvent {
  className(): string {
    return "FlowImportanceExpressionChanged";
  }
  constructor(readonly expression: string,
              readonly temporary: boolean) {}

}

export class ActionAddedToNode implements ProcessMapEditorUIEvent {
  className(): string {
    return "ActionAddedToNode";
  }
  constructor(readonly nodeId: ProcessNodeId,
              readonly place: "before"|"after",
              readonly action: AutomaticAction|undefined, // undefined if only reference is added
              readonly actionRef: AutomaticActionRef,
              readonly temporary: boolean) {}

}


export class ActionRefDeletedFromNode implements ProcessMapEditorUIEvent {
  className(): string {
    return "ActionRefDeletedFromNode";
  }
  constructor(readonly nodeId: ProcessNodeId,
              readonly place: "before"|"after",
              readonly actionRefId: AutomaticActionRefId,
              readonly temporary: boolean) {}
}

export class ActionRefMovedInNode implements ProcessMapEditorUIEvent {
  className(): string {
    return "ActionRefMovedInNode";
  }
  constructor(readonly nodeId: ProcessNodeId,
              readonly place: "before"|"after",
              readonly actionRefId: AutomaticActionRefId,
              readonly toIndex: number,
              readonly temporary: boolean) {}
}


export class ActionChanged implements ProcessMapEditorUIEvent {
  className(): string {
    return "ActionChanged";
  }
  constructor(readonly action: AutomaticAction,
              readonly temporary: boolean) {}
}


export class ActionRefChanged implements ProcessMapEditorUIEvent {
  className(): string {
    return "ActionRefChanged";
  }
  constructor(readonly nodeId: ProcessNodeId,
              readonly place: "before"|"after",
              readonly actionRef: AutomaticActionRef,
              readonly temporary: boolean) {}
}

export class PreviewFormCreated implements ProcessMapEditorUIEvent {
  className(): string {
    return "PreviewFormCreated";
  }
  constructor(readonly name: string,
              readonly temporary: boolean) {}
}

export class PreviewFormDeleted implements ProcessMapEditorUIEvent {
  className(): string {
    return "PreviewFormDeleted";
  }
  constructor(readonly previewFormId: ProcessPreviewFormId,
              readonly temporary: boolean) {}
}

export class PreviewFormRenamed implements ProcessMapEditorUIEvent {
  className(): string {
    return "PreviewFormRenamed";
  }
  constructor(readonly previewFormId: ProcessPreviewFormId,
              readonly name: I18nText,
              readonly temporary: boolean) {}
}

export class PreviewFormCustomAccessChanged implements ProcessMapEditorUIEvent {
  className(): string {
    return "PreviewFormCustomAccessChanged";
  }
  constructor(readonly previewFormId: ProcessPreviewFormId,
              readonly customAccess: boolean,
              readonly temporary: boolean) {}
}


export class PreviewFormAllowedRolesChanged implements ProcessMapEditorUIEvent {
  className(): string {
    return "PreviewFormAllowedRolesChanged";
  }
  constructor(readonly previewFormId: ProcessPreviewFormId,
              readonly allowedRoles: Array<ProcessRoleId>,
              readonly temporary: boolean) {}
}

export class PreviewFormMoved implements ProcessMapEditorUIEvent {
  className(): string {
    return "PreviewFormMoved";
  }
  constructor(readonly previewFormId: ProcessPreviewFormId,
              readonly fromIndex: number,
              readonly toIndex: number,
              readonly temporary: boolean) {}
}


export class AutomaticVariablesChanged implements ProcessMapEditorUIEvent {
  className(): string {
    return "AutomaticVariablesChanged";
  }
  constructor(readonly added: Array<AutomaticVariableModel>,
              readonly updated: Array<AutomaticVariableModel>,
              readonly deleted: Array<AnyVariablePath>,
              readonly temporary: boolean) {}
}

export class NodeStartupOtherProcessChanged implements ProcessMapEditorUIEvent {
  className(): string {
    return "NodeStartupOtherProcessChanged";
  }
  constructor(readonly nodeId: ProcessNodeId,
              readonly startupOtherProcess: boolean,
              readonly temporary: boolean) {}
}

export class NodeStartupEmailChanged implements ProcessMapEditorUIEvent {
  className(): string {
    return "NodeStartupEmailChanged";
  }
  constructor(readonly nodeId: ProcessNodeId,
              readonly startupEmail: boolean,
              readonly emailSubject: string,
              readonly emailFrom: string,
              readonly emailSent: string,
              readonly emailReceived: string,
              readonly emailBody: string,
              readonly emailAttachments: string,
              readonly emailEmail: string,
              readonly emailEML: string,
              readonly emailFlowId: string,
              readonly emailRecipients: string,
              readonly emailHeaders: Array<[string, string]>,
              readonly temporary: boolean) {}
}

export class NodeStartupScheduleChanged implements ProcessMapEditorUIEvent {
  className(): string {
    return "NodeStartupScheduleChanged";
  }
  constructor(readonly nodeId: ProcessNodeId,
              readonly startupSchedule: boolean,
              readonly temporary: boolean) {}
}

export class NodeStartupApiChanged implements ProcessMapEditorUIEvent {
  className(): string {
    return "NodeStartupApiChanged";
  }
  constructor(readonly nodeId: ProcessNodeId,
              readonly startupApi: boolean,
              readonly temporary: boolean) {}
}

export class NodeStartupIframeChanged implements ProcessMapEditorUIEvent {
  className(): string {
    return "NodeStartupIframeChanged";
  }
  constructor(readonly nodeId: ProcessNodeId,
              readonly startupIframe: boolean,
              readonly temporary: boolean) {}
}


export class NodeStartTriggerInputParameterAdded implements ProcessMapEditorUIEvent {
  className(): string {
    return "NodeStartTriggerInputParameterAdded";
  }
  constructor(readonly nodeId: ProcessNodeId,
              readonly paramId: number,
              readonly name: string,
              readonly valueType: BusinessVariableType,
              readonly required: boolean,
              readonly defaultValue: BusinessVariable | undefined,
              readonly temporary: boolean) {}
}

export class NodeStartTriggerInputParameterDeleted implements ProcessMapEditorUIEvent {
  className(): string {
    return "NodeStartTriggerInputParameterDeleted";
  }
  constructor(readonly nodeId: ProcessNodeId,
              readonly paramId: number,
              readonly temporary: boolean) {}
}

export class NodeStartTriggerInputParameterChanged implements ProcessMapEditorUIEvent {
  className(): string {
    return "NodeStartTriggerInputParameterChanged";
  }
  constructor(readonly nodeId: ProcessNodeId,
              readonly paramId: number,
              readonly name: string,
              readonly valueType: BusinessVariableType,
              readonly required: boolean,
              readonly defaultValue: BusinessVariable | undefined,
              readonly temporary: boolean) {}
}


export class NodeStartTriggerOutputParameterAdded implements ProcessMapEditorUIEvent {
  className(): string {
    return "NodeStartTriggerOutputParameterAdded";
  }
  constructor(readonly nodeId: ProcessNodeId,
              readonly paramId: number,
              readonly name: string,
              readonly valueType: BusinessVariableType,
              readonly temporary: boolean) {}
}

export class NodeStartTriggerOutputParameterDeleted implements ProcessMapEditorUIEvent {
  className(): string {
    return "NodeStartTriggerOutputParameterDeleted";
  }
  constructor(readonly nodeId: ProcessNodeId,
              readonly paramId: number,
              readonly temporary: boolean) {}
}

export class NodeStartTriggerOutputParameterChanged implements ProcessMapEditorUIEvent {
  className(): string {
    return "NodeStartTriggerOutputParameterChanged";
  }
  constructor(readonly nodeId: ProcessNodeId,
              readonly paramId: number,
              readonly name: string,
              readonly valueType: BusinessVariableType,
              readonly temporary: boolean) {}
}

export class ExternalProcessNodeProcessChanged implements ProcessMapEditorUIEvent {
  className(): string {
    return "ExternalProcessNodeProcessChanged";
  }
  constructor(readonly nodeId: ProcessNodeId,
              readonly identifiersMode: boolean,
              readonly processStart: ProcessStart|undefined,
              readonly processServiceId: ProcessServiceId|undefined,
              readonly applicationIdentifier: MultiTypeInput,
              readonly processInstanceIdentifier: MultiTypeInput,
              readonly startNodeIdentifier: MultiTypeInput,
              readonly killSubFlowOnTerminate: boolean,
              readonly errorHandling: ErrorHandlingType,
              readonly temporary: boolean) {}
}

export class ExternalProcessNodeInputMappingChangedOrAdded implements ProcessMapEditorUIEvent {
  className(): string {
    return "ExternalProcessNodeInputMappingChanged";
  }
  constructor(readonly nodeId: ProcessNodeId,
              readonly mappingId: number,
              readonly variableName: string,
              readonly value: MultiTypeInput,
              readonly temporary: boolean) {}
}

export class ExternalProcessNodeInputMappingDeleted implements ProcessMapEditorUIEvent {
  className(): string {
    return "ExternalProcessNodeInputMappingDeleted";
  }
  constructor(readonly nodeId: ProcessNodeId,
              readonly mappingId: number,
              readonly temporary: boolean) {}
}

export class ExternalProcessNodeOutputMappingChangedOrAdded implements ProcessMapEditorUIEvent {
  className(): string {
    return "ExternalProcessNodeInputMappingChanged";
  }
  constructor(readonly nodeId: ProcessNodeId,
              readonly mappingId: number,
              readonly externalVariableName: string,
              readonly saveToVariablePath: VariablePath,
              readonly temporary: boolean) {}
}

export class ExternalProcessNodeOutputMappingDeleted implements ProcessMapEditorUIEvent {
  className(): string {
    return "ExternalProcessNodeInputMappingDeleted";
  }
  constructor(readonly nodeId: ProcessNodeId,
              readonly mappingId: number,
              readonly temporary: boolean) {}
}

