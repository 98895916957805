import {
  ComponentIcons,
  ComponentViewModelUtils,
  LabelComponentRefState,
  LabelComponentState,
  ScreenComponentViewModel,
  ScreenContainerViewModel,
  ScreenInstanceServerModel,
  ScreenSharedViewModel, ScreenWrapperViewModel
} from "../..";
import {I18nText, None, VariableId} from "@utils";
import {CssBuilder, LabelComponentDefinition, LabelComponentRef} from "@screen-common";

export class LabelComponentViewModel extends ScreenComponentViewModel {

  override typeName = "Label";


  public text: string = "";
  public tooltipText: string = "";
  public tooltipEnabled: boolean = false;
  public css: string = "";
  public cssClasses: string = "";

  startIcon: string|undefined = undefined;
  centerIcon: string|undefined = undefined;
  endIcon: string|undefined = undefined;

  constructor(
    override readonly shared: ScreenSharedViewModel,
    override readonly parent: ScreenContainerViewModel | ScreenWrapperViewModel,
    readonly context: VariableId,
    override readonly definition: LabelComponentDefinition,
    override readonly componentScreenId: string,
    readonly ref: LabelComponentRef,
    override readonly refScreenId: string,
    readonly componentState: LabelComponentState,
    readonly refState: LabelComponentRefState,
    readonly serverModel: ScreenInstanceServerModel
  ) {
    super(parent, componentState, refState, definition, shared);
    this.update();
  }

  updateComponent(deep: boolean): void {

    const cssBuilder = CssBuilder.create();

    ComponentViewModelUtils.toPaddingsCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.paddingsProperties, this.componentState.paddingsState);
    ComponentViewModelUtils.toBorderCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.bordersProperties, this.componentState.bordersState);
    ComponentViewModelUtils.toBackgroundCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.backgroundsProperties, this.componentState.backgroundsState);
    ComponentViewModelUtils.toTextCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.textProperties, this.componentState.textState);

    ComponentViewModelUtils.toOuterShadowCss(cssBuilder, this.skinName, this.typeName, this.componentClass, this.defaultPropertyProvider, this.definition.bordersProperties, this.componentState.bordersState);

    this.text = this.definition.text.currentValue(() => this.componentState.text).valueOrDefault(I18nText.empty()).getCurrentWithFallback();

    const tooltip = this.definition.tooltip.currentValue(() => this.componentState.tooltip).valueOrDefault(None()).map(t => t.getCurrentWithFallback());

    this.tooltipEnabled = tooltip.exists(t => t.trim().length > 0);
    this.tooltipText = tooltip.getOrElse("");

    super.updatePosition();

    const icon = this.definition.icon.currentValue(() => this.componentState.icon).valueOrDefault(None()).map(t => ComponentIcons.getIcon(t)).getOrElse(undefined);
    const iconPosition: string = this.definition.iconPosition.currentValue(() => this.componentState.iconPosition).valueOrDefault("center");

    // if no icon is defined, use the startIcon deprecated property
    this.startIcon = icon !== undefined && iconPosition === "start" ? icon : undefined;

    // if no icon is defined, use the endIcon deprecated property
    this.endIcon = icon !== undefined && iconPosition === "end" ? icon : undefined;


    this.centerIcon = icon !== undefined && iconPosition === "center" ? icon : undefined;

    this.css = cssBuilder.toCss() + this.sizeCss;
    this.cssClasses = cssBuilder.toCssClasses();
  }

}
