import {
  __,
  AggregateId,
  AggregateVersion,
  AnyFlowId,
  DirectoryId,
  DocumentTypeId,
  FileProtocol,
  FileUri,
  i18n,
  LocalDateTime,
  None,
  Option,
  OrganizationNodeId,
  PersonId,
  restUrl,
  Typed
} from "@utils";
import {
  BusinessVariable,
  BusinessVariableFactory,
  BusinessVariablesValidation,
  BusinessVariableType,
  BusinessVariableTypeFactory,
  BusinessVariableValidationFactory,
  StringVariable
} from "@shared-model";
import {FileLockInfo, ViewableFile, ViewableFileUrl} from "./FileViewerSharedModel";

  export const rootDirectory = new DirectoryId(new AggregateId("1ek7el8khedsk"));
  export const orphanDirectory = new DirectoryId(new AggregateId("rm6s7ejlliwa"));
  export const sharedDirectory = new DirectoryId(new AggregateId("4o65tkipso00"));
  export const starDirectory = new DirectoryId(new AggregateId("10ltr4dl24vm7"));
  export const deletedDirectory = new DirectoryId(new AggregateId("19lgq1cmio8c4"));

  export const ROOT_DIRECTORY_PATH = "/";
  export const SHARED_DIRECTORY_PATH = "/*shared";
  export const STAR_DIRECTORY_PATH = "/*star";
  export const DELETED_DIRECTORY_PATH = "/*deleted";

  export const SHARED_DIRECTORY_NAME = "*shared";
  export const STAR_DIRECTORY_NAME = "*star";
  export const DELETED_DIRECTORY_NAME = "*deleted";

export class RepositoryNodeClientInfoFactory {
    static copyTyped(other: Typed<RepositoryNodeClientInfo>): Typed<RepositoryNodeClientInfo> {
      switch(Typed.className(other)) {
        case DirectoryClientInfo.className: return Typed.of(DirectoryClientInfo.copy(<DirectoryClientInfo>Typed.value(other)));
        case FileClientInfo.className: return Typed.of(FileClientInfo.copy(<FileClientInfo>Typed.value(other)));
        default: throw new Error("Unsupported type ["+Typed.className(other)+"]");
      }
    }
  }

  export interface FileRequestResult<T> {
    className(): string;
    isSuccess(): boolean;
    isNotFound(): boolean;
    isError(): boolean;
    isNotAuthorized(): boolean;
    isIncorrectRequest(): boolean;
    asSuccess(): FileRequestSuccess<T>;
    errorMessage(): string;
    uri: FileUri;
  }

  export class FileRequestSuccess<T> implements FileRequestResult<T>{
    static className = "FileRequestSuccess";
    className(): string {
      return FileRequestSuccess.className;
    }

    constructor(readonly uri: FileUri,
                readonly file: T) {}


    static copy<T>(other: FileRequestSuccess<T>, fileCopy: (file: T) => T) {
      return new FileRequestSuccess<T>(FileUri.copy(other.uri), fileCopy(other.file));
    }

    asSuccess(): FileRequestSuccess<T> {
      return this;
    }

    isSuccess(): boolean {
      return true;
    }

    isNotFound(): boolean {
      return false;
    }

    isError(): boolean {
      return false;
    }

    isNotAuthorized(): boolean {
      return false;
    }

    isIncorrectRequest(): boolean {
      return false;
    }

    errorMessage(): string {
      throw new Error("Not a failure");
    }

  }

  export class FileRequestNotFound implements FileRequestResult<any> {
    static className = "FileRequestNotFound";
    className(): string {
      return FileRequestNotFound.className;
    }
    constructor(readonly uri: FileUri) {}

    static copy(other: FileRequestNotFound) {
      return new FileRequestNotFound(FileUri.copy(other.uri));
    }

    asSuccess(): FileRequestSuccess<any> {
      throw new Error("Not a success: " + this.errorMessage());
    }

    isSuccess(): boolean {
      return false;
    }

    isNotFound(): boolean {
      return true;
    }

    isError(): boolean {
      return false;
    }

    isIncorrectRequest(): boolean {
      return false;
    }

    isNotAuthorized(): boolean {
      return false;
    }

    errorMessage(): string {
      return "Not found";
    }
  }

  export class FileRequestIncorrect implements FileRequestResult<any> {
    static className = "FileRequestIncorrect";
    className(): string {
      return FileRequestIncorrect.className;
    }
    constructor(readonly uri: FileUri, readonly message: string) {}

    static copy(other: FileRequestIncorrect) {
      return new FileRequestIncorrect(FileUri.copy(other.uri), other.message);
    }
    asSuccess(): FileRequestSuccess<any> {
      throw new Error("Not a success: " + this.errorMessage());
    }

    isSuccess(): boolean {
      return false;
    }

    isNotFound(): boolean {
      return false;
    }

    isError(): boolean {
      return false;
    }

    isIncorrectRequest(): boolean {
      return true;
    }

    isNotAuthorized(): boolean {
      return false;
    }

    errorMessage(): string {
      return "Request incorrect: " + this.message;
    }

  }

  export class FileRequestNotAuthorized implements FileRequestResult<any> {
    static className = "FileRequestNotAuthorized";
    className(): string {
      return FileRequestNotAuthorized.className;
    }
    constructor(readonly uri: FileUri, readonly message: string) {}
    static copy(other: FileRequestNotAuthorized) {
      return new FileRequestNotAuthorized(FileUri.copy(other.uri), other.message);
    }
    asSuccess(): FileRequestSuccess<any> {
      throw new Error("Not a success: " + this.errorMessage());
    }

    isNotFound(): boolean {
      return false;
    }

    isNotAuthorized(): boolean {
      return true;
    }

    isError(): boolean {
      return false;
    }

    isIncorrectRequest(): boolean {
      return false;
    }

    isSuccess(): boolean {
      return false;
    }
    errorMessage(): string {
      return "Not authorized: " + this.message;
    }
  }

  export class FileRequestFailed implements FileRequestResult<any> {
    static className = "FileRequestFailed";
    className(): string {
      return FileRequestFailed.className;
    }
    constructor(readonly uri: FileUri, readonly message: string) {}
    static copy(other: FileRequestFailed) {
      return new FileRequestFailed(FileUri.copy(other.uri), other.message);
    }
    asSuccess(): FileRequestSuccess<any> {
      throw new Error("Not a success: " + this.errorMessage());
    }

    isNotFound(): boolean {
      return false;
    }

    isNotAuthorized(): boolean {
      return false;
    }

    isError(): boolean {
      return true;
    }

    isIncorrectRequest(): boolean {
      return false;
    }

    isSuccess(): boolean {
      return false;
    }
    errorMessage(): string {
      return "Request failed: " + this.message;
    }
  }

  export class FileRequestResultFactory {
    static copyTypedAndUnwrap<T>(response: Typed<FileRequestResult<T>>, copyFile: (file: T) => T): FileRequestResult<T> {
      switch(Typed.className(response)) {
        case FileRequestSuccess.className: return FileRequestSuccess.copy<T>(<FileRequestSuccess<T>>Typed.value(response), copyFile);
        case FileRequestNotFound.className: return FileRequestNotFound.copy(<FileRequestNotFound>Typed.value(response));
        case FileRequestIncorrect.className: return FileRequestIncorrect.copy(<FileRequestIncorrect>Typed.value(response));
        case FileRequestNotAuthorized.className: return FileRequestNotAuthorized.copy(<FileRequestNotAuthorized>Typed.value(response));
        case FileRequestFailed.className: return FileRequestFailed.copy(<FileRequestFailed>Typed.value(response));
        default: throw new Error("Unsupported type ["+Typed.className(response)+"]");
      }
    }
  }


  export class PathEntry {
    public displayName: string;
    readonly virtual: boolean;
    constructor(readonly name: string, readonly link: string) {
      switch(name) {
        case STAR_DIRECTORY_NAME:
          this.displayName = i18n("documentsRepository_file_starred");
          this.virtual = true;
          break;
        case SHARED_DIRECTORY_NAME:
          this.displayName = i18n("documentRepository_sharing_shared");
          this.virtual = true;
          break;
        case DELETED_DIRECTORY_NAME:
          this.displayName = i18n("documentsRepository_file_deleted");
          this.virtual = true;
          break;
        default:
          this.displayName = name;
          this.virtual = false;
          break;
      }
    }

    static copy(other: PathEntry) {
      return new PathEntry(other.name, other.link);
    }
  }
  export class NodeWithAuthorization {
    constructor(readonly node: DocumentRepositoryNodeClientInfo,
                readonly nodeLink: string,
                readonly locationPath: Option<Array<PathEntry>>,
                readonly preview: boolean,
                readonly read: boolean,
                readonly write: boolean,
                readonly administration: boolean) {}

    static copy(other: NodeWithAuthorization) {
      return new NodeWithAuthorization(DocumentRepositoryNodeClientInfo.copy(other.node), other.nodeLink, Option.copy(other.locationPath).map(p => p.map(PathEntry.copy)), other.preview, other.read, other.write, other.administration);
    }
  }

  export class ContentWithAuthorization {
    constructor(
      readonly id: AggregateId,
      readonly content: Array<NodeWithAuthorization>,
      readonly preview: boolean,
      readonly read: boolean,
      readonly write: boolean,
      readonly administration: boolean,
      readonly contentAdministration: boolean) {}

    static copy(other: ContentWithAuthorization) {
      return new ContentWithAuthorization(AggregateId.copy(other.id), other.content.map(NodeWithAuthorization.copy), other.preview, other.read, other.write, other.administration, other.contentAdministration);
    }
  }

  export class SharingAccessLevel {
    constructor(readonly name: string) {}
    static preview = new SharingAccessLevel("preview");
    static read = new SharingAccessLevel("read");
    static write = new SharingAccessLevel("write");

    static copy(other: SharingAccessLevel) {
      switch(other.name) {
        case SharingAccessLevel.preview.name: return SharingAccessLevel.preview;
        case SharingAccessLevel.read.name: return SharingAccessLevel.read;
        case SharingAccessLevel.write.name: return SharingAccessLevel.write;
        default: throw new Error("Unsupported sharing level ["+other.name+"]");
      }
    }
  }

  export class DocumentShare {
    constructor(readonly id: number,
                readonly link: string,
                readonly users: Array<OrganizationNodeId>,
                readonly accessLevel: SharingAccessLevel) {}

    static copy(other: DocumentShare): DocumentShare {
      return new DocumentShare(other.id, other.link, other.users.map(OrganizationNodeId.copy), SharingAccessLevel.copy(other.accessLevel))
    }
  }

  export class DocumentRepositoryNodeClientInfo {

    constructor(readonly id: AggregateId,
                readonly version: AggregateVersion,
                readonly organizationId: AggregateId,
                readonly parentId: AggregateId,
                readonly name: string,
                readonly sortName: string,
                readonly description: string,
                readonly keywords: Array<string>,
                readonly starredBy: Array<PersonId>,
                readonly sharing: Array<DocumentShare>,
                readonly created: LocalDateTime,
                readonly updated: LocalDateTime,
                readonly info: Typed<RepositoryNodeClientInfo>,
                readonly deleted: Option<LocalDateTime>) {}


    isDirectory(): boolean {
      return Typed.className(this.info) === DirectoryClientInfo.className;
    }

    isRootDirectory(): boolean {
      return this.isDirectory() && this.id.id === rootDirectory.id.id;
    }

    isFile(): boolean {
      return Typed.className(this.info) === FileClientInfo.className;
    }

    getFileInfo(): FileClientInfo {
      if(this.isFile()) {
        return <FileClientInfo>Typed.value(this.info);
      } else {
        throw new Error("Cannot get file info for non file");
      }
    }

    getDirectoryInfo(): DirectoryClientInfo {
      if(this.isDirectory()) {
        return <DirectoryClientInfo>Typed.value(this.info);
      } else {
        throw new Error("Cannot get directory info for non directory");
      }
    }


    static directoryMock(id: AggregateId, directoryName: string) {
      return new DocumentRepositoryNodeClientInfo(id, new AggregateVersion(0), new AggregateId(""), new AggregateId(""), directoryName, "", "", [], [],[], LocalDateTime.now(),
        LocalDateTime.now(), Typed.of(new DirectoryClientInfo(new DirectoryAuthorization(false, [],[], [], []), DirectoryDisplayMode.DEFAULT)), None());
    }

    static copy(other: DocumentRepositoryNodeClientInfo) {
      return new DocumentRepositoryNodeClientInfo(other.id, other.version, other.organizationId, other.parentId, other.name, other.sortName,
        other.description, other.keywords.slice(), other.starredBy.map(PersonId.of), other.sharing.map(DocumentShare.copy), LocalDateTime.copy(other.created), LocalDateTime.copy(other.updated),
        RepositoryNodeClientInfoFactory.copyTyped(other.info), Option.copy(other.deleted).map(LocalDateTime.copy));
    }
  }

  export interface RepositoryNodeClientInfo {
    className(): string;
  }

  export class DirectoryAuthorization {

    constructor(readonly customAuthorization: boolean,
                readonly access: Array<OrganizationNodeId>,
                readonly read: Array<OrganizationNodeId>,
                readonly write: Array<OrganizationNodeId>,
                readonly administration: Array<OrganizationNodeId>) {}

    static copy(other: DirectoryAuthorization) {
      return new DirectoryAuthorization(other.customAuthorization, other.access.map(OrganizationNodeId.copy),
        other.read.map(OrganizationNodeId.copy), other.write.map(OrganizationNodeId.copy), other.administration.map(OrganizationNodeId.copy));
    }
  }

  export class DirectoryDisplayMode {
    constructor(readonly name: string) {}

    static standard = new DirectoryDisplayMode("s");
    static documentation = new DirectoryDisplayMode("d");
    static DEFAULT = DirectoryDisplayMode.standard;

    static copy(other: DirectoryDisplayMode) {
      switch (other.name) {
        case DirectoryDisplayMode.standard.name: return DirectoryDisplayMode.standard;
        case DirectoryDisplayMode.documentation.name: return DirectoryDisplayMode.documentation;
        default: throw new Error("Incorect display mode ["+other.name+"]");
      }
    }
  }

  export class DirectoryClientInfo implements RepositoryNodeClientInfo {
    static className = "DirectoryClientInfo";
    className() {
      return DirectoryClientInfo.className;
    }

    constructor(readonly authorization: DirectoryAuthorization,
                readonly mode: DirectoryDisplayMode) {}

    static copy(other: DirectoryClientInfo) {
      return new DirectoryClientInfo(DirectoryAuthorization.copy(other.authorization), DirectoryDisplayMode.copy(other.mode));
    }
  }



  export class FileComment {

    constructor(public commentId: number,
                public authorId: PersonId,
                public deleted: Option<LocalDateTime>,
                public versions: Array<FileCommentVersion>,
                public notifyPersons: Array<PersonId>) {}

    static copy(other: FileComment) {
      return new FileComment(other.commentId, PersonId.of(other.authorId), Option.copy(other.deleted).map(LocalDateTime.copy),
        other.versions.map(FileCommentVersion.copy), other.notifyPersons.map(PersonId.of));
    }
  }

  export class FileCommentVersion {

    constructor(readonly text: string,
                readonly created: LocalDateTime) {}

    static copy(other: FileCommentVersion) {
      return new FileCommentVersion(other.text, LocalDateTime.copy(other.created));
    }
  }

  export class FileAuthorization {

    constructor(readonly customAuthorization: boolean,
                readonly read: Array<OrganizationNodeId>,
                readonly write: Array<OrganizationNodeId>) {}

    static copy(other: FileAuthorization) {
      return new FileAuthorization(other.customAuthorization, other.read.map(OrganizationNodeId.copy), other.write.map(OrganizationNodeId.copy));
    }
  }

  export class FileClientInfo implements RepositoryNodeClientInfo {
    static className = "FileClientInfo";

    className() {
      return FileClientInfo.className;
    }

    constructor(public version: number,
                public fileVersions: Array<FileVersionClientInfo>,
                public lock: Option<FileLockInfo>,
                public description: string,
                public comments: Array<FileComment>,
                public authorization: FileAuthorization,
                public documentTypeId: Option<DocumentTypeId>,
                public properties: Array<DocumentPropertyIdVariable>,
                public watchersIds: Array<PersonId>,
                public commentsVersion: AggregateVersion,
                public settingsVersion: AggregateVersion,
                public authorizationVersion: AggregateVersion) {}

    static copy(other: FileClientInfo) {

      return new FileClientInfo(other.version, other.fileVersions.map(df => FileVersionClientInfo.copy(df)),
      Option.copy(other.lock).map(FileLockInfo.copy), other.description, other.comments.map(FileComment.copy),
        FileAuthorization.copy(other.authorization), Option.copy(other.documentTypeId), other.properties.map(DocumentPropertyIdVariable.copy),
        other.watchersIds.map(id => PersonId.of(id)), new AggregateVersion(other.commentsVersion.asInt),
        new AggregateVersion(other.settingsVersion.asInt), new AggregateVersion(other.authorizationVersion.asInt));
    }

    lastVersion() {
      return __(this.fileVersions).last();
    }

    getVersion(version: number) {
      return __(this.fileVersions).find(v => v.id == version);
    }
  }

  export class DocumentPropertyIdVariable {
    constructor(readonly name: string, readonly businessVariable: Typed<BusinessVariable>) {}

    businessVariableUnwrapped() {
      return Typed.value(this.businessVariable);
    }

    static copy(other: DocumentPropertyIdVariable) {
      return new DocumentPropertyIdVariable(other.name, BusinessVariableFactory.copyTyped(other.businessVariable));
    }

    static empty() {
      return new DocumentPropertyIdVariable("", Typed.of(new StringVariable("")))
    }
  }


  export class FileVersionClientInfo {

    constructor(readonly id: number,
                readonly fileSize: number,
                readonly created: LocalDateTime,
                readonly deleted: Option<LocalDateTime>,
                readonly deleter: Option<OrganizationNodeId>,
                readonly checksum: string,
                readonly creator: OrganizationNodeId,
                readonly mimeType: string) {}

    static copy(other: FileVersionClientInfo) {
      return new FileVersionClientInfo(other.id, other.fileSize, LocalDateTime.copy(other.created), Option.copy(other.deleted).map(LocalDateTime.copy),
        Option.copy(other.deleter).map(OrganizationNodeId.copy), other.checksum, OrganizationNodeId.copy(other.creator), other.mimeType);
    }
  }

  export class DirectoryPathEntry {
    constructor(readonly name: string) {}
    static copy(other: DirectoryPathEntry) {
      return new DirectoryPathEntry(other.name);
    }
  }

  export class DirectoryWithContent {
    constructor(readonly directory: NodeWithAuthorization, readonly content: Array<NodeWithAuthorization>) {}
    static copy(other: DirectoryWithContent) {
      return new DirectoryWithContent(NodeWithAuthorization.copy(other.directory), other.content.map(NodeWithAuthorization.copy));
    }
  }

  export class DocumentTypeInfo {
    constructor(readonly id: DocumentTypeId, readonly name: string, readonly parentsIds: Array<DocumentTypeId>, readonly properties: Array<DocumentPropertyInfo>) {}
    static copy(other: DocumentTypeInfo) {
      return new DocumentTypeInfo(DocumentTypeId.copy(other.id), other.name, other.parentsIds.map(DocumentTypeId.copy), other.properties.map(DocumentPropertyInfo.copy))
    }
  }

  export class DocumentPropertyInfo {
    constructor(readonly name: string, readonly variableType: Typed<BusinessVariableType>,
                readonly validation: Option<Typed<BusinessVariablesValidation>>) {}
    static copy(other: DocumentPropertyInfo) {
      return new DocumentPropertyInfo(other.name, BusinessVariableTypeFactory.copyTyped(other.variableType),
        Option.copy(other.validation).map(BusinessVariableValidationFactory.copyTyped))
    }

    unwrappedVariableType() {
      return Typed.value(this.variableType);
    }
    typeClassName() {
      return Typed.className(this.variableType);
    }
  }

  export class PageSize {
    constructor(readonly width: number, readonly height: number) {}

    static copy(other: PageSize) {
      return new PageSize(other.width, other.height);
    }
  }

  export class WebdavFileLink{
    constructor(readonly uri: string, readonly error: string) {}

    static copy(other: WebdavFileLink) {
      return new WebdavFileLink(other.uri, other.error);
    }
  }

  export interface RepositoryNodeSearchInfo {
    className(): string;
	  id: AggregateId;
	  version: AggregateVersion;
    name: string;
    modified: LocalDateTime;

	  // only UI
    isDirectory: boolean;
    parentPath: string;
    parentDirectoryName: string;
    getFullPath(): string;
  }

	export class RepositoryFileSearchInfo implements RepositoryNodeSearchInfo {
    static className = "RepositoryFileSearchInfo";

    className() {
      return RepositoryFileSearchInfo.className
    }

		isDirectory: boolean = false;
    parentPath: string = "";
    parentDirectoryName: string = "";

    getFullPath() {
      return this.path;
    }



		constructor(readonly id: AggregateId, readonly version: AggregateVersion, readonly name: string, readonly size: number,
                public path: string, readonly fileVersion: number, readonly modified: LocalDateTime) {
     this.parentPath = this.path.slice(0, this.path.length - this.name.length);
     if(this.parentPath.endsWith("/") && this.parentPath.length > 1) {
       this.parentDirectoryName = this.parentPath.slice(0, this.parentPath.length - 1).split("/").pop()!;
     } else {
      this.parentDirectoryName = i18n("documentsRepository_directory_root");
     }
    }

		static copy(other: RepositoryFileSearchInfo): RepositoryFileSearchInfo {
			return new RepositoryFileSearchInfo(AggregateId.copy(other.id), other.version, other.name, other.size, other.path, other.fileVersion,
        LocalDateTime.copy(other.modified))
		}

		toViewableFile(): ViewableFile {
      const downloadUrl = restUrl("documents/download/"+FileProtocol.RepositoryFile.name+encodeURI(this.getFullPath()));
      return new ViewableFile(this.name, this.size, None(), new ViewableFileUrl(downloadUrl), false, this.modified, new FileUri(FileProtocol.RepositoryFile, this.getFullPath()), false, None(), None(), None(), None(), false);
    }
	}

	export class RepositoryDirSearchInfo implements RepositoryNodeSearchInfo {
		static className = "RepositoryDirSearchInfo";

    parentPath: string = "";
    parentDirectoryName: string = "";

		className() {
			return RepositoryDirSearchInfo.className
		}

		isDirectory: boolean = true;

		getFullPath() {
			return this.path;
		}


		constructor(readonly id: AggregateId, readonly version: AggregateVersion, readonly name: string,
                readonly path: string, readonly modified: LocalDateTime) {
      this.parentPath = this.path.slice(0, this.path.length - this.name.length);
      if(this.parentPath.endsWith("/") && this.parentPath.length > 1) {
        this.parentDirectoryName = this.parentPath.slice(0, this.parentPath.length - 1).split("/").pop()!;
      } else {
        this.parentDirectoryName = i18n("documentsRepository_directory_root");
      }
    }

		static copy(other: RepositoryDirSearchInfo): RepositoryDirSearchInfo {
			return new RepositoryDirSearchInfo(AggregateId.copy(other.id), other.version, other.name, other.path,
        LocalDateTime.copy(other.modified))
		}
	}

	export class RepositoryNodeBasicInfoFactory {

		static copy(repositoryNodeBasicInfo: RepositoryNodeSearchInfo): RepositoryNodeSearchInfo {
			return RepositoryNodeBasicInfoFactory.copyByType(repositoryNodeBasicInfo, repositoryNodeBasicInfo.className());
		}

		static copyTyped(repositoryNodeBasicInfo: Typed<RepositoryNodeSearchInfo>): Typed<RepositoryNodeSearchInfo> {
			return Typed.of(RepositoryNodeBasicInfoFactory.copyByType(Typed.value(repositoryNodeBasicInfo), Typed.className(repositoryNodeBasicInfo)));
		}

		static copyByType(repositoryNodeBasicInfo: RepositoryNodeSearchInfo, className: string): RepositoryNodeSearchInfo {
			switch (className) {
				case RepositoryFileSearchInfo.className: {
					const v = <RepositoryFileSearchInfo>repositoryNodeBasicInfo;
					return RepositoryFileSearchInfo.copy(v);
				}
				case RepositoryDirSearchInfo.className: {
					const v = <RepositoryDirSearchInfo>repositoryNodeBasicInfo;
					return RepositoryDirSearchInfo.copy(v);
				}
				default:
					throw new Error("Unsupported repository class: " + className + ", repository node: " + JSON.stringify(repositoryNodeBasicInfo));
			}
		}

	}

	export class GetNodeInfoAdditionalAuthorization {
    constructor(readonly flowId: Option<Typed<AnyFlowId>>,
                readonly nodeId: Option<number>) {
    }
  }

