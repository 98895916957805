import {
  AggregateVersion,
  AnyFlowId,
  ApplicationId,
  ApplicationIdentifier,
  Either,
  FlowId,
  LocalDateTime,
  Option, ScreenIdentifier,
  ScreenInstanceId, Typed
} from "@utils";
import {ApplicationComponentRef} from "../..";
import {ProcessNodeId, TaskIdentifier} from "@shared-model";

export class CreateScreenInstance {
  constructor(
    readonly applicationIdOrIdentifier: Either<ApplicationId, ApplicationIdentifier>,
    readonly applicationComponent: ApplicationComponentRef,
    readonly params: Array<[string, string]>) {}
}

export class CreateScreenInstanceForFlow {
  constructor(
    readonly flowId: FlowId,
    readonly rootComponent: ScreenComponentRefIdentifier) {}
}

export class CreateScreenInstanceForFlowTaskHistory {
  constructor(
    readonly flowId: Typed<AnyFlowId>,
    readonly nodeId: ProcessNodeId,
    readonly historyIndex: number,
    readonly screen: Option<ScreenIdentifier>) {}
}

export class CreateScreenInstanceForFlowTask {
  constructor(
    readonly taskId: TaskIdentifier) {}
}

export class CreateScreenWorkingReleaseInstance {
  constructor(
    readonly applicationIdOrIdentifier: Either<ApplicationId, ApplicationIdentifier>,
    readonly applicationComponent: ApplicationComponentRef,
    readonly params: Array<[string, string]>,
    readonly minReleaseVersion: AggregateVersion,
    readonly requestedRootComponent: ScreenComponentRefIdentifier) {}
}

export class GetScreenInstanceState {
  constructor(readonly instanceId: ScreenInstanceId) {}
}

export class ScreenInitResponse {
  constructor(readonly screenInstanceId: ScreenInstanceId,
              readonly volatileScreen: boolean,
              readonly multipleInstancesAllowed: boolean) {}

  static copy(other: ScreenInitResponse) {
    return new ScreenInitResponse(ScreenInstanceId.copy(other.screenInstanceId), other.volatileScreen, other.multipleInstancesAllowed);
  }
}

export class TerminateScreen {
  constructor(
    readonly instanceId: ScreenInstanceId
  ) {}
}

export type ScreenComponentRefIdentifier = string;

export class ScreenComponentRefId {
  private readonly _ScreenComponentRefId: undefined; // force TypeScript to check types (workaround for duck typing)

  static ZERO = new ScreenComponentRefId(0);

  constructor(readonly id: number) {}

  static of(id: number) {
    return new ScreenComponentRefId(id);
  }

  static copy(other: ScreenComponentRefId) {
    return new ScreenComponentRefId(other.id);
  }
}
