
const entityMap: {[character: string]: string} = {
  "&": "&amp;",
  "<": "&lt;",
  ">": "&gt;",
  '"': '&quot;',
  "'": '&#39;',
  "/": '&#x2F;'
};

export function htmlEscape(html: string) {
  return String(html).replace(/[&<>"'\/]/g, (s: string) => entityMap[s]);
}

export function unescape(html: string) {
  const e = document.createElement('div');
  e.innerHTML = html;
  return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
}

export function isHtmlContent(content: string): boolean {
  return /<[a-z][\s\S]*>/i.test(content);
}

export function setHtmlElementData(element: HTMLElement, name: string, value: any) {
  element.dataset[name] = value;
}

export function clearInnerHtml(element: HTMLElement) {
  element.innerHTML = '';
}
