import {MetadataFormViewModel} from "./metadata.view-model";
import {BusinessEntityId, FileUri, Option, replaceArrayContent, ResponsiveWidthMonitor, toastr} from "@utils";
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewContainerRef} from "@angular/core";
import {
  BusinessVariable,
  BusinessVariableInputServerModel, EmailsSharedService,
  FilesSharedService,
  ObjectVariable,
  VariablePath
} from "@shared-model";
import {NamedAttributeViewModel} from "./MetadataModel";


@Component({
  selector: 'my-metadata',
  templateUrl: './metadata.component.html',
  host: {
    "[class.small]": "small"
  }
})
export class MetadataComponent implements OnInit, OnChanges {

  private _editMode: boolean = false;
  get editMode(): boolean {return this._editMode;}
  @Input() set editMode(value: boolean) {this._editMode = value; this.onEditModeChange()}

  @Input() downloadableFileUri: (uri: FileUri) => FileUri = uri => uri;

  @Input() showLabels: boolean = false;
  @Input() showNames: boolean = false;
  @Input() showTypes: boolean = false;

  @Input() sortMainAttributes: boolean = true;

  private _data!: BusinessVariable;
  get data(): BusinessVariable {return this._data;}
  @Input({required:true}) set data(value: BusinessVariable) {this._data = value; this.onDataChanged();}


  @Input() validateType: boolean = true;


  private _attributes: Array<NamedAttributeViewModel> = [];
  get attributes(): Array<NamedAttributeViewModel> {return this._attributes;}
  @Input() set attributes(value: Array<NamedAttributeViewModel>) {this._attributes = value; this.onAttributesChanged()}

  @Output() attributeChange = new EventEmitter<{value: BusinessVariable|null, path: VariablePath}>();

  @Input() businessEntityId?: BusinessEntityId; // optional, depends on context type

  @Input() selectable: boolean = false;

  @Input() selected: Array<VariablePath> = [];
  @Output() selectedChange = new EventEmitter<Array<VariablePath>>();

  viewModel!: MetadataFormViewModel;

  initiated: boolean = false;

  small = false;

  readonly responsive = ResponsiveWidthMonitor.of("metadata", this.viewContainerRef).onSmallerThan(50, (small) => {
    this.small = small;
  });
  @Output() sizeChanged = new EventEmitter<void>();

  constructor(readonly filesSharedService: FilesSharedService,
              readonly viewContainerRef: ViewContainerRef,
              readonly emailsSharedService: EmailsSharedService) {
  }

  ngOnChanges() {
    if(this.viewModel) { // if not initialized yet, it will be initialized in ngOnInit
      this.viewModel.sort(this.showLabels);
    }
  }

  ngOnInit() {

    const businessVariableInputServerModel = new BusinessVariableInputServerModel(this.filesSharedService, this.emailsSharedService);

    this.viewModel = new MetadataFormViewModel(businessVariableInputServerModel, Option.of(this.businessEntityId), !!this.attributes, this.downloadableFileUri, this.attributeChange, this.sortMainAttributes);

    this.viewModel.init(this.editMode, this._data, !this.attributes ? [] : this.attributes);

    this.viewModel.sort(this.showLabels);

    this.initiated = true;
  }


  private onEditModeChange() {
    if(this.viewModel) { // if not initialized yet, it will be initialized in ngOnInit
      this.viewModel.changeEditMode(this.editMode);
    }
  }


  private onAttributesChanged() {
    if(this.viewModel) { // if not initialized yet, it will be initialized in ngOnInit
      this.viewModel.init(this.editMode, this._data, !this.attributes ? [] : this.attributes);
    }
  }

  private onDataChanged() {
    if(this.initiated) {
      this.viewModel.init(this.editMode, this._data, !this.attributes ? [] : this.attributes);
    }
  }

  onSelectedChanged() {
    replaceArrayContent(this.selected, this.viewModel.getSelected());
    this.selectedChange.emit(this.selected);
  }


}
