import {
  Component,
  ComponentRef,
  createNgModule,
  Injector, Input,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from "@angular/core";
import {
  StyleSet,
  StylesResolver,
  TranslationsResolver,
} from "@shared";
import {
  DataTableDataProvider,
  DataTableEventBus,
  FlowInfoProvider,
  VariableNameProvider
} from "./data-table.shared-model";
import {DataTableFormEventBus} from "./data-table-form.event-bus";
import {ApplicationId} from "@utils";

@Component({
  selector: 'my-data-table',
  templateUrl: './data-table.component.html'
})
export class DataTableComponent implements OnInit {

  private instance?: ComponentRef<any>;

  @ViewChild(TemplateRef, { read: ViewContainerRef })
  private templateViewContainerRef: ViewContainerRef|undefined = undefined;

  @Input() eventBus: DataTableEventBus = new DataTableEventBus();
  @Input({required: true}) dataProvider!: DataTableDataProvider;

  @Input() variableNameProvider?: VariableNameProvider;
  @Input() flowInfoProvider?: FlowInfoProvider;

  @Input() dataTableFormEventBus?: DataTableFormEventBus;

  @Input() applicationId?: ApplicationId;

  private _dataTableName: string = "";
  @Input() set dataTableName(value: string) {
    this._dataTableName = value;
    if(this.instance) {
      this.instance.instance.dataTableName = value;
    }
  }

  private _dataReadOnly: boolean = true;
  @Input() set dataReadOnly(value: boolean) {
    this._dataReadOnly = value;
    if(this.instance) {
      this.instance.instance.dataReadOnly = value;
    }
  }
  private _structureReadOnly: boolean = true;
  @Input() set structureReadOnly(value: boolean) {
    this._structureReadOnly = value;
    if(this.instance) {
      this.instance.instance.structureReadOnly = value;
    }
  }

  private _allowInsertRowsInMiddle: boolean = false;
  @Input() set allowInsertRowsInMiddle(value: boolean) {
    this._allowInsertRowsInMiddle = value;
    if(this.instance) {
      this.instance.instance.allowInsertRowsInMiddle = value;
    }
  }

  private _showColumnTypesInPreview: boolean = false;
  @Input() set showColumnTypesInPreview(value: boolean) {
    this._showColumnTypesInPreview = value;
    if(this.instance) {
      this.instance.instance.showColumnTypesInPreview = value;
    }
  }

  private _useTimezone: boolean = true;
  @Input() set useTimezone(value: boolean) {
    this._useTimezone = value;
    if(this.instance) {
      this.instance.instance.useTimezone = value;
    }
  }

  private _useGrouping: boolean = false;
  @Input() set useGrouping(value: boolean) {
    this._useGrouping = value;
    if(this.instance) {
      this.instance.instance.useGrouping = value;
    }
  }

  private _allowLanguageSwitch: boolean = false;
  @Input() set allowLanguageSwitch(value: boolean) {
    this._allowLanguageSwitch = value;
    if(this.instance) {
      this.instance.instance.allowLanguageSwitch = value;
    }
  }


  constructor(private injector: Injector,
              private stylesResolver: StylesResolver,
              private translationsResolver: TranslationsResolver) {
  }

  ngOnInit(): void {
    const module = import('../../modules/data-table.module/data-table.module');
    const styles = this.stylesResolver.loadStyles(StyleSet.dataTable, StyleSet.dataTable);
    const translations = this.translationsResolver.loadTranslations();

    Promise.all([module, styles, translations]).then(values => {

      const DataTableModule = values[0].DataTableModule;

      if (this.templateViewContainerRef === undefined) {
        throw new Error("templateViewContainerRef is undefined");
      } else {
        const moduleRef = createNgModule(DataTableModule, this.injector)

        this.templateViewContainerRef.clear();
        const instance = this.templateViewContainerRef.createComponent(moduleRef.instance.getDataTableInternalComponent(), {ngModuleRef: moduleRef});
        this.instance = instance;

        if (instance === undefined) {
          throw new Error("Failed to create component");
        } else {

          // init instance
          instance.instance.dataTableName = this._dataTableName;
          instance.instance.eventBus = this.eventBus;
          instance.instance.dataProvider = this.dataProvider;
          instance.instance.dataReadOnly = this._dataReadOnly;
          instance.instance.structureReadOnly = this._structureReadOnly;
          instance.instance.allowInsertRowsInMiddle = this._allowInsertRowsInMiddle;
          instance.instance.showColumnTypesInPreview = this._showColumnTypesInPreview;
          instance.instance.useTimezone = this._useTimezone;
          instance.instance.useGrouping = this._useGrouping;
          instance.instance.applicationId = this.applicationId;
          instance.instance.allowLanguageSwitch = this._allowLanguageSwitch;
          instance.instance.variableNameProvider = this.variableNameProvider;
          instance.instance.flowInfoProvider = this.flowInfoProvider;
          instance.instance.dataTableFormEventBus = this.dataTableFormEventBus;
        }
      }

    });
  }

}
