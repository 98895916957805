import {Injectable} from "@angular/core";
import {i18n, ProcessId} from "@utils";
import {Constants} from "@shared";
import {BusinessVariable, StringVariable} from "@shared-model";

@Injectable({
  providedIn: 'root',
})
export class AdHocTaskHelper {

  readonly own = new StringVariable(i18n("tasks_system_label_own"));
  readonly individual = new StringVariable(i18n("tasks_system_label_individual"));

  translateSystemLabel(processId: ProcessId, label: BusinessVariable): BusinessVariable {
    if(label instanceof StringVariable && processId.id.id === Constants.taskProcessId.id.id) {
      switch (label.value) {
        case "own": return this.own;
        case "individual": return this.individual;
        default: return label;
      }
    } else {
      return label;
    }
  }

}
