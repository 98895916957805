import {InputElement, InputElementRef} from "../FormModel";
import {FormElementId, FormElementRefId, GridSize, GridXY, I18nText, Option, Some, Trilean, Typed} from "@utils";
import {
  ArrayVariable,
  BusinessVariable,
  BusinessVariableFactory,
  BusinessVariablesValidation,
  BusinessVariableType,
  BusinessVariableValidationFactory,
  ExpressionWithAst,
  VariableTypePath
} from "@shared-model";
import {LabelPosition} from "../FormField";

export class Checkbox implements InputElement {
  static className = "Checkbox";

  defaultVariable(variableType: BusinessVariableType) {
    return Some(BusinessVariableFactory.copy(this.defaultValue));
  }

  className() {
    return Checkbox.className;
  }

  isInputElement(): boolean {
    return true;
  }

  constructor(public id: FormElementId,
              public tooltip: I18nText,
              public variableTypePath: VariableTypePath,
              public validation: Option<Typed<BusinessVariablesValidation>>,
              public entries: ArrayVariable<BusinessVariable>,
              public defaultValue: ArrayVariable<BusinessVariable>,
              public entriesExpression: Option<ExpressionWithAst>,
              public hiddenExpression: Option<ExpressionWithAst>,
              public requiredExpression: Option<ExpressionWithAst>,
              public readOnlyExpression: Option<ExpressionWithAst>) {}

  static copy(other: Checkbox): Checkbox {
    return new Checkbox(other.id, I18nText.copy(other.tooltip),
      VariableTypePath.copy(other.variableTypePath),
      Option.copy(other.validation).map(BusinessVariableValidationFactory.copyTyped),
      <ArrayVariable<BusinessVariable>>BusinessVariableFactory.copyByType(other.entries, ArrayVariable.className),
      <ArrayVariable<BusinessVariable>>BusinessVariableFactory.copyByType(other.defaultValue, ArrayVariable.className),
      Option.copy(other.entriesExpression).map(ExpressionWithAst.copy),
      Option.copy(other.hiddenExpression).map(ExpressionWithAst.copy),
      Option.copy(other.requiredExpression).map(ExpressionWithAst.copy),
      Option.copy(other.readOnlyExpression).map(ExpressionWithAst.copy));
  }
}


export class CheckboxRef implements InputElementRef {
  static className = "CheckboxRef";

  className(): string {
    return CheckboxRef.className;
  }

  isInputElementRef() {
    return true;
  }

  constructor(public id: FormElementRefId,
              public elementId: FormElementId,
              public gridXY: GridXY,
              public gridSize: GridSize,
              public label: I18nText,
              public labelPosition: LabelPosition,
              public labelGridShift: GridXY,
              public required: Trilean,
              public readOnly: Trilean,
              public hidden: Trilean,
              public visibleInSummary: boolean,
              public visibleInTaskBox: boolean) {}

  static copy(other: CheckboxRef): CheckboxRef {
    return new CheckboxRef(other.id, other.elementId, GridXY.copy(other.gridXY), GridSize.copy(other.gridSize),
      I18nText.copy(other.label), LabelPosition.copy(other.labelPosition), GridXY.copy(other.labelGridShift),
      Trilean.copy(other.required), Trilean.copy(other.readOnly), Trilean.copy(other.hidden), other.visibleInSummary, other.visibleInTaskBox);
  }

}
