import {createNgModule, Injectable, Injector} from "@angular/core";
import {
  AggregateId,
  AggregateVersion,
  AnyFlowId, AnyFlowIdHelper,
  AnyInstanceId,
  AnyPersonId,
  AnyPersonIdFactory,
  FlowId,
  InstanceId,
  LocalDateTime,
  Option,
  OrganizationNodeId,
  RemoteOrganizationIdentifier, toastr,
  Typed
} from "@utils";
import {FlowWithCursor, TaskIdentifier, TaskModelSummary} from "./TaskModel";
import {FlowBasicInfo, FlowImportance} from "./FlowModel";
import {AuthenticatedHttp} from "../AuthenticatedHttp";
import {FlowAndTasksInfoForUser} from "./FlowAndTask";
import {BusinessVariable, RootVariable} from "../variables/BusinessVariables";
import {CommandResponse} from "../StandardResponses";
import {commandResponseHandler} from "../ResponsesHandler";
import {AnyFlowCommandResponse} from "./flows/AnyFlowStandardResponses";
import {anyFlowCommandResponseHandler} from "./flows/AnyFlowResponsesHandler";
import {CancelFlowRouting} from "../../modules/task-form.module/service/FlowMessages";

export type ProcessAnnotationId = number;
export type ProcessEdgeId = number;
export type ProcessNodeId = number;
export type ProcessRoleId = number;
export type ProcessCommentsGroupId = number;
export type FlowCursorId = number;
export type FlowCursorVersion = number;
export type FlowCommentId = number;
export type ProcessPhaseId = number;
export type ProcessPreviewFormId = number;

// FindPersonsAssignableToTask(flowId: AggregateId, nodeId: Int, query: string)
export class FindPersonsAssignableToTask {
  constructor(readonly flowId: AggregateId,
              readonly nodeId: ProcessNodeId,
              readonly query: string) {}
}

export class FindPersonsAssignableToRole {
  constructor(readonly flowId: Typed<AnyFlowId>,
              readonly roleId: ProcessRoleId,
              readonly query: string) {}
}

export class CheckPersonsAssignableToTaskAvailable {
  constructor(readonly flowId: AggregateId,
              readonly nodeId: ProcessNodeId,
              readonly exclude: Array<Typed<AnyPersonId>>) {}
}


export class CreateProcessFlowRouting {
  constructor(readonly processInstanceId: Typed<AnyInstanceId>,
              readonly startNodeId: ProcessNodeId,
              readonly startVariablesData: Array<RootVariable<BusinessVariable>>) {}
}

export class CreateTaskFlow {

    constructor(readonly description: string,
                readonly assignee: Option<OrganizationNodeId>,
                readonly deadline: Option<LocalDateTime>,
                readonly importance: FlowImportance,
                readonly submit: boolean) {}
}

export class FillAndSubmitTaskFlow {

  constructor(readonly flowId: Typed<AnyFlowId>,
              readonly flowCursor: FlowCursorId,
              readonly flowCursorVersion: FlowCursorVersion,
              readonly description: string,
              readonly assignee: Option<OrganizationNodeId>,
              readonly deadline: Option<LocalDateTime>,
              readonly importance: FlowImportance) {}
}


export class CreateAndStartFlow {

  constructor(readonly processInstanceId: InstanceId,
              readonly startNodeId: ProcessNodeId,
              readonly edgeId: ProcessEdgeId,
              readonly startVariablesData: Array<RootVariable<BusinessVariable>>) {}
}

export class FindFlowsBasicInfo {
  constructor(readonly flowsIds: Array<FlowId>) {}
}

export class FindFlowsCodesByIds {
  constructor(readonly ids: Array<AggregateId>) {}
}

export class FlowWithCode {
  constructor(readonly flowId: FlowId,
              readonly flowCode: Option<string>) {}

  static copy(other: FlowWithCode) {
    return new FlowWithCode(FlowId.copy(other.flowId),
      Option.copy(other.flowCode))
  }
}

@Injectable({
  providedIn: 'root',
})
export class FlowsSharedService {

  constructor(readonly authenticatedHttp: AuthenticatedHttp,
              private injector: Injector) {}

  loadFlowBasicInfo(flowId: FlowId, onSuccess: (flow: Option<FlowBasicInfo>) => void) {
    this.authenticatedHttp.get(
      "process-flow/get-flow-basic-info/"+flowId.urlSerialized(),
      (code: Option<FlowBasicInfo>) => {
        onSuccess(Option.copy(code, FlowBasicInfo.copy));
      })
  }

  loadFlowsBasicInfo(flowsIds: Array<FlowId>, onSuccess: (flow: Array<FlowBasicInfo>) => void) {
    this.authenticatedHttp.post("process-flow/find-flows-basic-info", new FindFlowsBasicInfo(flowsIds), (infos: Array<FlowBasicInfo>) => {
      onSuccess(infos.map(FlowBasicInfo.copy));
    })
  }

  private canceledFlows = new Array<AnyFlowId>();


  cancelFlow(flowId: AnyFlowId, flowCursor: FlowCursorId, flowCursorVersion: FlowCursorVersion, onSuccess: () => void, onError: () => void) {
    if(this.canceledFlows.find(f => AnyFlowIdHelper.equals(f, flowId)) === undefined) {
      this.canceledFlows.push(flowId);
      this.authenticatedHttp.post("process-flow/cancel-flow",
        new CancelFlowRouting(Typed.of(flowId), flowCursor, flowCursorVersion),
        (data: Typed<AnyFlowCommandResponse>) => {
          anyFlowCommandResponseHandler(data).onSuccess((flowId: AnyFlowId, version: AggregateVersion) => {
            onSuccess();
          }).onAnyError(onError).handle();
        }, () => onError()
      );
    }
  }

  loadTasksForFlow(flowCodeOrId: string): Promise<Array<TaskModelSummary>> {
    return this.authenticatedHttp.getPromise<Array<TaskModelSummary>>(
      "process-flow/tasks-for-flow/"+flowCodeOrId).then(data => {
      return data.map(t => TaskModelSummary.copy(t))
    });
  }

  getTasksForMe(onSuccess: (tasks: Array<TaskModelSummary>) => void) {
    this.authenticatedHttp.get(
      "process-flow/tasks-for-me",
      (data: Array<TaskModelSummary>) => {
        onSuccess(data.map(t => TaskModelSummary.copy(t)));
      }
    )
  }

  getRemoteTasksForMe(remoteOrganizationIdentifier: RemoteOrganizationIdentifier, onSuccess: (tasks: TaskModelSummary[]) => void) {
    this.authenticatedHttp.get(
      "process-flow/remote-tasks-for-me/"+remoteOrganizationIdentifier.id,
      (data: Array<TaskModelSummary>) => {
        onSuccess(data.map(t => TaskModelSummary.copy(t)));
      }
    )
  }

  loadProcessFlowInfoForUserByFlowCode(flowCode: string, onSuccess: (modelInfo: Option<FlowAndTasksInfoForUser>) => void) {
    this.authenticatedHttp.get("process-flow/get-flow-info-for-user-by-code/" + flowCode,
      (data:Option<FlowAndTasksInfoForUser>) => {
        onSuccess(Option.copy(data, FlowAndTasksInfoForUser.copy));
      });
  }

  createProcessFlow(processInstanceId: AnyInstanceId, startNodeId: ProcessNodeId, variablesData: Array<RootVariable<BusinessVariable>>,
                    onSuccess: (id: AnyFlowId, version: AggregateVersion) => void,
                    onCallEnd: () => void) {
    this.authenticatedHttp.post(
      "process-flow/new",
      new CreateProcessFlowRouting(Typed.of(processInstanceId), startNodeId, variablesData),
      (data: Typed<AnyFlowCommandResponse>) => {
        onCallEnd();
        // toastr.info("Exceptions handling not implmented in flow creation");

        anyFlowCommandResponseHandler(data).onSuccess((flowId: AnyFlowId, aggregateVersion: AggregateVersion) => {
          onSuccess(flowId, aggregateVersion);
        }).handle();

        // const flowId = new FlowId(data["AnyFlowSuccessResponse"].flowId.FlowId.id);
        // const aggregateVersion = new AggregateVersion(data["AnyFlowSuccessResponse"].aggregateVersion.asInt);
        //
        // onSuccess(flowId, aggregateVersion);

        // anyFlowCommandResponseHandler(data).onSuccess((flowId: AnyFlowId, aggregateVersion: AggregateVersion) => {
        //   onSuccess(flowId, aggregateVersion);
        // }).handle();
      }, () => {
        onCallEnd();
      });
  }


  createAndStartTaskFlow(description: string,
                         assignee: Option<OrganizationNodeId>, deadline: Option<LocalDateTime>, importance: FlowImportance,
                         submit: boolean,
                         onSuccess: (id: AnyFlowId, version: AggregateVersion) => void,
                         onError: (message: string) => void) {
    this.authenticatedHttp.post("process-flow/create-task-flow",
      new CreateTaskFlow(description, assignee, deadline, importance, submit), (data: Typed<CommandResponse>) => {
        commandResponseHandler(data).onSuccess((aggregateId: AggregateId, aggregateVersion: AggregateVersion) => {
          onSuccess(FlowId.of(aggregateId), aggregateVersion);
        }).handle();
      }, (status: number, data: any) => {
        onError(data+"");
      });
  }

  createAndStartFlow(instanceId: InstanceId, startNodeId: ProcessNodeId, edgeId: ProcessEdgeId, variablesData: Array<RootVariable<BusinessVariable>>,
                     onSuccess: (id: AnyFlowId, version: AggregateVersion) => void,
                     onError: (message: string) => void) {
    this.authenticatedHttp.post("process-flow/create-and-start-flow",
      new CreateAndStartFlow(instanceId, startNodeId, edgeId, variablesData), (data: Typed<CommandResponse>) => {
        commandResponseHandler(data).onSuccess((aggregateId: AggregateId, aggregateVersion: AggregateVersion) => {
          onSuccess(FlowId.of(aggregateId), aggregateVersion);
        }).handle();
      }, (status: number, data: any) => {
        onError(data+"");
      });
  }

  findFlowsCodesById(requestNumber: number, flowsIds: Array<FlowId>, onSuccess: (requestNumber: number, flows: Array<FlowWithCode>) => void) {
    this.authenticatedHttp.post(
      "process-flow/find-flows-codes-by-ids", new FindFlowsCodesByIds(flowsIds.map(f => f.toAggregateId())),
      (flows: Array<FlowWithCode>) => {
        onSuccess(requestNumber, flows.map(FlowWithCode.copy));
      })
  }

  fillAndSubmitTaskFlow(flowId: AnyFlowId, flowCursor: FlowCursorId, flowCursorVersion: FlowCursorVersion,
                        description: string, plan: "today" | "tomorrow" | "remaining",
                        assignee: Option<OrganizationNodeId>, deadline: Option<LocalDateTime>, importance: FlowImportance,
                        onSuccess: (id: AnyFlowId, version: AggregateVersion) => void,
                        onFailure: (id: AnyFlowId, version: AggregateVersion) => void,
                        onError: (message: string) => void) {

    import('../../modules/task-form.module/task-form.module').then(module => {
      const moduleRef = createNgModule(module.TaskFormModule, this.injector)
      const flowService = this.injector.get(moduleRef.instance.getFlowService())
      flowService.fillAndSubmitTaskFlow(flowId, flowCursor, flowCursorVersion, description, plan, assignee, deadline, importance, onSuccess, onFailure, onError);

    });
  }

  findPersonsAssignableToTask(taskIdentifier: TaskIdentifier, query: string): Promise<Array<AnyPersonId>> {
    return this.authenticatedHttp.postPromise<Array<Typed<AnyPersonId>>>("process-flow/persons-assignable-to-task", new FindPersonsAssignableToTask(taskIdentifier.flowIdUnwrapped().toFlowId().toAggregateId(), taskIdentifier.nodeId, query)).then(data => {
      return data.map(d => Typed.value(AnyPersonIdFactory.copyTyped(d)));
    });
  }


  findPersonsAssignableToRole(flowId: AnyFlowId, roleId: ProcessRoleId, query: string): Promise<Array<AnyPersonId>> {
    return this.authenticatedHttp.postPromise<Array<Typed<AnyPersonId>>>("process-flow/persons-assignable-to-role", new FindPersonsAssignableToRole(Typed.of(flowId), roleId, query)).then(data => {
      return data.map(d => Typed.value(AnyPersonIdFactory.copyTyped(d)));
    });
  }

  checkPersonsAssignableToTask(taskIdentifier: TaskIdentifier, exclude: Array<AnyPersonId>,
                              onSuccess: (available: boolean) => void) {
    this.authenticatedHttp.post(
      "process-flow/persons-assignable-to-task-available", new CheckPersonsAssignableToTaskAvailable(taskIdentifier.flowIdUnwrapped().toFlowId().toAggregateId(), taskIdentifier.nodeId, exclude.map(p => Typed.of(p))),
      (data: boolean) => {
        onSuccess(data);
      })
  }

  getAdHocTaskData(flowId: AnyFlowId, nodeId: ProcessNodeId, onSuccess: (task: {
    description: string;
    importance: FlowImportance;
    assignee: Option<OrganizationNodeId>;
    deadline: LocalDateTime|null;
    taskId: TaskIdentifier;
    flowWithCursor: FlowWithCursor;
  }) => void, onNotFound: () => void) {
    import('../../modules/task-form.module/task-form.module').then(module => {
      const moduleRef = createNgModule(module.TaskFormModule, this.injector)
      const flowService = this.injector.get(moduleRef.instance.getFlowService())

      flowService.getTaskFlowData(flowId.toFlowId(), (taskData) => {
        onSuccess({
          description: taskData.description,
          importance: taskData.importance,
          assignee: taskData.assignee,
          deadline: taskData.deadline.getOrNull(),
          taskId: TaskIdentifier.of(taskData.flowIdUnwrapped(), nodeId),
          flowWithCursor: new FlowWithCursor(taskData.flowIdUnwrapped(), taskData.cursor),
        });
      }, onNotFound);
    });
  }
}
