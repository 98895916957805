import {LogicOperationValue} from "../../modules/process-common.module";

/**
 * Value holder for a value that can be present or not. Based on Scala's Option or Java 8 Optional.
 */
export class Option<T> {

  private readonly value: T | undefined;

  constructor(value:T | undefined) {
    if (value == null) {
      this.value = undefined;
    } else {
      this.value = value;
    }
  }

  getOrUndefined() {
    return this.value;
  }

  getOrNull() {
    if(this.value == undefined) {
      return null;
    } else {
      return this.value;
    }
  }

  /**
   * @deprecated The method should not be used, use getOrError instead.
   */
  get(): T {
    if (this.value === undefined) {
      throw new Error("Trying to get value from None");
    } else {
      return <T>this.value;
    }
  }

  isDefined() {
    return this.value !== undefined;
  }

  isEmpty() {
    return this.value === undefined;
  }

  map<R>(converter: (value:T) => R):Option<R> {
    if(this.value === undefined) {
      return None();
    } else {
      return new Option<R>(converter(this.value));
    }
  }

  flatMap<R>(converter: (value:T) => Option<R>):Option<R> {
    if (this.value === undefined) {
      return None();
    } else {
      return converter(this.value);
    }
  }

  flatMapZip<R>(converter: (value:T) => Option<R>):Option<[T, R]> {
    if(this.value === undefined) {
      return None();
    } else {
      return converter(this.value).map(v => <[T, R]>[this.value, v]);
    }
  }

  filter(filter: (value: T) => boolean): Option<T> {
    if (this.value !== undefined && filter(this.value)) {
      return this;
    } else {
      return None();
    }
  }

  forEach(apply: (value: T) => void): void {
    if (this.value !== undefined) {
      apply(this.value);
    }
  }

  toArray(): Array<T> {
    if (this.value !== undefined) {
      return [this.value];
    } else {
      return [];
    }
  }

  whenPresent(callback: (value:T) => void):Option<T> {
    if (this.value !== undefined) {
      callback(this.value);
    }
    return this
  }

  whenNotPresent(callback: () => void):Option<T> {
    if (!this.value !== undefined) {
      callback();
    }
    return this;
  }

  orElse(otherValue: Option<T>): Option<T> {
    if (this.value !== undefined) {
      return this;
    } else {
      return otherValue;
    }
  }

  orElseLazy(otherValue: () => Option<T>): Option<T> {
    if (this.value !== undefined) {
      return this;
    } else {
      return otherValue();
    }
  }

  getOrElse(otherValue: T): T {
    if (this.value !== undefined) {
      return this.value;
    } else {
      return otherValue;
    }
  }

  getOrElseLazy(otherValue: () => T): T {
    if (this.value !== undefined) {
      return this.value;
    } else {
      return otherValue();
    }
  }

  getOrError(message: string): T {
    if (this.value !== undefined) {
      return this.value;
    } else {
      throw new Error(message);
    }
  }

  contains(value: T): boolean {
    if (this.value !== undefined) {
      return this.value === value;
    } else {
      return false;
    }
  }

  exists(predicate: (value: T) => boolean): boolean {
    if (this.value !== undefined) {
      return predicate(this.value);
    } else {
      return false;
    }
  }

  existsNot(predicate: (value: T) => boolean): boolean {
    return !this.exists(predicate);
  }


  equals(other: Option<T>, equal: (a: T, b: T) => boolean = (a: T, b: T) => a === b) {
    return this.value === undefined && other.value === undefined ||
        this.value !== undefined && other.value !== undefined && equal(this.value, other.value);
  }

  notEquals(other: Option<T>, equal: (a: T, b: T) => boolean = (a: T, b: T) => a === b) {
    return !this.equals(other, equal);
  }

  static of<E>(value:E|undefined|null): Option<E> {
    return new Option<E>(value !== null && value !== undefined ? value : undefined);
  }

  static ofArray<E>(value:Array<E>): Option<E> {
    if(value.length == 0) {
      return None();
    } else if(value.length === 1) {
      return Some(value[0]);
    } else {
      throw new Error("Cannot create optional from array with " + value.length+" elements. Must be empty or has single element.");
    }
  }

  static copy<T>(other: Option<T>, contentCopy: (value: T) => T = T => T):Option<T>  {
    if(other.value === undefined || other.value === null) {
      return new Option<T>(undefined);
    } else {
      return new Option<T>(contentCopy(other.value));
    }
  }
}

export const NoneSingleton: Option<any> = new Option<any>(undefined);

export function None() {
  return NoneSingleton;
}

export function Some<E>(value:E) {
  return new Option<E>(value);
}


export function findOption<T>(array: Array<T>, pred: (t: T) => boolean): Option<T> {
  return Option.of<T>(array.find(p => pred(p)));
}
