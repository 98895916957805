import {Injectable} from '@angular/core';

import {NavigationService} from "../navigation/navigation.service";
import i18next from "i18next";
import HttpApi from 'i18next-http-backend';
import {UserSettingsController} from "@shared";
import {UserSettingsStateService} from "@shared";
import {
  Failure,
  global, I18nServiceInterface,
  Language,
  LocalDate,
  LocalDateTime,
  LocalTime,
  Option,
  Success,
  TimeHoursFormat,
  TimezonedLocalDateTime, toastr, toFourDigits, toLocalDateTimeFromTimezoned,
  toTimezonedLocalDateTimeFromUTC, toTwoDigits,
  Try
} from "@utils";
import {environment} from "@environments";

export class Translation {
  constructor(readonly fileName: string) {}
  static common = new Translation("common");
  static shared = new Translation("shared");
  static login = new Translation("login");
  static loginLayout = new Translation("login-layout");
  static mainDashboard = new Translation("main-dashboard");
  static admin = new Translation("admin");
  static applications = new Translation("applications");
  static processes = new Translation("processes");
  static designer = new Translation("designer");
  static screenEditor = new Translation("screen-editor");
  static processMapEditor = new Translation("process-map-editor");
  static processValidationErrors = new Translation("process-validation-errors");
  static screen = new Translation("screen");
  static user = new Translation("user");
  static fileUploader = new Translation("file-uploader");
  static date = new Translation("date");
  static tasks = new Translation("tasks");
  static tasksDashboard = new Translation("tasks_dashboard");
  static flows = new Translation("flows");
  static help = new Translation("help");
  static reports = new Translation("reports");
  static documentsRepository = new Translation("documents-repository");
  static fileViewer = new Translation("file-viewer");
  static registerOrganization = new Translation("register-organization");
  static termsAndPolicy = new Translation("terms-and-conditions");
  static emailConfirm = new Translation("email-confirm");
  static passwordRecovery = new Translation("password-recovery");
  static passwordReset = new Translation("password-reset");
  static password = new Translation("password");
  static comments = new Translation("comments");
  static organization = new Translation("organization");
  static groups = new Translation("groups");
  static authorization = new Translation("authorization");
  static system = new Translation("system");
  static calendar = new Translation("calendar");
  static integrations = new Translation("integrations");
  static notifications = new Translation("notifications");
  static mailbox = new Translation("mailbox");
  static standardCategories = new Translation("standard-categories");
  static formValidationErrors = new Translation("form-validation-errors");
  static functions = new Translation("functions");
  static constants = new Translation("constants");
  static datastores = new Translation("datastores");
  static businessModel = new Translation("business-model");
  static mailboxEditor = new Translation("mailbox-editor");
  static businessEntities = new Translation("business-entities");
  static databaseConnections = new Translation("database-connections");
  static schedules = new Translation("schedules");
  static signature = new Translation("signature");
  static dashboardEditor = new Translation("dashboard-editor");
  static dataVisualizationEditor = new Translation("data-visualization-editor");
}

@Injectable({
  providedIn: 'root',
})
export class I18nService implements I18nServiceInterface {

  private loadedTranslations: Array<Translation> = [];
  private defaultOptions: {ns: Array<string>} = {ns: []};

  constructor(private readonly userSettingsService: UserSettingsStateService,
              private readonly userSettingsController: UserSettingsController,
              private readonly navigationService: NavigationService) {
    // This is used in many places so for convenience we give it a global access without angular injection needed
    global.i18nService = this;
    userSettingsService.getLanguageObservable().forEach((language: Option<Language>) => {
      this.onLanguageChanged(this.userSettingsService.getEffectiveLanguage());
    });
  }

  private onLanguageChanged(language: Language) {
    i18next.changeLanguage(language.toBaseLanguage().name).then(() => {
      this.userSettingsController.onLanguageChanged(language);
      this.navigationService.refreshPage();
    });
  }

  initialize(translations: Array<Translation>): Promise<boolean> {

    const translationsToLoad = translations.concat(Translation.common, Translation.shared, Translation.date, Translation.comments).filter(t => this.loadedTranslations.indexOf(t) === -1);

    if(i18next.isInitialized && translationsToLoad.length === 0) {
      return Promise.resolve(true);
    } else {
      return new Promise<boolean>((resolve, reject) => {

        if(i18next.isInitialized) {
          i18next.loadNamespaces(translationsToLoad.map(t => t.fileName)).then(() => {
            resolve(true);
            this.loadedTranslations = this.loadedTranslations.concat(translationsToLoad);
            this.defaultOptions = {ns: this.loadedTranslations.map(t => t.fileName)};
          })
        } else {
          i18next
            .use(HttpApi)
            .init({
              "debug": false,
              "lng": this.userSettingsService.getEffectiveLanguage().toBaseLanguage().name,
              "fallbackLng": [], // Maybe we do not need it
              "ns": translationsToLoad.map(t => t.fileName),
              "backend": {
                "loadPath": "i18n/{{ns}}_{{lng}}.json?nocache="+environment.buildStamp
              }
            }, (err, t) => {
              this.loadedTranslations = this.loadedTranslations.concat(translationsToLoad);
              this.defaultOptions = {ns: this.loadedTranslations.map(t => t.fileName)};
              resolve(true) ;
            });
        }
      });
    }
  }

  translate(key: string): string {
    if(i18next.exists(key, this.defaultOptions)) {
      return i18next.t(key, this.defaultOptions);
    } else {
      return key;
    }
  }

  translateCount(key: string, count: number): string {

    const options = {ns: this.defaultOptions.ns, count: count};
    if(i18next.exists(key, options)) {
      return i18next.t(key,  options);
     } else {
       return key+" "+count;
     }
  }



  formatDate(timestamp: number|Date|LocalDate) {
    const toFormat = timestamp instanceof LocalDate ? timestamp.toDate() : timestamp;
    return new Intl.DateTimeFormat(this.userSettingsService.getEffectiveLocale(),
      {
        year: 'numeric', month: 'short', day: '2-digit',
      }
    ).format(toFormat);
  }

  formatFullDate(timestamp: number|Date|LocalDate) {
    const toFormat = timestamp instanceof LocalDate ? timestamp.toDate() : timestamp;
    // TODO fallback if Intl.DateTimeFormat is not available
    return new Intl.DateTimeFormat(this.userSettingsService.getEffectiveLocale(), {
      dateStyle: 'full',
      timeZone: this.userSettingsService.getEffectiveTimeZone()
    }).format(toFormat);
  }

  formatLongDate(timestamp: number|Date|LocalDate) {
    const toFormat = timestamp instanceof LocalDate ? timestamp.toDate() : timestamp;
    // TODO fallback if Intl.DateTimeFormat is not available
    return new Intl.DateTimeFormat(this.userSettingsService.getEffectiveLocale(), {
      dateStyle: 'long',
      timeZone: this.userSettingsService.getEffectiveTimeZone()
    }).format(toFormat);
  }

  formatMediumDate(timestamp: number|Date|LocalDate) {
    const toFormat = timestamp instanceof LocalDate ? timestamp.toDate() : timestamp;
    // TODO fallback if Intl.DateTimeFormat is not available
    return new Intl.DateTimeFormat(this.userSettingsService.getEffectiveLocale(), {
      dateStyle: 'medium',
      timeZone: this.userSettingsService.getEffectiveTimeZone()
    }).format(toFormat);
  }

  formatShortDate(timestamp: number|Date|LocalDate) {
    const toFormat = timestamp instanceof LocalDate ? timestamp.toDate() : timestamp;
    // TODO fallback if Intl.DateTimeFormat is not available
    return new Intl.DateTimeFormat(this.userSettingsService.getEffectiveLocale(), {
      dateStyle: 'short',
      timeZone: this.userSettingsService.getEffectiveTimeZone()
    }).format(toFormat);
  }

  formatDateTimeSeconds(timestamp: number|Date|LocalDateTime): string {
    // TODO FIX not implemented
    toastr.error("Formatting time with seconds is not implemented yet");
    return "?";
  }


    formatDateTimeMinutes(timestamp: number|Date|LocalDateTime) {

    const toFormat = timestamp instanceof LocalDateTime ? timestamp.asUTCDate() : timestamp;

    // TODO fallback if Intl.DateTimeFormat is not available
    return new Intl.DateTimeFormat(this.userSettingsService.getEffectiveLocale(), {
      timeStyle: 'short',
      dateStyle: 'medium',
      timeZone: this.userSettingsService.getEffectiveTimeZone(),
      hour12: this.userSettingsService.getTimeHoursFormat().map((f: TimeHoursFormat)  => f === TimeHoursFormat.h12).getOrUndefined()
    }).format(toFormat);
  }

  formatTimeSeconds(timestamp: number|Date|LocalDateTime) {
    const toFormat = timestamp instanceof LocalDateTime ? timestamp.asUTCDate() : timestamp;
    // TODO fallback if Intl.DateTimeFormat is not available
    return new Intl.DateTimeFormat(this.userSettingsService.getEffectiveLocale(), {
      timeStyle: 'medium',
      timeZone: this.userSettingsService.getEffectiveTimeZone(),
      hour12: this.userSettingsService.getTimeHoursFormat().map((f: TimeHoursFormat) => f === TimeHoursFormat.h12).getOrUndefined()
    }).format(toFormat);
  }


  /** */
  formatShortDateTime(timestamp: number|Date|LocalDateTime, hideCurrentYear: boolean = false) {
    const toFormat = timestamp instanceof LocalDateTime ? timestamp.asUTCDate() : timestamp;
    // TODO fallback if Intl.DateTimeFormat is not available
    const date = new Intl.DateTimeFormat(this.userSettingsService.getEffectiveLocale(), {
      year: hideCurrentYear ? undefined : "numeric",
      month: 'short',
      day: 'numeric',
      timeZone: this.userSettingsService.getEffectiveTimeZone(),
    }).format(toFormat);


    const time = new Intl.DateTimeFormat(this.userSettingsService.getEffectiveLocale(), {
      timeStyle: 'short',
      timeZone: this.userSettingsService.getEffectiveTimeZone(),
      hour12: this.userSettingsService.getTimeHoursFormat().map((f: TimeHoursFormat) => f === TimeHoursFormat.h12).getOrUndefined()
    }).format(toFormat);

    return date+", "+time;
  }

  /** 03-03-2023 15:56 */
  formatEditableDateTime(timestamp: number|Date|LocalDateTime) {
    const toFormat = timestamp instanceof LocalDateTime ? timestamp.asUTCDate() : timestamp;

    const date = new Intl.DateTimeFormat('pl-PL', {
      day: '2-digit', month: '2-digit', year: 'numeric',
      timeZone: this.userSettingsService.getEffectiveTimeZone(),
      hour12: this.userSettingsService.getTimeHoursFormat().map((f: TimeHoursFormat) => f === TimeHoursFormat.h12).getOrUndefined()
    }).format(toFormat);

    const day = date.substring(0,2);
    const month = date.substring(3,5);
    const year = date.substring(6,10);

    const time = new Intl.DateTimeFormat(this.userSettingsService.getEffectiveLocale(), {
      hour: '2-digit', minute: '2-digit',
      timeZone: this.userSettingsService.getEffectiveTimeZone(),
      hour12: this.userSettingsService.getTimeHoursFormat().map((f: TimeHoursFormat) => f === TimeHoursFormat.h12).getOrUndefined()
    }).format(toFormat);

    return day+"-"+month+"-"+year+" "+time;
  }

  /** 9 mar 2023, 10:20:00 */
  formatMediumDateTime(timestamp: number|Date|LocalDateTime): string {
    const toFormat = timestamp instanceof LocalDateTime ? timestamp.asUTCDate() : timestamp;
    // TODO fallback if Intl.DateTimeFormat is not available
    const formatted = new Intl.DateTimeFormat(this.userSettingsService.getEffectiveLocale(), {
      dateStyle: 'medium',
      timeStyle: 'medium',
      timeZone: this.userSettingsService.getEffectiveTimeZone(),
      hour12: this.userSettingsService.getTimeHoursFormat().map((f: TimeHoursFormat) => f === TimeHoursFormat.h12).getOrUndefined()
    }).format(toFormat);
    return formatted;
  }

  formatTimezonedISO(timestamp: LocalDateTime) {
    const timezoned = this.toTimezonedLocalDateTimeFromUTC(timestamp);
    return toFourDigits(timezoned.date.year)+"-"+toTwoDigits(timezoned.date.month)+"-"+toTwoDigits(timezoned.date.day)+" "+toTwoDigits(timezoned.time.hour)+":"+toTwoDigits(timezoned.time.minute)+":"+toTwoDigits(timezoned.time.second)+"."+timezoned.time.nano;
  }


  toTimezonedLocalDateTimeFromUTC(timestamp: LocalDateTime): TimezonedLocalDateTime {
    return toTimezonedLocalDateTimeFromUTC(timestamp, this.userSettingsService.getEffectiveTimeZone());
  }


  formatRelativeTime(time: LocalDateTime): string;
  formatRelativeTime(timestamp: number): string;
  formatRelativeTime(time: number|LocalDateTime) {
    // TODO fallback if Intl.DateTimeFormat is not available

    const timestamp = time instanceof LocalDateTime ? time.asMillis() : time;

    const units: {[key: string]: number} = {
      "year"  : 24 * 60 * 60 * 1000 * 365,
      "month" : 24 * 60 * 60 * 1000 * 365/12,
      "day"   : 24 * 60 * 60 * 1000,
      "hour"  : 60 * 60 * 1000,
      "minute": 60 * 1000,
      "second": 1000
    };

    const elapsed = timestamp - Date.now();

    for (let u in units) {
      if (Math.abs(elapsed) > units[u] || u == 'second') {
        return new Intl.RelativeTimeFormat(this.userSettingsService.getEffectiveLocale(), {numeric: "auto"}).format(Math.round(elapsed / units[u]), <any>u);
      }
    }

    return "Unit not found";
  }

  formatNumber(input: number, minimumFractionDigits: number, maximumFractionDigits: number) {
    return new Intl.NumberFormat(this.userSettingsService.getEffectiveLocale(), {
      minimumFractionDigits: minimumFractionDigits,
      maximumFractionDigits: maximumFractionDigits
    }).format(input);
  }

  formatCurrency(input: number, currencyCode: string, minimumFractionDigits: number|undefined, maximumFractionDigits: number|undefined) {
    return new Intl.NumberFormat(this.userSettingsService.getEffectiveLocale(), {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: minimumFractionDigits,
      maximumFractionDigits: maximumFractionDigits
    }).format(input);
  }

  /** Parses yyyy-mm-dd hh:mm:ss */
  parseUTCDateTime(input: string): Try<LocalDateTime> {
    // yyyy-mm-dd hh:mm:ss
    let parsed = input.match(/([0-9]{4})-([0-9]{2})-([0-9]{2}) ([0-9]{2}):([0-9]{2}):([0-9]{2})/);
    if(parsed !== null) {
      return Success(new LocalDateTime(new LocalDate(parseInt(parsed[1]), parseInt(parsed[2]), parseInt(parsed[3])), new LocalTime(parseInt(parsed[4]), parseInt(parsed[5]), parseInt(parsed[6]), 0)));
    }

    // yyyy-mm-dd hh:mm
    parsed = input.match(/([0-9]{4})-([0-9]{2})-([0-9]{2}) ([0-9]{2}):([0-9]{2})/);
    if(parsed !== null) {
      return Success(new LocalDateTime(new LocalDate(parseInt(parsed[1]), parseInt(parsed[2]), parseInt(parsed[3])), new LocalTime(parseInt(parsed[4]), parseInt(parsed[5]), 0, 0)));
    }

    // dd-mm-yyyy hh:mm:ss
    parsed = input.match(/([0-9]{2})-([0-9]{2})-([0-9]{4}) ([0-9]{2}):([0-9]{2}):([0-9]{2})/);
    if(parsed !== null) {
      return Success(new LocalDateTime(new LocalDate(parseInt(parsed[3]), parseInt(parsed[2]), parseInt(parsed[1])), new LocalTime(parseInt(parsed[4]), parseInt(parsed[5]), parseInt(parsed[6]), 0)));
    }

    // dd-mm-yyyy hh:mm
    parsed = input.match(/([0-9]{2})-([0-9]{2})-([0-9]{4}) ([0-9]{2}):([0-9]{2})/);
    if(parsed !== null) {
      return Success(new LocalDateTime(new LocalDate(parseInt(parsed[3]), parseInt(parsed[2]), parseInt(parsed[1])), new LocalTime(parseInt(parsed[4]), parseInt(parsed[5]), 0, 0)));
    }

    return Failure('Invalid date time format');
  }


  parseDateTime(input: string): Try<LocalDateTime> {
    const parsedUTC = this.parseUTCDateTime(input);
    if(parsedUTC.isSuccess()) {
      // This is little hacky, as timezone offset might be incorrect close (+- 1hour)
      // to summer/winter time change, as we teke timezone offset from artificial date time
      const timezoned = toTimezonedLocalDateTimeFromUTC(parsedUTC.result, this.userSettingsService.getEffectiveTimeZone());
      const paresdWithTimezoneOffset = new TimezonedLocalDateTime(parsedUTC.result.date, parsedUTC.result.time, timezoned.timezoneOffeset);

      const utc = toLocalDateTimeFromTimezoned(paresdWithTimezoneOffset);

      return Success(utc);
    } else {
      return parsedUTC;
    }

  }


  parseDateTimeTimezoned(input: string): Try<TimezonedLocalDateTime> {
    const parsedUTC = this.parseUTCDateTime(input);
    if(parsedUTC.isSuccess()) {
      // This is little hacky, as timezone offset might be incorrect close (+- 1hour)
      // to summer/winter time change, as we teke timezone offset from artificial date time
      const timezoned = toTimezonedLocalDateTimeFromUTC(parsedUTC.result, this.userSettingsService.getEffectiveTimeZone());
      const paresdWithTimezoneOffset = new TimezonedLocalDateTime(parsedUTC.result.date, parsedUTC.result.time, timezoned.timezoneOffeset);

      return Success(paresdWithTimezoneOffset);
    } else {
      return Failure(parsedUTC.error);
    }

  }

  currentLanguage() {
    return this.userSettingsService.getEffectiveLanguage();
  }

  currentLocale() {
    return this.userSettingsService.getEffectiveLocale();
  }


  timeZoneOffset() {
    return toTimezonedLocalDateTimeFromUTC(LocalDateTime.now(), this.userSettingsService.getEffectiveTimeZone()).timezoneOffeset;
  }

  timeZoneOffsetForDateTime(timestamp: LocalDateTime) {
    return toTimezonedLocalDateTimeFromUTC(timestamp, this.userSettingsService.getEffectiveTimeZone()).timezoneOffeset;
  }

  toPreviousDayIfMidnight(timestamp: LocalDateTime): LocalDateTime|LocalDate {
    const local = toTimezonedLocalDateTimeFromUTC(timestamp, this.userSettingsService.getEffectiveTimeZone());
    if(local.time.isZero()) {
      return local.date.minusDays(1);
    } else {
      return timestamp;
    }
  }
}
