import {FormElementId, FormElementRefId, GridSize, GridXY, I18nText, Option, Trilean} from "@utils";
import {StaticElement, StaticElementRef} from "../FormModel";
import {ExpressionWithAst} from "@shared-model";

export class Label implements StaticElement {
  static className = "Label";
  static defaultTextContent = "Text";

  className() {
    return Label.className;
  }

  isInputElement(): boolean {
    return false;
  }

  constructor(public id: FormElementId,
              public tooltip: I18nText,
              public text: I18nText,
              public bold: boolean,
              public hiddenExpression: Option<ExpressionWithAst>) {}

  static copy(other: Label): Label {
    return new Label(other.id, I18nText.copy(other.tooltip), I18nText.copy(other.text), other.bold, Option.copy(other.hiddenExpression).map(ExpressionWithAst.copy));
  }
}


export class LabelRef implements StaticElementRef {

  static className = "LabelRef";

  className(): string {
    return LabelRef.className;
  }

  isInputElementRef() {
    return false;
  }

  constructor(public id: FormElementRefId,
              public elementId: FormElementId,
              public gridXY: GridXY,
              public gridSize: GridSize,
              public hidden: Trilean) {}

  static copy(other: LabelRef): LabelRef {
    return new LabelRef(other.id, other.elementId, GridXY.copy(other.gridXY), GridSize.copy(other.gridSize),
      Trilean.copy(other.hidden));
  }
}
