<div class="screenInstance screen" *ngIf="viewModel">
  <button class="debugButton" (click)="viewModel.toggleDebugInfo()"
          *ngIf="viewModel.debugAvailable && !externalDebugButton"
          [my-tooltip]="{i18n: 'Debug', position: 'left'}"><i class="mi-info"></i></button>
  <div class="screenCanvas childrenPlain" *ngIf="!viewModel.debugInfoVisible"><div
    *ngFor="let component of viewModel.mainContainerViewModel" class="ScreenComponent ScreenComponent{{component.id}} {{component.errorClass}}"
    [class.nonVisibleComponent]="component.nonVisibleComponent"
    [class.mobile]="responsive.mobile"
    [class.desktop]="responsive.desktop"
    [style]="component.positionCss">
    <div class="componentBodyContainer labelPosition_{{component.label?.positionClass}}">
      <div class="componentLabel" *ngIf="component.label && component.label.visible">
        <component-label [viewModel]="component.label" />
      </div>
    <!--              SHARED -->
    <ng-container [ngSwitch]="component.typeName">
      <my-section-container *ngSwitchCase="'Section'" [viewModel]="$any(component)" />
      <my-modal-container *ngSwitchCase="'Modal'" [viewModel]="$any(component)" />
      <my-repeatable-container *ngSwitchCase="'Repeatable'" [viewModel]="$any(component)" />
      <my-tabs-container *ngSwitchCase="'Tabs'" [viewModel]="$any(component)" />
      <my-view-switcher-container *ngSwitchCase="'ViewSwitcher'" [viewModel]="$any(component)" />
      <my-table-container *ngSwitchCase="'Table'" [viewModel]="$any(component)" />

      <my-single-attachment-input-component *ngSwitchCase="'SingleAttachmentInput'" [viewModel]="$any(component)" />
      <my-multi-attachment-input-component *ngSwitchCase="'MultiAttachmentInput'" [viewModel]="$any(component)" />

      <label-component *ngSwitchCase="'Label'" [viewModel]="$any(component)" />
      <a link-component *ngSwitchCase="'Link'" [viewModel]="$any(component)"></a>
      <button button-component *ngSwitchCase="'Button'" [viewModel]="$any(component)"></button>
      <img image-component *ngSwitchCase="'Image'" [viewModel]="$any(component)" />
      <my-map-component *ngSwitchCase="'Map'" [viewModel]="$any(component)" />
      <my-calendar-component *ngSwitchCase="'Calendar'" [viewModel]="$any(component)" />
      <my-html-component *ngSwitchCase="'Html'" [viewModel]="$any(component)" />

      <my-date-input-component *ngSwitchCase="'DateInput'" [viewModel]="$any(component)" />
      <my-time-input-component *ngSwitchCase="'TimeInput'" [viewModel]="$any(component)" />
      <my-date-time-input-component *ngSwitchCase="'DateTimeInput'" [viewModel]="$any(component)" />
      <my-duration-input-component *ngSwitchCase="'DurationInput'" [viewModel]="$any(component)" />

      <my-single-checkbox-component *ngSwitchCase="'SingleCheckbox'" [viewModel]="$any(component)" />
      <my-multi-checkbox-component *ngSwitchCase="'MultiCheckbox'" [viewModel]="$any(component)" />
      <my-radio-button-component *ngSwitchCase="'RadioButton'" [viewModel]="$any(component)" />
      <my-drop-list-component *ngSwitchCase="'DropList'" [viewModel]="$any(component)" />

      <my-text-input-component *ngSwitchCase="'TextInput'" [viewModel]="$any(component)" />
      <my-number-input-component *ngSwitchCase="'NumberInput'" [viewModel]="$any(component)" />
      <my-password-input-component *ngSwitchCase="'PasswordInput'" [viewModel]="$any(component)" />

      <switch-component *ngSwitchCase="'Switch'" [viewModel]="$any(component)" />

      <div *ngSwitchDefault>
        Unsupported Component {{component.typeName}}
      </div>
    </ng-container>
    <div class="errorsInfo" *ngIf="component.error">
      <i class="errorsIcon mi-sensor-alert" [my-tooltip]="{text: component.errorText, position: 'top'}"></i>
    </div>

    <div class="inProgressGlass" *ngIf="component.inProgress"></div>
    <!--              SHARED -->
    </div>
  </div>
</div>


<!--  <my-file-viewer [visible]="viewModel.screenFileViewerVisible"-->
<!--  [files]="viewModel.screenViewableFiles"-->
<!--  current-file-index="viewModel.screenFileViewerIndex"-->
<!--  on-close="viewModel.onScreenCloseFileViewer()"-->
<!--  full-screen-only="true" />-->

  <my-file-viewer [visible]="viewModel.screenFileViewerVisible"
                  [files]="viewModel.screenViewableFiles"
                  [currentFileIndex]="viewModel.screenFileViewerIndex"
                  (close)="viewModel.onScreenCloseFileViewer()"
                  [fullScreenOnly]="true" />

  <my-modal [backgroundVisible]="false" cssClass="allScreenErrorsInfo" [visible]="viewModel.validationErrors.length > 0 || viewModel.internalErrors.length > 0"
            horizontalPosition="end" verticalPosition="bottom">
    <ng-container *ngIf="viewModel.validationErrors.length > 0 || viewModel.internalErrors.length > 0">
      <button class="minimize" (click)="viewModel.toggleErrorsExpanded()">
        <i *ngIf="!viewModel.errorsExpanded" class="mi-navigate-up"></i>
        <i *ngIf="viewModel.errorsExpanded" class="mi-navigate-down"></i>
      </button>
      <div *ngIf="viewModel.errorsExpanded" class="theme-selectable-text">
        <div class="errorsLabel" *ngIf="viewModel.internalErrors.length > 0">Errors:</div>
        <button class="errorInfo" *ngFor="let error of viewModel.internalErrors">
          <span class="definitionId">{{error.definitionId}}</span>
          <span class="refId">{{error.refId}}</span>
          <div class="componentName"><i class="errorsIcon mi-sensor-alert"></i>{{error.componentName}} :</div>
          <div class="errorMessage" *ngFor="let err of error.errors">{{err.name}}: {{err.message}}</div>
        </button>
        <div class="errorsLabel" *ngIf="viewModel.validationErrors.length > 0">{{'screen_incorrect_form_values' | i18n}}:</div>
        <button class="errorInfo" *ngFor="let error of viewModel.validationErrors">
          <div class="componentName">{{error.componentName}}:</div>
          <div class="errorMessages">
            <div class="errorMessage" *ngFor="let err of error.errors">{{err}}</div>
          </div>
        </button>
      </div>
    </ng-container>
  </my-modal>

  <my-modal cssClass="screenDebugInfoPopup" [(visible)]="viewModel.debugInfoVisible" [backgroundVisible]="true" [closeOnBackgroundClick]="true">
    <my-screen-debug *ngIf="viewModel.debugInfoVisible && viewModel.debugInfo" [viewModel]="viewModel.debugInfo" />
  </my-modal>

</div>
