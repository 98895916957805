<div class="entryName" *ngIf="notRoot && nameEnabled">
  <div class="entryNameContent">
    <button class="expand" (click)="entry.toggleExpanded(); sizeChanged.emit()">
      <i class="mi-navigate-down theme-expand-arrow" [class.theme-expanded]="entry.expanded"></i>
    </button>
    <span class="entryLabel" *ngIf="showLabels && entry.fieldName.length > 0" [my-tooltip]="{text:entry.codeName}">{{entry.fieldName}}</span>
    <span class="codeName" *ngIf="showNames || entry.fieldName.length == 0" [my-tooltip]="{text: entry.fieldName}">{{entry.codeName}}</span>
    <span class="typeName" *ngIf="showTypes" [my-tooltip]="{text: entry.fieldName}">{{entry.dataTypeName}}</span>
    <span class="arraySize theme-count-badge-small">{{entry.subEntriesCount}}</span>
    <button class="arrayExpand theme-button-icon-action-flat-small" (click)="entry.toggleSingleMode(); sizeChanged.emit()" *ngIf="entry.subEntriesCount > 1">
      <i class="mi-expand" *ngIf="entry.arrayEntriesSingleMode"></i>
      <i class="mi-expand" *ngIf="!entry.arrayEntriesSingleMode"></i>
    </button>
    <span class="entriesNavigation" *ngIf="entry.arrayEntriesSingleMode && entry.expanded">
      <button class="theme-button-icon-action-flat-small" (click)="entry.previousSubEntry(); sizeChanged.emit()"
              [class.invisible]="entry.subEntriesCount === 0 || entry.visibleArraySubEntryIndex <= 0"
              [disabled]="entry.subEntriesCount === 0 || entry.visibleArraySubEntryIndex <= 0"
              *ngIf="entry.arrayEntriesSingleMode">
        <i class="mi-navigate-left"></i>
      </button>
      <span>{{entry.visibleArraySubEntryIndex + 1}} / {{entry.subEntriesCount}}</span>
      <button class="theme-button-icon-action-flat-small" (click)="entry.nextSubEntry(); sizeChanged.emit()"
              [class.invisible]="entry.subEntriesCount === 0 || entry.visibleArraySubEntryIndex >= entry.subEntriesCount - 1"
              [disabled]="entry.subEntriesCount === 0 || entry.visibleArraySubEntryIndex >= entry.subEntriesCount - 1"
              *ngIf="entry.arrayEntriesSingleMode">
        <i class="mi-navigate-right"></i>
      </button>
    </span>
  </div>
</div>

<div class="entryValue" *ngIf="entry.expanded && entry.visibleArraySubEntries.length > 0">
  <ng-container *ngFor="let subEntry of entry.visibleArraySubEntries">
    <div class="entryIndex">{{subEntry.index}}.</div>
    <div class="entryValue" [class.first]="subEntry.index === 1 || entry.visibleArraySubEntries.length === 1" [class.last]="subEntry.index === entry.visibleArraySubEntries.length || entry.visibleArraySubEntries.length === 1">
      <metadata-object [selectable]="selectable" (selectedChanged)="selectedChanged.emit()" [nameEnabled]="false" *ngIf="subEntry.objectType" [viewModel]="viewModel" [entry]="subEntry" [validateType]="validateType" [showNames]="showNames" [showLabels]="showLabels" [showTypes]="showTypes" [notRoot]="true"></metadata-object>
      <metadata-array [selectable]="selectable" (selectedChanged)="selectedChanged.emit()" [nameEnabled]="false" *ngIf="subEntry.arrayType" [viewModel]="viewModel" [entry]="subEntry" [validateType]="validateType" [showNames]="showNames" [showLabels]="showLabels" [showTypes]="showTypes" [notRoot]="true"></metadata-array>
      <metadata-simple [selectable]="selectable" (selectedChanged)="selectedChanged.emit()" [nameEnabled]="false" *ngIf="subEntry.simpleType" [viewModel]="viewModel" [entry]="subEntry" [validateType]="validateType" [showNames]="showNames" [showLabels]="showLabels" [showTypes]="showTypes"></metadata-simple>
    </div>
  </ng-container>
</div>

<div class="entryValue" *ngIf="entry.expanded && entry.visibleArraySubEntries.length === 0">
  <div class="empty">Empty</div>
</div>

<div class="entryError" *ngIf="validateType && (entry.notFromType || entry.incorrectType)">
  <i class="mi-sensor-alert warning" *ngIf="!entry.incorrectType && entry.notFromType" [my-tooltip]="{text: 'Not from type'}"></i>
  <i class="mi-sensor-alert error" *ngIf="entry.incorrectType" [my-tooltip]="{text: 'Incorrect type, expected '+entry.expectedTypeName+' but was ' + entry.fieldTypeName}"></i>
</div>
