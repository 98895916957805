import {FormElementId, FormElementRefId, GridSize, GridXY, I18nText, Option, Trilean, Typed} from "@utils";
import {
  ArrayVariable,
  BusinessVariable,
  BusinessVariableFactory,
  BusinessVariablesValidation,
  BusinessVariableValidationFactory,
  ExpressionWithAst,
  VariableTypePath
} from "@shared-model";
import {InputElementRef} from "../FormModel";
import {LabelPosition} from "../FormField";
import {SingleSelectInputElement} from "./RadioButton";

export class DropList extends SingleSelectInputElement {
  static className = "DropList";

  className() {
    return DropList.className;
  }

  constructor(id: FormElementId,
              tooltip: I18nText,
              variableTypePath: VariableTypePath,
              validation: Option<Typed<BusinessVariablesValidation>>,
              entries: ArrayVariable<BusinessVariable>,
              defaultValue: Option<Typed<BusinessVariable>>,
              entriesExpression: Option<ExpressionWithAst>,
              hiddenExpression: Option<ExpressionWithAst>,
              requiredExpression: Option<ExpressionWithAst>,
              readOnlyExpression: Option<ExpressionWithAst>,
              public searchPersonsWithin: boolean,
              public searchDepartmentsWithin: boolean) {
    super(id, tooltip, variableTypePath, validation, entries,
      defaultValue, entriesExpression, hiddenExpression, requiredExpression, readOnlyExpression);
  }

  static copy(other: DropList): DropList {
    return new DropList(other.id, I18nText.copy(other.tooltip),
      VariableTypePath.copy(other.variableTypePath),
      Option.copy(other.validation).map(BusinessVariableValidationFactory.copyTyped),
      <ArrayVariable<BusinessVariable>>BusinessVariableFactory.copyByType(other.entries, ArrayVariable.className),
      Option.copy(other.defaultValue).map(BusinessVariableFactory.copyTyped),
      Option.copy(other.entriesExpression).map(ExpressionWithAst.copy),
      Option.copy(other.hiddenExpression).map(ExpressionWithAst.copy),
      Option.copy(other.requiredExpression).map(ExpressionWithAst.copy),
      Option.copy(other.readOnlyExpression).map(ExpressionWithAst.copy),
      other.searchPersonsWithin,
      other.searchDepartmentsWithin);
  }
}

export class DropListRef implements InputElementRef {
  static className = "DropListRef";

  className(): string {
    return DropListRef.className;
  }

  isInputElementRef() {
    return true;
  }

  constructor(public id: FormElementRefId,
              public elementId: FormElementId,
              public gridXY: GridXY,
              public gridSize: GridSize,
              public label: I18nText,
              public labelPosition: LabelPosition,
              public labelGridShift: GridXY,
              public required: Trilean,
              public readOnly: Trilean,
              public hidden: Trilean,
              public visibleInSummary: boolean,
              public visibleInTaskBox: boolean) {}

  static copy(other: DropListRef): DropListRef {
    return new DropListRef(other.id, other.elementId, GridXY.copy(other.gridXY), GridSize.copy(other.gridSize),
      I18nText.copy(other.label), LabelPosition.copy(other.labelPosition), GridXY.copy(other.labelGridShift),
      Trilean.copy(other.required), Trilean.copy(other.readOnly), Trilean.copy(other.hidden), other.visibleInSummary, other.visibleInTaskBox);
  }
}
