import {Component, Input} from "@angular/core";
import {AnyPersonId, AnyPersonIdHelper, organizationsImageDownloadUrl, PersonId} from "@utils";
import {Constants, GravatarService, SessionServiceProvider, TooltipPosition} from "@shared";
import {BasicPersonInfo, PersonsSharedService} from "@shared-model";

@Component({
  selector: 'my-person-summary',
  templateUrl: './person-summary.component.html',
  host: {
    '[class.small]': 'small',
    '[class.avatarEnd]': 'avatarPosition === "end"',
  }
})
export class PersonSummaryComponent {

  private _personId!: AnyPersonId;
  get personId() {
    return this._personId;
  }
  @Input({required:true}) set personId(value: AnyPersonId) {
    this._personId = value;
    this.update();
  }

  @Input() avatar: boolean = true;
  @Input() showName: boolean = true;
  @Input() showTooltip: boolean = false;
  @Input() tooltipPosition: TooltipPosition = "right";
  @Input() small: boolean = false;
  @Input() shortCurrentUserLabel: string|undefined;
  @Input() avatarPosition: "start"|"end" = "start";

  incomingEmailPerson = false;

  personInfo: BasicPersonInfo|undefined = undefined;
  avatarUrl: string|null = null;
  gravatarBlobUrl: string|null = null;

  remoteOrganizationInfo: string = "";

  currentUser: boolean = false;

  constructor(readonly personsSharedService: PersonsSharedService,
              readonly sessionServiceProvider: SessionServiceProvider,
              readonly gravatarService: GravatarService) {
  }


  private update() {
    if (AnyPersonIdHelper.equals(this._personId, Constants.incomingEmailPersonId)) {
      this.incomingEmailPerson = true;
    } else {

      this.personsSharedService.findPersonBasicInfo([this._personId], (personInfos: { [personId: string]: BasicPersonInfo }) => {
        this.personInfo = personInfos[this._personId.serialize()];

        if (this.personInfo && this.personInfo.avatar.isDefined()) {
          this.avatarUrl = organizationsImageDownloadUrl(this.personInfo.avatar.get());
          this.gravatarBlobUrl = null;
        } else if (this.personInfo && this.personInfo.gravatarHash().length > 0) {
          this.avatarUrl = null;
          this.gravatarService.getImageBlobUrl(this.personInfo.gravatarHash()).then((result) => {
            this.gravatarBlobUrl = result.getOrNull();
          });
        }

        if (this.personInfo === undefined || this.personInfo.idUnwrapped().isPersonId()) {
          this.remoteOrganizationInfo = "";
        } else {
          this.remoteOrganizationInfo = this.personInfo.idUnwrapped().asRemotePersonId().remoteOrganization;
        }


      });

    }

    this.sessionServiceProvider.getOrganizationSessionInfo(sessionInfo => {
      this.currentUser = AnyPersonIdHelper.equals(this._personId, PersonId.of(sessionInfo.personId));
    });

  }

}
