import {BusinessVariable, DateTimeVariable, DateVariable} from "@shared-model";
import {__, LocalDateTime, None, Option, overwriteArray, Some, VariableId} from "@utils";
import {ScreenComponentViewModel, ScreenContainerViewModel, ScreenWrapperViewModel} from "../screen-component.view-model";
import {
  CalendarComponentDefinition,
  CalendarComponentRef,
  CalendarSelectedPopupDefinition, LayoutType, ScreenComponentRefIdInScreen
} from "@screen-common";
import {ScreenSharedViewModel} from "../../model/ViewModel";
import {CalendarComponentRefState, CalendarComponentState} from "./CalendarComponentState";
import {ScreenInstanceServerModel} from "../../screen-instance.server-model";
import {CalendarEntryModel, DropDownSelectorOption, ScreenComponentRefId} from "@shared";
import {ResourceCalendarViewModel} from "./ResourceCalendarViewModel";
import {MonthlyCalendarViewModel} from "./MonthlyCalendarViewModel";
import {WeeklyCalendarViewModel} from "./WeeklyCalendarViewModel";
import {YearlyCalendarViewModel} from "./YearlyCalendarViewModel";
import {CalendarEventBus} from "@shared";


export class CalendarSelectedPopupViewModel {
  readonly children: Array<ScreenComponentViewModel> = [];
  public layout: string = LayoutType.horizontal.name;

  constructor(readonly parent: CalendarComponentViewModel,
              readonly definition: CalendarSelectedPopupDefinition) {
  }

  update() {
    // this.css = ComponentViewModelUtils.toBoxCss(this.definition.boxProperties, this.componentState.boxState);
    const children = this.definition.children.map((childRefId, index) => {
      return __(this.children).find(child => child.ref.id.id === childRefId.id).
      getOrElseLazy(() => this.parent.createViewModel(this.parent, ScreenComponentRefIdInScreen.of(this.parent.componentScreenId, childRefId), None()));
    });
    overwriteArray(this.children, children);

    this.layout = this.definition.layout.name;
  }

  static create(parent: CalendarComponentViewModel, definition: CalendarSelectedPopupDefinition) {

    return new CalendarSelectedPopupViewModel(parent, definition);
  }
}

export enum CalendarComponentType {
  MONTHLY, WEEKLY, YEARLY, RESOURCES
}

export class CalendarTypesOption implements DropDownSelectorOption {
  constructor(readonly name: string,
              readonly value: CalendarComponentType) {}
}

export class CalendarComponentViewModel extends ScreenContainerViewModel {


  override typeName = "Calendar";

  public entries: Array<CalendarEntryModel> = [];
  public css: string = "";
  public monthlyType: boolean = true;
  public weeklyType: boolean = false;
  public resourceType: boolean = false;
  public yearlyType: boolean = false;
  readonly calendarEventBus = new CalendarEventBus();

  readonly typeOptions = [
    new CalendarTypesOption("Miesięczny", CalendarComponentType.MONTHLY),
    new CalendarTypesOption("Tygodniowy", CalendarComponentType.WEEKLY),
    new CalendarTypesOption("Roczny", CalendarComponentType.YEARLY),
    new CalendarTypesOption("Zasoby", CalendarComponentType.RESOURCES)];


  calendarType = this.typeOptions[0];

  public selectedValue: Option<BusinessVariable> = None()

  public monthly = new MonthlyCalendarViewModel(this, this.calendarEventBus, (entry) => {
    this.entrySelected(entry);
  });

  public weekly = new WeeklyCalendarViewModel(this, this.calendarEventBus, (entry) => {
    this.entrySelected(entry);
  })

  public resource = new ResourceCalendarViewModel(this, this.calendarEventBus, (entry) => {
    this.entrySelected(entry);
  })

  public yearly = new YearlyCalendarViewModel(this, this.calendarEventBus, (entry) => {
    this.entrySelected(entry);
  })

  public selectedPopupVisible = false;

  private static entryId = 0;

  constructor(override readonly shared: ScreenSharedViewModel,
              override readonly parent: ScreenContainerViewModel | ScreenWrapperViewModel,
              readonly context: VariableId,
              override readonly definition: CalendarComponentDefinition,
              override readonly componentScreenId: string,
              readonly ref: CalendarComponentRef,
              override readonly refScreenId: string,
              readonly componentState: CalendarComponentState,
              readonly refState: CalendarComponentRefState,
              readonly serverModel: ScreenInstanceServerModel,
              public selectedPopup: Option<CalendarSelectedPopupViewModel>,
              readonly createViewModel: (parent: ScreenContainerViewModel, componentRef: ScreenComponentRefIdInScreen, childContext: Option<VariableId>) => ScreenComponentViewModel) {
    super(parent, componentState, refState, definition, shared);
    this.update();
  }

  getAllChildren(): Array<ScreenComponentViewModel> {
    return [];
  }

  static create(shared: ScreenSharedViewModel,
                parent: ScreenContainerViewModel | ScreenWrapperViewModel,
                context: VariableId,
                definition: CalendarComponentDefinition,
                componentScreenId: string,
                ref: CalendarComponentRef,
                refScreenId: string,
                componentState: CalendarComponentState,
                refState: CalendarComponentRefState,
                serverModel: ScreenInstanceServerModel,
                createViewModel: (parent: ScreenContainerViewModel, componentRef: ScreenComponentRefIdInScreen, childContext: Option<VariableId>) => ScreenComponentViewModel) {



    const viewModel =  new CalendarComponentViewModel(shared, parent, context, definition, componentScreenId,
      ref, refScreenId, componentState, refState, serverModel, None(), createViewModel);

    const popup = definition.selectedPopup.map(p => {
      return CalendarSelectedPopupViewModel.create(viewModel, p);
    });

    viewModel.injectSelectedPopup(popup);

    return viewModel;
  }

  static nextPeriodId() {
    return CalendarComponentViewModel.entryId++;
  }

  static hashCode(text: string): number {
    let hash = 0;
    for (let i = 0; i < text.length; i++) {
      hash += Math.pow(text.charCodeAt(i) * 31, text.length - i);
      hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
  }

  calendarTypeChanged = (calendarType: CalendarTypesOption) => {
    this.calendarType = calendarType;
    this.monthlyType = calendarType.value == CalendarComponentType.MONTHLY;
    this.weeklyType = calendarType.value == CalendarComponentType.WEEKLY;
    this.resourceType = calendarType.value == CalendarComponentType.RESOURCES;
    this.yearlyType = calendarType.value == CalendarComponentType.YEARLY;
    if(this.monthlyType) {
      this.monthly.updateEntries(this.entries);
    }
    if(this.weeklyType) {
      this.weekly.updateEntries(this.entries);
    }
    if(this.resourceType) {
      this.resource.updateEntries(this.entries);
    }
    if(this.yearlyType) {
      this.yearly.updateEntries(this.entries);
    }
  };

  updateContainer(deep: boolean): void {


    if(this.uncoveredAndVisible) {
      this.selectedPopup.forEach(p => p.update());
    }

    this.entries = this.componentState.entries.unwrappedValue().map(entry => {

      const fromVariable: BusinessVariable = entry.valueFor("from").getOrError("required 'from' value not available");
      const toVariable = entry.valueFor("to");

      let from: LocalDateTime;
      if(fromVariable.className() == DateVariable.className) {
        from = LocalDateTime.fromLocalDateEnd((<DateVariable>fromVariable).value);
      } else if(fromVariable.className() == DateTimeVariable.className) {
        from = (<DateTimeVariable>fromVariable).value;
      } else {
        throw new Error("'From' must be Date or DateTime, not [" + fromVariable.className()+"]");
      }

      let to: LocalDateTime|null = null;
      if(toVariable.isDefined()) {
        if(toVariable.get().className() == DateVariable.className) {
          to = LocalDateTime.fromLocalDateEnd((<DateVariable>toVariable.get()).value);
        } else if(toVariable.get().className() == DateTimeVariable.className) {
          to = (<DateTimeVariable>toVariable.get()).value;
        } else {
          throw new Error("'To' must be Date or DateTime, not [" + fromVariable.className()+"]");
        }
      }


      const value = entry.valueFor("value").getOrError("Required 'value' not available");
      const label = entry.valueFor("label").map(d => d.valueToSimpleString()).getOrError("Required 'label' not available");
      const type = entry.valueFor("type").getOrNull();
      const resource = entry.valueFor("resource").getOrNull();
      return new CalendarEntryModel(value, resource, type, from, to, label);
    });

    this.selectedValue = this.componentState.selected.valueOrDefault(None());

    this.monthly.updateEntries(this.entries);
    this.weekly.updateEntries(this.entries);
    this.resource.updateEntries(this.entries);
    this.yearly.updateEntries(this.entries);
    super.updatePosition();

    this.css = this.sizeCss;

  }


  entrySelected(entry: CalendarEntryModel) {
    this.serverModel.changeModelWithAction(this.componentRefPath(), CalendarComponentDefinition.SELECTED, entry.value, CalendarComponentDefinition.ON_SELECTED_CHANGE);
    this.selectedPopupVisible = true;
    this.calendarEventBus.entrySelected(entry);
  }

  entryUnselected() {
    this.serverModel.clearModel(this.componentRefPath(), CalendarComponentDefinition.SELECTED);
    this.selectedPopupVisible = false;
    this.calendarEventBus.entryDeselected();
  }

  private injectSelectedPopup(selectedPopup: Option<CalendarSelectedPopupViewModel>) {
    this.selectedPopup = selectedPopup;
  }

  childDeltaHeightChanged(): void {
  }

  getComponentById(id: number): Option<ScreenComponentViewModel> {
    throw new Error("Not yet implemented");
  }

  getLayoutForComponent(componentRefId: ScreenComponentRefId): LayoutType {
    throw new Error("Not implemented");
  }

}
