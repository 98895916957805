<div *ngFor="let node of visibleAuthorizations">
  <div class="authorization" *ngIf="!hideNode || node.id.id !== hideNode.id.id">
    <my-node-summary [nodeId]="node" />
    <button class="theme-button-icon-action-flat" (click)="removeAuthorization(node)" *ngIf="!disabled"><i class="mi-close"></i></button>
  </div>
</div>

<ng-container *ngIf="!disabled">

  <button #Assign class="theme-button-link-icon-small" (click)="toggleAddAuthorization()"><i class="mi-user-add"></i>{{addButtonLabel}}</button>

  <my-drop-menu cssClass="addNewMember" position="bottom" backgroundStyle="light" [contentChangeNotifier]="contentChangeNotifier"
                [(visible)]="selectorVisible && resultsAvailable" [anchor]="Assign" [backgroundVisible]="true" [closeOnBlur]="true" (closed)="selectorVisible = false">

    <my-organization-nodes-search-box *ngIf="selectorVisible" [excludeNodes]="authorizations" (resultsChanged)="onResultsChanged()"
                                      [persons]="persons"
                                      [includeDeletedNodes]="includeDeletedNodes"
                                      [departments]="departments"
                                      [groups]="groups"
                                      [services]="services"
                                      [processes]="processes"
                                      [reports]="reports"
                                      [applications]="applications"
                                      [screens]="screens"
                                      [functions]="functions"
                                      [anonymous]="anonymous"
                                      [inAllApplications]="inAllApplications"
                                      [applicationId]="applicationId"
                                      (nodeSelected)="newAuthorizationSelected($event)" [newEntryPlaceholder]="'common_add' | i18n" [alwaysShowResults]="true" />

  </my-drop-menu>

</ng-container>
