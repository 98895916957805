import {Injectable} from "@angular/core";
import {
  LibrariesService,
  NavigationService,
  SessionEventBus,
  SessionServiceProvider
} from "@shared";
import {AuthenticatedHttp} from "@shared-model";

declare function initChatbot(pagePrefix: string, rewriteUrlTo: string|undefined, container: HTMLElement, text2speech: boolean, speech2text: boolean,
                             userFirstName?: string, userLastName?: string, userPersonId?: string, userToken?: string): void;


interface BasicAuthenticatedUserInfoWithToken {
  personId: string;
  firstName: string;
  lastName: string;
  token: string;
}


@Injectable({
  providedIn: 'root',
})
export class ChatbotService {
  private container?: HTMLElement;

  constructor(private readonly librariesService: LibrariesService,
              private readonly sessionServiceProvider: SessionServiceProvider,
              private readonly sessionEventBus: SessionEventBus,
              private readonly authenticatedHttp: AuthenticatedHttp,
              private readonly navigationService: NavigationService) {

    // sessionEventBus.on(sessionEventBus.userLoggedIn, () => {
    //   this.initChatbot();
    // });
    //
    // sessionEventBus.on(sessionEventBus.userLoggedOut, () => {
    //   this.stopChatbot();
    // });

  }


  public getAuthenticationToken(onSuccess: (token: BasicAuthenticatedUserInfoWithToken) => void) {
    this.authenticatedHttp.getPromise<BasicAuthenticatedUserInfoWithToken>("session/get-authentication-token-for-user").then(response => {
      onSuccess(response);
    });


    // ((response: HttpResponse<{ token: string, authenticationLink: string }>) => {
    //   toastr.info("token " + response.token);
    // });
  }

  public initChatbot() {

    if(window.location.host === "ai.next.neula.in" ||
      window.location.host === "presentation.neula.cloud") { // for now only in ai environment

      this.sessionServiceProvider.getSessionService(sessionService => {
        if (sessionService.isLoggedIn()) {

          this.getAuthenticationToken((token) => {
            this.librariesService.loadChatbot(() => {
              this.init(token);
            });
          });
        }
      });
    }

  }

  private init(authenticatedInfo: BasicAuthenticatedUserInfoWithToken) {
    if(this.container) {
      this.container.remove();
    }
    this.container = document.createElement('div');
    document.body.appendChild(this.container);

    initChatbot("https://ai.neula.in/chatbot-master", "https://ai.neula.in:", this.container, true, true,
      authenticatedInfo.firstName, authenticatedInfo.lastName, authenticatedInfo.personId, authenticatedInfo.token);
  }


  private stopChatbot() {
    if(this.container) {
      this.container.remove();
    }
  }
}
