import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewContainerRef} from '@angular/core';
import {
  __,
  clearArray,
  mySetInterval,
  mySetTimeoutNoAngular,
  Option, required,
  ScreenInstanceId,
  setPageVisibleBox,
} from "@utils";
import {ScreenInstanceService} from "./";
import {ActivatedRoute} from "@angular/router";
import {BusinessVariable, ScreenExternalEventBus} from "@shared-model";
import {ScreenInstanceSharedWrapperService} from "@shared";


export class ScreenPortletViewModel {
  notFound: boolean = false;
  error: string | null = null;
  cssContent: string = "";

  screenInstanceId: ScreenInstanceId | null = null;

  readonly screenExternalEventBus = new ScreenExternalEventBus();

  readonly eventBusSubscriptions: Array<number> = [];

  constructor(readonly screenInstanceService: ScreenInstanceService,
              readonly screenInstanceSharedService: ScreenInstanceSharedWrapperService,
              readonly iframeId: string|null,
              applicationIdentifier: string|null,
              screenIdentifier: string|null,
              screenInstanceId: ScreenInstanceId|null,
              params: any|null) {
    this.screenInstanceId = screenInstanceId;
    if(screenIdentifier !== null && applicationIdentifier !== null) {
      this.runInstance(applicationIdentifier, screenIdentifier, params);
    }

    this.eventBusSubscriptions.push(this.screenExternalEventBus.on(this.screenExternalEventBus.outputParameterChanged, (input: boolean, variableName: string, value: Option<BusinessVariable>) => {
      const iframeIdPart = this.iframeId == undefined ? "" : (this.iframeId + "::");
      const simpleValue = value.map(v => v.valueToSimpleString()).getOrNull();
      if(input) {
        console.log("Not implemented");
       // $location.search(variableName, simpleValue);
      }
      parent.postMessage("screenPortletAttributeChanged::"+iframeIdPart+JSON.stringify([variableName, simpleValue]),"*");
    }));
  }

  static forNewScreenInstance(screenInstanceService: ScreenInstanceService, screenInstanceSharedService: ScreenInstanceSharedWrapperService,
                              iframeId: string|null,
                              applicationIdentifier: string,
                              screenIdentifier: string, params: Record<string, string>) {
    return new ScreenPortletViewModel(screenInstanceService, screenInstanceSharedService, iframeId, applicationIdentifier, screenIdentifier, null, params);
  }

  static forGivenScreenInstance(screenInstanceService: ScreenInstanceService, screenInstanceSharedService: ScreenInstanceSharedWrapperService,
                                iframeId: string|null,
                                screenInstanceId: ScreenInstanceId) {
    return new ScreenPortletViewModel(screenInstanceService, screenInstanceSharedService, iframeId, null, null, screenInstanceId, null);
  }

  runInstance(applicationIdentifier: string, screenIdentifier: string, params: any) {
    this.screenInstanceService.runExternal(applicationIdentifier, screenIdentifier, this.toParamsList(params),
      (screenInstanceId: ScreenInstanceId, volatileScreen: boolean) => {

      this.screenInstanceId = screenInstanceId;
      this.error = null;
    }, (error: string) => {
      this.error = error;
      this.screenInstanceId = null;
      console.log("Error when starting screen:" + error);
    });

  }

  private toParamsList(params: any): Array<[string, string]> {
    const paramsList: Array<[string, string]> = [];
    for (const [key, value] of (<any>Object).entries(params)) {
      paramsList.push([key, value]);
    }
    return paramsList;
  }

  destroy() {
    this.eventBusSubscriptions.forEach(s => this.screenExternalEventBus.unsubscribe(s));
    clearArray(this.eventBusSubscriptions);
  }
}


  export class ScreenPortletController {
    private sizeInterval = 0;
    constructor(readonly element: HTMLElement,
                readonly iframeId: string|undefined,
                readonly fontSizePx: number,
                readonly backgroundColor: string) {
    }

    start() {

      // Resize iframe
      this.sizeInterval = <any>mySetInterval(() => {
        if(this.element !== null) {
          const height = this.element.querySelector(".screenCanvas")?.clientHeight;
          const iframeIdPart = this.iframeId === undefined ? "" : this.iframeId + "::";
          parent.postMessage("screenPortletResize::" + iframeIdPart + height, "*");
        }
      }, 100);
    }


    stop() {
      clearInterval(this.sizeInterval);
      this.sizeInterval = 0;
    }

  }


@Component({
  selector: 'my-screen-portlet',
  templateUrl: './screen-portlet.component.html',
  styleUrls: ['./screen-portlet.component.scss']
})
export class ScreenPortletComponent implements OnInit, OnDestroy {

  viewModel: ScreenPortletViewModel|null = null;
  iframeId: string|null = null;
  controller!: ScreenPortletController;

  constructor(private screenInstanceService: ScreenInstanceService,
              private screenInstanceSharedService: ScreenInstanceSharedWrapperService,
              private readonly viewContainerRef: ViewContainerRef,
              private changeDetectorRef: ChangeDetectorRef) {}

  private lastHash = "";

  ngOnInit() {

    this.init();
    this.lastHash = window.location.pathname;

    mySetInterval(this.onLocationChanged, 100); // there seem to be no way to listen on url search params



    // Handling setting of visible box size from external page (if portlet is in iframe)

    const eventMethod = <any>window.addEventListener ? "addEventListener" : "attachEvent";
    const eventer: any = window[<any>eventMethod];
    const messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";

    eventer(messageEvent,(e: any) => {
      if((typeof e.data == "string") && e.data.indexOf('portletVisibleBox::') !== -1) {
        const visibleBox = JSON.parse(e.data.substring("portletVisibleBox::".length));
        setPageVisibleBox(visibleBox);
      }
    })
  }

  private onLocationChanged = () => {
    if(window.location.pathname !== this.lastHash) {
      this.lastHash = window.location.pathname;
      this.init();
    }
  }


  private init() {

    let newViewModel!: ScreenPortletViewModel;

    const parsed = this.parseUrl();

    if(parsed.screenInstanceId !== undefined) {
      newViewModel = ScreenPortletViewModel.forGivenScreenInstance(this.screenInstanceService, this.screenInstanceSharedService, this.iframeId,
        new ScreenInstanceId(parsed.screenInstanceId));
    } else if(parsed.applicationIdentifier !== undefined && parsed.screenIdentifier !== undefined) {
      newViewModel = ScreenPortletViewModel.forNewScreenInstance(this.screenInstanceService, this.screenInstanceSharedService, this.iframeId,
        parsed.applicationIdentifier, parsed.screenIdentifier, this.getParamsFromUrl());
    } else {
      throw new Error("Incorrect params");
    }

    this.controller = new ScreenPortletController(this.viewContainerRef.element.nativeElement, undefined, 13, "#ffffff");
    this.controller.start();



    this.initGlobalStyles(parsed.fontSizePx, parsed.backgroundColor, parsed.fontFamily, parsed.pageScroll);

    // TODO make it responsive
    const int = mySetInterval(() => {
      if(newViewModel.screenInstanceId !== null || newViewModel.error !== null) {
        this.viewModel = newViewModel;
        this.changeDetectorRef.detectChanges();
        clearInterval(int);
        mySetTimeoutNoAngular(() => { // TODO this can be improved by getting callback from screen view model, or maybe from screen component
          parent.postMessage("screenPortletLoaded","*");
        });
      }
    }, 8);


  }

  private getParamsFromUrl() {
    const params: Record<string, string> = {};
    for (const [key, value] of (new URLSearchParams(window.location.search).entries())) {
      params[key] = value;
    }
    return params;
  }

  private initGlobalStyles(fontSizePx: number|undefined, backgroundColor: string|undefined, fontFamily: string|undefined, pageScroll: "on"|"off"|undefined) {
    let style = "";

    if (fontSizePx !== undefined) {
      style += `html, body {font-size: ${fontSizePx}px !important;}`;
    }

    if (backgroundColor !== undefined) {
      style += "html, body {background-color: #" + backgroundColor + " !important;} #pageWelcome {background-color: #" + backgroundColor + " !important;}";
    }

    if (pageScroll === "on") {
      style += `html {overflow-y: scroll !important;}`;
    } else if (pageScroll === "off") {
      style += `html {overflow-y: hidden !important;}`;
    }

    if (style.length > 0) {
      required(document.getElementById("predefinedStyle"), "No predefinedStyle").innerHTML = style;
    }
  }

  private parseUrl(): {
    screenInstanceId?: string,
    applicationIdentifier?: string,
    screenIdentifier?: string,
    backgroundColor?: string,
    pageScroll?: "on"|"off",
    fontSizePx?: number,
    fontFamily?: string,
    iframeId?: string
  } {


    // Removes first segment of URL
    const urlWithoutFirstSegment = window.location.pathname.substring(window.location.pathname.indexOf("/", 1));

    let questionMarkIndex = urlWithoutFirstSegment.indexOf("?");
    const hash = questionMarkIndex >= 0
      ? urlWithoutFirstSegment.substring(1, questionMarkIndex)
      : urlWithoutFirstSegment.substring(1);

    const urlPath = hash.split("/").filter(t => t.length > 0);

    let screenInstanceId: string|undefined = undefined;
    let applicationIdentifier: string|undefined = undefined
    let screenIdentifier: string|undefined = undefined;
    let backgroundColor: string|undefined = undefined;
    let fontSizePx: number|undefined = undefined;
    let fontFamily: string|undefined = undefined;
    let iframeId: string|undefined = undefined;
    let pageScroll: string|undefined = undefined;


    let portletStyleParams: Array<string> = [];
    if(urlPath.length === 0) {
      throw new Error("Incorrect URL (1)");
    } else if(urlPath[0].startsWith("@")) {
      screenInstanceId = urlPath[0].substring(1);
      portletStyleParams = urlPath.slice(1);
    } else if(urlPath.length === 1) {
      throw new Error("Incorrect URL (2)");
    } else {
      applicationIdentifier = urlPath[0];
      screenIdentifier = urlPath[1];
      portletStyleParams = urlPath.slice(2);
    }

    __(portletStyleParams).find(p => p.toLowerCase().match(/^n-background-color=[0-9a-f]{6}$/) !== null).forEach(definition => {
      backgroundColor = definition.substring("n-background-color=".length);
    });

    __(portletStyleParams).find(p => p.toLowerCase().match(/^n-font-size=[1-9]?[0-9]+$/) !== null).forEach(definition => {
      fontSizePx = parseInt(definition.substring("n-font-size=".length));
    });

    __(portletStyleParams).find(p => p.toLowerCase().match(/^n-scroll=(on|off)$/) !== null).forEach(definition => {
      pageScroll = definition.substring("n-scroll=".length);
    });

    __(portletStyleParams).find(p => p.toLowerCase().match(/^n-iframe-id=[1-9]?[0-9]+$/) !== null).forEach(definition => {
      iframeId = definition.substring("n-iframe-id=".length);
    });

    return {screenInstanceId: screenInstanceId,
      applicationIdentifier: applicationIdentifier,
      screenIdentifier: screenIdentifier,
      backgroundColor: backgroundColor,
      fontSizePx: fontSizePx,
      pageScroll: pageScroll,
      fontFamily: fontFamily,
      iframeId: iframeId
    };

  }

  // based on https://stackoverflow.com/questions/2090551/parse-query-string-in-javascript
  private parseParams(queryString: string): Record<string, string> {
    let query: Record<string, string> = {};
    let pairs = (queryString[0] === '?' ? queryString.substring(1) : queryString).split('&');
    for (let i = 0; i < pairs.length; i++) {
      let pair = pairs[i].split('=');
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
  }



  ngOnDestroy(): void {
    window.removeEventListener('locationchange', this.onLocationChanged);
    this.controller.stop();
  }


}
