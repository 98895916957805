import {
  AggregateId,
  AggregateVersion,
  ApplicationId,
  I18nText,
  None,
  Option,
  OrganizationId,
  OrganizationNodeId,
  PersonId
} from "@utils";


export class ApplicationAuthorizations {
  constructor(readonly read: Array<OrganizationNodeId>,
              readonly edit: Array<OrganizationNodeId>,
              readonly groupsManagement: Array<OrganizationNodeId>,
              readonly access: Array<OrganizationNodeId>) {
  }

  static copy(other: ApplicationAuthorizations) {
    return new ApplicationAuthorizations(
      other.read.map(OrganizationNodeId.copy),
      other.edit.map(OrganizationNodeId.copy),
      other.groupsManagement.map(OrganizationNodeId.copy),
      other.access.map(OrganizationNodeId.copy)
    )
  }
}





export class UsableApplicationSummary {
  constructor(readonly id: ApplicationId,
              readonly name: string,
              readonly identifier: string,
              readonly categoryId: number,
              readonly description: string,
              readonly iconCode: string,
              readonly colorId: number) {}

  static copy(other: UsableApplicationSummary) {
    return new UsableApplicationSummary(
      ApplicationId.copy(other.id),
      other.name,
      other.identifier,
      other.categoryId,
      other.description,
      other.iconCode,
      other.colorId);
  }
}




export class ApplicationsSearchQuery {

  constructor(public textQuery: Option<string>,
              public onlyOwnedByPerson: Option<AggregateId>) {
  }

  static empty(): ApplicationsSearchQuery {
    return new ApplicationsSearchQuery(None(), None());
  }
}


