import {
  AggregateId,
  ApplicationId,
  Duration,
  i18n,
  I18nText,
  LocalDate,
  LocalDateTime,
  LocalTime,
  Option,
  ScreenId,
  ScreenInstanceId,
  ScreenReleaseId,
  Typed,
  VariableId,
  WidgetInstanceId
} from "@utils";
import {
  RepeatableContext,
  ScreenComponentRefState,
  ScreenComponentRefStateFactory,
  ScreenComponentsState,
  ScreenComponentState,
  ScreenComponentStateFactory
} from "..";
import {NewScreenInstanceEvents, ScreenComponentRefId, ScreenInstanceStateEvent, ScreenLifeMode} from "@shared";
import {BusinessVariable, BusinessVariableFactory, ObjectVariable, ProcessEdgeId} from "@shared-model";
import {ScreenComponentIdInScreen, ScreenComponentRefIdInScreen, ScreenComponents} from "@screen-common";


export class GetScreenInstanceForWidgetScope {
    constructor(
      readonly widgetInstanceId: WidgetInstanceId) {}
  }



  export class ScreenDefinitionComponents {
    constructor(  public components: Array<[string, ScreenComponents]>) {}

    static copy(other: ScreenDefinitionComponents) {
      return new ScreenDefinitionComponents(
        other.components.map((s: [string, ScreenComponents]) => <[string, ScreenComponents]>[s[0], ScreenComponents.copy(s[1])])
      )
    }
  }

  export class ScreenInstanceState {

    constructor(readonly id: ScreenInstanceId,
                readonly screenId: ScreenId,
                readonly screenReleaseId: ScreenReleaseId,
                readonly applicationId: ApplicationId,
                readonly mainTitle: string,
                readonly subTitle: Option<string>,
                readonly contextId: VariableId,
                readonly version: number,
                readonly subscriptionId: string,
                readonly allScreensReleases: Array<string>,
                readonly workingCopy: boolean,
                public components: Option<Array<[string, ScreenComponents]>>,
                readonly root: ScreenComponentRefId,
                readonly componentsState: ScreenComponentsState,
                readonly contextProviderId: Option<AggregateId>,
                readonly lifeMode: ScreenLifeMode) {}

    static copy(other: ScreenInstanceState): ScreenInstanceState {
      return new ScreenInstanceState(
        ScreenInstanceId.copy(other.id),
        ScreenId.copy(other.screenId),
        ScreenReleaseId.copy(other.screenReleaseId),
        ApplicationId.of(other.applicationId),
        other.mainTitle,
        Option.copy(other.subTitle),
        VariableId.copy(other.contextId),
        other.version,
        other.subscriptionId,
        other.allScreensReleases.slice(),
        other.workingCopy,
        Option.copy(other.components, c => c.map((s: [string, ScreenComponents]) => <[string, ScreenComponents]>[s[0], ScreenComponents.copy(s[1])])),
        ScreenComponentRefId.copy(other.root),
        ScreenComponentsState.copy(other.componentsState).updateMaps(),
        Option.copy(other.contextProviderId, AggregateId.copy),
        ScreenLifeMode.copy(other.lifeMode)
      );
    }

    componentsAsMap(): {[screenId: string]: ScreenComponents} {
      if(this.components.isDefined()) {
        const map: {[screenId: string]: ScreenComponents} = {};
        this.components.get().forEach((s: [string, ScreenComponents]) => map[s[0]] = s[1]);
        return map;
      } else {
        throw new Error("Components not available");
      }
    }
  }

  export class ComponentModelChange {
    constructor( readonly modelName: string,
                 readonly value: Option<Typed<BusinessVariable>>) {
    }
  }

  export class ChangeSimpleInputParameters {
    constructor(readonly instanceId: ScreenInstanceId,
                readonly params: Array<[string, string]>) {
    }
  }

  export class ChangeMultipleComponentModels {

    constructor(readonly instanceId: ScreenInstanceId,
                readonly refsPath: Array<RefIdInContext>,
                readonly modelsChanges: Array<ComponentModelChange>,
                readonly actionsTriggered: Array<string>) {}
  }

export class ModelToChange {
  constructor(readonly modelName: string,
              readonly value: Option<Typed<BusinessVariable>>) {}

  static of(modelName: string,
            value: Option<BusinessVariable>) {
    return new ModelToChange(modelName, value.map(Typed.of));
  }
}

  export class ChangeComponentModels {

    constructor(readonly instanceId: ScreenInstanceId,
                readonly refsPath: Array<RefIdInContext>,
                readonly models: Array<ModelToChange>,
                readonly actionsTriggered: Array<string>
    ) {}
  }

  export class ChangeComponentModel {

    constructor(readonly instanceId: ScreenInstanceId,
                readonly refsPath: Array<RefIdInContext>,
                readonly modelName: string,
                readonly value: Typed<BusinessVariable>,
                readonly actionsTriggered: Array<string>
                ) {}
  }

  export class CloseModalComponent {
    constructor(readonly instanceId: ScreenInstanceId,
                readonly refsPath: Array<RefIdInContext>,
                readonly accept: boolean
    ) {}
  }

  export class ClearComponentModel {

    constructor(readonly instanceId: ScreenInstanceId,
                readonly refsPath: Array<RefIdInContext>,
                readonly modelName: string,
                readonly actionsTriggered: Array<string>
    ) {}
  }

  export class AppendToComponentModel {

    constructor(readonly instanceId: ScreenInstanceId,
                readonly refsPath: Array<RefIdInContext>,
                readonly modelName: string,
                readonly expectedModelLength: Option<number>,
                readonly value: Typed<BusinessVariable>
    ) {}
  }

  export class RefIdInContext {
    constructor(readonly refId: ScreenComponentRefIdInScreen,
                readonly contextId: VariableId) {}
  }

  export class RemoveFromComponentModel {
    constructor(readonly instanceId: ScreenInstanceId,
                readonly refsPath: Array<RefIdInContext>,
                readonly modelName: string,
                readonly valueIndex: number,
                readonly value: Typed<BusinessVariable>,
                readonly actionsTriggered: Array<string>) {}
  }

  export class ChangeModel {
    constructor(readonly modelName: string,
                readonly value: Typed<BusinessVariable>) {
    }
  }

  export class ExecuteAction {
    constructor(readonly instanceId: ScreenInstanceId,
                readonly refsPath: Array<RefIdInContext>,
                readonly actionName: string,
                readonly optional: boolean,
                readonly changeModelAfter: Option<ChangeModel>) {}
  }

  export class ExecuteEntryAction {
    constructor(readonly instanceId: ScreenInstanceId,
                readonly refsPath: Array<RefIdInContext>,
                readonly entryContextId: VariableId,
                readonly actionName: string) {}
  }

  export class AddRepeatableContextEntry {
    constructor(
      readonly instanceId: ScreenInstanceId,
      readonly refsPath: Array<RefIdInContext>,
      readonly contextLength: number,
      readonly actionsTriggered: Array<string>) {}
  }

  export class DeleteRepeatableContextEntry {
    constructor(
      readonly instanceId: ScreenInstanceId,
      readonly refsPath: Array<RefIdInContext>,
      readonly entryId: VariableId,
      readonly entryIndex: number,
      readonly actionsTriggered: Array<string>
    ) {}
  }

  export class MoveEntry {
    constructor(
      readonly instanceId: ScreenInstanceId,
      readonly refsPath: Array<RefIdInContext>,
      readonly entryId: VariableId,
      readonly fromIndex: number,
      readonly toIndex: number,
      readonly actionsTriggered: Array<string>
    ) {}
  }

  export class SubmitForm {
    constructor(
      readonly edgeId: ProcessEdgeId,
      readonly instanceId: ScreenInstanceId) {}
  }

  export class EvaluateExpressionInScreen {
    constructor(
      readonly instanceId: ScreenInstanceId,
      readonly expression: string,
      readonly contextPath: string
    ) {}
  }



  export interface EvaluateExpressionInScreenResponse {
    className(): string;
    isSuccess(): boolean;
  }

  export class EvaluateExpressionInScreenSuccessResponse implements EvaluateExpressionInScreenResponse {

    constructor(readonly resultVariable: Typed<BusinessVariable>,
                readonly durationMillis: number) {}

    static copy(other: EvaluateExpressionInScreenSuccessResponse) {
      return new EvaluateExpressionInScreenSuccessResponse(BusinessVariableFactory.copyTyped(other.resultVariable), other.durationMillis);
    }

    static className: string = "EvaluateExpressionInScreenSuccessResponse";

    className(): string {
      return EvaluateExpressionInScreenSuccessResponse.className;
    }

    isSuccess(): boolean {
      return true
    };

  }

  export class EvaluateExpressionInScreenFailureResponse implements EvaluateExpressionInScreenResponse {

    constructor(readonly error: string,
                readonly durationMillis: number) {}

    static copy(other: EvaluateExpressionInScreenFailureResponse) {
      return new EvaluateExpressionInScreenFailureResponse(other.error, other.durationMillis);
    }

    static className: string = "EvaluateExpressionInScreenFailureResponse";

    className(): string {
      return EvaluateExpressionInScreenFailureResponse.className;
    }

    isSuccess(): boolean {
      return false
    };

  }

  export class EvaluateExpressionInScreenResponseFactory {

    static copy(response: EvaluateExpressionInScreenResponse): EvaluateExpressionInScreenResponse {
      return EvaluateExpressionInScreenResponseFactory.copyByType(response, response.className());
    }

    static copyTyped(response: Typed<EvaluateExpressionInScreenResponse>): Typed<EvaluateExpressionInScreenResponse> {
      return Typed.of(EvaluateExpressionInScreenResponseFactory.copyByType(Typed.value(response), Typed.className(response)));
    }

    static copyByType(response: EvaluateExpressionInScreenResponse, className: string): EvaluateExpressionInScreenResponse {
      switch (className) {
        case EvaluateExpressionInScreenSuccessResponse.className:
          return new EvaluateExpressionInScreenSuccessResponse(BusinessVariableFactory.copyTyped((<EvaluateExpressionInScreenSuccessResponse>response).resultVariable), (<EvaluateExpressionInScreenSuccessResponse>response).durationMillis);
        case EvaluateExpressionInScreenFailureResponse.className:
          return new EvaluateExpressionInScreenFailureResponse((<EvaluateExpressionInScreenFailureResponse>response).error, (<EvaluateExpressionInScreenFailureResponse>response).durationMillis);
        default:
          throw new Error("Unsupported response class: " + className + ", response: " + JSON.stringify(response));
      }
    }
  }




  export class ApplicationResponse {
    constructor(readonly status: string) {}

    static Success = new ApplicationResponse("Success");
    static Unauthorized = new ApplicationResponse("Unauthorized");
    static Terminated = new ApplicationResponse("Terminated");
    static ValidationError = new ApplicationResponse("ValidationError");

    static copy(other: ApplicationResponse) {
      return new ApplicationResponse(other.status);
    }

    isSuccess() {
      return this.status == "Success";
    }

    isValidationError() {
      return this.status == "ValidationError";
    }
  }







  export class ComponentModelChanged implements ScreenInstanceStateEvent {
    static className = "ComponentModelChanged";

    className(): string {
      return ComponentModelChanged.className;
    }

    constructor(readonly version: number,
                readonly modelName: string,
                readonly contextId: VariableId,
                readonly componentId: ScreenComponentIdInScreen,
                readonly value: Option<Typed<BusinessVariable>>) {}

    get unwrappedValue(): Option<BusinessVariable> {
      return this.value.map(v => Typed.value(v));
    }

    static copy(other: ComponentModelChanged) {
      return new ComponentModelChanged(other.version,
        other.modelName,
        VariableId.copy(other.contextId),
        ScreenComponentIdInScreen.copy(other.componentId),
        Option.copy(other.value).map(BusinessVariableFactory.copyTyped));
    }
  }


  export class ComponentModelInProgress implements ScreenInstanceStateEvent {
    static className = "ComponentModelInProgress";

    className(): string {
      return ComponentModelInProgress.className;
    }

    constructor(readonly version: number,
                readonly modelName: string,
                readonly contextId: VariableId,
                readonly componentId: ScreenComponentIdInScreen) {}

    static copy(other: ComponentModelInProgress) {
      return new ComponentModelInProgress(other.version,
        other.modelName,
        VariableId.copy(other.contextId),
        ScreenComponentIdInScreen.copy(other.componentId));
    }
  }

  export class ComponentInnerRepeatableContextChanged implements ScreenInstanceStateEvent {
    static className = "ComponentInnerRepeatableContextChanged";

    className(): string {
      return ComponentInnerRepeatableContextChanged.className;
    }

    constructor(readonly version: number,
                readonly contextId: VariableId,
                readonly componentId: ScreenComponentIdInScreen,
                readonly previousInnerContext: Option<RepeatableContext>,
                readonly innerContext: Option<RepeatableContext>,
                readonly status: number) {}

    static copy(other: ComponentInnerRepeatableContextChanged) {
      return new ComponentInnerRepeatableContextChanged(other.version,
        VariableId.copy(other.contextId),
        ScreenComponentIdInScreen.copy(other.componentId),
        Option.copy(other.previousInnerContext, RepeatableContext.copy),
        Option.copy(other.innerContext, RepeatableContext.copy),
        other.status);
    }
  }

  export class ComponentSingleContextChanged implements ScreenInstanceStateEvent {
    static className = "ComponentSingleContextChanged";

    className(): string {
      return ComponentSingleContextChanged.className;
    }

    constructor(readonly version: number,
                readonly contextId: VariableId,
                readonly componentId: ScreenComponentIdInScreen,
                readonly innerContext: Option<VariableId>,
                readonly status: number) {}

    static copy(other: ComponentSingleContextChanged) {
      return new ComponentSingleContextChanged(other.version,
        VariableId.copy(other.contextId),
        ScreenComponentIdInScreen.copy(other.componentId),
        Option.copy(other.innerContext, VariableId.copy),
        other.status);
    }
  }

  export class ComponentPropertyChanged implements ScreenInstanceStateEvent {
    static className = "ComponentPropertyChanged";

    className(): string {
      return ComponentPropertyChanged.className;
    }

    constructor(readonly version: number,
                readonly contextId: VariableId,
                readonly componentId: ScreenComponentIdInScreen,
                readonly propertyName: string,
                readonly value: Option<Typed<BusinessVariable>>) {}

    get unwrappedValue(): Option<BusinessVariable> {
      return this.value.map(v => Typed.value(v));
    }

    static copy(other: ComponentPropertyChanged) {
      return new ComponentPropertyChanged(other.version,
        VariableId.copy(other.contextId),
        ScreenComponentIdInScreen.copy(other.componentId),
        other.propertyName,
        Option.copy(other.value).map(BusinessVariableFactory.copyTyped));
    }
  }

  export class ComponentPropertyInProgress implements ScreenInstanceStateEvent {
    static className = "ComponentPropertyInProgress";

    className(): string {
      return ComponentPropertyInProgress.className;
    }

    constructor(readonly version: number,
                readonly contextId: VariableId,
                readonly componentId: ScreenComponentIdInScreen,
                readonly propertyName: string) {}

    static copy(other: ComponentPropertyInProgress) {
      return new ComponentPropertyInProgress(other.version,
        VariableId.copy(other.contextId),
        ScreenComponentIdInScreen.copy(other.componentId),
        other.propertyName);
    }
  }

  export class ComponentPropertyErrorOccurred implements ScreenInstanceStateEvent {
    static className = "ComponentPropertyErrorOccurred";

    className(): string {
      return ComponentPropertyErrorOccurred.className;
    }

    constructor(readonly version: number,
                readonly contextId: VariableId,
                readonly componentId: ScreenComponentIdInScreen,
                readonly propertyName: string,
                readonly timestamp: LocalDateTime,
                readonly message: string) {}

    static copy(other: ComponentPropertyErrorOccurred) {
      return new ComponentPropertyErrorOccurred(other.version,
        VariableId.copy(other.contextId),
        ScreenComponentIdInScreen.copy(other.componentId),
        other.propertyName,
        LocalDateTime.copy(other.timestamp),
        other.message);
    }
  }

  export class ComponentRefPropertyChanged implements ScreenInstanceStateEvent {
    static className = "ComponentRefPropertyChanged";

    className(): string {
      return ComponentRefPropertyChanged.className;
    }

    constructor(readonly version: number,
                readonly contextId: VariableId,
                readonly componentRefId: ScreenComponentRefIdInScreen,
                readonly propertyName: string,
                readonly value: Option<Typed<BusinessVariable>>) {}

    get unwrappedValue(): Option<BusinessVariable> {
      return this.value.map(v => Typed.value(v));
    }

    static copy(other: ComponentRefPropertyChanged) {
      return new ComponentRefPropertyChanged(other.version,
        VariableId.copy(other.contextId),
        ScreenComponentRefIdInScreen.copy(other.componentRefId),
        other.propertyName,
        Option.copy(other.value).map(BusinessVariableFactory.copyTyped));
    }
  }


  export class ComponentRefPropertyInProgress implements ScreenInstanceStateEvent {
    static className = "ComponentRefPropertyInProgress";

    className(): string {
      return ComponentRefPropertyInProgress.className;
    }

    constructor(readonly version: number,
                readonly contextId: VariableId,
                readonly componentRefId: ScreenComponentRefIdInScreen,
                readonly propertyName: string) {}


    static copy(other: ComponentRefPropertyInProgress) {
      return new ComponentRefPropertyInProgress(other.version,
        VariableId.copy(other.contextId),
        ScreenComponentRefIdInScreen.copy(other.componentRefId),
        other.propertyName);
    }
  }

  export class ComponentRefPropertyErrorOccurred implements ScreenInstanceStateEvent {
    static className = "ComponentRefPropertyErrorOccured";

    className(): string {
      return ComponentRefPropertyErrorOccurred.className;
    }

    constructor(readonly version: number,
                readonly contextId: VariableId,
                readonly componentRefId: ScreenComponentRefIdInScreen,
                readonly propertyName: string,
                readonly timestamp: LocalDateTime,
                readonly message: string) {}

    static copy(other: ComponentRefPropertyErrorOccurred) {
      return new ComponentRefPropertyErrorOccurred(other.version,
        VariableId.copy(other.contextId),
        ScreenComponentRefIdInScreen.copy(other.componentRefId),
        other.propertyName,
        LocalDateTime.copy(other.timestamp),
        other.message);
    }
  }

  export class RefStateCreated implements ScreenInstanceStateEvent {
    static className = "RefStateCreated";
    className(): string {
      return RefStateCreated.className;
    }

    constructor(readonly version: number,
                readonly contextId: VariableId,
                readonly refId: ScreenComponentRefIdInScreen,
                readonly state: Typed<ScreenComponentRefState>) {}

    get stateUnwrapped() {
      return Typed.value(this.state);
    }

    static copy(other: RefStateCreated) {
      return new RefStateCreated(other.version,
        VariableId.copy(other.contextId),
        ScreenComponentRefIdInScreen.copy(other.refId),
        ScreenComponentRefStateFactory.copyTyped(other.state))
    }
  }


  export class RefStateDeleted implements ScreenInstanceStateEvent {
    static className = "RefStateDeleted";
    className(): string {
      return RefStateDeleted.className;
    }

    constructor(readonly version: number,
                readonly contextId: VariableId,
                readonly refId: ScreenComponentRefIdInScreen) {}

    static copy(other: RefStateDeleted) {
      return new RefStateDeleted(other.version,
        VariableId.copy(other.contextId),
        ScreenComponentRefIdInScreen.copy(other.refId));
    }
  }

export class ParentRefId {
  constructor(readonly context: VariableId,
              readonly refId: ScreenComponentRefIdInScreen) {}

  static copy(other: ParentRefId) {
    return new ParentRefId(VariableId.copy(other.context), ScreenComponentRefIdInScreen.copy(other.refId));
  }
}

  export class ComponentStateCreated implements ScreenInstanceStateEvent {
    static className = "ComponentStateCreated";

    className(): string {
      return ComponentStateCreated.className;
    }

    constructor(readonly version: number,
                readonly parent: Option<ParentRefId>,
                readonly contextId: VariableId,
                readonly componentId: ScreenComponentIdInScreen,
                readonly state: Typed<ScreenComponentState>,
                readonly status: number,
                readonly hasChildren: boolean) {}

    get stateUnwrapped() {
      return Typed.value(this.state);
    }

    static copy(other: ComponentStateCreated) {
      return new ComponentStateCreated(other.version,
        Option.copy(other.parent, ParentRefId.copy),
        VariableId.copy(other.contextId),
        ScreenComponentIdInScreen.copy(other.componentId),
        ScreenComponentStateFactory.copyTyped(other.state),
        other.status,
        other.hasChildren)
    }
  }

  export class ComponentStateDeleted implements ScreenInstanceStateEvent {
    static className = "ComponentStateDeleted";

    className(): string {
      return ComponentStateDeleted.className;
    }

    constructor(readonly version: number,
                readonly parent: Option<ParentRefId>,
                readonly contextId: VariableId,
                readonly componentId: ScreenComponentIdInScreen) {}

    static copy(other: ComponentStateDeleted) {
      return new ComponentStateDeleted(other.version,
        Option.copy(other.parent, ParentRefId.copy),
        VariableId.copy(other.contextId),
        ScreenComponentIdInScreen.copy(other.componentId));
    }
  }





  export class ComponentStatusChanged implements ScreenInstanceStateEvent {
    static className = "ComponentStatusChanged";

    className(): string {
      return ComponentStatusChanged.className;
    }

    constructor(readonly version: number,
                readonly contextId: VariableId,
                readonly componentId: ScreenComponentIdInScreen,
                readonly status: number,
                readonly hasChildren: boolean) {}

    static copy(other: ComponentStatusChanged) {
      return new ComponentStatusChanged(other.version,
        VariableId.copy(other.contextId),
        ScreenComponentIdInScreen.copy(other.componentId),
        other.status,
        other.hasChildren)
    }
  }


  export class RepeatableComponentEntryAdded implements ScreenInstanceStateEvent {
    static className = "RepeatableComponentEntryAdded";

    className(): string {
      return RepeatableComponentEntryAdded.className;
    }

    constructor(readonly version: number,
                readonly contextId: VariableId,
                readonly componentId: ScreenComponentIdInScreen,
                readonly entryId: VariableId) {}

    static copy(other: RepeatableComponentEntryAdded) {
      return new RepeatableComponentEntryAdded(other.version,
        VariableId.copy(other.contextId),
        ScreenComponentIdInScreen.copy(other.componentId),
        VariableId.copy(other.entryId));
    }
  }


  export class RepeatableComponentEntryDeleted implements ScreenInstanceStateEvent {
    static className = "RepeatableComponentEntryDeleted";

    className(): string {
      return RepeatableComponentEntryDeleted.className;
    }

    constructor(readonly version: number,
                readonly contextId: VariableId,
                readonly componentId: ScreenComponentIdInScreen,
                readonly entryId: VariableId) {}

    static copy(other: RepeatableComponentEntryDeleted) {
      return new RepeatableComponentEntryDeleted(other.version,
        VariableId.copy(other.contextId),
        ScreenComponentIdInScreen.copy(other.componentId),
        VariableId.copy(other.entryId));
    }
  }


  export class ActionExecutionTriggered implements ScreenInstanceStateEvent {
    static className = "ActionExecutionTriggered";

    constructor(
      readonly version: number,
      readonly contextId: VariableId,
      readonly entryContextId: Option<VariableId>,
      readonly componentId: ScreenComponentIdInScreen,
      readonly actionName: string,
      readonly timestamp: LocalDateTime
    ) {}

    className(): string {
      return ActionExecutionTriggered.className;
    }

    static copy(other: ActionExecutionTriggered) {
      return new ActionExecutionTriggered(other.version,
        VariableId.copy(other.contextId),
        Option.copy(other.entryContextId, VariableId.copy),
        ScreenComponentIdInScreen.copy(other.componentId),
        other.actionName,
        LocalDateTime.copy(other.timestamp));
    }
  }

  export class ActionValidationFailed implements ScreenInstanceStateEvent {
    static className = "ActionValidationFailed";

    constructor(
      readonly version: number,
      readonly contextId: VariableId,
      readonly entryContextId: Option<VariableId>,
      readonly componentId: ScreenComponentIdInScreen,
      readonly actionName: string,
      readonly messages: Array<I18nText>
    ) {}

    className(): string {
      return ActionValidationFailed.className;
    }

    static copy(other: ActionValidationFailed) {
      return new ActionValidationFailed(other.version,
        VariableId.copy(other.contextId),
        Option.copy(other.entryContextId, VariableId.copy),
        ScreenComponentIdInScreen.copy(other.componentId),
        other.actionName,
        other.messages.map(I18nText.copy));
    }
  }

  export class ActionSyncExecutionFailed implements ScreenInstanceStateEvent {
    static className = "ActionSyncExecutionFailed";

    constructor(
      readonly version: number,
      readonly contextId: VariableId,
      readonly entryContextId: Option<VariableId>,
      readonly componentId: ScreenComponentIdInScreen,
      readonly actionName: string,
      readonly timestamp: LocalDateTime,
      readonly index: number,
      readonly message: string
    ) {}

    className(): string {
      return ActionSyncExecutionFailed.className;
    }

    static copy(other: ActionSyncExecutionFailed) {
      return new ActionSyncExecutionFailed(other.version,
        VariableId.copy(other.contextId),
        Option.copy(other.entryContextId, VariableId.copy),
        ScreenComponentIdInScreen.copy(other.componentId),
        other.actionName,
        LocalDateTime.copy(other.timestamp),
        other.index,
        other.message);
    }
  }

  export class ActionSyncExecutionCompleted implements ScreenInstanceStateEvent {
    static className = "ActionSyncExecutionCompleted";

    constructor(
      readonly version: number,
      readonly contextId: VariableId,
      readonly entryContextId: Option<VariableId>,
      readonly componentId: ScreenComponentIdInScreen,
      readonly actionName: string
    ) {}

    className(): string {
      return ActionSyncExecutionCompleted.className;
    }

    static copy(other: ActionSyncExecutionCompleted) {
      return new ActionSyncExecutionCompleted(other.version,
        VariableId.copy(other.contextId),
        Option.copy(other.entryContextId, VariableId.copy),
        ScreenComponentIdInScreen.copy(other.componentId),
        other.actionName);
    }
  }



  export class ActionAsyncExecutionStarted implements ScreenInstanceStateEvent {
    static className = "ActionAsyncExecutionStarted";

    constructor(readonly version: number,
                readonly contextId: VariableId,
                readonly entryContextId: Option<VariableId>,
                readonly componentId: ScreenComponentIdInScreen,
                readonly actionName: string,
                readonly asyncActions: Array<number>) {
    }

    className(): string {
      return ActionAsyncExecutionStarted.className;
    }

    static copy(other: ActionAsyncExecutionStarted) {
      return new ActionAsyncExecutionStarted(other.version,
        VariableId.copy(other.contextId),
        Option.copy(other.entryContextId, VariableId.copy),
        ScreenComponentIdInScreen.copy(other.componentId),
        other.actionName,
        other.asyncActions.slice());
    }
  }

  export class ActionAsyncExecutionFailed implements ScreenInstanceStateEvent {
    static className = "ActionAsyncExecutionFailed";

    constructor(readonly version: number,
                readonly contextId: VariableId,
                readonly entryContextId: Option<VariableId>,
                readonly componentId: ScreenComponentIdInScreen,
                readonly actionName: string,
                readonly timestamp: LocalDateTime,
                readonly index: number,
                readonly message: string) {
    }

    className(): string {
      return ActionAsyncExecutionFailed.className;
    }

    static copy(other: ActionAsyncExecutionFailed) {
      return new ActionAsyncExecutionFailed(other.version,
        VariableId.copy(other.contextId),
        Option.copy(other.entryContextId, VariableId.copy),
        ScreenComponentIdInScreen.copy(other.componentId),
        other.actionName, LocalDateTime.copy(other.timestamp), other.index, other.message);
    }
  }

  export class ActionAsyncExecutionCompleted implements ScreenInstanceStateEvent {
    static className = "ActionAsyncExecutionCompleted";

    constructor(readonly version: number,
                readonly contextId: VariableId,
                readonly entryContextId: Option<VariableId>,
                readonly componentId: ScreenComponentIdInScreen,
                readonly actionName: string,
                readonly index: number) {
    }

    className(): string {
      return ActionAsyncExecutionCompleted.className;
    }

    static copy(other: ActionAsyncExecutionCompleted) {
      return new ActionAsyncExecutionCompleted(other.version,
        VariableId.copy(other.contextId),
        Option.copy(other.entryContextId, VariableId.copy),
        ScreenComponentIdInScreen.copy(other.componentId),
        other.actionName, other.index);
    }
  }


  export class ComponentValidationChanged implements ScreenInstanceStateEvent {
    static className = "ComponentValidationChanged";
    constructor(readonly version: number,
                readonly contextId: VariableId,
                readonly componentId: ScreenComponentIdInScreen,
                readonly errors: Array<Typed<ComponentValidationError>>) {}
    className(): string {
      return ComponentValidationChanged.className;
    }

    static copy(other: ComponentValidationChanged) {
      return new ComponentValidationChanged(
        other.version,
        VariableId.copy(other.contextId),
        ScreenComponentIdInScreen.copy(other.componentId),
        other.errors.map(ComponentValidationErrorFactory.copyTyped)
      );
    }
  }

  export class ComponentValidationErrorOccurred implements ScreenInstanceStateEvent {
    static className = "ComponentValidationErrorOccurred";
    constructor(readonly version: number,
                readonly contextId: VariableId,
                readonly componentId: ScreenComponentIdInScreen,
                readonly index: number,
                readonly timestamp: LocalDateTime,
                readonly message: string) {}
    className(): string {
      return ComponentValidationErrorOccurred.className;
    }

    static copy(other: ComponentValidationErrorOccurred) {
      return new ComponentValidationErrorOccurred(
        other.version,
        VariableId.copy(other.contextId),
        ScreenComponentIdInScreen.copy(other.componentId),
        other.index,
        LocalDateTime.copy(other.timestamp),
        other.message
      );
    }
  }

  export class ComponentRefValidationChanged implements ScreenInstanceStateEvent {
    static className = "ComponentRefValidationChanged";
    constructor(readonly version: number,
                readonly contextId: VariableId,
                readonly componentRefId: ScreenComponentRefIdInScreen,
                readonly errors: Array<Typed<ComponentValidationError>>) {}
    className(): string {
      return ComponentRefValidationChanged.className;
    }

    static copy(other: ComponentRefValidationChanged) {
      return new ComponentRefValidationChanged(
        other.version,
        VariableId.copy(other.contextId),
        ScreenComponentRefIdInScreen.copy(other.componentRefId),
        other.errors.map(ComponentValidationErrorFactory.copyTyped)
      );
    }

  }

  export class OutputParameterChanged implements ScreenInstanceStateEvent {
    static className = "OutputParameterChanged";
    constructor(readonly version: number,
                readonly input: boolean, /** if it also an input param */
                readonly variableName: string,
                readonly value: Option<Typed<BusinessVariable>>) {}
    className(): string {
      return OutputParameterChanged.className;
    }

    static copy(other: OutputParameterChanged) {
      return new OutputParameterChanged(
        other.version,
        other.input,
        other.variableName,
        Option.copy(other.value, BusinessVariableFactory.copyTyped)
      );
    }

  }

export class ExternalContextIdChanged implements ScreenInstanceStateEvent {
  static className = "ExternalContextIdChanged";
  constructor(readonly version: number,
              readonly externalContextId: AggregateId,) {}
  className(): string {
    return ExternalContextIdChanged.className;
  }

  static copy(other: ExternalContextIdChanged) {
    return new ExternalContextIdChanged(
      other.version,
      AggregateId.copy(other.externalContextId)
    );
  }

}


export class ScreenInstanceStateEventFactory {

    static copyWrapper(other: NewScreenInstanceEvents) {
      return new NewScreenInstanceEvents(ScreenInstanceId.copy(other.screenInstanceId), other.events.map(ScreenInstanceStateEventFactory.copyTyped), Option.copy(other.error))
    }

    static copy(other: ScreenInstanceStateEvent): ScreenInstanceStateEvent {
      return ScreenInstanceStateEventFactory.copyByType(other, other.className());
    }

    static copyTyped(other: Typed<ScreenInstanceStateEvent>): Typed<ScreenInstanceStateEvent> {
      return Typed.of(ScreenInstanceStateEventFactory.copyByType(Typed.value(other), Typed.className(other)));
    }

    static copyByType(other: ScreenInstanceStateEvent, className: string): ScreenInstanceStateEvent {
      switch (className) {
        case ComponentModelInProgress.className: return ComponentModelInProgress.copy(<ComponentModelInProgress>other);
        case ComponentModelChanged.className: return ComponentModelChanged.copy(<ComponentModelChanged>other);
        case ComponentInnerRepeatableContextChanged.className: return ComponentInnerRepeatableContextChanged.copy(<ComponentInnerRepeatableContextChanged>other);
        case ComponentSingleContextChanged.className: return ComponentSingleContextChanged.copy(<ComponentSingleContextChanged>other);
        case ComponentPropertyInProgress.className: return ComponentPropertyInProgress.copy(<ComponentPropertyInProgress>other);
        case ComponentPropertyChanged.className: return ComponentPropertyChanged.copy(<ComponentPropertyChanged>other);
        case ComponentPropertyErrorOccurred.className: return ComponentPropertyErrorOccurred.copy(<ComponentPropertyErrorOccurred>other);
        case ComponentRefPropertyInProgress.className: return ComponentRefPropertyInProgress.copy(<ComponentRefPropertyInProgress>other);
        case ComponentRefPropertyChanged.className: return ComponentRefPropertyChanged.copy(<ComponentRefPropertyChanged>other);
        case ComponentRefPropertyErrorOccurred.className: return ComponentRefPropertyErrorOccurred.copy(<ComponentRefPropertyErrorOccurred>other);
        case RefStateCreated.className: return RefStateCreated.copy(<RefStateCreated>other);
        case RefStateDeleted.className: return RefStateDeleted.copy(<RefStateDeleted>other);
        case ComponentStateCreated.className: return ComponentStateCreated.copy(<ComponentStateCreated>other);
        case ComponentStateDeleted.className: return ComponentStateDeleted.copy(<ComponentStateDeleted>other);
        case ComponentStatusChanged.className: return ComponentStatusChanged.copy(<ComponentStatusChanged>other);
        case RepeatableComponentEntryAdded.className: return RepeatableComponentEntryAdded.copy(<RepeatableComponentEntryAdded>other);
        case RepeatableComponentEntryDeleted.className: return RepeatableComponentEntryDeleted.copy(<RepeatableComponentEntryDeleted>other);
        case ActionSyncExecutionCompleted.className: return ActionSyncExecutionCompleted.copy(<ActionSyncExecutionCompleted>other);
        case ActionExecutionTriggered.className: return ActionExecutionTriggered.copy(<ActionExecutionTriggered>other);
        case ActionValidationFailed.className: return ActionValidationFailed.copy(<ActionValidationFailed>other);
        case ActionSyncExecutionFailed.className: return ActionSyncExecutionFailed.copy(<ActionSyncExecutionFailed>other);
        case ActionAsyncExecutionStarted.className: return ActionAsyncExecutionStarted.copy(<ActionAsyncExecutionStarted>other);
        case ActionAsyncExecutionFailed.className: return ActionAsyncExecutionFailed.copy(<ActionAsyncExecutionFailed>other);
        case ActionAsyncExecutionCompleted.className: return ActionAsyncExecutionCompleted.copy(<ActionAsyncExecutionCompleted>other);
        case ComponentValidationChanged.className: return ComponentValidationChanged.copy(<ComponentValidationChanged>other);
        case ComponentValidationErrorOccurred.className: return ComponentValidationErrorOccurred.copy(<ComponentValidationErrorOccurred>other);
        case ComponentRefValidationChanged.className: return ComponentRefValidationChanged.copy(<ComponentRefValidationChanged>other);
        case OutputParameterChanged.className: return OutputParameterChanged.copy(<OutputParameterChanged>other);
        case ExternalContextIdChanged.className: return ExternalContextIdChanged.copy(<ExternalContextIdChanged>other);
        default: throw new Error("Not supported ["+className+"]");
      }
    }
  }




  export interface ComponentValidationError {
    className(): string;
    toMessage(): string;
  }


  export class RequiredModelValueNotAvailable implements ComponentValidationError {
    static className = "RequiredModelValueNotAvailable";
    className(): string {
      return RequiredModelValueNotAvailable.className;
    }

    constructor() {}

    static copy(other: RequiredModelValueNotAvailable) {
      return new RequiredModelValueNotAvailable();
    }

    toMessage(): string {
      return i18n("screen_validation_value_not_available");
    }
  }

  export class ValueOfIncorrectType implements ComponentValidationError {
    static className = "ValueOfIncorrectType";
    className(): string {
      return ValueOfIncorrectType.className;
    }

    constructor(readonly actualTypeName: string,
                readonly expectedTypeName: string) {}

    static copy(other: ValueOfIncorrectType) {
      return new ValueOfIncorrectType(other.actualTypeName, other.expectedTypeName);
    }

    toMessage(): string {
      return i18n("screen_validation_value_incorrect_type", {actual: this.actualTypeName, expected: this.expectedTypeName});
    }
  }

  export class NumberLargerThanMaximum implements ComponentValidationError {
    static className = "NumberLargerThanMaximum";
    className(): string {
      return NumberLargerThanMaximum.className;
    }

    constructor(readonly actualValue: number,
                readonly maximumValue: number) {}

    static copy(other: NumberLargerThanMaximum) {
      return new NumberLargerThanMaximum(other.actualValue, other.maximumValue);
    }

    toMessage(): string {
      return i18n("screen_validation_number_too_large", {actual: this.actualValue, maximum: this.maximumValue});
    }
  }

  export class NumberSmallerThanMinimum implements ComponentValidationError {
    static className = "NumberSmallerThanMinimum";
    className(): string {
      return NumberSmallerThanMinimum.className;
    }

    constructor(readonly actualValue: number,
                readonly minimumValue: number) {}

    static copy(other: NumberSmallerThanMinimum) {
      return new NumberSmallerThanMinimum(other.actualValue, other.minimumValue);
    }

    toMessage(): string {
      return i18n("screen_validation_number_too_small", {actual: this.actualValue, minimum: this.minimumValue});
    }

  }

  export class DateLargerThanMaximum implements ComponentValidationError {
    static className = "DateLargerThanMaximum";
    className(): string {
      return DateLargerThanMaximum.className;
    }

    constructor(readonly actualValue: LocalDate,
                readonly maximumValue: LocalDate) {}

    static copy(other: DateLargerThanMaximum) {
      return new DateLargerThanMaximum(LocalDate.copy(other.actualValue), LocalDate.copy(other.maximumValue));
    }

    toMessage(): string {
      return i18n("screen_validation_date_too_large", {actual: this.actualValue.formatted(), maximum: this.maximumValue.formatted()});
    }
  }

  export class DateSmallerThanMinimum implements ComponentValidationError {
    static className = "DateSmallerThanMinimum";
    className(): string {
      return DateSmallerThanMinimum.className;
    }

    constructor(readonly actualValue: LocalDate,
                readonly minimumValue: LocalDate) {}

    static copy(other: DateSmallerThanMinimum) {
      return new DateSmallerThanMinimum(LocalDate.copy(other.actualValue), LocalDate.copy(other.minimumValue));
    }

    toMessage(): string {
      return i18n("screen_validation_date_too_small", {actual: this.actualValue.formatted(), minimum: this.minimumValue.formatted()});
    }

  }

  export class TimeLargerThanMaximum implements ComponentValidationError {
    static className = "TimeLargerThanMaximum";
    className(): string {
      return TimeLargerThanMaximum.className;
    }

    constructor(readonly actualValue: LocalTime,
                readonly maximumValue: LocalTime) {}

    static copy(other: TimeLargerThanMaximum) {
      return new TimeLargerThanMaximum(LocalTime.copy(other.actualValue), LocalTime.copy(other.maximumValue));
    }

    toMessage(): string {
      return i18n("screen_validation_time_too_large", {actual: this.actualValue.formattedToMinutes(), maximum: this.maximumValue.formattedToMinutes()});
    }
  }

  export class TimeSmallerThanMinimum implements ComponentValidationError {
    static className = "TimeSmallerThanMinimum";
    className(): string {
      return TimeSmallerThanMinimum.className;
    }

    constructor(readonly actualValue: LocalTime,
                readonly minimumValue: LocalTime) {}

    static copy(other: TimeSmallerThanMinimum) {
      return new TimeSmallerThanMinimum(LocalTime.copy(other.actualValue), LocalTime.copy(other.minimumValue));
    }

    toMessage(): string {
      return i18n("screen_validation_time_too_small", {actual: this.actualValue.formattedToMinutes(), minimum: this.minimumValue.formattedToMinutes()});
    }

  }

  export class DateTimeLargerThanMaximum implements ComponentValidationError {
    static className = "DateTimeLargerThanMaximum";
    className(): string {
      return DateTimeLargerThanMaximum.className;
    }

    constructor(readonly actualValue: LocalDateTime,
                readonly maximumValue: LocalDateTime) {}

    static copy(other: DateTimeLargerThanMaximum) {
      return new DateTimeLargerThanMaximum(LocalDateTime.copy(other.actualValue), LocalDateTime.copy(other.maximumValue));
    }

    toMessage(): string {
      return i18n("screen_validation_datetime_too_large", {actual: this.actualValue.formattedToMinutes(), maximum: this.maximumValue.formattedToMinutes()});
    }
  }

  export class DateTimeSmallerThanMinimum implements ComponentValidationError {
    static className = "DateTimeSmallerThanMinimum";
    className(): string {
      return DateTimeSmallerThanMinimum.className;
    }

    constructor(readonly actualValue: LocalDateTime,
                readonly minimumValue: LocalDateTime) {}

    static copy(other: DateTimeSmallerThanMinimum) {
      return new DateTimeSmallerThanMinimum(LocalDateTime.copy(other.actualValue), LocalDateTime.copy(other.minimumValue));
    }

    toMessage(): string {
      return i18n("screen_validation_datetime_too_small", {actual: this.actualValue.formattedToMinutes(), minimum: this.minimumValue.formattedToMinutes()});
    }

  }

  export class DurationLargerThanMaximum implements ComponentValidationError {
    static className = "DurationLargerThanMaximum";
    className(): string {
      return DurationLargerThanMaximum.className;
    }

    constructor(readonly actualValue: Duration,
                readonly maximumValue: Duration) {}

    static copy(other: DurationLargerThanMaximum) {
      return new DurationLargerThanMaximum(Duration.copy(other.actualValue), Duration.copy(other.maximumValue));
    }

    toMessage(): string {
      return i18n("screen_validation_duration_too_large", {actual: this.actualValue.formatted24(), maximum: this.maximumValue.formatted24()});
    }
  }

  export class DurationSmallerThanMinimum implements ComponentValidationError {
    static className = "DurationSmallerThanMinimum";
    className(): string {
      return DurationSmallerThanMinimum.className;
    }

    constructor(readonly actualValue: Duration,
                readonly minimumValue: Duration) {}

    static copy(other: DurationSmallerThanMinimum) {
      return new DurationSmallerThanMinimum(Duration.copy(other.actualValue), Duration.copy(other.minimumValue));
    }

    toMessage(): string {
      return i18n("screen_validation_duration_too_small", {actual: this.actualValue.formatted24(), minimum: this.minimumValue.formatted24()});
    }

  }

  export class StringShorterThanMinimum implements ComponentValidationError {
    static className = "StringShorterThanMinimum";
    className(): string {
      return StringShorterThanMinimum.className;
    }

    constructor(readonly actualLength: number,
                readonly minimumLength: number) {}

    static copy(other: StringShorterThanMinimum) {
      return new StringShorterThanMinimum(other.actualLength, other.minimumLength);
    }

    toMessage(): string {
      return i18n("screen_validation_text_too_short", {actual: this.actualLength, minimum: this.minimumLength});
    }
  }

  export class StringLongerThanMaximum implements ComponentValidationError {
    static className = "StringLongerThanMaximum";
    className(): string {
      return StringLongerThanMaximum.className;
    }

    constructor(readonly actualLength: number,
                readonly maximumLength: number) {}

    static copy(other: StringLongerThanMaximum) {
      return new StringLongerThanMaximum(other.actualLength, other.maximumLength);
    }

    toMessage(): string {
      return i18n("screen_validation_text_too_long", {actual: this.actualLength, maximum: this.maximumLength});
    }
  }

  export class StringDoesNotMatchRegex implements ComponentValidationError {
    static className = "StringDoesNotMatchRegex";
    className(): string {
      return StringDoesNotMatchRegex.className;
    }

    constructor() {}

    static copy(other: StringDoesNotMatchRegex) {
      return new StringDoesNotMatchRegex();
    }

    toMessage(): string {
      return i18n("screen_validation_text_not_match_regex", {});
    }
  }


  export class StringPolishNipIncorrect implements ComponentValidationError {
    static className = "StringPolishNipIncorrect";
    className(): string {
      return StringPolishNipIncorrect.className;
    }

    constructor() {}

    static copy(other: StringPolishNipIncorrect) {
      return new StringPolishNipIncorrect();
    }

    toMessage(): string {
      return i18n("screen_validation_text_StringPolishNipIncorrect", {});
    }
  }


  export class StringPolishPeselIncorrect implements ComponentValidationError {
    static className = "StringPolishPeselIncorrect";
    className(): string {
      return StringPolishPeselIncorrect.className;
    }

    constructor() {}

    static copy(other: StringPolishPeselIncorrect) {
      return new StringPolishPeselIncorrect();
    }

    toMessage(): string {
      return i18n("screen_validation_text_pesel_incorrect", {});
    }
  }


  export class StringPolishBankAccountIncorrect implements ComponentValidationError {
    static className = "StringPolishBankAccountIncorrect";
    className(): string {
      return StringPolishBankAccountIncorrect.className;
    }

    constructor() {}

    static copy(other: StringPolishBankAccountIncorrect) {
      return new StringPolishBankAccountIncorrect();
    }

    toMessage(): string {
      return i18n("screen_validation_text_bank_account_incorrect", {});
    }
  }


  export class StringPolishPostalCodeIncorrect implements ComponentValidationError {
    static className = "StringPolishPostalCodeIncorrect";
    className(): string {
      return StringPolishPostalCodeIncorrect.className;
    }

    constructor() {}

    static copy(other: StringPolishPostalCodeIncorrect) {
      return new StringPolishPostalCodeIncorrect();
    }

    toMessage(): string {
      return i18n("screen_validation_text_postal_code_incorrect", {});
    }
  }


  export class StringEmailIncorrect implements ComponentValidationError {
    static className = "StringEmailIncorrect";
    className(): string {
      return StringEmailIncorrect.className;
    }

    constructor() {}

    static copy(other: StringEmailIncorrect) {
      return new StringEmailIncorrect();
    }

    toMessage(): string {
      return i18n("screen_validation_text_email_incorrect", {});
    }
  }

  export class SelectInputIncorrectValue implements ComponentValidationError {
    static className = "SelectInputIncorrectValue";
    className(): string {
      return SelectInputIncorrectValue.className;
    }

    constructor() {}

    static copy(other: SelectInputIncorrectValue) {
      return new SelectInputIncorrectValue();
    }

    toMessage(): string {
      return i18n("screen_validation_select_incorrect_value", {});
    }
  }


  export class ComponentRuleError implements ComponentValidationError {
    static className = "ComponentRuleError";
    className(): string {
      return ComponentRuleError.className;
    }

    constructor(readonly message: I18nText) {}

    static copy(other: ComponentRuleError) {
      return new ComponentRuleError(I18nText.copy(other.message));
    }

    toMessage(): string {
      return this.message.getCurrentWithFallback();
    }
  }


  export class ComponentValidationErrorFactory {
    static copy(other: ComponentValidationError): ComponentValidationError {
      return ComponentValidationErrorFactory.copyByType(other, other.className());
    }

    static copyTyped(other: Typed<ComponentValidationError>): Typed<ComponentValidationError> {
      return Typed.of(ComponentValidationErrorFactory.copyByType(Typed.value(other), Typed.className(other)));
    }

    static copyByType(other: ComponentValidationError, className: string): ComponentValidationError {
      switch (className) {
        case RequiredModelValueNotAvailable.className: return RequiredModelValueNotAvailable.copy(<RequiredModelValueNotAvailable>other);
        case ValueOfIncorrectType.className: return ValueOfIncorrectType.copy(<ValueOfIncorrectType>other);
        case NumberLargerThanMaximum.className: return NumberLargerThanMaximum.copy(<NumberLargerThanMaximum>other);
        case NumberSmallerThanMinimum.className: return NumberSmallerThanMinimum.copy(<NumberSmallerThanMinimum>other);
        case DateLargerThanMaximum.className: return DateLargerThanMaximum.copy(<DateLargerThanMaximum>other);
        case DateSmallerThanMinimum.className: return DateSmallerThanMinimum.copy(<DateSmallerThanMinimum>other);
        case TimeLargerThanMaximum.className: return TimeLargerThanMaximum.copy(<TimeLargerThanMaximum>other);
        case TimeSmallerThanMinimum.className: return TimeSmallerThanMinimum.copy(<TimeSmallerThanMinimum>other);
        case DateTimeLargerThanMaximum.className: return DateTimeLargerThanMaximum.copy(<DateTimeLargerThanMaximum>other);
        case DateTimeSmallerThanMinimum.className: return DateTimeSmallerThanMinimum.copy(<DateTimeSmallerThanMinimum>other);
        case DurationLargerThanMaximum.className: return DurationLargerThanMaximum.copy(<DurationLargerThanMaximum>other);
        case DurationSmallerThanMinimum.className: return DurationSmallerThanMinimum.copy(<DurationSmallerThanMinimum>other);
        case StringShorterThanMinimum.className: return StringShorterThanMinimum.copy(<StringShorterThanMinimum>other);
        case StringLongerThanMaximum.className: return StringLongerThanMaximum.copy(<StringLongerThanMaximum>other);
        case StringDoesNotMatchRegex.className: return StringDoesNotMatchRegex.copy(<StringDoesNotMatchRegex>other);
        case StringPolishNipIncorrect.className: return StringPolishNipIncorrect.copy(<StringPolishNipIncorrect>other);
        case StringPolishPeselIncorrect.className: return StringPolishPeselIncorrect.copy(<StringPolishPeselIncorrect>other);
        case StringPolishBankAccountIncorrect.className: return StringPolishBankAccountIncorrect.copy(<StringPolishBankAccountIncorrect>other);
        case StringPolishPostalCodeIncorrect.className: return StringPolishPostalCodeIncorrect.copy(<StringPolishPostalCodeIncorrect>other);
        case StringEmailIncorrect.className: return StringEmailIncorrect.copy(<StringEmailIncorrect>other);
        case SelectInputIncorrectValue.className: return SelectInputIncorrectValue.copy(<SelectInputIncorrectValue>other);
        case ComponentRuleError.className: return ComponentRuleError.copy(<ComponentRuleError>other);
        default: throw new Error("Not supported ["+className+"]");
      }
    }
  }


  export class ScreenLogEntryLevel {
    constructor(readonly level: number) {}
    static error = new ScreenLogEntryLevel(3);
    static warning = new ScreenLogEntryLevel(2);
    static debug = new ScreenLogEntryLevel(1);

    static ofLevel(level: number) {
      switch (level) {
        case ScreenLogEntryLevel.error.level: return ScreenLogEntryLevel.error;
        case ScreenLogEntryLevel.warning.level: return ScreenLogEntryLevel.warning;
        case ScreenLogEntryLevel.debug.level: return ScreenLogEntryLevel.debug;
        default: throw new Error("Unsupported error level '"+level+"'");
      }
    }
  }

  export class VariableError {
    constructor(
      readonly contextId: VariableId,
      readonly variableName: string,
      readonly error: string,
      readonly timestamp: LocalDateTime
    ) {}

    static copy(other: VariableError) {
      return new VariableError(
        VariableId.copy(other.contextId),
        other.variableName,
        other.error,
        LocalDateTime.copy(other.timestamp));
    }
  }

  export class ScreenLogEntry {
    constructor(
      readonly timestamp: number,
      readonly logType: string,
      readonly level: ScreenLogEntryLevel,
      readonly message: string
    ) {}

    static copy(other: ScreenLogEntry) {
      return new ScreenLogEntry(
        other.timestamp,
        other.logType,
        ScreenLogEntryLevel.ofLevel(other.level.level),
        other.message);
    }
  }

  export class ScreenDebugInfo {
    constructor(readonly logs: Array<ScreenLogEntry>,
                readonly errors: Array<VariableError>,
                readonly data: ObjectVariable) {
    }

    static copy(other: ScreenDebugInfo) {
      return new ScreenDebugInfo(
        other.logs.map(ScreenLogEntry.copy),
        other.errors.map(VariableError.copy),
        ObjectVariable.copy(other.data))
    }
  }


