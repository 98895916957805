import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {__, AggregateId, FileProtocol, FileUri} from "@utils";
import {DocumentsSharedService} from "../documents.shared-service";
import {ContentWithAuthorization, DirectoryWithContent, NodeWithAuthorization} from "@shared";

export enum FilesSelectType {
  pin, copy
}

class DirectoryEntry {
  constructor(readonly name: string,
              public selected: boolean) {}
}

class FileEntry {
  constructor(readonly name: string,
              readonly size: number,
              public selected: boolean) {}
}

class FileSelectorViewModel {
  directories: Array<DirectoryEntry> = [];
  files: Array<FileEntry> = [];
  path: string = "/";
  constructor(readonly documentsSharedService: DocumentsSharedService,
              readonly singleFileMode: boolean,
              readonly close: () => void,
              readonly nodesSelected: (directories: Array<FileUri>, files: Array<FileUri>, selectType: FilesSelectType) => void) {} // true is pin

  init() {
    this.documentsSharedService.findRootDirectoryContent(false).then((content: ContentWithAuthorization) => {
      this.directoryContentLoaded(content.id, content.content, "/");
    });
  }


  private directoryContentLoaded(id: AggregateId, content: Array<NodeWithAuthorization>, path: string): void {
    this.path = path;
    this.directories = [];
    this.files = [];
    content.forEach(node => {
      if (node.node.isDirectory()) {
        // const directoryInfo = node.node.getDirectoryInfo();
        this.directories.push(new DirectoryEntry(node.node.name, false));
      } else if (node.node.isFile()) {
        const fileInfo = node.node.getFileInfo();
        this.files.push(new FileEntry(node.node.name, __(fileInfo.fileVersions).last().fileSize, false));
      } else {
        throw new Error("Incorrect node type");
      }
    });
    this.files = __(this.files).sortBy(f => f.name.toLocaleLowerCase());
    this.directories = __(this.directories).sortBy(d => d.name.toLocaleLowerCase());
  }

  directoryClicked(directory: DirectoryEntry) {
    const newPath = this.path === "/"
      ? ("/" + directory.name)
      : (this.path + "/" + directory.name);

    this.documentsSharedService.findDirectoryWithContent(FileUri.fromDirectoryPath(newPath), false).then((content: DirectoryWithContent) => {
     this.directoryContentLoaded(content.directory.node.id, content.content,newPath);
    });
  }

  navigateUpClicked() {
    const newPath = "/"+this.path.split("/").filter(d => d.length > 0).slice(0, -1).join("/");
    if(newPath === "/") {
      this.documentsSharedService.findRootDirectoryContent(false).then((content: ContentWithAuthorization) => {
        this.directoryContentLoaded(content.id, content.content, "/");
      });
    } else {
      this.documentsSharedService.findDirectoryWithContent(FileUri.fromDirectoryPath(newPath), false).then((content: DirectoryWithContent) => {
        this.directoryContentLoaded(content.directory.node.id, content.content,newPath);
      });
    }
  }

  pin() {
    const pathPrefix = this.path === "/"
      ? this.path
      : this.path + "/";
    this.nodesSelected(
      this.directories.filter(d => d.selected).map(d => new FileUri(FileProtocol.RepositoryDir, pathPrefix+d.name)),
      this.files.filter(d => d.selected).map(d => new FileUri(FileProtocol.RepositoryFile, pathPrefix+d.name)),
      FilesSelectType.pin
    );
  }

  copy() {
    const pathPrefix = this.path === "/"
      ? this.path
      : this.path + "/";
    this.nodesSelected(
      this.directories.filter(d => d.selected).map(d => new FileUri(FileProtocol.RepositoryDir, pathPrefix+d.name)),
      this.files.filter(d => d.selected).map(d => new FileUri(FileProtocol.RepositoryFile, pathPrefix+d.name)),
      FilesSelectType.copy
    );
  }


  fileClicked(file: FileEntry) {
    const pathPrefix = this.path === "/"
      ? this.path
      : this.path + "/";
    this.nodesSelected([], [new FileUri(FileProtocol.RepositoryFile, pathPrefix+file.name)], FilesSelectType.pin);
  }

  directoryIsEmpty() {
    return this.files.length === 0 && this.directories.length === 0;
  }

}


@Component({
  selector: "my-file-selector",
  templateUrl: "./file-selector.component.html"
})
export class FormAttachmentComponent implements OnInit {
  @Output() close = new EventEmitter<void>();
  @Output() nodesSelected = new EventEmitter<{directories: Array<FileUri>, files: Array<FileUri>, selectType: FilesSelectType}>();
  @Input() singleFileMode: boolean = false;
  viewModel!: FileSelectorViewModel;

  constructor(readonly documentsSharedService: DocumentsSharedService) {}

  ngOnInit() {
    this.viewModel = new FileSelectorViewModel(this.documentsSharedService,
      this.singleFileMode,
      () => {
        this.close.emit();
      }, (dirs, files, selectType) => {
        this.nodesSelected.emit({directories: dirs, files: files, selectType: selectType});
      });
    this.viewModel.init();
  }
}
