import {
  AggregateId,
  AnyFlowId,
  BusinessEntityId,
  FileUri,
  FlowId,
  RemoteFlowId,
  restUrl,
  ScreenInstanceId
} from "@utils";
import {FlowCursorVersion, ProcessNodeId} from "@shared-model";

export function processDesignDownloadUrl(releaseId: string, nodeId: ProcessNodeId, uri: FileUri): string {
    return restUrl(`download/attachment/${releaseId}/${nodeId}/${uri.urlSerialized()}`);
  }

  export function taskDesignAttachmentDownloadUrl(flowId: AnyFlowId, nodeId: ProcessNodeId, uri: FileUri): string {
    return restUrl(`download/attachment/task/${flowId.urlSerialized()}/${nodeId}/${uri.urlSerialized()}`)
  }

  export function taskFlowAttachmentDownloadUrl(uri: FileUri): string {
    return documentRepositoryUri(uri);
  }


  export function supervisorAttachmentDownloadUrl(flowId: AnyFlowId, nodeId: ProcessNodeId, cursorVersion: FlowCursorVersion, uri: FileUri): string {
    if(flowId instanceof FlowId) {
      return restUrl(`download/attachment-flow-task-supervisor/${flowId.urlSerialized()}/${nodeId}/${cursorVersion}/${uri.urlSerialized()}`);
    } else if(flowId instanceof RemoteFlowId) {
      throw new Error("Remote flow not yet supported");
    } else {
      throw new Error("Incorrect flowIdType")
    }
  }

  export function flowCommentAttachmentDownloadUrl(flowId: AnyFlowId, uri: FileUri): string {
    if(flowId instanceof FlowId) {
      return restUrl(`process-flow/attachment/comment/${flowId.urlSerialized()}/${uri.urlSerialized()}`)
    } else if(flowId instanceof RemoteFlowId) {
      throw new Error("Remote flow not yet supported");
    } else {
      throw new Error("Incorrect flowIdType")
    }
  }

  export function documentRepositoryUri(uri: FileUri): string {
    return restUrl(`documents/download/${uri.urlSerialized()}`);
  }

  export function organizationsImageDownloadUrl(uri: FileUri): string {
    return restUrl(`download/organizations-images/${uri.urlSerialized()}`);
  }

  export function screenInstanceDownloadUrl(screenInstanceId: ScreenInstanceId, uri: FileUri): string {
    return restUrl(`download/screen-instance-file/${screenInstanceId.id}/${uri.urlSerialized()}`);
  }

  export function organizationsLogoDownloadUrl(organizationId: AggregateId): string {
    return restUrl(`download/organization-logo/${organizationId.id}`);
  }

  export function businessEntityDownloadUrl(businessEntityId: BusinessEntityId, uri: FileUri): string {
    return restUrl(`download/business-entity-file/${businessEntityId.id}/${uri.urlSerialized()}`);
  }
