import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {I18nPipe, NonPureI18nPipe} from "./pipes/i18n.pipe";
import {LanguageSelectorComponent} from "./components/language-selector/language-selector.component";
import {ThemeSelectorComponent} from "./components/theme-selector/theme-selector.component";
import {FontSizeSelectorComponent} from "./components/font-size-selector/font-size-selector.component";
import {UserTimeZoneSelectorComponent} from "./components/time-zone-selector/user-time-zone-selector.component";
import {
  TimeHoursFormatSelectorComponent
} from "./components/time-hours-format-selector/time-hours-format-selector.component";
import {
  LanguageSelectorUserSettingsComponent
} from "./components/language-selector/language-selector-user-settings.component";
import {DateShortPipe} from "./pipes/dateShort.pipe";
import {DateMediumPipe} from "./pipes/dateMedium.pipe";
import {DateLongPipe} from "./pipes/dateLong.pipe";
import {DateFullPipe} from "./pipes/dateFull.pipe";
import {DateTimeMinutesPipe} from "./pipes/dateTimeMinutes.pipe";
import {DateTimeSecondsPipe} from "./pipes/dateTimeSeconds.pipe";
import {NumberPipe} from "./pipes/number.pipe";
import {NumberIntegerPipe} from "./pipes/numberInteger.pipe";
import {CurrencySymbolPipe} from "./pipes/currencySymbol.pipe";
import {
  FirstDayOfWeekSelectorComponent
} from "./components/first-day-of-week-selector/first-day-of-week-selector.component";
import {RelativeTimePipe} from "./pipes/relativeTime.pipe";
import {LocaleSelectorComponent} from "./components/locale-selector/locale-selector.component";
import {I18nCountPipe} from "./pipes/i18nCount.pipe";
import {WeekendDaysSelectorComponent} from "./components/weekend-days-selector/weekend-days-selector.component";
import {DateTimeShortPipe} from "./pipes/dateTimeShort.pipe";
import {PopupComponent} from "./components/popups/popup.component";
import {SanitizeStylePipe} from "./pipes/sanitizeStyle.pipe";
import {TimeSummaryComponent} from "./components/time-summary/time-summary.component";
import {TooltipDirective} from "./directives/tooltip/tooltip.directive";
import {ToastrOverlayComponent} from "./components/toastr/toastr-overlay.component";
import {AdjustingTextInput} from "./components/adjusting-text-input/adjusting-text-input.component";
import {LoginFormComponent} from "./components/login-form/login-form.component";
import {CheckboxComponent} from "./components/form/checkbox/checkbox.component";
import {FileSizeFormatPipe} from "./pipes/fileSizeFormat.pipe";
import {DropMenuComponent} from "./components/popups/drop-menu.component";
import {
  OrganizationNodeSelectComponent
} from "./components/organization-node-select/organization-node-select.component";
import {
  OrganizationNodesSearchBoxComponent
} from "./components/organization-node-select/organization-nodes-search-box.component";
import {AvatarComponent} from "./components/avatar/avatar.component";
import {FocusOnShowDirective} from "./directives/focus-on-show/focus-on-show.directive";
import {DurationPickerComponent} from "./components/duration-picker/duration-picker.component";
import {DurationPickerBoardComponent} from "./components/duration-picker/duration-picker-board.component";
import {TimePickerBoardComponent} from "./components/time-picker/time-picker-board.component";
import {DatePickerBoardComponent} from "./components/date-picker/date-picker-board.component";
import {ScrollComponent} from "./components/scrolls/scroll.component";
import {I18nTextInputComponent} from "./components/i18n-text-input/i18n-text-input.component";
import {RadiobuttonComponent} from "./components/form/radiobutton/radiobutton.component";
import {TrustUrlPipe} from "./pipes/trustUrl.pipe";
import {TermsAndPolicyComponent} from "./components/terms-and-policy/terms-and-policy.component";
import {LogoPreloaderComponent} from "./components/preloader/logo-preloader.component";
import {DropDownSelectorComponent} from "./components/drop-down-selector/drop-down-selector.component";
import {ModalComponent} from "./components/popups/modal.component";
import {TrustHtmlPipe} from "./pipes/trustHtml.pipe";
import {TabsComponent} from "./components/tabs/tabs.component";
import {DrawInLogoComponent} from "./components/preloader/draw-in-logo.component";
import {PasswordMeterComponent} from "./components/password-meter/password-meter-component";
import {SwitchComponent} from "./components/form/switch/switch.component";
import {DateComponent} from "./components/date-picker/date.component";
import {DateTimeComponent} from "./components/date-picker/date-time.component";
import {TrileanComponent} from "./components/form/checkbox/trilean.component";
import {KeywordsComponent} from "./components/keywords/keywords.component";
import {ResponsiveDirective} from "./directives/responsive/responsive.directive";
import {ExpandableContentComponent} from "./components/expandable-content/expandable-content.component";
import {ChatComponent} from "./components/chat/chat.component";
import {SuggestingInputComponent} from "./components/suggesting-input/suggesting-input.component";
import {VariableTextPreviewComponent} from "./components/variable-text-preview/variable-text-preview.component";
import {
  LanguageSelectorSystemSettingsComponent
} from "./components/language-selector/language-selector-system-settings.component";
import {AuthorizationListComponent} from "./components/organization-nodes-list/authorization-list.component";
import {NodeSummaryComponent} from "./components/node-summary/node-summary.component";
import {PersonSummaryComponent} from "./components/person-summary/person-summary.component";
import {ExpressionEditorComponent} from "./components/expression-editor/expression-editor.component";
import {TimeComponent} from "./components/time-picker/time.component";
import {ReorderComponent} from "./components/reorder/reorder.component";
import {SearchBarComponent} from "./components/search-bar/search-bar.component";
import {NgxMaskDirective} from "ngx-mask";
import {TooltipComponent} from "./directives/tooltip/tooltip.component";
import {DatumDirective} from "./directives/datum/datum.directive";
import {NotificationsComponent} from "./components/notifications/notifications.component";
import {NotificationComponent} from "./components/notifications/notification.component";
import {AddLabelInputComponent} from "./components/add-label-input/add-label-input.component";
import {LanguageListComponent} from "./components/language-selector/language-list/language-list.component";
import {DarkModeClassDirective} from "./directives/dark-mode/dark-mode-class.directive";
import {LabelsComponent} from "./components/labels/labels.component";
import {BasicFlowSummaryComponent} from "./components/basic-flow-summary/basic-flow-summary.component";
import {SimplePreloaderComponent} from "./components/preloader/simple-preloader.component";

import {PersonIconEditorComponent} from "./components/person-icon-editor/person-icon-editor.component";
import {IdentifierInputComponent} from "./components/identifier-input/identifier-input.component";
import {ColorSelectorComponent} from "./components/color-selector/color-selector.component";
import {IconSelectorComponent} from "./components/icon-selector/icon-selector.component";
import {SelectOnShowDirective} from "./directives/focus-on-show/select-on-show.directive";
import {ColorPickerComponent} from "./components/color-picker/color-picker.component";
import {DataTypeSelectorComponent} from "./components/data-type-selector/data-type-selector.component";
import {SortableComponent} from "./components/sortable/sortable.component";
import {ProcessThumbnailComponent} from "./components/process-thumbnail/process-thumbnail.component";
import {SqlEditorComponent} from "./components/sql-editor/sql-editor.component";
import {ComponentThumbnailComponent} from "./components/component-thumbnail/component-thumbnail.component";
import {
  PathInputWithContextComponent
} from "./components/identifier-input/path-input-with-context.component";
import {LogsComponent} from "./components/logs/logs.component";
import {WeekDaysSelectorComponent} from "./components/week-days-selector/week-days-selector.component";
import {TimeZoneSelectorComponent} from "./components/time-zone-selector/time-zone-selector.component";
import {ScreenPlaceholderComponent} from "./components/screen-placeholder/screen-placeholder.component";
import {CalendarComponent} from "./components/calendar/calendar.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NgxMaskDirective
  ],
  declarations: [
    LoginFormComponent,
    LogsComponent,
    ReorderComponent,
    LanguageSelectorComponent,
    LanguageListComponent,
    LanguageSelectorUserSettingsComponent,
    LanguageSelectorSystemSettingsComponent,
    ThemeSelectorComponent,
    ScrollComponent,
    ScreenPlaceholderComponent,
    ExpandableContentComponent,
    TabsComponent,
    FontSizeSelectorComponent,
    LocaleSelectorComponent,
    KeywordsComponent,
    IdentifierInputComponent,
    PathInputWithContextComponent,
    UserTimeZoneSelectorComponent,
    TimeZoneSelectorComponent,
    TimeHoursFormatSelectorComponent,
    FirstDayOfWeekSelectorComponent,
    WeekendDaysSelectorComponent,
    WeekDaysSelectorComponent,
    VariableTextPreviewComponent,
    PopupComponent,
    LogoPreloaderComponent,
    SimplePreloaderComponent,
    DrawInLogoComponent,
    CheckboxComponent,
    SwitchComponent,
    TrileanComponent,
    I18nTextInputComponent,
    RadiobuttonComponent,
    I18nPipe,
    NonPureI18nPipe,
    I18nCountPipe,
    DateShortPipe,
    DateMediumPipe,
    DateLongPipe,
    DateFullPipe,
    DateTimeShortPipe,
    DateTimeMinutesPipe,
    DateTimeSecondsPipe,
    RelativeTimePipe,
    NumberPipe,
    NumberIntegerPipe,
    CurrencySymbolPipe,
    SanitizeStylePipe,
    TrustUrlPipe,
    TrustHtmlPipe,
    TimeSummaryComponent,
    TooltipDirective,
    TooltipComponent,
    DatumDirective,
    ResponsiveDirective,
    ToastrOverlayComponent,
    AdjustingTextInput,
    FileSizeFormatPipe,
    DropMenuComponent,
    ModalComponent,
    DropDownSelectorComponent,
    OrganizationNodeSelectComponent,
    OrganizationNodesSearchBoxComponent,
    AvatarComponent,
    DurationPickerBoardComponent,
    DurationPickerComponent,
    DateComponent,
    ColorSelectorComponent,
    IconSelectorComponent,
    TimeComponent,
    DateTimeComponent,
    DatePickerBoardComponent,
    TimePickerBoardComponent,
    FocusOnShowDirective,
    SelectOnShowDirective,
    I18nTextInputComponent,
    TermsAndPolicyComponent,
    PasswordMeterComponent,
    ChatComponent,
    SuggestingInputComponent,
    AuthorizationListComponent,
    NodeSummaryComponent,
    PersonSummaryComponent,
    ExpressionEditorComponent,
    SearchBarComponent,
    NotificationsComponent,
    NotificationComponent,
    AddLabelInputComponent,
    LabelsComponent,
    BasicFlowSummaryComponent,
    DarkModeClassDirective,
    PersonIconEditorComponent,
    ColorPickerComponent,
    DataTypeSelectorComponent,
    SortableComponent,
    ProcessThumbnailComponent,
    ComponentThumbnailComponent,
    SqlEditorComponent,
    CalendarComponent
  ],
  exports: [
    FormsModule,
    CommonModule,
    RouterModule,
    LoginFormComponent,
    LogsComponent,
    ReorderComponent,
    LanguageSelectorComponent,
    LanguageListComponent,
    LanguageSelectorUserSettingsComponent,
    TimeHoursFormatSelectorComponent,
    FirstDayOfWeekSelectorComponent,
    WeekendDaysSelectorComponent,
    WeekDaysSelectorComponent,
    VariableTextPreviewComponent,
    ThemeSelectorComponent,
    FontSizeSelectorComponent,
    LocaleSelectorComponent,
    KeywordsComponent,
    IdentifierInputComponent,
    PathInputWithContextComponent,
    UserTimeZoneSelectorComponent,
    TimeZoneSelectorComponent,
    PopupComponent,
    LogoPreloaderComponent,
    SimplePreloaderComponent,
    DrawInLogoComponent,
    CheckboxComponent,
    SwitchComponent,
    TrileanComponent,
    I18nPipe,
    NonPureI18nPipe,
    I18nCountPipe,
    DateShortPipe,
    DateMediumPipe,
    DateLongPipe,
    DateFullPipe,
    DateTimeShortPipe,
    DateTimeMinutesPipe,
    DateTimeSecondsPipe,
    RelativeTimePipe,
    NumberPipe,
    NumberIntegerPipe,
    CurrencySymbolPipe,
    SanitizeStylePipe,
    FileSizeFormatPipe,
    TimeSummaryComponent,
    TooltipDirective,
    TooltipComponent,
    DatumDirective,
    ResponsiveDirective,
    ToastrOverlayComponent,
    AddLabelInputComponent,
    AdjustingTextInput,
    DropMenuComponent,
    ModalComponent,
    DropDownSelectorComponent,
    OrganizationNodeSelectComponent,
    OrganizationNodesSearchBoxComponent,
    AvatarComponent,
    DurationPickerBoardComponent,
    DurationPickerComponent,
    DateComponent,
    ColorSelectorComponent,
    IconSelectorComponent,
    TimeComponent,
    DateTimeComponent,
    DataTypeSelectorComponent,
    DatePickerBoardComponent,
    TimePickerBoardComponent,
    ScrollComponent,
    ScreenPlaceholderComponent,
    SortableComponent,
    ExpandableContentComponent,
    FocusOnShowDirective,
    SelectOnShowDirective,
    I18nTextInputComponent,
    RadiobuttonComponent,
    TrustUrlPipe,
    TrustHtmlPipe,
    TermsAndPolicyComponent,
    TabsComponent,
    PasswordMeterComponent,
    ChatComponent,
    SuggestingInputComponent,
    LanguageSelectorSystemSettingsComponent,
    AuthorizationListComponent,
    NodeSummaryComponent,
    PersonSummaryComponent,
    ExpressionEditorComponent,
    SearchBarComponent,
    NotificationsComponent,
    NotificationComponent,
    LabelsComponent,
    BasicFlowSummaryComponent,
    CalendarComponent,
    DarkModeClassDirective,
    PersonIconEditorComponent,
    ColorPickerComponent,
    DataTypeSelectorComponent,
    ProcessThumbnailComponent,
    ComponentThumbnailComponent,
    SqlEditorComponent
  ]
})
export class SharedModule {
}
