import {AnyFlowId, AnyFlowIdFactory, FileUri, Option, Typed} from "@utils";

export interface UploadFormFileResponse {
  className(): string;
}

export class UploadFormFileFailure implements UploadFormFileResponse {
  constructor(readonly exceptions: Array<string>) {}
  static className = "UploadFormFileFailure";
  className() {
    return UploadFormFileFailure.className;
  }

  static copy(other: UploadFormFileFailure) {
    return new UploadFormFileFailure(other.exceptions.slice());
  }
}

export class UploadFormFileSuccess implements UploadFormFileResponse {
  constructor(
    readonly fileUri: FileUri,
    readonly materializedFlowId: Option<Typed<AnyFlowId>>) {}

  static className = "UploadFormFileSuccess";
  className() {
    return UploadFormFileSuccess.className;
  }

  static copy(other: UploadFormFileSuccess): UploadFormFileSuccess {
    return new UploadFormFileSuccess(FileUri.copy(other.fileUri), Option.copy(other.materializedFlowId, AnyFlowIdFactory.copyTyped));
  }
}


export class UploadFileResultHelper {
  static isSuccess(result: UploadFileResult): boolean {
    return (<any>result).success === true;
  }

  static getFileUri(result: UploadFileResult): FileUri {
    if(this.isSuccess(result)) {
      return FileUri.copy((<any>result).fileUri);
    } else {
      throw new Error("Not a success");
    }
  }

  static getError(result: UploadFileResult): string {
    if(!(<any>result).error) {
      return (<any>result).error;
    } else {
      throw new Error("Not an error");
    }
  }
}

export interface UploadFileResult {}

export class UploadFileSuccess implements UploadFileResult {
  constructor(readonly fileUri: FileUri,
              readonly fileVersion: number,
              readonly success: boolean) {}

  static copy(other: UploadFileSuccess) {
    return new UploadFileSuccess(FileUri.copy(other.fileUri), other.fileVersion, other.success);
  }
}

export class UploadFileFailure implements UploadFileResult {
  constructor(readonly error: string) {}

  static copy(other: UploadFileFailure) {
    return new UploadFileFailure(other.error);
  }
}
