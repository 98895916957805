import {Injectable} from "@angular/core";
import {AuthenticatedHttp, BasicPersonInfo, GetBasicPersonsInfoByIds} from "..";
import {
  __,
  AggregateId,
  AggregateVersion,
  AnyPersonId,
  None,
  OrganizationId,
  ResolvablePromise,
  Typed,
  values
} from "@utils";

export class GetBasicPersonsInfoByUserIds {
  constructor(readonly usersIds: Array<AggregateId>) {}
}

@Injectable({
  providedIn: 'root',
})
export class PersonsSharedService {
  private basicInfoCache: {[personId: string]: Promise<BasicPersonInfo>} = {};
  private findPersonsByPersonsIdsCache: {[key: string]: Promise<BasicPersonInfo>} = {};

  constructor(readonly authenticatedHttp: AuthenticatedHttp) {
  }

  addToCache(persons: BasicPersonInfo[]) {
    persons.forEach(p => {
      const basicPersonInfoPromise = Promise.resolve(p);
      this.basicInfoCache[p.idUnwrapped().serialize()] = basicPersonInfoPromise;
      this.findPersonsByPersonsIdsCache[p.idUnwrapped().serialize()] = basicPersonInfoPromise;
    })
  }

  findPersonBasicInfo(personsIds: Array<AnyPersonId>, onSuccess: (data: {[personId: string]: BasicPersonInfo}) => void): void {
    const result: {[personId: string]: Promise<BasicPersonInfo>} = {};

    const unique = __(personsIds).uniqueBy(p => p.serialize());

    const personsToLoad = unique.filter(personId => {
      const fromCache: Promise<BasicPersonInfo> = this.basicInfoCache[personId.serialize()];
      if(fromCache !== undefined) {
        result[personId.serialize()] = fromCache.then(BasicPersonInfo.copy);
        return false;
      } else {
        return true;
      }
    });

    if(personsToLoad.length > 0) {
      const localPersonsToLoad = personsToLoad.filter(p => p.isPersonId());

      personsToLoad.filter(p => p.isRemotePersonId()).forEach(remotePersonId =>
        result[remotePersonId.serialize()] = Promise.resolve(new BasicPersonInfo(Typed.of(remotePersonId), new AggregateVersion(0), OrganizationId.of(""), None(),
          "Remote Person", "", "", "", None(), false))
      );

      if (localPersonsToLoad.length > 0) {


        const cache: {[personId: string]: ResolvablePromise<BasicPersonInfo>} = {};

        unique.forEach(personId => {
          const resolvablePromise = new ResolvablePromise<BasicPersonInfo>();
          cache[personId.serialize()] = resolvablePromise;
          this.basicInfoCache[personId.serialize()] = resolvablePromise.promise;
        });


        this.authenticatedHttp.post("organization-structure/person/basic-person-info", new GetBasicPersonsInfoByIds(unique.map(Typed.of)),
          (data: BasicPersonInfo[]) => {

            data.forEach(info => {
              const infoCopy = BasicPersonInfo.copy(info);
              cache[infoCopy.idUnwrapped().serialize()].resolve(infoCopy);
              result[infoCopy.idUnwrapped().serialize()] = Promise.resolve(BasicPersonInfo.copy(info));
            });

            if (data.length === unique.length) {
              this.handlePromisedResults(result, onSuccess);
            } else {

              // PersonId must be an we want to find it if that is the case
              this.authenticatedHttp.post("organization-structure/person/basic-person-info-by-user-id", new GetBasicPersonsInfoByUserIds(localPersonsToLoad.map(p => p.asPersonId().id)),
                (dataByUserId: Array<[AggregateId, BasicPersonInfo]>) => {
                  dataByUserId.forEach(info => {

                    const userId = AggregateId.copy(info[0]);
                    const personInfo = BasicPersonInfo.copy(info[1]);
                    cache[personInfo.idUnwrapped().serialize()].resolve(personInfo);
                    cache[userId.id].resolve(personInfo);
                    result[userId.id] = Promise.resolve(BasicPersonInfo.copy(personInfo));
                  });
                  this.handlePromisedResults(result, onSuccess);
                })

            }
          }
        )
      } else {
        this.handlePromisedResults(result, onSuccess);
      }
    } else {
      this.handlePromisedResults(result, onSuccess);
    }
  }


  private handlePromisedResults(result: {[personId: string]: Promise<BasicPersonInfo>}, onSuccess: (data: {[personId: string]: BasicPersonInfo}) => void) {
    Promise.all(values(result)).then((data: BasicPersonInfo[]) => {
      const result: {[personId: string]: BasicPersonInfo} = {};
      data.forEach(info => {
        result[info.idUnwrapped().serialize()] = info;
      });
      onSuccess(result);
    });
  }

}
