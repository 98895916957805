import {Injectable} from "@angular/core";
import {Action, AuthenticatedHttp} from "..";
import {AggregateId, AggregateVersion, AggregateWrapper, Option, OrganizationNodeId, ReportId, toastr} from "@utils";
import {CustomAuthorization, OrganizationAuthorization} from "./OrganizationAuthorizationModel";
import {CommandService} from "../command-service";
import {SessionEventBus} from "@shared";
import {
  AuthorizeActionsByReport
} from "../../modules/designer.module/services/authorization/designer-authorization.messages";

class AddAuthorization {
  constructor(readonly aggregateId: AggregateId,
              readonly expectedVersion: AggregateVersion,
              readonly authorization: CustomAuthorization,
              readonly nodeId: OrganizationNodeId) {
  }
}

class DeleteAuthorization {

  constructor(readonly aggregateId: AggregateId,
              readonly expectedVersion: AggregateVersion,
              readonly authorization: CustomAuthorization,
              readonly nodeId: OrganizationNodeId) {
  }
}


class DeleteAndAddAuthorization {

  constructor(readonly aggregateId: AggregateId,
              readonly expectedVersion: AggregateVersion,
              readonly deleteAuthorization: CustomAuthorization,
              readonly addAuthorization: CustomAuthorization,
              readonly nodeId: OrganizationNodeId) {
  }
}


class AuthorizeActionsByOrganization {
  constructor(readonly actions: Array<Action>) {
  }
}


@Injectable({
  providedIn: "root"
})
export class AuthorizationSharedService extends CommandService {

  // WARNING, after adding new rights bucket, add clearing it in constructor
  private organizationBasedAccessRights:{[actionName:string]:boolean} = {};
  private reportBasedAccessRights:{[reportAndActionName:string]:boolean} = {};

  // WARNING, after adding new rights bucket, add clearing it in constructor

  constructor(authenticatedHttp: AuthenticatedHttp, sessionEventBus: SessionEventBus) {
    super(authenticatedHttp);

    sessionEventBus.on(sessionEventBus.userLoggedIn, () => {
      this.organizationBasedAccessRights = {};
    });
  }

  findForOrganizationPromise(): Promise<Option<AggregateWrapper<OrganizationAuthorization>>> {
    return this.authenticatedHttp.getPromise<Option<AggregateWrapper<OrganizationAuthorization>>>("organization-authorization/find-for-organization")
      .then((response: Option<AggregateWrapper<OrganizationAuthorization>>) => {
        return Option.copy(response).map(g => AggregateWrapper.copy(g, OrganizationAuthorization.copy));
      });

  }

  getOrganizationPermissions(onSuccess: (authorizations: Array<[CustomAuthorization, Array<OrganizationNodeId>]>) => void): void {
    return this.authenticatedHttp.get("organization-authorization/find-for-organization", (response: Option<AggregateWrapper<OrganizationAuthorization>>) => {
      const copied = Option.copy(response, g => AggregateWrapper.copy(g, OrganizationAuthorization.copy));
      if (copied.isEmpty()) {
        toastr.error("No authorizations found for this organization id");
      } else {
        onSuccess(copied.get().aggregate.authorization);
      }
    })
  }

  addAuthorization(authorization: CustomAuthorization, nodeId: OrganizationNodeId, aggregateId: AggregateId, expectedVersion: AggregateVersion, onSuccess: (version: AggregateVersion) => void, onFailure: () => void) {
    this.command("organization-authorization/add-authorization", new AddAuthorization(aggregateId, expectedVersion, authorization, nodeId), (id: AggregateId, version: AggregateVersion) => onSuccess(version), onFailure);
  }

  deleteAuthorization(authorization: CustomAuthorization, nodeId: OrganizationNodeId, aggregateId: AggregateId, expectedVersion: AggregateVersion, onSuccess: (version: AggregateVersion) => void, onFailure: () => void) {
    this.command("organization-authorization/delete-authorization", new DeleteAuthorization(aggregateId, expectedVersion, authorization, nodeId), (id: AggregateId, version: AggregateVersion) => onSuccess(version), onFailure);
  }

  deleteAndAddAuthorization(deleteAuthorization: CustomAuthorization, addAuthorization: CustomAuthorization, nodeId: OrganizationNodeId, aggregateId: AggregateId, expectedVersion: AggregateVersion, onSuccess: (version: AggregateVersion) => void, onFailure: () => void) {
    this.command("organization-authorization/delete-and-add-authorization", new DeleteAndAddAuthorization(aggregateId, expectedVersion, deleteAuthorization, addAuthorization, nodeId), (id: AggregateId, version: AggregateVersion) => onSuccess(version), onFailure);
  }

  hasRightsByOrganization(actions: Array<Action>, onSuccess: (result: { [rightName: string]: boolean }) => void) {
    const result: { [rightName: string]: boolean } = {};

    const actionsToLoad = actions.filter(action => {
      const fromCacheRight = this.organizationBasedAccessRights[action.name];
      if (fromCacheRight !== undefined) {
        result[action.name] = fromCacheRight;
        return false;
      } else {
        return true;
      }
    });

    if (actionsToLoad.length > 0) {
      this.authenticatedHttp.post("authorization/authorize-actions-by-organization", new AuthorizeActionsByOrganization(actionsToLoad),
        (data: [Action, boolean][]) => {
          data.forEach((r: [Action, boolean]) => {
            result[r[0].name] = r[1];
            this.organizationBasedAccessRights[r[0].name] = r[1];
          });
          onSuccess(result);
        });
    } else {
      onSuccess(result);
    }
  }

  hasRightByOrganization(action: Action, onSuccess: (result: boolean) => void) {
    this.hasRightsByOrganization([action], (result: { [rightName: string]: boolean }) => {
      onSuccess(result[action.name]);
    });
  }

  hasRightsByReport(reportId: ReportId, actions:Action[], onSuccess:(result:{[rightName:string]:boolean}) => void) {
    const result:{[rightName:string]:boolean} = {};

    const actionsToLoad = actions.filter(action => {
      const fromCacheRight = this.reportBasedAccessRights[reportId.id+"|"+action.name];
      if (fromCacheRight !== undefined) {
        result[action.name] = fromCacheRight;
        return false;
      } else {
        return true;
      }
    });

    if (actionsToLoad.length > 0) {
      this.authenticatedHttp.post("authorization/authorize-actions-by-report", new AuthorizeActionsByReport(reportId.toAggregateId(), actionsToLoad),
        (data:[Action, boolean][]) => {
          data.forEach((r:[Action, boolean]) => {
            result[r[0].name] = r[1];
            this.reportBasedAccessRights[reportId.id+"|"+r[0].name] = r[1];
          });
          onSuccess(result);
        });
    } else {
      onSuccess(result);
    }
  }

  hasRightByReport(reportId: ReportId, action: Action, onSuccess: (result: boolean) => void) {
    this.hasRightsByReport(reportId, [action], (result: { [rightName: string]: boolean }) => {
      onSuccess(result[action.name]);
    });
  }

  authorizeByOrganizationOr(action: Action, onSuccess: (authorized: boolean) => void) {
    this.hasRightByOrganization(action, (result: boolean) => {
      onSuccess(result);
    });
  }

}
