import {
  BusinessEntityId,
  BusinessEntityTypeId,
  BusinessEntityTypeReleaseId, None,
  Option,
  OrganizationId, Some,
  Typed
} from "@utils";
import {
  BusinessVariable,
  BusinessVariableFactory,
  FieldQueryExpression,
  ObjectVariable,
  TimeConstraint
} from "@shared-model";
import {BusinessEntitySummary} from "../../../../shared-model/business-entity/business-entity.shared-model";


export class BusinessEntityData {
  constructor(readonly id: BusinessEntityId,
              readonly typeId: BusinessEntityTypeId,
              readonly organizationId: OrganizationId,
              readonly name: Array<Typed<BusinessVariable>>,
              readonly description: Array<Typed<BusinessVariable>>,
              readonly data: ObjectVariable) {}

  static copy(other: BusinessEntityData) {
    return new BusinessEntityData(
      BusinessEntityId.copy(other.id),
      BusinessEntityTypeId.copy(other.typeId),
      OrganizationId.copy(other.organizationId),
      other.name.map(BusinessVariableFactory.copyTyped),
      other.description.map(BusinessVariableFactory.copyTyped),
      ObjectVariable.copy(other.data))
  }

  nameUnwrapped(): Array<BusinessVariable> {
    return this.name.map(v => Typed.value(v));
  }

  descriptionUnwrapped(): Array<BusinessVariable> {
    return this.description.map(v => Typed.value(v));
  }
}


export class BusinessEntitySearchQuery {
  constructor(public textQuery: string,
              public resultsAfter: Option<BusinessEntityId>,
              public results: Option<number>,
              public created: Option<TimeConstraint>,
              public modified: Option<TimeConstraint>,
              public codeName: Option<string>,
              public businessEntityId: Option<string>,
              public deleted: Option<boolean>,
              public expressionQuery: string,
              public fieldQuery: Option<Typed<FieldQueryExpression>>) {
  }

  static empty() {
    return BusinessEntitySearchQuery.textOnly("");
  }

  static textOnly(query: string) {
    return new BusinessEntitySearchQuery(
      query,
      None(),
      None(),
      None(),
      None(),
      None(),
      None(),
      Some(false),
      "",
      None()
    )
  }
  equals(other: BusinessEntitySearchQuery) {
    return this.textQuery === other.textQuery &&
      this.resultsAfter.equals(other.resultsAfter, (a, b) => a.id === b.id) &&
      this.results.equals(other.results) &&
      this.created.equals(other.created, (a, b) => a.equals(b)) &&
      this.modified.equals(other.modified, (a, b) => a.equals(b)) &&
      this.codeName.equals(other.codeName) &&
      this.businessEntityId.equals(other.businessEntityId) &&
      this.deleted.equals(other.deleted) &&
      this.expressionQuery.trim() === other.expressionQuery.trim() &&
      this.fieldQuery === other.fieldQuery; // TODO field query equality
  }
}

export class BusinessEntitySearchSummariesQuery {
  constructor(readonly query: BusinessEntitySearchQuery,
              readonly businessEntityTypeId: BusinessEntityTypeId,
              readonly nameFields: Array<string>,
              readonly descriptionFields: Array<string>) {}
}

export class BusinessEntitySearchSummariesResponse {
  constructor(readonly entities: Array<BusinessEntitySummary>,
              readonly moreAvailable: boolean) {}

  static copy(other: BusinessEntitySearchSummariesResponse) {
    return new BusinessEntitySearchSummariesResponse(other.entities.map(BusinessEntitySummary.copy), other.moreAvailable);
  }
}
