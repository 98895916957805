import {
  __,
  FormElementId,
  FormElementRefId,
  GridSize,
  GridXY,
  I18nText,
  MultiTypeInput,
  MultiTypeInputFactory,
  None,
  Option,
  ScriptInputType,
  Trilean,
  Typed
} from "@utils";
import {
  ArrayVariableType,
  BusinessVariablesValidation,
  BusinessVariableType,
  ExpressionWithAst,
  ObjectVariableType, TaskIdentifier,
  VariableTypePath
} from "@shared-model";
import {ElementsRefsFactory} from "./ElementsFactory";
import {LabelPosition} from "./FormField";
import {FormSectionId, FormSectionRefId, ScreenComponentRefIdentifier} from "@shared";

export class FormModelValidation {
  constructor(readonly validationFormula: Typed<MultiTypeInput>,
              readonly messageFormula: Typed<MultiTypeInput>) {}

  static empty(): FormModelValidation {
    return new FormModelValidation(Typed.of(new ScriptInputType("")),
      Typed.of(new ScriptInputType("")));
  }

  unwrappedValidationFormula() {
    return Typed.value(this.validationFormula);
  }

  unwrappedMessageFormula() {
    return Typed.value(this.messageFormula);
  }

  static copy(other: FormModelValidation) {
    return new FormModelValidation(
      MultiTypeInputFactory.copyTyped(other.validationFormula),
      MultiTypeInputFactory.copyTyped(other.messageFormula));
  }
}

export class FormModelOptions {
  constructor(readonly validations: Array<FormModelValidation>) {}

  static empty(): FormModelOptions {
    return new FormModelOptions([]);
  }

  static copy(other: FormModelOptions) {
    return new FormModelOptions(other.validations.map(FormModelValidation.copy));
  }
}

export class FormModel {

  constructor(readonly screenComponentRef: Option<ScreenComponentRefIdentifier>,
              readonly quickSubmitAllowed: boolean,
              readonly sectionsRefs: Array<FormSectionRef>,
              readonly options: FormModelOptions) {}

  static copy(other: FormModel) {
    return new FormModel(
      Option.copy(other.screenComponentRef),
      other.quickSubmitAllowed,
      other.sectionsRefs.map(FormSectionRef.copy),
      FormModelOptions.copy(other.options))
  }

  static empty(): FormModel {
    return new FormModel(None(), false, [], FormModelOptions.empty());
  }

  maxSectionRefId(): FormSectionRefId {
    return new FormSectionRefId(Math.max(0, __(this.sectionsRefs.map(n => n.id.id)).maxOrDefault(0)));
  }

  sectionRefById(sectionRefId: FormSectionRefId): FormSectionRef {
    return this.sectionsRefs.filter(s => s.id.id === sectionRefId.id)[0];
  }

  getFormSectionsIds(): FormSectionId[] {
    return this.sectionsRefs.map(sectionRef => sectionRef.sectionId);
  }
}





export class FormSectionRef {

  constructor(readonly id: FormSectionRefId,
              readonly sectionId: FormSectionId,
              readonly readOnly: boolean) {}

  static copy(other: FormSectionRef) {
    return new FormSectionRef(
      FormSectionRefId.copy(other.id),
      FormSectionId.copy(other.sectionId),
      other.readOnly);
  }
}

export class FormSectionInfo {

  constructor(readonly sectionRef: FormSectionRef,
              readonly section: FormSection) {}

  static copy(other: FormSectionInfo) {
    return new FormSectionInfo(FormSectionRef.copy(other.sectionRef), FormSection.copy(other.section));
  }
}

export class TaskStorageModel {
  constructor(readonly taskIdentifier: TaskIdentifier,
              readonly ended: boolean) {}

  static copy(other: TaskStorageModel) {
    return new TaskStorageModel(TaskIdentifier.copy(other.taskIdentifier), other.ended)
  }
}

export class FormSection {

  constructor(readonly id: FormSectionId,
              readonly gridHeight: number,
              readonly name: I18nText,
              readonly staticElementsRefs: Typed<StaticElementRef>[],
              readonly inputElementsRefs: Typed<InputElementRef>[],
              readonly  visibilityExpression: Option<ExpressionWithAst>,
              readonly forEachVariableName: Option<string>,
              readonly hideHeader: boolean,
              readonly lengthEditable: boolean,
              readonly minimumLength: Option<number>,
              readonly maximumLength: Option<number>,
              readonly tableMode: boolean) {

  }

  static copy(other: FormSection) {
    return new FormSection(
      FormSectionId.copy(other.id),
      other.gridHeight,
      I18nText.copy(other.name),
      other.staticElementsRefs.map(e => <Typed<StaticElementRef>>ElementsRefsFactory.copyTyped(e)),
      other.inputElementsRefs.map(e => <Typed<InputElementRef>>ElementsRefsFactory.copyTyped(e)),
      Option.copy(other.visibilityExpression, ExpressionWithAst.copy),
      Option.copy(other.forEachVariableName),
      other.hideHeader,
      other.lengthEditable,
      Option.copy(other.minimumLength),
      Option.copy(other.maximumLength),
      other.tableMode);
  }

  static empty(id: FormSectionId, name: string) {
    return new FormSection(id, 20, I18nText.en(name), [], [], None(), None(), false, true, None(), None(), false);
  }

  static defaultVariableTypeForRepeatableSectionTyped() {
    return Typed.of(FormSection.defaultVariableTypeForRepeatableSection());
  }

  static defaultVariableTypeForRepeatableSection() {
    return new ArrayVariableType(Typed.of(new ObjectVariableType([])));
  }

  inputElementsRefsUnwrapped(): InputElementRef[] {
    return this.inputElementsRefs.map(e => Typed.value(e));
  }

  staticElementsRefsUnwrapped(): StaticElementRef[] {
    return this.staticElementsRefs.map(e => Typed.value(e));
  }


  getInputElementRefById(id: FormElementRefId): InputElementRef {
    const foundElement = __(this.inputElementsRefsUnwrapped()).find((e:InputElementRef) => {
      return e.id.id === id.id
    }).getOrUndefined();
    if (foundElement == undefined) {
      throw new Error("Element " + id.id + " not found");
    } else {
      return foundElement;
    }
  }

  hasInputElementRefById(id: FormElementRefId): boolean {
    return __(this.inputElementsRefsUnwrapped()).exists(e => e.id.id === id.id);
  }

  getStaticElementRefById(id: FormElementRefId): StaticElementRef {
    const foundElement = __(this.staticElementsRefsUnwrapped()).find((e:StaticElementRef) => {
      return e.id.id === id.id
    }).getOrUndefined();
    if (foundElement == undefined) {
      throw new Error("Element " + id.id + " not found");
    } else {
      return foundElement;
    }
  }

  maxElementId(): FormElementRefId {
      return new FormElementRefId(Math.max(0,
        __(this.inputElementsRefs.map(n => Typed.value(n).id.id)).maxOrDefault(0),
        __(this.staticElementsRefs.map(n => Typed.value(n).id.id)).maxOrDefault(0)));
  }

}



export interface FormElementRef {
  className(): string;
  id: FormElementRefId;
  elementId: FormElementId;
  gridXY: GridXY;
  gridSize: GridSize;
  isInputElementRef(): boolean;
  hidden: Trilean;
}

export interface StaticElementRef extends FormElementRef {
}

export interface InputElementRef extends FormElementRef {
  label: I18nText;
  labelPosition: LabelPosition;
  labelGridShift: GridXY;
  required: Trilean;
  readOnly: Trilean;
  visibleInSummary: boolean;
  visibleInTaskBox: boolean;
}

export interface FormElement {
  className(): string;
  id: FormElementId;
  isInputElement(): boolean;
  hiddenExpression: Option<ExpressionWithAst>;
  tooltip: I18nText;
}

export interface StaticElement extends FormElement {
}


export class StaticElementWithRef {
  constructor(readonly element: StaticElement,
              readonly elementRef: StaticElementRef) {}
}

export class InputElementWithRef {
  constructor(readonly element: InputElement,
              readonly elementRef: InputElementRef) {}
}

export interface InputElement extends FormElement {
  variableTypePath: VariableTypePath;
  validation: Option<Typed<BusinessVariablesValidation>>;
  requiredExpression: Option<ExpressionWithAst>;
  readOnlyExpression: Option<ExpressionWithAst>;
}


export interface ElementTypeProperties {
  defaultGridSize: GridSize;
  minimumGridSize: GridSize;
  inputElement: boolean; // otherwise static
  defaultElement(id: FormElementId, variableTypePath: VariableTypePath): FormElement;
  defaultElementRef(id: FormElementRefId, elementId: FormElementId, gridXY: GridXY): FormElementRef;
  defaultVariableType(): BusinessVariableType;
  defaultVariableName(): string
}




export class VariableTypes {
  stringType: boolean = false;
  numberType: boolean = false;
  personType: boolean = false;
  personGroupDepartmentType: boolean = false;
  dateType: boolean = false;
  dateTimeType: boolean = false;
  timeType: boolean = false;

  static of() {
    return new VariableTypes();
  }

  string() {
    this.stringType = true;
    return this;
  }

  number() {
    this.numberType = true;
    return this;
  }

  person() {
    this.personType = true;
    return this;
  }

  personGroupDepartment() {
    this.personGroupDepartmentType = true;
    return this;
  }

  date() {
    this.dateType = true;
    return this;
  }

  dateTime() {
    this.dateTimeType = true;
    return this;
  }

  time() {
    this.timeType = true;
    return this;
  }
}
