import {
  AnyFlowId, ApplicationId, ApplicationIdentifier,
  Either, FlowId, mySetTimeoutNoAngular, None,
  Option, Right,
  ScreenIdentifier,
  ScreenInstanceId,
  Some,
  toastr,
  Typed,
  VariableId,
  WidgetInstanceId
} from "@utils";
import {
  AddRepeatableContextEntry,
  AppendToComponentModel,
  ApplicationResponse,
  ChangeComponentModel, ChangeComponentModels, ChangeModel,
  ChangeMultipleComponentModels,
  ChangeSimpleInputParameters,
  ClearComponentModel, CloseModalComponent,
  ComponentModelChange,
  DeleteRepeatableContextEntry,
  EvaluateExpressionInScreen,
  EvaluateExpressionInScreenFailureResponse,
  EvaluateExpressionInScreenResponse,
  EvaluateExpressionInScreenResponseFactory,
  EvaluateExpressionInScreenSuccessResponse,
  ExecuteAction,
  ExecuteEntryAction,
  GetScreenInstanceForWidgetScope, ModelToChange,
  MoveEntry,
  RefIdInContext,
  RemoveFromComponentModel,
  ScreenDebugInfo, ScreenDefinitionComponents,
  ScreenInstanceState, SubmitForm,
} from "..";
import {
  ApplicationComponentRef,
  CreateScreenInstance,
  CreateScreenInstanceForFlow,
  CreateScreenInstanceForFlowTask,
  CreateScreenInstanceForFlowTaskHistory,
  GetScreenInstanceState, ScreenComponentRefIdentifier,
  ScreenInstanceSharedWrapperService,
  ScreenInstanceSummary
} from "@shared";
import {Injectable} from "@angular/core";
import {AuthenticatedHttp, BusinessVariable, ProcessEdgeId, ProcessNodeId, TaskIdentifier} from "@shared-model";


@Injectable({
  providedIn: "root",
})
export class ScreenInstanceService {

  private instanceCache: Map<string, Option<ScreenInstanceState>> = new Map();
  private tasksToInstanceCache: Map<string, Promise<ScreenInstanceId>> = new Map();

  constructor(readonly authenticatedHttp: AuthenticatedHttp,
              readonly screenInstanceSharedService: ScreenInstanceSharedWrapperService) {
  }


  changeInputParameters(instanceId: ScreenInstanceId, params: Array<[string, string]>, onSuccess: () => void) {
    this.authenticatedHttp.post("screen/change-input-parameters", new ChangeSimpleInputParameters(instanceId, params), (data: ApplicationResponse) => {
      const copied = ApplicationResponse.copy(data);
      if (copied.isSuccess()) {
        onSuccess();
      } else {
        toastr.error(copied.status);
      }
    })
  }

  getForWidgetScope(widgetInstanceId: WidgetInstanceId, onSuccess: (instance: ScreenInstanceId) => void, onError: (message: string) => void) {
    this.authenticatedHttp.post("screen/get-for-widget-scope", new GetScreenInstanceForWidgetScope(widgetInstanceId), (data: Either<string, ScreenInstanceId>) => {
      const result = Either.copy(data, l => l, r => r);
      if (result.isRight()) {
        onSuccess(result.getRight());
      } else {
        onError(result.getLeft());
      }
    })
  }

  runAndPreload(application: Either<ApplicationId, ApplicationIdentifier>, screen: ApplicationComponentRef, params: Array<[string, string]>,
                onSuccess: (screenInstanceId: ScreenInstanceId, volatileScreen: boolean, multipleInstancesAllowed: boolean) => void, onError: (error: string) => void) {
    this.runAndGet(application, screen, params, (screenInstanceId, volatileScreen, multipleInstancesAllowed) => {
      this.getInstance(screenInstanceId, (instance) => {
        this.putInCache(screenInstanceId.id, instance);
        onSuccess(screenInstanceId, volatileScreen, multipleInstancesAllowed);
      });
    }, onError);
  }


  preloadInstance(id: ScreenInstanceId, onSuccess: () => void) {
    this.getInstance(id, (instance) => {
      this.putInCache(id.id, instance);
      onSuccess();
    });
  };

  getInstance(id: ScreenInstanceId, onSuccess: (instance: Option<ScreenInstanceState>) => void) {
    const fromCache = this.getFromCacheOnce(id.id);
    if (fromCache !== undefined) {
      onSuccess(Option.copy(fromCache).map(aw => ScreenInstanceState.copy(aw)))
    } else {
      this.authenticatedHttp.post("screen/get-instance", new GetScreenInstanceState(id), (data: Option<ScreenInstanceState>) => {
        onSuccess(Option.copy(data).map(aw => ScreenInstanceState.copy(aw)));
      })
    }
  };

  // getPromise(id: ScreenInstanceId): IPromise<Option<ScreenInstanceState>> {
  //   if (!id.id) {
  //     toastr.error("Application view instance id is undefined, getPromise");
  //   }
  //   return this.authenticatedHttp.getPromise("screen/get-instance/" + id.id)
  //     .then((response: IHttpPromiseCallbackArg<Option<ScreenInstanceState>>) => {
  //       return Option.copy(response.data).map(aw => ScreenInstanceState.copy(aw));
  //     })
  // }

  changeMultipleModels(instanceId: ScreenInstanceId,
                       refsPath: Array<RefIdInContext>,
                       modelsChanges: Array<ComponentModelChange>,
                       actionsTriggered: Array<string>,
                       onSuccess: () => void) {
    this.authenticatedHttp.post("screen/change-multiple-models", new ChangeMultipleComponentModels(instanceId, refsPath, modelsChanges, actionsTriggered), (data: ApplicationResponse) => {
      const copied = ApplicationResponse.copy(data);
      if (copied.isSuccess()) {
        onSuccess();
      } else {
        toastr.error(copied.status);
      }

    })
  }

  changeModel(instanceId: ScreenInstanceId,
              refsPath: Array<RefIdInContext>,
              modelName: string,
              value: BusinessVariable,
              actionsTriggered: Array<string>,
              onSuccess: () => void) {
    this.authenticatedHttp.post("screen/change-model", new ChangeComponentModel(instanceId, refsPath, modelName, Typed.of(value), actionsTriggered), (data: ApplicationResponse) => {
      const copied = ApplicationResponse.copy(data);
      if (copied.isSuccess()) {
        onSuccess();
      } else {
        toastr.error(copied.status);
      }

    })
  }


  changeModels(instanceId: ScreenInstanceId,
              refsPath: Array<RefIdInContext>,
              models: Array<ModelToChange>,
              actionsTriggered: Array<string>,
              onSuccess: () => void) {
    this.authenticatedHttp.post("screen/change-models", new ChangeComponentModels(instanceId, refsPath, models, actionsTriggered), (data: ApplicationResponse) => {
      const copied = ApplicationResponse.copy(data);
      if (copied.isSuccess()) {
        onSuccess();
      } else {
        toastr.error(copied.status);
      }

    })
  }

  closeModal(instanceId: ScreenInstanceId,
             refsPath: Array<RefIdInContext>,
             accept: boolean,
             onSuccess: () => void) {
    this.authenticatedHttp.post("screen/close-modal", new CloseModalComponent(instanceId, refsPath, accept), (data: ApplicationResponse) => {
      const copied = ApplicationResponse.copy(data);
      if (copied.isSuccess()) {
        onSuccess();
      } else {
        toastr.error(copied.status);
      }

    })
  }


  clearModel(instanceId: ScreenInstanceId,
             refsPath: Array<RefIdInContext>,
             modelName: string,
             actionsTriggered: Array<string>,
             onSuccess: () => void) {
    this.authenticatedHttp.post("screen/clear-model", new ClearComponentModel(instanceId, refsPath, modelName, actionsTriggered), (data: ApplicationResponse) => {
      const copied = ApplicationResponse.copy(data);
      if (copied.isSuccess()) {
        onSuccess();
      } else {
        toastr.error(copied.status);
      }

    })
  }

  appendToModel(instanceId: ScreenInstanceId,
                refsPath: Array<RefIdInContext>,
                modelName: string,
                expectedModelLength: Option<number>,
                value: BusinessVariable,
                onSuccess: () => void) {
    this.authenticatedHttp.post("screen/append-to-model", new AppendToComponentModel(instanceId, refsPath, modelName, expectedModelLength, Typed.of(value)), (data: ApplicationResponse) => {
      const copied = ApplicationResponse.copy(data);
      if (copied.isSuccess()) {
        onSuccess();
      } else {
        toastr.error(copied.status);
      }

    })
  }


  removeFromModel(instanceId: ScreenInstanceId,
                  refsPath: Array<RefIdInContext>,
                  modelName: string,
                  valueIndex: number,
                  value: BusinessVariable,
                  actionsTriggered: Array<string>,
                  onSuccess: () => void) {
    this.authenticatedHttp.post("screen/remove-from-model", new RemoveFromComponentModel(instanceId, refsPath, modelName, valueIndex, Typed.of(value), actionsTriggered), (data: ApplicationResponse) => {
      const copied = ApplicationResponse.copy(data);
      if (copied.isSuccess()) {
        onSuccess();
      } else {
        toastr.error(copied.status);
      }

    })
  }

  executeAction(instanceId: ScreenInstanceId,
                refsPath: Array<RefIdInContext>,
                actionName: string,
                optional: boolean,
                changeModelAfter: Option<ChangeModel>,
                onSuccess: () => void) {
    this.authenticatedHttp.post("screen/execute-action", new ExecuteAction(instanceId, refsPath, actionName, optional, changeModelAfter), (data: ApplicationResponse) => {
      const copied = ApplicationResponse.copy(data);
      if (copied.isSuccess()) {
        onSuccess();
      } else {
        toastr.error(copied.status);
      }

    })
  }

  executeEntryAction(instanceId: ScreenInstanceId,
                     refsPath: Array<RefIdInContext>,
                     entryContextId: VariableId,
                     actionName: string,
                     onSuccess: () => void) {
    this.authenticatedHttp.post("screen/execute-entry-action", new ExecuteEntryAction(instanceId, refsPath, entryContextId, actionName), (data: ApplicationResponse) => {
      const copied = ApplicationResponse.copy(data);
      if (copied.isSuccess()) {
        onSuccess();
      } else {
        toastr.error(copied.status);
      }

    })
  }

  addRepeatableContextEntry(instanceId: ScreenInstanceId, contextLength: number, refsPath: Array<RefIdInContext>, actionsTriggered: Array<string>, onSuccess: () => void) {
    this.authenticatedHttp.post("screen/add-entry", new AddRepeatableContextEntry(instanceId, refsPath, contextLength, actionsTriggered), (data: ApplicationResponse) => {
      const copied = ApplicationResponse.copy(data);
      if (copied.isSuccess()) {
        onSuccess();
      } else {
        toastr.error(copied.status);
      }

    })
  }

  deleteRepeatableContextEntry(instanceId: ScreenInstanceId, refsPath: Array<RefIdInContext>, entryId: VariableId, entryIndex: number, actionsTriggered: Array<string>, onSuccess: () => void) {
    this.authenticatedHttp.post("screen/delete-entry", new DeleteRepeatableContextEntry(instanceId, refsPath, entryId, entryIndex, actionsTriggered), (data: ApplicationResponse) => {
      const copied = ApplicationResponse.copy(data);
      if (copied.isSuccess()) {
        onSuccess();
      } else {
        toastr.error(copied.status);
      }

    })
  }

  moveEntry(instanceId: ScreenInstanceId, refsPath: Array<RefIdInContext>, entryId: VariableId, fromIndex: number, toIndex: number, actionsTriggered: Array<string>, onSuccess: () => void) {
    this.authenticatedHttp.post("screen/move-entry", new MoveEntry(instanceId, refsPath, entryId, fromIndex, toIndex, actionsTriggered), (data: ApplicationResponse) => {
      const copied = ApplicationResponse.copy(data);
      if (copied.isSuccess()) {
        onSuccess();
      } else {
        toastr.error(copied.status);
      }

    })
  }

  submit(instanceId: ScreenInstanceId, edgeId: ProcessEdgeId, onSuccess: () => void) {
    this.authenticatedHttp.post("screen/submit", new SubmitForm(edgeId, instanceId), (data: ApplicationResponse) => {
      const copied = ApplicationResponse.copy(data);
      if (copied.isSuccess()) {
        onSuccess();
      } else {
        if(copied.isValidationError()) {
          // ignore as it'll be handled by the screen
        } else {
          toastr.error(copied.status);
        }
      }
    })
  }


  getDebugInfoAvailable(screenInstanceId: ScreenInstanceId, onSuccess: (debugInfoAvailable: boolean) => void) {
    this.authenticatedHttp.get("screen/debug-info-available/" + screenInstanceId.id, (data: Option<boolean>) => {
      const result = Option.copy(data);
      if (result.isDefined()) {
        onSuccess(result.get());
      } else {
        toastr.error("No debug info available loaded");
      }
    })
  }

  getDebugInfo(screenInstanceId: ScreenInstanceId, onSuccess: (debugInfo: ScreenDebugInfo) => void) {
    this.authenticatedHttp.get("screen/debug-info/" + screenInstanceId.id, (data: Option<ScreenDebugInfo>) => {
      const result = Option.copy(data, ScreenDebugInfo.copy);
      if (result.isDefined()) {
        onSuccess(result.get());
      } else {
        toastr.error("No debug info loaded");
      }
    })
  }

  evaluateExpression(screenInstanceId: ScreenInstanceId, expression: string, contextPath: string,
                     onSuccess: (result: BusinessVariable, durationMillis: number) => void,
                     onFailure: (error: string, durationMillis: number) => void) {
    this.authenticatedHttp.post("screen/evaluate-expression", new EvaluateExpressionInScreen(screenInstanceId, expression, contextPath), (data: Typed<EvaluateExpressionInScreenResponse>) => {
      const responseUnwrapped: EvaluateExpressionInScreenResponse = Typed.value(EvaluateExpressionInScreenResponseFactory.copyTyped(data));
      if (responseUnwrapped.isSuccess()) {
        onSuccess(Typed.value((<EvaluateExpressionInScreenSuccessResponse>responseUnwrapped).resultVariable), (<EvaluateExpressionInScreenSuccessResponse>responseUnwrapped).durationMillis);
      } else {
        onFailure((<EvaluateExpressionInScreenFailureResponse>responseUnwrapped).error, (<EvaluateExpressionInScreenFailureResponse>responseUnwrapped).durationMillis);
      }
    })
  }

  loadActiveInstances(onSuccess: (instance: Array<ScreenInstanceSummary>) => void) {
    this.authenticatedHttp.get("screen/active-instances", (data: Array<ScreenInstanceSummary>) => {
      onSuccess(data.map(ScreenInstanceSummary.copy));
    })
  }

  runForFlowTaskHistory(flowId: AnyFlowId, nodeId: ProcessNodeId, historyIndex: number, screen: Option<ScreenIdentifier>): Promise<ScreenInstanceId> {
    return new Promise<ScreenInstanceId>((resolve, reject) => {
      this.authenticatedHttp.post("screen/run-for-flow-task-history-and-get",
        new CreateScreenInstanceForFlowTaskHistory(Typed.of(flowId), nodeId, historyIndex, screen), (data: Either<string, ScreenInstanceState>) => {
          this.handleInstanceResponseWithComponents(Either.copy(data, s => s, ScreenInstanceState.copy), (error => {
            toastr.error(error);
          }), (screenInstanceId, volatileScreen, multipleInstancesAllowed) => {
            resolve(screenInstanceId);
          });
        })
    });
  }

  runForFlowTask(taskId: TaskIdentifier, preload: boolean = false): Promise<ScreenInstanceId> {

    const idSerialized = taskId.serialize();
    const fromCache = this.tasksToInstanceCache.get(idSerialized);
    this.tasksToInstanceCache.delete(idSerialized);

    if(fromCache && !preload) {
      return fromCache;
    } else {
      const promise = new Promise<ScreenInstanceId>((resolve, reject) => {
        this.authenticatedHttp.post("screen/run-for-flow-task-and-get", new CreateScreenInstanceForFlowTask(taskId), (data: Either<string, ScreenInstanceState>) => {
          this.handleInstanceResponseWithComponents(Either.copy(data, s => s, ScreenInstanceState.copy), (error => {
            toastr.error(error);
          }), (screenInstanceId, volatileScreen, multipleInstancesAllowed) => {
            resolve(screenInstanceId);
          });
        })
      });
      if(preload) {
        this.tasksToInstanceCache.set(idSerialized, promise);
      }
      return promise;
    }
  }

  runForFlow(flowId: FlowId, rootComponent: ScreenComponentRefIdentifier): Promise<ScreenInstanceId> {
    return new Promise<ScreenInstanceId>((resolve, reject) => {
      this.authenticatedHttp.post("screen/run-for-flow-and-get", new CreateScreenInstanceForFlow(flowId, rootComponent), (data: Either<string, ScreenInstanceState>) => {
        this.handleInstanceResponseWithComponents(Either.copy(data, s => s, ScreenInstanceState.copy), (error => {
          toastr.error(error);
        }), (screenInstanceId, volatileScreen, multipleInstancesAllowed) => {
          resolve(screenInstanceId);
        });
      })
    });
  }

  runAndGet(application: Either<ApplicationId, ApplicationIdentifier>, screen: ApplicationComponentRef, params: Array<[string, string]>, onSuccess: (screenInstanceId: ScreenInstanceId, volatileScreen: boolean, multipleInstancesAllowed: boolean) => void, onError: (error: string) => void) {
    this.authenticatedHttp.post("screen/run-and-get", new CreateScreenInstance(application, screen, params), (data: Either<string, ScreenInstanceState>) => {
      this.handleInstanceResponseWithComponents(Either.copy(data, s => s, ScreenInstanceState.copy), onError, (screenInstanceId, volatileScreen, multipleInstancesAllowed) => {
        onSuccess(screenInstanceId, volatileScreen, multipleInstancesAllowed);
      });
    })
  }

  runExternal(applicationIdentifier: string, screenIdentifier: string, params: Array<[string, string]>,
              onSuccess: (screenInstanceId: ScreenInstanceId, volatileScreen: boolean) => void, onError: (error: string) => void) {
    this.authenticatedHttp.post("screen/run-external-anonymously-and-get", new CreateScreenInstance(Right(applicationIdentifier), ApplicationComponentRef.ofIdentifier(screenIdentifier), params), (data: Either<string, ScreenInstanceState>) => {
      const result = Either.copy(Either.copy(data, s => s, ScreenInstanceState.copy), l => l, ScreenInstanceState.copy);
      if (result.isRight()) {
        this.handleInstanceResponse(result, applicationIdentifier, screenIdentifier, onError, onSuccess);
      } else {
        onError(result.getLeft());
      }
    }, (status) => {
      onError("Unable to initialize Screen: " + status);
    })
  }

  private getComponents(applicationIdentifier: string, screenIdentifier: string, storedScreenReleasesIds: string, workingCopy: boolean, onError: (message: string) => void): Promise<ScreenDefinitionComponents> {
    // Components definition is quite large, so we want browser to cache it, thous separate GET request
    return new Promise<ScreenDefinitionComponents>((resolve, reject) => {

      const params = workingCopy ? ("no-cache=" + Date.now()) : storedScreenReleasesIds;

      this.authenticatedHttp.get("screen/external-get-screen-components/" + applicationIdentifier + "/" + screenIdentifier + "?" + params, (data: Either<string, ScreenDefinitionComponents>) => {
        const result = Either.copy(data, l => l, r => ScreenDefinitionComponents.copy(r));
        if (result.isRight()) {
          resolve(result.getRight());
        } else {
          onError(result.getLeft());
        }
      });
    });
  }

  private handleInstanceResponse(result: Either<string, ScreenInstanceState>, applicationIdentifier: string, screenIdentifier: string, onError: (error: string) => void, onSuccess: (screenInstanceId: ScreenInstanceId, volatileScreen: boolean) => void) {

    let instance: ScreenInstanceState = result.getRight();

    let storedScreenReleasesIds = localStorage.getItem("screenReleases|" + applicationIdentifier + "|" + screenIdentifier);
    let components: Promise<ScreenDefinitionComponents> | null =
      storedScreenReleasesIds === null || instance.workingCopy ? null : this.getComponents(applicationIdentifier, screenIdentifier, storedScreenReleasesIds, instance.workingCopy, onError);



    let allReleases = instance.allScreensReleases.sort().join(",");
    if (components === null || storedScreenReleasesIds !== allReleases || instance.workingCopy) {
      components = this.getComponents(applicationIdentifier, screenIdentifier, allReleases, instance.workingCopy, onError);
      localStorage.setItem("screenReleases|" + applicationIdentifier + "|" + screenIdentifier, allReleases);
    }

    if (components === null) {
      throw new Error("Should not happen");
    } else {
      components.then(data => {
        instance.components = Some(data.components);

        this.instanceCache.set(instance.id.id, Some(instance));

        mySetTimeoutNoAngular(() => {
          this.instanceCache.delete(instance.id.id);
        }, 10000); // just to not keep it unnecessary

        onSuccess(instance.id, (instance.lifeMode.isVolatile()));
      });
    }
  }

  private handleInstanceResponseWithComponents(result: Either<string, ScreenInstanceState>,onError: (error: string) => void, onSuccess: (screenInstanceId: ScreenInstanceId, volatileScreen: boolean, multipleInstancesAllowed: boolean) => void) {
    if(result.isRight()) {
      let instance: ScreenInstanceState = result.getRight();
      this.instanceCache.set(instance.id.id, Some(instance));

      mySetTimeoutNoAngular(() => {
        this.instanceCache.delete(instance.id.id);
      }, 10000); // just to not keep it unnecessary

      onSuccess(instance.id, instance.lifeMode.isVolatile(), instance.lifeMode.isMultipleInstances());
    } else {
      onError(result.getLeft());
    }
  }

  private putInCache(id: string, instance: Option<ScreenInstanceState>) {
    this.instanceCache.set(id, instance);
  }

  private getFromCacheOnce(id: string) {
    let result = this.instanceCache.get(id);
    this.instanceCache.delete(id);
    return result;
  }


}
