import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {catchError, map} from "rxjs/operators";
import {Observable, of} from "rxjs";
import {clearArray} from "@utils";
import {I18nService, Translation} from "../..";
import {environment} from "@environments";
import {Chart, registerables} from "chart.js";

class LibraryInfo {
  loading = false;
  loaded = false;
  callbacks: Array<() => void> = [];

  constructor(readonly scripts: Array<string>,
              readonly modules: Array<string>, // for mjs modules
              readonly styles: Array<string>,
              readonly translations: Array<Translation>,
              readonly init: (onSuccess: () => void) => void = (inititated: () => void) => inititated()) {}
}

@Injectable({
  providedIn: 'root',
})
export class LibrariesService {

  private googleMapsObservable: Observable<boolean>|undefined;
  private googleMapsLoaded = false;
  private googleMapsCallbacks: Array<() => void> = [];


  private chartjs = new LibraryInfo(["libs/chart.js/dist/chart.umd.js", "libs/chartjs-plugin-datalabels/dist/chartjs-plugin-datalabels.min.js"], [], [], []);
  private papaParse = new LibraryInfo(["libs/papaparse/papaparse.min.js"], [], [], []);
  private pdfJs = new LibraryInfo([], [
    "libs/pdfjs-dist/build/pdf.min.mjs",
    "libs/pdfjs-dist/build/pdf.worker.min.mjs",
    "libs/pdfjs-dist/web/pdf_viewer.mjs"],
    [
    "libs/pdfjs-dist/web/pdf_viewer.css",
    ], []);
  private fineUploader = new LibraryInfo(["libs/fine-uploader/fine-uploader.min.js"], [], [], [Translation.fileUploader]);
  private showdown = new LibraryInfo(["libs/showdown/showdown.min.js"], [], [], []);
  private tinyMCE = new LibraryInfo(
    [
      "libs/tinymce/tinymce.min.js",
      "libs/tinymce/themes/silver/theme.min.js",
      "libs/tinymce/plugins/lists/plugin.min.js",
      "libs/tinymce/plugins/advlist/plugin.min.js",
      "libs/tinymce/plugins/table/plugin.min.js",
      "libs/tinymce/plugins/autoresize/plugin.min.js",
      "libs/tinymce/plugins/codesample/plugin.min.js",
      // "libs/tinymce/plugins/hr/plugin.min.js",
      "libs/tinymce/plugins/image/plugin.min.js",
      // "libs/tinymce/plugins/imagetools/plugin.min.js",
      "libs/tinymce/plugins/link/plugin.min.js",
      // "libs/tinymce/plugins/paste/plugin.min.js",
      // "libs/tinymce/plugins/print/plugin.min.js",
      "libs/tinymce/plugins/searchreplace/plugin.min.js",
      "libs/tinymce/plugins/table/plugin.min.js",
      // "libs/tinymce/plugins/toc/plugin.min.js",
      "libs/tinymce/plugins/media/plugin.min.js",
    ], [], [], []);
  private esprima = new LibraryInfo(["libs/esprima/esprima.js"], [], [], []);
  private diff = new LibraryInfo(["libs/diff/diff.min.js"], [], [], []);
  private vanillaPicker = new LibraryInfo(["libs/vanilla-picker/vanilla-picker.csp.min.js"], [], ["libs/vanilla-picker/vanilla-picker.csp.css"], []);

  private monacoEditor = new LibraryInfo(["libs/monaco-editor/min/vs/loader.js"], [], [], [], (initiated) => {
    (<any>window).require.config({paths: {vs: 'libs/monaco-editor/min/vs'}});
    (<any>window).require(['vs/editor/editor.main'], () => {
      initiated();
    });
  });

  private chatbot = new LibraryInfo(["https://ai.neula.in/chatbot-master/index.js"], [], [], []);

  constructor(private readonly httpClient: HttpClient,
              private readonly i18nService: I18nService) {}



  loadEsprima(onSuccess: () => void): void {
    this.loadLibraryViaTags(this.esprima, onSuccess);
  }

  loadDiff(onSuccess: () => void): void {
    this.loadLibraryViaTags(this.diff, onSuccess);
  }

  loadVanillaPicker(onSuccess: () => void): void {
    this.loadLibraryViaTags(this.vanillaPicker, onSuccess);
  }

  loadMonacoEditor(onSuccess: () => void): void {
    this.loadLibraryViaTags(this.monacoEditor, onSuccess);
  }

  loadChartJs(onSuccess: () => void): void {
    this.loadLibraryViaTags(this.chartjs, onSuccess, () => {
      Chart.register(...registerables);
    });
  }

  loadPapaParse(onSuccess: () => void): void {
    this.loadLibraryViaTags(this.papaParse, onSuccess);
  }

  loadPdfJs(onSuccess: () => void): void {
    this.loadLibraryViaTags(this.pdfJs, onSuccess);
  }

  loadFineUploader(onSuccess: () => void): void {
    this.loadLibraryViaTags(this.fineUploader, onSuccess);
  }

  loadShowdown(onSuccess: () => void): void {
    this.loadLibraryViaTags(this.showdown, onSuccess);
  }

  loadChatbot(onSuccess: () => void): void {
    this.loadLibraryViaTags(this.chatbot, onSuccess);
  }


  loadTinymce(onSuccess: () => void) {
    this.loadLibraryViaTags(this.tinyMCE, onSuccess);
  }

  loadGoogleMaps(apiKey: string, onSuccess: () => void): Observable<boolean> {

    if(this.googleMapsLoaded) {
      if(this.googleMapsObservable !== undefined) {
        onSuccess();
      } else {
        throw new Error("No observable");
      }
    } else {
      this.googleMapsCallbacks.push(onSuccess);
      if(this.googleMapsObservable === undefined) {
        this.googleMapsObservable = this.loadLibraryViaHttpClient("https://maps.googleapis.com/maps/api/js?key=" + apiKey, this.onGoogleMapsLoaded);
      }
    }
    return this.googleMapsObservable;

  }

  private onGoogleMapsLoaded = () => {
    this.googleMapsLoaded = true;
    const callbacks = this.googleMapsCallbacks.slice();
    clearArray(this.googleMapsCallbacks);
    callbacks.forEach(callback => callback());
  };

  private loadLibraryViaTags(library: LibraryInfo, onSuccess: () => void, onFirstSuccess: () => void = () => {}): void {

    if(library.loaded) {
      onSuccess();
    } else {
      library.callbacks.push(onSuccess);

      if (!library.loading) {
        library.loading = true;

        let translationLoaded = false;
        let libraryLoaded = false;

        if(library.translations.length > 0) {
          this.i18nService.initialize(library.translations).finally(() => {
            if (libraryLoaded) {
              library.init(() => {
                onFirstSuccess();
                this.onLibraryLoaded(library);
              });
            } else {
              translationLoaded = true;
            }
          });
        } else {
          translationLoaded = true;
        }

        this.createLibraryTags(library.scripts, library.modules, library.styles,() => {
          if(translationLoaded) {
            library.init(() => {
              onFirstSuccess();
              this.onLibraryLoaded(library);
            });
          } else {
            libraryLoaded = true;
          }
        });


      }
    }
  }


  private onLibraryLoaded = (library: LibraryInfo) => {
    library.loaded = true;
    const callbacks = library.callbacks.slice();
    clearArray(library.callbacks);
    callbacks.forEach(callback => callback());
  };

  private loadLibraryViaHttpClient(url: string, onSuccess: () => void): Observable<boolean> {
    return this.httpClient.jsonp(url, "callback").pipe(
        map(() => {
          onSuccess();
          return true;
        }),
        catchError((err, caught) => {
          return of(false)
        }),
      );
  }


  private createLibraryTags(scripts: Array<string>, modules: Array<string>, styles: Array<string>, onSuccess: () => void): void {

    let loaded = 0;
    let toLoad = scripts.length + modules.length + styles.length;

    scripts.forEach(url => {
      this.addScript(url, false, () => {
        loaded++;
        if(loaded === toLoad) {
          onSuccess();
        }
      });

    });

    modules.forEach(url => {
      this.addScript(url, true, () => {
        loaded++;
        if(loaded === toLoad) {
          onSuccess();
        }
      });
    });


    styles.forEach(url => {
      const style = document.createElement("link");
      style.href = url;
      style.rel = "stylesheet";
      document.head.appendChild(style);

      style.addEventListener("load", () => {
        loaded++;
        if(loaded === toLoad) {
          onSuccess();
        }
      });

    })

  }

  private addScript(url: string, module: boolean, onSuccess: () => void) {
    const script = document.createElement("script");
    script.src = url + "?no-cache=" + environment.buildStamp;
    script.async = false;
    if(module) {
      script.type = "module";
    } else {
      script.type = "text/javascript";
    }
    document.head.appendChild(script);

    script.addEventListener("load", onSuccess);
  }

  ensureEsprimaLoaded() {
    if(!this.esprima.loaded) {
      throw new Error("Esprima not loaded");
    }
  }
}
