import {Component, Input, OnChanges, SimpleChanges, ViewContainerRef} from "@angular/core";
import {BusinessVariable} from "@shared-model";
import {VariableTextPreviewService} from "./variable-text-preview.service";

@Component({
  selector: 'my-variable-text-preview',
  templateUrl: './variable-text-preview.component.html'
})
export class VariableTextPreviewComponent implements OnChanges {

  @Input() model!: BusinessVariable;
  text: string = "";

  @Input() adHocTaskSystemLabel: boolean = false;


  constructor(readonly variableTextPreviewService: VariableTextPreviewService,
              private readonly viewContainerRef: ViewContainerRef) {}


  ngOnChanges(changes: SimpleChanges): void {
    this.updateText();
  }

  updateText() {
    this.variableTextPreviewService.getText(this.model, this.adHocTaskSystemLabel).then(text => {
      this.text = text;
    });
  }

}
