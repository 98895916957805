export class DeferredValue<T> {

  private requested: boolean = false;

  private constructor(private readonly onRequested: ((resolve: (value: T) => void) => void) | null,
                      private value: T | null,
                      private resolved: boolean,
                      private readyCallbacks: Array<(value: T) => void> = []) {
  }

  private requestValue() {
    if (this.onRequested != null && !this.requested && !this.resolved) {
      this.onRequested((value: T) => this.resolve(value));
      this.requested = true;
    }
  }

  onReady(onReadyCallback: (value: T) => void) {
    this.requestValue();
    if (this.resolved) {
      if (this.value !== null) {
        onReadyCallback(this.value);
      } else {
        throw new Error("Resolved value is still null");
      }
    } else {
      this.readyCallbacks.push(onReadyCallback);
    }
  }

  getValue(): T {
    if (this.resolved) {
      if (this.value !== null) {
        return this.value;
      } else {
        throw new Error("Resolved value is still null");
      }
    } else {
      throw new Error("Promise not yet resolved");
    }
  }

  isResolved() {
    return this.resolved;
  }

  resolve(value: T) {
    if (this.resolved) {
      throw new Error("Cannot resolve resolved");
    } else {
      this.value = value;
      this.resolved = true;
      this.readyCallbacks.forEach(c => c(value));
      this.readyCallbacks = [];
    }
  }

  static ofPromise<T>(promise: Promise<T>|T): DeferredValue<T> {
    if (promise instanceof Promise) {
      return DeferredValue.create<T>(resolve => {
        promise.then(value => {
          resolve(value);
        });
      });
    } else {
      return DeferredValue.resolved(promise);
    }
  }

  static createNoOnRequest<T>() {
    return new DeferredValue<T>(null, null, false, []);
  }

  static create<T>(onRequested: (resolve: (value: T) => void) => void) {
    return new DeferredValue<T>(onRequested, null, false, []);
  }

  static resolved<T>(value: T) {
    return new DeferredValue<T>(null, value, true, []);
  }

  static onAllReady(deferred: Array<DeferredValue<any>>, onReady: () => void) {
    const notResolved = deferred.filter(d => !d.isResolved());
    let count = notResolved.length;
    if(count > 0) {
      notResolved.forEach(d => {
        d.onReady((value) => {
          count--;
          if (count == 0) {
            onReady();
          }
        })
      })
    } else {
      onReady();
    }
  }

  map<NEW_T>(transform: (v: T) => NEW_T): DeferredValue<NEW_T> {

    if (this.resolved) {
      if (this.value !== null) {
        return DeferredValue.resolved<NEW_T>(transform(this.value));
      } else {
        throw new Error("Resolved value is still null");
      }
    } else {
      const deferred = DeferredValue.create<NEW_T>(resolve => {
        this.requestValue();
      });
      this.onReady(value => {
        deferred.resolve(transform(value));
      });
      return deferred;

    }
  }

  static flatten<T>(deferred: Array<DeferredValue<T>>): DeferredValue<Array<T>> {

    const newDeferred = DeferredValue.createNoOnRequest<Array<T>>();

    DeferredValue.onAllReady(deferred, () => {
      newDeferred.resolve(deferred.map(d => d.getValue()));
    });

    return newDeferred;
  }
}
