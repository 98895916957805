import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewContainerRef
} from "@angular/core";
import {
  $$,
  getElementPositionAndSize,
  getREMSize,
  getElementSize,
  getVisibleBox,
  isRTL,
  myRequestAnimationFrameNoAngular, mySetIntervalNoAngular,
  onFormFocusLost,
  PointerXY,
  RectXY,
  required, $$Element, Nothing,
} from "@utils";
import {Observable, Subject, Subscription} from "rxjs";
import {NavigationService} from "../../services/navigation/navigation.service";
import {AppSettingsController} from "../../services/app-settings/app-settings-controller";
import {deprecated} from "pdfjs-dist/types/src/display/display_utils";

interface DropDownPosition {
  start: number,
  top: number,
  end: number|null,
  minWidth: number|null,
  maxHeight: number|null,
}

export type DropMenuAnchor = $$Element|HTMLElement|MouseEvent|PointerXY;


export class DropMenuState<T> {
  visible: boolean = false;
  anchor?: DropMenuAnchor;
  model?: T;
  changeNotifierSubject = new Subject<void>;
  changeNotifier: Observable<void> = this.changeNotifierSubject.asObservable();

  private onShowCallbacks: Array<(model: T) => void> = [];
  private onCloseCallbacks: Array<() => void> = [];

  constructor() {}

  onShow(callback: (model: T) => void) {
    this.onShowCallbacks.push(callback);
    return this;
  }

  onClose(callback: () => void) {
    this.onCloseCallbacks.push(callback);
    return this;
  }

  show(anchorOrEvent: DropMenuAnchor, model: T): void {
    this.visible = true;
    this.anchor = anchorOrEvent;
    this.model = model;
    this.onShowCallbacks.forEach(c => c(model));
  }

  close(): void {
    this.visible = false;
    this.anchor = undefined;
    this.model = undefined;
    this.onCloseCallbacks.forEach(c => c());
  }
}


@Component({
  selector: 'my-drop-menu[anchor],my-drop-menu[pointAnchor],my-drop-menu[model]',
  templateUrl: './drop-menu.component.html',
  styleUrls: ['./drop-menu.component.shadow.scss']
})
// Directive annotation is here so compiler would not complain, but it should have no side effects
export class DropMenuComponent implements OnInit, OnDestroy, OnChanges {

  popupVisible: boolean = false;
  above: boolean = false;

  @Input() backgroundVisible: boolean = false;
  @Input() backgroundStyle: "transparent"|"light"|"dark" = "light";
  @Input() blurBackground: boolean = false;
  @Input() position: "bottom"|"top"|"start"|"end"|"inPlace" = "bottom";
  @Input() verticalPosition: "top"|"bottom"|"middle" = "bottom";
  @Input() horizontalPosition: "start"|"end" = "start";
  @Input() maxSpace: boolean = false;
  @Input() closeOnBlur: boolean = true;

  @Input() anchorDistance: number|"default"|"none" = "default";

  @Input({required:true}) cssClass: string|undefined;
  @Input() anchor: DropMenuAnchor|undefined;
  /**
   * @deprecated Use anchor only
   */
  @Input() pointAnchor: PointerXY|undefined;

  @ViewChild("popupComponent") popupComponent: ElementRef<HTMLElement>|undefined;

  @Output() opened = new EventEmitter<HTMLElement>();
  @Output() closed = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();

  /** Used to delay showing of drop down menu until content is ready to render*/
  @Input() contentAvailableNotifier: Observable<boolean>|undefined;
  /** Used to adjust size and position of drop down menu after if content changed */
  @Input() contentChangeNotifier: Observable<void>|undefined;

  @Input() asModalOnMobile: boolean|"top" = false;

  @Input() reverseAboveOrder: boolean = false;

  contentAvailable: boolean = false;

  private fixInterval: number = 0;

  private lastPosition: DropDownPosition|undefined = undefined;

  private formFocusLostCancel: (() => void)|null = null;
  private initialized = false;
  private contentAvailableSubscription?: Subscription;
  private contentChangeSubscription?: Subscription;
  private layer: HTMLElement|undefined;
  private destroying: boolean = false;
  private mobileModal: boolean = false;
  private navigationStateId?: string;

  private themeChanged = false;

  constructor(private readonly viewContainerRef: ViewContainerRef,
              private readonly navigationService: NavigationService,
              private readonly appSettingsController: AppSettingsController) {}

  ngOnChanges(changes: SimpleChanges) {
    if(this.initialized) {
      if (this.visible && this.contentAvailable) {
        if(this.anchor && this.pointAnchor) {
          throw new Error("Use only single anchor, not both");
        }
        this.showPopup();
      } else  {
        this.hidePopupIfVisible(false);
      }
    }
  }

  @Input({required:true}) visible: boolean = false;

  @Output() visibleChange = new EventEmitter<boolean>();

  private showPopup(): void {

    this.popupVisible = true;
    this.lastPosition = undefined;

    if(this.popupComponent === undefined) {
      throw new Error("No popupComponent");
    } else if(this.anchor === undefined && this.pointAnchor === undefined) {
      throw new Error("No anchor, nor pointAnchor");
    } else {


      this.navigationStateId = this.navigationService.pushTemporaryState(() => {
        this.hidePopupIfVisible(true);
      });

      this.mobileModal = this.asModalOnMobile !== false && $$(document.body).width() / getREMSize() < 40;


      if(!this.layer) {
        this.layer = document.createElement("div");
      } else {
        this.layer.innerHTML = "";
      }

      // This will be used by orm focus lost to handle nested popup elements
      $$(this.layer).prop("remoteParentElement", this.viewContainerRef.element.nativeElement);

      this.layer.classList.add("drop-menu-overlay");
      document.body.appendChild(this.layer);

      $$(this.popupComponent).classed("mobileModal", this.mobileModal && this.asModalOnMobile === true);
      $$(this.popupComponent).classed("mobileModalTop", this.mobileModal && this.asModalOnMobile === "top");
      this.layer.classList
        .toggle("blockingVisible", this.backgroundVisible || this.mobileModal);
      this.layer.classList
        .toggle("transparent", this.backgroundVisible && this.backgroundStyle === "transparent" && !this.mobileModal);
      this.layer.classList
        .toggle("light", this.backgroundVisible && this.backgroundStyle === "light" && !this.mobileModal);
      this.layer.classList
        .toggle("dark", this.backgroundVisible && this.backgroundStyle === "dark" || this.mobileModal);
      this.layer.classList
        .toggle("blur", this.backgroundVisible && this.blurBackground);
      this.layer.classList
        .toggle("nonBlocking", !this.backgroundVisible && !this.mobileModal);

      this.layer.appendChild(this.popupComponent.nativeElement);
      this.opened.emit(this.popupComponent.nativeElement);

      this.layer.onclick = (event) => {
        if(event.target === this.layer && this.closeOnBlur && this.backgroundVisible) {
          this.hidePopupIfVisible(true);
        }
      };

      // position now
      myRequestAnimationFrameNoAngular(() => {
        this.fixPosition();
        myRequestAnimationFrameNoAngular(() => {
          this.fixPosition();
        });
      });
      // position when scroll happen
      document.addEventListener('scroll', this.onScroll, true);
      // position every 0.5 second
      if(this.fixInterval) {
        clearInterval(this.fixInterval);
      }
      this.fixInterval = mySetIntervalNoAngular(() => this.fixPosition(), 500);


      if(this.mobileModal) {
        $$(this.layer).on("mousedown", (event) => {
          if(event.target === this.layer) {
            event.preventDefault();
            event.stopImmediatePropagation();
            this.hidePopupIfVisible(true);
          }
        });
      } else {

        if (this.closeOnBlur && !this.backgroundVisible) {
          if (this.anchor instanceof HTMLElement || this.anchor instanceof $$Element) {
            this.formFocusLostCancel = onFormFocusLost([this.anchor, this.popupComponent.nativeElement], () => this.hidePopupIfVisible(true));
          } else {
            this.formFocusLostCancel = onFormFocusLost(this.popupComponent.nativeElement, () => this.hidePopupIfVisible(true));
          }
        }
      }

      $$(window.document.body).on("keydown", this.keyDownHandler);


      if(this.mobileModal || this.backgroundVisible && this.backgroundStyle === "dark") {
        this.appSettingsController.changeBrowserThemeToDarkBackground();
        this.themeChanged = true;
      } else if(this.backgroundVisible && this.backgroundStyle === "light") {
        this.appSettingsController.changeBrowserThemeToLightBackground();
        this.themeChanged = true;
      }

    }
  }

  keyDownHandler = (event: KeyboardEvent) => {
    if(event.key === "Escape") {
      this.hidePopupIfVisible(true);
    }
  }

  ngOnInit() {

    this.contentAvailable = this.contentAvailableNotifier === undefined;

    if(this.contentAvailableNotifier) {
      this.contentAvailableSubscription = this.contentAvailableNotifier.subscribe((available) => {
        this.contentAvailable = available;
        if(this.visible && this.contentAvailable) {
          this.showPopup();
        }
      })
    }


    this.initialized = true;
    if (this.visible && this.contentAvailable) {
      requestAnimationFrame(() => {
        this.showPopup();
      });
    }

    if(this.contentChangeNotifier) {
      this.contentChangeSubscription = this.contentChangeNotifier.subscribe(() => {
        if(this.popupVisible) {
          this.fixPosition();
          myRequestAnimationFrameNoAngular(() => this.fixPosition());
        }
      })
    }
  }

  private onScroll = () => {
    this.fixPosition();
  }

  hidePopupIfVisible(canceled: boolean): void {

    if (this.popupVisible) {

      if(this.navigationStateId) {
        this.navigationService.removeTemporaryState(this.navigationStateId);
        this.navigationStateId = undefined;
      }

      this.popupVisible = false;

      if (!this.destroying) {
        if (this.popupComponent === undefined) {
          throw new Error("No popupComponent");
        } else {
          if (this.layer === undefined) {
            // do nothing
          } else {
            document.body.removeChild(this.layer);
            this.layer = undefined;
          }
        }
      }

      clearInterval(this.fixInterval);
      document.removeEventListener('scroll', this.onScroll, true);

      this.visibleChange.emit(false);
      this.closed.emit();
      if (canceled) {
        this.cancel.emit();
      }

      if (this.formFocusLostCancel !== null) {
        this.formFocusLostCancel();
      }

      $$(window.document.body).off("keydown", this.keyDownHandler);

      if(this.themeChanged) {
        this.appSettingsController.returnToPreviousTheme();
        this.themeChanged = false;
      }
    }
  }



  private fixPosition = () => {

    const end = isRTL() ? this.horizontalPosition === "start" : this.horizontalPosition === "end";

    // console.log("fixPosition " + (new Date().getTime() % 1000000));

    if(this.popupComponent === undefined) {
      throw new Error("No popupComponent")
    } else if (this.anchor === undefined && this.pointAnchor === undefined) {
      throw new Error("No anchor, nor pointAnchor");
    } else {

      const containerWidth = getElementSize($$(required(this.layer, "layer"))).width;

      const popupElement = this.popupComponent.nativeElement;
      let anchorPosition!: RectXY;
      if(this.anchor instanceof HTMLElement || this.anchor instanceof $$Element) {
        anchorPosition = getElementPositionAndSize(this.anchor);
      } else if(this.anchor instanceof MouseEvent) {
        anchorPosition = new RectXY(this.anchor.clientX, this.anchor.clientY, 0, 0);
      } else if(this.anchor) {
        anchorPosition = new RectXY(this.anchor.x, this.anchor.y, 0, 0);
      } else if(this.pointAnchor) {
        anchorPosition = new RectXY(this.pointAnchor.x, this.pointAnchor.y, 0, 0);
      } else {
        throw new Error("No anchor, nor pointAnchor");
      }

      const anchorPositionStartX = end ? containerWidth - anchorPosition.endX : anchorPosition.x;
      const anchorPositionEndX = end ? containerWidth - anchorPosition.x : anchorPosition.endX;

      const elementSize = getElementSize(popupElement);

      const visibleBox = getVisibleBox();

      const visibleBoxStart = end ? containerWidth - visibleBox.right : visibleBox.left;
      const visibleBoxEnd = end ? containerWidth - visibleBox.left : visibleBox.right;


      const remSize = getREMSize();

      let distance = 0;

      switch (this.anchorDistance) {
        case "none": distance = 0; break;
        case "default": distance = 0.3125 * remSize; break;
        default: distance = this.anchorDistance * remSize;
      }

      const visibleBoxHeight = visibleBox.bottom - visibleBox.top;
      const visibleBoxWidth = visibleBoxEnd - visibleBoxStart;

      const tooLittleSpaceBottom = anchorPosition.endY + distance + elementSize.height > visibleBox.bottom;
      const tooLittleSpaceEnd = anchorPositionStartX + elementSize.width > visibleBoxEnd;
      const tooLittleSpaceTop = anchorPosition.y - distance - elementSize.height < visibleBox.top;
      const tooLittleSpaceStart = anchorPositionStartX - elementSize.width < visibleBoxStart;

      const spaceAbove = anchorPosition.y - distance - visibleBox.top;
      const spaceBelow = visibleBoxHeight + visibleBox.top - anchorPosition.endY - distance;
      const spaceStart = anchorPositionStartX - visibleBoxStart;
      const spaceEnd = visibleBoxWidth + visibleBoxStart - anchorPositionEndX;

      let newPosition: DropDownPosition|undefined = undefined;

      let above = false;

      if (this.mobileModal) {
        newPosition = {
          start: 0,
          top: 0,
          end: null,
          minWidth: 0,
          maxHeight: 0
        };
      } else if (this.position === "bottom") {
        above = tooLittleSpaceBottom && spaceAbove > spaceBelow;
        const onEnd = tooLittleSpaceEnd && spaceStart > spaceEnd;

        const newTop = above
          ? (anchorPosition.y - distance - elementSize.height)
          : anchorPosition.endY + distance;

        const newStart = onEnd
          ? (visibleBoxEnd - elementSize.width - Math.floor(remSize))
          : anchorPositionStartX;

        const newMaxHeight = above
          ? spaceAbove - Math.floor(remSize)
          : spaceBelow - Math.floor(remSize);

        newPosition = {
          start: newStart,
          top: newTop,
          end: null,
          minWidth: anchorPosition.width,
          maxHeight: newMaxHeight
        };

      } else if (this.position === "top") {
        const below = tooLittleSpaceTop && spaceAbove < spaceBelow;
        above = !below;
        const onEnd = tooLittleSpaceEnd && spaceStart > spaceEnd;

        const newTop = above
          ? (anchorPosition.y - distance - elementSize.height)
          : anchorPosition.endY + distance;

        const newStart = onEnd
          ? (visibleBoxEnd - elementSize.width - Math.floor(remSize))
          : anchorPositionStartX;

        const newMaxHeight = above
          ? spaceAbove - Math.floor(remSize)
          : spaceBelow - Math.floor(remSize);

        newPosition = {
          start: newStart,
          top: newTop,
          end: null,
          minWidth: anchorPosition.width,
          maxHeight: newMaxHeight
        };

      } else if (this.position === "start" || this.position === "end") {

        const onEnd =
          (this.position === "end" && (!tooLittleSpaceEnd || spaceEnd > spaceStart))
          || (this.position === "start" && tooLittleSpaceStart && spaceEnd > spaceStart);


        above = tooLittleSpaceBottom && spaceAbove > spaceBelow;

        const newStart = onEnd
          ? anchorPositionEndX + distance
          : (anchorPositionStartX - distance - elementSize.width);

        const newTop = this.verticalPosition === "middle" ? (
          anchorPosition.y + (anchorPosition.height - elementSize.height) / 2
        ) : (
          above
            ? (anchorPosition.endY - elementSize.height)
            : anchorPosition.y
        );

        newPosition = {
          start: newStart,
          top: newTop,
          end: this.maxSpace ? 0 : null,
          minWidth: null,
          maxHeight: null
        };

      } else if (this.position === "inPlace") {

        above = tooLittleSpaceBottom && spaceAbove > spaceBelow;

        const newStart = anchorPositionStartX;

        const newTop = this.verticalPosition === "middle" ? (
          anchorPosition.y + (anchorPosition.height - elementSize.height) / 2
        ) : (
          above
            ? (anchorPosition.endY - elementSize.height)
            : anchorPosition.y
        );

        newPosition = {
          start: newStart,
          top: newTop,
          end: null,
          minWidth: anchorPosition.width,
          maxHeight: null
        };
      }

      if(newPosition === undefined) {
        throw new Error("newPosition is undefined");
      } else if(this.lastPosition === undefined || JSON.stringify(newPosition) !== JSON.stringify(this.lastPosition) || this.above !== above) {
        this.above = above;
        this.lastPosition = newPosition;
        // console.log("style applied");
        popupElement.style.top = newPosition.top+"px";
        const left = end ? newPosition.end : newPosition.start;
        popupElement.style.left = left ? left + "px": "";
        popupElement.style.right = (end ? newPosition.start : newPosition.end) !== null ? (end ? newPosition.start : newPosition.end)+"px" : "";

        popupElement.style.minWidth = newPosition.minWidth !== null ? newPosition.minWidth+"px" : "";
        popupElement.style.maxHeight = newPosition.maxHeight !== null ? newPosition.maxHeight+"px" : "";

      } else {
        // console.log("style not applied");
      }



    }

  };


  ngOnDestroy(): void {
    this.destroying = true;

    if(this.themeChanged) {
      this.appSettingsController.returnToPreviousTheme();
    }

    if (this.layer) {
      document.body.removeChild(this.layer);
      this.layer = undefined;
    }

    if(this.contentAvailableSubscription) {
      this.contentAvailableSubscription.unsubscribe();
    }


    if(this.contentChangeSubscription) {
      this.contentChangeSubscription.unsubscribe();
    }

    document.removeEventListener('scroll', this.onScroll, true);

    $$(window.document.body).off("keydown", this.keyDownHandler);

    clearInterval(this.fixInterval);
  }
}
