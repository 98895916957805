import {
  __,
  AggregateId, arrayMove,
  BusinessEntityIdWithType, BusinessEntityTypeId,
  compareNumbers,
  DepartmentId,
  Duration, DurationInputFormatter,
  FileUri,
  FlowId,
  GroupId,
  i18n,
  I18nText,
  LocalDate,
  LocalDateTime,
  LocalTime, millisOfYear,
  None,
  ObjectId,
  Option, OrganizationNodeId,
  PersonId, toDoubleDigit,
  toFixedLengthString,
  Typed
} from "@utils";
import {VariablePath, VariableTypePath} from "./VariablePath";
import {ContextPath} from "./ContextPath";
import {FlowStatus} from "../flow-and-task/FlowModel";

export interface BusinessVariable {
  className(): string;

  isEqual(other: BusinessVariable | undefined): boolean;

  valueToString(): string;

  valueToSimpleString(): string;

  valueToSortableString(): string;

  value: any;

  isEmpty(): boolean;

  simpleValueType(): string;

  compare(other: BusinessVariable): number;

  copy(): BusinessVariable;

  toStringVariable(): StringVariable;

  toJsonValue(): any;

  bytesSize(): number;

  toSearchable(): string;

  toJsonString(): string;
}

export interface OrganizationNodeVariable extends BusinessVariable {
  toOrganizationNodeId(): OrganizationNodeId;
}


/** Basic types */


export class StringVariable implements BusinessVariable {
  static className = "StringVariable";
  static empty: StringVariable = new StringVariable("");

  toJsonString(): string {
    return JSON.stringify(this.value);
  }

  className() {
    return StringVariable.className
  }

  simpleValueType() {
    return StringVariable.simpleValueType;
  }

  static simpleValueType = "String";

  value: string;

  isEmpty(): boolean {
    return this.valueToSimpleString().length === 0;
  }

  toJsonValue(): any {
    return this.value;
  }

  valueToString(): string {
    return '"' + this.value + '"';
  }

  valueToSimpleString(): string {
    return this.value;
  }

  valueToSortableString(): string {
    return this.value;
  }

  constructor(value: string) {
    this.value = value;
  }

  isEqual(other: BusinessVariable | undefined) {
    if (this === other) {
      return true;
    } else if (other == undefined || other.className() !== this.className()) {
      return false;
    } else {
      return other.value === this.value;
    }
  }

  compare(other: BusinessVariable) {
    if (other == undefined) {
      return 1;
    } else if (other.className() !== this.className()) {
      return this.valueToSimpleString().localeCompare(other.valueToSimpleString(), undefined, {
        numeric: true,
        sensitivity: 'base'
      });
    } else {
      return this.value.localeCompare(other.value, undefined, {numeric: true, sensitivity: 'base'});
    }
  }

  copy() {
    return new StringVariable(this.value);
  }

  toStringVariable(): StringVariable {
    return this;
  }

  bytesSize(): number {
    return 4 + this.value.length;
  }

  toSearchable(): string {
    return this.value.split(/^[0-9a-ząćęłńóśźż]+/).join(" ").toLowerCase();
  }

}


export class PasswordValue {
  constructor(readonly password: string) {
  }

  static copy(other: PasswordValue): PasswordValue {
    return new PasswordValue(other.password)
  }
}

export class PasswordVariable implements BusinessVariable {
  static className = "PasswordVariable";

  className() {
    return PasswordVariable.className
  }

  toJsonString(): string {
    return `"*secured*"`;
  }

  simpleValueType() {
    return PasswordVariable.simpleValueType;
  }

  static simpleValueType = "Password";

  value: PasswordValue;

  toSearchable(): string {
    return "";
  }

  isEmpty(): boolean {
    return this.valueToSimpleString().length === 0;
  }

  toJsonValue(): any {
    return this.value.password;
  }

  valueToString(): string {
    return '"' + this.value.password + '"';
  }

  valueToSimpleString(): string {
    return this.value.password;
  }

  valueToSortableString(): string {
    return this.value.password;
  }

  constructor(value: PasswordValue) {
    this.value = value;
  }

  isEqual(other: BusinessVariable | undefined) {
    if (this === other) {
      return true;
    } else if (other == undefined || other.className() !== this.className()) {
      return false;
    } else {
      return other.value.password === this.value.password;
    }
  }

  compare(other: BusinessVariable) {
    if (other == undefined) {
      return 1;
    } else if (other.className() !== this.className()) {
      return this.valueToSimpleString().localeCompare(other.valueToSimpleString(), undefined, {
        numeric: true,
        sensitivity: 'base'
      });
    } else {
      return this.value.password.localeCompare((<PasswordValue>other.value).password, undefined, {
        numeric: true,
        sensitivity: 'base'
      });
    }
  }

  copy() {
    return new PasswordVariable(new PasswordValue(this.value.password));
  }

  toStringVariable(): StringVariable {
    return new StringVariable(this.value.password);
  }

  bytesSize(): number {
    return 4 + 20; // do not expose password length
  }

}


export class I18nTextVariable implements BusinessVariable {
  static className = "I18nTextVariable";


  className() {
    return I18nTextVariable.className
  }

  simpleValueType() {
    return I18nTextVariable.simpleValueType;
  }

  static simpleValueType = "I18nText";

  value: I18nText;

  toSearchable(): string {
    return this.value.getCurrentWithFallback().split(/^[0-9a-ząćęłńóśźż]+/).join(" ").toLowerCase();
  }

  isEmpty(): boolean {
    return this.value.isEmpty();
  }

  valueToString(): string {
    return '"' + this.value.getCurrentWithFallback() + '"';
  }

  valueToSimpleString(): string {
    return this.value.getCurrentWithFallback();
  }

  valueToSortableString(): string {
    return this.value.getCurrentWithFallback();
  }


  toJsonString(): string {
    return JSON.stringify(this.value.value);
  }

  toJsonValue(): any {
    return this.value.value;
  }

  constructor(value: I18nText) {
    this.value = value;
  }

  isEqual(other: BusinessVariable | undefined): boolean {
    if (this === other) {
      return true;
    } else if (other == undefined || other.className() !== this.className()) {
      return false;
    } else {
      return (<I18nTextVariable>other).value.isEqual(this.value);
    }
  }

  compare(other: BusinessVariable) {
    if (other == undefined) {
      return 1;
    } else if (other.className() !== this.className()) {
      return this.valueToSimpleString().localeCompare(other.valueToSimpleString(), undefined, {
        numeric: true,
        sensitivity: 'base'
      });
    } else {
      return this.value.getCurrentWithFallback().localeCompare(other.value, undefined, {
        numeric: true,
        sensitivity: 'base'
      });
    }
  }

  copy() {
    return new I18nTextVariable(I18nText.copy(this.value));
  }

  toStringVariable(): StringVariable {
    return new StringVariable(this.value.getCurrentWithFallback());
  }

  bytesSize(): number {
    // return 24 + (this.value.isEmpty() ? 0 : ___(this.value.value).map(c => c[0].length+c[1].length+8).sum());
    return 10;
  }

}


export class NumberVariable implements BusinessVariable {
  static className = "NumberVariable";

  toJsonString(): string {
    return JSON.stringify(this.value);
  }

  className() {
    return NumberVariable.className
  }

  simpleValueType() {
    return NumberVariable.simpleValueType;
  }

  toSearchable(): string {
    return Math.round(this.value) + "";
  }

  static simpleValueType = "Number";

  value: number;

  isEmpty(): boolean {
    return this.valueToSimpleString().length === 0;
  }

  toJsonValue(): any {
    return this.value;
  }

  valueToString(): string {
    return this.value.toString();
  }

  valueToSimpleString(): string {
    return this.valueToString();
  }

  valueToSortableString(): string {
    return this.valueToString();
  }

  constructor(value: number) {
    this.value = value;
  }

  isEqual(other: BusinessVariable | undefined) {
    if (this === other) {
      return true;
    } else if (other == undefined || other.className() !== this.className()) {
      return false;
    } else {
      return other.value === this.value;
    }
  }

  compare(other: BusinessVariable) {
    if (other == undefined) {
      return 1;
    } else if (other.className() !== this.className()) {
      return this.valueToSimpleString().localeCompare(other.valueToSimpleString(), undefined, {
        numeric: true,
        sensitivity: 'base'
      });
    } else {
      return this.value - other.value;
    }
  }

  copy() {
    return new NumberVariable(this.value);
  }

  toStringVariable(): StringVariable {
    return new StringVariable(this.valueToString());
  }


  bytesSize(): number {
    return 4 + 8;
  }

}


export class BooleanVariable implements BusinessVariable {
  static className = "BooleanVariable";

  toJsonString(): string {
    return JSON.stringify(this.value);
  }

  className() {
    return BooleanVariable.className
  }

  toSearchable(): string {
    return "";
  }

  simpleValueType() {
    return BooleanVariable.simpleValueType;
  }

  static simpleValueType = "Boolean";

  value: boolean;

  isEmpty(): boolean {
    return this.valueToSimpleString().length === 0;
  }

  valueToString(): string {
    return this.value.toString();
  }

  toJsonValue(): any {
    return this.value.toString();
  }

  valueToSimpleString(): string {
    return this.valueToString();
  }

  valueToSortableString(): string {
    return this.valueToString();
  }

  constructor(value: boolean) {
    this.value = value;
  }

  isEqual(other: BusinessVariable | undefined) {
    if (this === other) {
      return true;
    } else if (other == undefined || other.className() !== this.className()) {
      return false;
    } else {
      return other.value === this.value;
    }
  }

  compare(other: BusinessVariable) {
    if (other == undefined) {
      return 1;
    } else if (other.className() !== this.className()) {
      return this.valueToSimpleString().localeCompare(other.valueToSimpleString(), undefined, {
        numeric: true,
        sensitivity: 'base'
      });
    } else {
      return (this.value ? 1 : 0) - (other.value ? 1 : 0);
    }
  }

  copy() {
    return new BooleanVariable(this.value);
  }

  static TRUE() {
    return new BooleanVariable(true);
  }

  static FALSE() {
    return new BooleanVariable(false);
  }

  toStringVariable(): StringVariable {
    return new StringVariable(this.valueToString());
  }

  bytesSize(): number {
    return 4 + 4;
  }

  static of(value: boolean) {
    if (value) {
      return BooleanVariable.TRUE();
    } else {
      return BooleanVariable.FALSE();
    }
  }
}


export class DateVariable implements BusinessVariable {
  static className = "DateVariable";

  className() {
    return DateVariable.className
  }

  simpleValueType() {
    return DateVariable.simpleValueType;
  }

  toSearchable(): string {
    return "";
  }

  static simpleValueType = "Date";

  value: LocalDate;

  isEmpty(): boolean {
    return this.valueToSimpleString().length === 0;
  }

  valueToString(): string {
    return this.value.formattedWords();
  }

  valueToSimpleString(): string {
    return this.valueToString();
  }

  valueToSortableString(): string {
    return this.valueToString();
  }

  toJsonString(): string {
    return JSON.stringify(this.value.isoFormatted());
  }

  toJsonValue(): any {
    return this.value.isoFormatted();
  }

  constructor(value: LocalDate) {
    this.value = value;
  }

  isEqual(other: BusinessVariable | undefined) {
    if (this === other) {
      return true;
    } else if (other == undefined || other.className() !== this.className()) {
      return false;
    } else if (this.value == undefined && other.value == undefined) {
      return true;
    } else {
      return this.value.isEqual(other.value);
    }
  }

  compare(other: BusinessVariable) {
    if (other == undefined) {
      return 1;
    } else if (other.className() !== this.className()) {
      return this.valueToSimpleString().localeCompare(other.valueToSimpleString(), undefined, {
        numeric: true,
        sensitivity: 'base'
      });
    } else if (this.value.isAfter(other.value)) {
      return 1;
    } else if (this.value.isBefore(other.value)) {
      return -1;
    } else {
      return 0;
    }
  }

  copy() {
    return new DateVariable(this.value);
  }

  toStringVariable(): StringVariable {
    return new StringVariable(this.valueToString());
  }

  bytesSize(): number {
    return 4 + 12;
  }
}


export class DateTimeVariable implements BusinessVariable {
  static className = "DateTimeVariable";


  className() {
    return DateTimeVariable.className
  }

  toSearchable(): string {
    return "";
  }

  simpleValueType() {
    return DateTimeVariable.simpleValueType;
  }

  static simpleValueType = "DateTime";

  value: LocalDateTime;

  isEmpty(): boolean {
    return this.valueToSimpleString().length === 0;
  }

  valueToString(): string {
    return this.value.formattedWords()+" UTC";
  }

  valueToSimpleString(): string {
    return this.valueToString();
  }

  valueToSortableString(): string {
    return this.valueToString();
  }


  toJsonString(): string {
    return JSON.stringify(this.toJsonValue());
  }

  toJsonValue(): any {
    return this.value.isoSimpleFormattedToMinutes()+" UTC";
  }

  valueToSimpleStringKeepTimezone(): string {
    return this.value.formattedToMinutes();
  }

  constructor(value: LocalDateTime) {
    this.value = value;
  }

  isEqual(other: BusinessVariable | undefined) {
    if (this === other) {
      return true;
    } else if (other == undefined || other.className() !== this.className()) {
      return false;
    } else if (this.value == undefined && other.value == undefined) {
      return true;
    } else {
      return this.value.isEqual(other.value);
    }
  }

  compare(other: BusinessVariable) {
    if (other == undefined) {
      return 1;
    } else if (other.className() !== this.className()) {
      return this.valueToSimpleString().localeCompare(other.valueToSimpleString(), undefined, {
        numeric: true,
        sensitivity: 'base'
      });
    } else if (this.value.isAfter(other.value)) {
      return 1;
    } else if (this.value.isBefore(other.value)) {
      return -1;
    } else {
      return 0;
    }
  }

  copy() {
    return new DateTimeVariable(this.value);
  }

  toStringVariable(): StringVariable {
    return new StringVariable(this.valueToString());
  }

  bytesSize(): number {
    return 4 + 20;
  }
}


export class TimeVariable implements BusinessVariable {
  static className = "TimeVariable";

  toSearchable(): string {
    return "";
  }

  className() {
    return TimeVariable.className
  }

  simpleValueType() {
    return TimeVariable.simpleValueType;
  }

  static simpleValueType = "Time";

  value: LocalTime;

  isEmpty(): boolean {
    return this.valueToSimpleString().length === 0;
  }

  toJsonString(): string {
    return JSON.stringify(this.value.formattedToMinutes());
  }

  toJsonValue(): any {
    return this.value.formattedToMinutes();
  }

  valueToString(): string {
    return this.value.formattedToMinutes();
  }

  valueToSimpleString(): string {
    return this.valueToString();
  }

  valueToSortableString(): string {
    return this.valueToString();
  }

  constructor(value: LocalTime) {
    this.value = value;
  }

  isEqual(other: BusinessVariable | undefined) {
    if (this === other) {
      return true;
    } else if (other == undefined || other.className() !== this.className()) {
      return false;
    } else if (this.value == undefined && other.value == undefined) {
      return true;
    } else {
      return this.value.isEqual(other.value);
    }
  }

  compare(other: BusinessVariable) {
    if (other == undefined) {
      return 1;
    } else if (other.className() !== this.className()) {
      return this.valueToSimpleString().localeCompare(other.valueToSimpleString(), undefined, {
        numeric: true,
        sensitivity: 'base'
      });
    } else if (this.value.isAfter(other.value)) {
      return 1;
    } else if (this.value.isBefore(other.value)) {
      return -1;
    } else {
      return 0;
    }
  }

  copy() {
    return new TimeVariable(this.value);
  }

  toStringVariable(): StringVariable {
    return new StringVariable(this.valueToString());
  }

  bytesSize(): number {
    return 4 + 8;
  }
}

export class IntervalVariable implements BusinessVariable {
  static className = "IntervalVariable";

  toSearchable(): string {
    return "";
  }

  className() {
    return IntervalVariable.className
  }

  simpleValueType() {
    return IntervalVariable.simpleValueType;
  }

  static simpleValueType = "Interval";

  value: Duration;

  isEmpty(): boolean {
    return this.valueToSimpleString().length === 0;
  }

  toJsonString(): string {
    return JSON.stringify(this.value.formatted24());
  }

  toJsonValue(): any {
    return this.value.formatted24();
  }

  valueToString(): string {
    return new DurationInputFormatter(24).format(this.value);
  }

  valueToSimpleString(): string {
    return this.valueToString();
  }

  valueToSortableString(): string {
    return this.valueToString();
  }

  constructor(value: Duration) {
    this.value = value;
  }

  isEqual(other: BusinessVariable | undefined) {
    if (this === other) {
      return true;
    } else if (other == undefined || other.className() !== this.className()) {
      return false;
    } else {
      return this.value.isEqual(other.value);
    }
  }

  compare(other: BusinessVariable) {
    if (other == undefined) {
      return 1;
    } else if (other.className() !== this.className()) {
      return this.valueToSimpleString().localeCompare(other.valueToSimpleString(), undefined, {
        numeric: true,
        sensitivity: 'base'
      });
    } else {
      return this.value.compare(other.value);
    }
  }

  copy() {
    return new IntervalVariable(this.value);
  }

  toStringVariable(): StringVariable {
    return new StringVariable(this.valueToString());
  }

  bytesSize(): number {
    return 4 + 8;
  }
}

export class GeoCoordinate {
  constructor(readonly latitude: number, readonly longitude: number) {
  }

  isEqual(other: GeoCoordinate) {
    return this.latitude === other.latitude && this.longitude === other.longitude;
  }

  compare(other: GeoCoordinate) {
    if (this.longitude > other.longitude) {
      return 1;
    } else if (this.longitude < other.longitude) {
      return -1;
    } else if (this.latitude > other.latitude) {
      return 1;
    } else if (this.latitude < other.latitude) {
      return -1;
    } else {
      return 0;
    }
  }

  static copy(other: GeoCoordinate) {
    return new GeoCoordinate(other.latitude, other.longitude);
  }

  copy() {
    return new GeoCoordinate(this.latitude, this.longitude);
  }

}

export class GeoCoordinateVariable implements BusinessVariable {
  static className = "GeoCoordinateVariable";


  className() {
    return GeoCoordinateVariable.className
  }

  toSearchable(): string {
    return "";
  }

  simpleValueType() {
    return 'GeoCoordinate';
  }

  value: GeoCoordinate;

  toJsonString(): string {
    return JSON.stringify(this.value.latitude+","+this.value.longitude);
  }

  toJsonValue(): any {
    return [this.value.latitude, this.value.longitude];
  }


  isEmpty(): boolean {
    return this.value.latitude.toString().length === 0 || this.value.longitude.toString().length === 0
  }

  valueToString(): string {
    return this.value.latitude + ";" + this.value.longitude;
  }

  valueToSimpleString(): string {
    return this.valueToString();
  }

  valueToSortableString(): string {
    return this.valueToString();
  }

  constructor(value: GeoCoordinate) {
    this.value = value;
  }

  isEqual(other: BusinessVariable | undefined) {
    if (this === other) {
      return true;
    } else if (other == undefined || other.className() !== this.className()) {
      return false;
    } else {
      return this.value.isEqual(other.value);
    }
  }

  compare(other: BusinessVariable) {
    if (other == undefined) {
      return 1;
    } else if (other.className() !== this.className()) {
      return this.valueToSimpleString().localeCompare(other.valueToSimpleString(), undefined, {
        numeric: true,
        sensitivity: 'base'
      });
    } else {
      return this.value.compare(other.value);
    }
  }

  copy() {
    return new GeoCoordinateVariable(this.value);
  }

  toStringVariable(): StringVariable {
    return new StringVariable(this.valueToString());
  }

  bytesSize(): number {
    return 4 + 12;
  }
}

export class Link {
  constructor(readonly name: string, readonly url: string) {
  }

  isEqual(other: Link) {
    return this.name === other.name && this.url === other.url;
  }

  static copy(other: Link) {
    return new Link(other.name, other.url);
  }

  copy() {
    return new Link(this.name, this.url);
  }

}

export class LinkVariable implements BusinessVariable {
  static className = "LinkVariable";


  className() {
    return LinkVariable.className
  }

  toSearchable(): string {
    return this.value.name.split(/^[0-9a-ząćęłńóśźż]+/).join(" ").toLowerCase() + " " + this.value.url.split(/^[0-9a-ząćęłńóśźż]+/).join(" ").toLowerCase();
  }

  simpleValueType() {
    return 'Link';
  }

  value: Link;
  separator = String.fromCharCode(31); // Unit separator

  isEmpty(): boolean {
    return this.value.name.length === 0 || this.value.url.length === 0
  }

  toJsonString(): string {
    if(this.value.name.length === 0) {
      return JSON.stringify(this.value.url);
    } else {
      return JSON.stringify(this.value.name+" ("+this.value.url+")");
    }
  }

  toJsonValue(): any {
    return [this.value.name, this.value.url];
  }

  valueToString(): string {
    return this.value.name + "#" + this.value.url;
  }

  valueToSimpleString(): string {
    return this.value.name;
  }

  valueToSortableString(): string {
    return this.value.name;
  }

  constructor(value: Link) {
    this.value = value;
  }

  isEqual(other: BusinessVariable | undefined) {
    if (this === other) {
      return true;
    } else if (other == undefined || other.className() !== this.className()) {
      return false;
    } else {
      return this.value.isEqual(other.value);
    }
  }

  compare(other: BusinessVariable): number {
    if (other == undefined) {
      return 1;
    } else if (other.className() !== this.className()) {
      return this.valueToSimpleString().localeCompare(other.valueToSimpleString(), undefined, {
        numeric: true,
        sensitivity: 'base'
      });
    } else {
      return this.value.name.localeCompare((<LinkVariable>other).value.name, undefined, {
        numeric: true,
        sensitivity: 'base'
      });
    }
  }

  copy() {
    return new LinkVariable(this.value);
  }

  toStringVariable(): StringVariable {
    return new StringVariable(this.valueToString());
  }


  bytesSize(): number {
    return 4 + this.value.name.length + this.value.url.length;
  }
}

export class FileVariableV2 implements BusinessVariable {
  static className = "FileVariableV2";


  className() {
    return FileVariableV2.className
  }

  toSearchable(): string {
    return "";
  }

  simpleValueType() {
    return FileVariableV2.simpleValueType;
  }

  static simpleValueType = "File";

  value: FileUri;

  isEmpty(): boolean {
    return this.valueToSimpleString().length === 0;
  }

  valueToString(): string {
    if (this.value == null) {
      return ""
    } else {
      return "File#" + this.value.serialize();
    }
  }


  toJsonString(): string {
    return JSON.stringify(this.toJsonValue());
  }

  toJsonValue(): any {
    return this.valueToSimpleString();
  }

  valueToSimpleString(): string {
    return this.valueToString();
  }

  valueToSortableString(): string {
    return this.valueToString();
  }

  constructor(value: FileUri) {
    this.value = value;
  }

  isEqual(other: BusinessVariable | undefined) {
    if (this === other) {
      return true;
    } else if (other == undefined || other.className() !== this.className()) {
      return false;
    } else {
      return this.value.isEqual(other.value);
    }
  }

  compare(other: BusinessVariable) {
    if (other == undefined) {
      return 1;
    } else if (other.className() !== this.className()) {
      return this.valueToSimpleString().localeCompare(other.valueToSimpleString(), undefined, {
        numeric: true,
        sensitivity: 'base'
      });
    } else {
      return (this.value.protocol.name + "://" + this.value.path).localeCompare(other.value.protocol.name + "://" + other.value.path, undefined, {
        numeric: true,
        sensitivity: 'base'
      });
    }
  }

  copy() {
    return new FileVariableV2(FileUri.copy(this.value));
  }

  toStringVariable(): StringVariable {
    return new StringVariable(this.valueToString());
  }

  bytesSize(): number {
    return 4 + 8 + this.value.path.length;
  }
}

export class PersonVariable implements OrganizationNodeVariable {
  static className = "PersonVariable";

  className() {
    return PersonVariable.className
  }

  toSearchable(): string {
    return "";
  }

  simpleValueType() {
    return 'Person';
  }

  value: PersonId;

  isEmpty(): boolean {
    return this.valueToSimpleString().length === 0;
  }

  valueToString(): string {
    return "Person#" + this.value.id.id;
  }

  toJsonString(): string {
    return `"Person#${this.value.id.id}"`;
  }

  toJsonValue(): any {
    return this.valueToSimpleString();
  }

  valueToSimpleString(): string {
    return this.valueToString();
  }

  valueToSortableString(): string {
    return this.valueToString();
  }

  toOrganizationNodeId(): OrganizationNodeId {
    return OrganizationNodeId.fromPersonId(this.value);
  }


  constructor(value: PersonId) {
    this.value = value;
  }

  isEqual(other: BusinessVariable | undefined): boolean {
    if (this === other) {
      return true;
    } else if (other == undefined || other.className() !== this.className()) {
      return false;
    } else {
      return (<PersonVariable>other).value.id.id === this.value.id.id;
    }
  }

  compare(other: BusinessVariable) {
    if (other == undefined) {
      return 1;
    } else if (other.className() !== this.className()) {
      return this.valueToSimpleString().localeCompare(other.valueToSimpleString(), undefined, {
        numeric: true,
        sensitivity: 'base'
      });
    } else {
      return this.value.id.id.localeCompare(other.value.id.id, undefined, {numeric: true, sensitivity: 'base'});
    }
  }

  copy() {
    return new PersonVariable(PersonId.of(this.value));
  }

  static copy(other: PersonVariable) {
    return new PersonVariable(PersonId.of(other.value));
  }

  toStringVariable(): StringVariable {
    return new StringVariable(this.valueToString());
  }

  bytesSize(): number {
    return 4 + 6;
  }

}


export class DepartmentVariable implements OrganizationNodeVariable {
  static className = "DepartmentVariable";

  className() {
    return DepartmentVariable.className
  }

  toSearchable(): string {
    return "";
  }

  simpleValueType() {
    return 'Department';
  }

  value: DepartmentId;

  isEmpty(): boolean {
    return this.valueToSimpleString().length === 0;
  }

  valueToString(): string {
    return "Department#" + this.value.id.id;
  }

  toJsonString(): string {
    return `"Department#${this.value.id.id}"`;
  }

  toJsonValue(): any {
    return this.valueToSimpleString();
  }

  valueToSimpleString(): string {
    return this.valueToString();
  }

  valueToSortableString(): string {
    return this.valueToString();
  }

  toOrganizationNodeId(): OrganizationNodeId {
    return OrganizationNodeId.fromDepartmentId(this.value);
  }



  constructor(value: DepartmentId) {
    this.value = value;
  }

  isEqual(other: BusinessVariable | undefined): boolean {
    if (this === other) {
      return true;
    } else if (other == undefined || other.className() !== this.className()) {
      return false;
    } else {
      return (<DepartmentVariable>other).value.id.id === this.value.id.id;
    }
  }

  compare(other: BusinessVariable) {
    if (other == undefined) {
      return 1;
    } else if (other.className() !== this.className()) {
      return this.valueToSimpleString().localeCompare(other.valueToSimpleString(), undefined, {
        numeric: true,
        sensitivity: 'base'
      });
    } else {
      return this.value.id.id.localeCompare(other.value.id.id, undefined, {numeric: true, sensitivity: 'base'});
    }
  }

  copy() {
    return new DepartmentVariable(this.value);
  }

  toStringVariable(): StringVariable {
    return new StringVariable(this.valueToString());
  }

  bytesSize(): number {
    return 4 + 6;
  }

}

export class GroupVariable implements OrganizationNodeVariable {
  static className = "GroupVariable";

  className() {
    return GroupVariable.className
  }

  toSearchable(): string {
    return "";
  }

  simpleValueType() {
    return 'Group';
  }

  value: GroupId;

  isEmpty(): boolean {
    return this.valueToSimpleString().length === 0;
  }

  valueToString(): string {
    return "Group#" + this.value.id.id;
  }

  toJsonString(): string {
    return `"Group#${this.value.id.id}"`;
  }

  toJsonValue(): any {
    return this.valueToSimpleString();
  }

  valueToSimpleString(): string {
    return this.valueToString();
  }

  valueToSortableString(): string {
    return this.valueToString();
  }

  toOrganizationNodeId(): OrganizationNodeId {
    return OrganizationNodeId.fromGroupId(this.value);
  }


  constructor(value: GroupId) {
    this.value = value;
  }

  isEqual(other: BusinessVariable | undefined): boolean {
    if (this === other) {
      return true;
    } else if (other == undefined || other.className() !== this.className()) {
      return false;
    } else {
      return (<GroupVariable>other).value.id.id === this.value.id.id;
    }
  }

  compare(other: BusinessVariable) {
    if (other == undefined) {
      return 1;
    } else if (other.className() !== this.className()) {
      return this.valueToSimpleString().localeCompare(other.valueToSimpleString());
    } else {
      return this.value.id.id.localeCompare(other.value.id.id);
    }
  }

  copy() {
    return new GroupVariable(this.value);
  }

  toStringVariable(): StringVariable {
    return new StringVariable(this.valueToString());
  }

  bytesSize(): number {
    return 4 + 6;
  }

}

export class BusinessEntityVariable implements BusinessVariable {

  static className = "BusinessEntityVariable";

  constructor(readonly value: BusinessEntityIdWithType) {}

  bytesSize(): number {
    return 4 + 6;
  }

  className(): string {
    return BusinessEntityVariable.className;
  }

  compare(other: BusinessVariable): number {
    if (other == undefined) {
      return 1;
    } else if (other.className() !== this.className()) {
      return this.valueToSimpleString().localeCompare(other.valueToSimpleString());
    } else {
      return this.value.id.localeCompare(other.value.id.id);
    }
  }

  copy() {
    return new BusinessEntityVariable(this.value);
  }

  isEmpty(): boolean {
    return this.valueToSimpleString().length === 0;
  }

  isEqual(other: BusinessVariable | undefined): boolean {
    if (this === other) {
      return true;
    } else if (other == undefined || other.className() !== this.className()) {
      return false;
    } else {
      return (<BusinessEntityVariable>other).value.id === this.value.id;
    }
  }

  simpleValueType(): string {
    return "BusinessEntity";
  }

  toJsonString(): string {
    return `"BusinessEntity#${this.value.id}@${this.value.typeId}"`;
  }

  toJsonValue(): any {
    return this.valueToSimpleString();
  }

  toSearchable(): string {
    return "";
  }

  toStringVariable(): StringVariable {
    return new StringVariable(this.valueToString());
  }

  valueToSimpleString(): string {
    return this.valueToString();
  }

  valueToSortableString(): string {
    return this.valueToString();
  }

  valueToString(): string {
    return "BusinessEntity#" + this.value.id;
  }

}

export class BusinessEntityVersionVariable implements BusinessVariable {
  static className = "BusinessEntityVersionVariable";

  className() {
    return BusinessEntityVersionVariable.className
  }

  toSearchable(): string {
    return "";
  }

  toJsonString(): string {
    return `"BusinessEntityVersion#${this.value.id}"`;
  }

  simpleValueType() {
    return 'BusinessEntityVersion';
  }

  value: AggregateId;

  isEmpty(): boolean {
    return this.valueToSimpleString().length === 0;
  }

  valueToString(): string {
    return "BusinessEntityVersion#" + this.value.id;
  }


  toJsonValue(): any {
    return this.valueToSimpleString();
  }

  valueToSimpleString(): string {
    return this.valueToString();
  }

  valueToSortableString(): string {
    return this.valueToString();
  }

  constructor(value: AggregateId) {
    this.value = value;
  }

  isEqual(other: BusinessVariable | undefined): boolean {
    if (this === other) {
      return true;
    } else if (other == undefined || other.className() !== this.className()) {
      return false;
    } else {
      return (<BusinessEntityVersionVariable>other).value.id === this.value.id;
    }
  }

  compare(other: BusinessVariable) {
    if (other == undefined) {
      return 1;
    } else if (other.className() !== this.className()) {
      return this.valueToSimpleString().localeCompare(other.valueToSimpleString(), undefined, {
        numeric: true,
        sensitivity: 'base'
      });
    } else {
      return this.value.id.localeCompare(other.value.id, undefined, {numeric: true, sensitivity: 'base'});
    }
  }

  copy() {
    return new BusinessEntityVersionVariable(this.value);
  }

  toStringVariable(): StringVariable {
    return new StringVariable(this.valueToString());
  }

  bytesSize(): number {
    return 4 + 6;
  }


}

/** Collections */

export class ArrayVariable<T extends BusinessVariable> implements BusinessVariable {
  static className = "ArrayVariable";


  className() {
    return ArrayVariable.className
  }

  toSearchable(): string {
    return this.unwrappedValue().map(v => v.toSearchable()).join(" ");
  }

  simpleValueType() {
    return ArrayVariable.simpleValueType;
  }

  static simpleValueType = "Array";

  id: ObjectId;
  value: Typed<T>[];

  isEmpty(): boolean {
    return this.value.length === 0;
  }


  toJsonString(): string {

    return "["+this.unwrappedValue().map(v => {
      return v.toJsonString();
    }).join(",")+"]";
  }

  toJsonValue(): any {
    return this.unwrappedValue().map(v => v.toJsonValue());
  }

  get(index: number): T {
    if (this.value.length < index) {
      return Typed.value(this.value[index]);
    } else {
      throw new Error("Index " + index + "out of bounds (" + this.value.length + ")");
    }
  }

  put(index: number, value: T) {
    if (index < this.value.length) {
      this.value.splice(index, 1, Typed.of(value))
    } else if (index == this.value.length) {
      this.value.push(Typed.of(value));
    } else {
      throw new Error("Index " + index + "out of bounds (" + this.value.length + ")");
    }
  }

  nonEmpty(): boolean {
    return this.value.length > 0;
  }

  valueToString(): string {
    return "[" + this.unwrappedValue().map(v => v.valueToString()).join(", ") + "]";
  }

  valueToSimpleString(): string {
    return this.unwrappedValue().map(v => v.valueToString()).join(", ");
  }

  valueToSortableString(): string {
    return this.unwrappedValue().map(v => v.valueToSortableString()).join(", ");
  }

  constructor(value: Typed<T>[], id: ObjectId = ObjectId.next()) {
    this.value = value;
    this.id = id;
  }

  unwrappedValue(): Array<T> {
    return this.value.map((v: Typed<T>) => Typed.value<T>(v));
  }

  isEqual(other: BusinessVariable | undefined) {
    if (this === other) {
      return true;
    } else if (other == undefined || other.className() !== this.className()) {
      return false;
    } else if ((<ArrayVariable<any>>other).value.length !== this.value.length) {
      return false;
    } else {
      for (let i = 0; i < this.value.length; i++) {
        if (!Typed.value(this.value[i]).isEqual(Typed.value((<ArrayVariable<any>>other).value[i]))) {
          return false;
        }
      }
      return true;
    }
  }

  compare(other: BusinessVariable) {
    if (other == undefined) {
      return 1;
    } else {
      return this.valueToSortableString().localeCompare(other.valueToSortableString(), undefined, {
        numeric: true,
        sensitivity: 'base'
      });
    }
  }

  setValue(index: number, value: Option<BusinessVariable>) {
    this.setValueByPath(index, VariablePath.empty(), value);
  }


  getValueByPath(index: number, path: VariablePath): Option<BusinessVariable> {
    if (index < this.value.length) {
      if (path.isEmpty()) {
        return Option.of(this.value[index]).map(v => Typed.value(v));
      } else {
        const entryValue = Typed.value(this.value[index]);

        if (path.startsWithIndex()) {
          if (entryValue.className() === ArrayVariable.className) {
            return (<ArrayVariable<BusinessVariable>><any>entryValue).getValueByPath(path.headIndex(), path.tail());
          } else {
            throw new Error("Expected Array but found " + entryValue.className());
          }
        } else {
          if (entryValue.className() === ObjectVariable.className) {
            return (<ObjectVariable><any>entryValue).getValueByPath(path);
          } else {
            throw new Error("Expected Object but found " + entryValue.className());
          }
        }
      }
    } else {
      throw new Error("Index exceeds array length [" + index + "] >= [" + this.value.length + "]");
    }
  }


  setValueByPath(index: number, path: VariablePath, value: Option<BusinessVariable>) {
    if (index < this.value.length) {
      if (path.isEmpty()) {
        if (value.isDefined()) {
          this.value[index] = Typed.of(<T>value.get());
        } else {
          delete this.value[index];
        }
      } else {
        const entryValue = Typed.value(this.value[index]);

        if (path.startsWithIndex()) {
          if (entryValue.className() === ArrayVariable.className) {
            (<ArrayVariable<BusinessVariable>><any>entryValue).setValueByPath(path.headIndex(), path.tail(), value);
          } else {
            throw new Error("Expected Array but found " + entryValue.className());
          }
        } else {
          if (entryValue.className() === ObjectVariable.className) {
            (<ObjectVariable><any>entryValue).setValueByPath(path, value);
          } else {
            throw new Error("Expected Object but found " + entryValue.className());
          }
        }
      }
    } else {
      throw new Error("Index exceeds array length [" + index + "] >= [" + this.value.length + "]");
    }
  }

  static fromTexts(texts: Array<string>) {
    return new ArrayVariable<StringVariable>(texts.map(t => Typed.of(new StringVariable(t))));
  }

  static fromNumbers(texts: Array<string>) {
    return new ArrayVariable<NumberVariable>(texts.map(t => Typed.of(new NumberVariable(parseFloat(t)))));
  }

  static fromNumbersTyped(texts: Array<string>) {
    return Typed.of(this.fromNumbers(texts));
  }

  static fromTextsTyped(texts: Array<string>) {
    return this.fromTexts(texts);
  }

  static fromStringVariables(entries: StringVariable[]) {
    return new ArrayVariable<StringVariable>(entries.map(t => Typed.of(t)));
  }

  static fromNumberVariables(entries: NumberVariable[]) {
    return new ArrayVariable<NumberVariable>(entries.map(t => Typed.of(t)));
  }

  static fromFileVariables(entries: FileVariableV2[]) {
    return new ArrayVariable<FileVariableV2>(entries.map(t => Typed.of(t)));
  }

  static fromObjectVariables(entries: ObjectVariable[]) {
    return new ArrayVariable<ObjectVariable>(entries.map(t => Typed.of(t)));
  }

  deleteRow(index: number) {
    this.value.splice(index, 1);
  }

  deleteRows(indexes: Array<number>) {
    const values = this.value.splice(__(indexes).first(), indexes.length);
    this.value = values;
  }

  addRow(row: T) {
    this.value.push(Typed.of(row));
  }

  copy(): ArrayVariable<BusinessVariable> {
    return new ArrayVariable(this.value.map(v => BusinessVariableFactory.copyTyped(v)));
  }

  static copy(other: ArrayVariable<BusinessVariable>): ArrayVariable<BusinessVariable> {
    return new ArrayVariable(other.value.map(v => BusinessVariableFactory.copyTyped(v)), other.id ? ObjectId.copy(other.id) : ObjectId.next());
  }

  findObjectById(objectId: ObjectId): Option<[VariablePath, ObjectVariable]> {
    const found = __(this.value.map((e, index) => <[Typed<BusinessVariable>, number]>[e, index])).find((e: [Typed<BusinessVariable>, number]) => Typed.className(e[0]) === ObjectVariable.className && (<ObjectVariable>Typed.value(e[0])).id.id == objectId.id);
    if (found.isDefined()) {
      return found.map(f => <[VariablePath, ObjectVariable]>[VariablePath.rootIndex(found.get()[1]), <ObjectVariable>Typed.value(found.get()[0])]);
    } else {
      const foundInSubObjects = this.findObjectByIdInSubObjects(objectId);
      if (foundInSubObjects.isDefined()) {
        return foundInSubObjects
      } else {
        return this.findObjectByIdInSubArrays(objectId);
      }
    }
  }


  findObjectByIdInSubObjects(objectId: ObjectId): Option<[VariablePath, ObjectVariable]> {
    const subObjects: Array<[Typed<ObjectVariable>, number]> = <Array<[Typed<ObjectVariable>, number]>>this.value.map((e, index) => <[Typed<BusinessVariable>, number]>[e, index]).filter(v => Typed.className(v[0]) === ObjectVariable.className);
    let found: Option<[VariablePath, ObjectVariable]> = None();
    let i = 0;
    while (found.isEmpty() && i < subObjects.length) {
      const subObject = subObjects[i];
      found = (<ObjectVariable>Typed.value(subObject[0])).findObjectById(objectId).map(f => <[VariablePath, ObjectVariable]>[VariablePath.rootIndex(subObject[1]).concat(f[0]), f[1]]);
      i++;
    }
    return found;
  }

  findObjectByIdInSubArrays(objectId: ObjectId): Option<[VariablePath, ObjectVariable]> {
    const subArrays: Array<[Typed<ArrayVariable<BusinessVariable>>, number]> = this.value.map((e, index) => <[Typed<ArrayVariable<BusinessVariable>>, number]><any>[e, index]).filter(v => Typed.className(v[0]) === ArrayVariable.className);
    let found: Option<[VariablePath, ObjectVariable]> = None();
    let i = 0;

    while (found.isEmpty() && i < subArrays.length) {
      const subArray = subArrays[i];
      i++;
      found = (<ArrayVariable<BusinessVariable>>Typed.value(subArray[0])).findObjectById(objectId).map(f => <[VariablePath, ObjectVariable]>[VariablePath.rootIndex(subArray[1]).concat(f[0]), f[1]]);
    }

    return found;
  }

  toStringVariable(): StringVariable {
    return new StringVariable(this.valueToString());
  }

  bytesSize(): number {
    return 4;
  }


  static empty<T extends BusinessVariable>(): ArrayVariable<T> {
    return new ArrayVariable<T>([]);
  }
}


export class ObjectVariable implements BusinessVariable {
  static className = "ObjectVariable";

  className() {
    return ObjectVariable.className
  }

  toSearchable(): string {
    return this.value.map(v => Typed.value(v[1]).toSearchable()).join(" ");
  }

  simpleValueType() {
    return 'Object';
  }

  id: ObjectId;
  value: [string, Typed<BusinessVariable>][];

  isEmpty(): boolean {
    return this.value.length === 0;
  }

  toJsonString(): string {

    let json = "{";
    __(this.value).sortByAlphanumeric(v => v[0]).forEach(v => json += JSON.stringify(v[0])+":" + Typed.value(v[1]).toJsonString() + ",");
    json = json.length > 1 ? json.substring(0, json.length - 1) : json;
    json += "}";
    return json;
  }

  toJsonValue(): any {
    let obj: any = {};
    this.value.forEach(v => obj[v[0]] = Typed.value(v[1]).toJsonValue());
    return obj;
  }

  valueToString(): string {
    return "{" + this.value.map((v: [string, Typed<BusinessVariable>]) => v[0] + ": " + Typed.value(v[1]).valueToString()).join(", ") + "}";
  }

  valueToSimpleString(): string {
    return this.value.map((v: [string, Typed<BusinessVariable>]) => v[0] + ": " + Typed.value(v[1]).valueToSimpleString()).join(", ");
  }

  valueToSortableString(): string {
    return "{" + this.value.map((v: [string, Typed<BusinessVariable>]) => v[0] + ": " + Typed.value(v[1]).valueToSortableString()).join(", ") + "}";
  }


  constructor(value: Record<string, BusinessVariable>, id: ObjectId = ObjectId.next()) {
    this.value = [];
    this.id = id;
    for (const [key, v] of Object.entries(value)) {
      this.value.push([key, Typed.of(v)]);
    }
  }

  valueAsMap() {
    const v: { [fieldName: string]: BusinessVariable } = {};
    this.value.forEach((entry: [string, Typed<BusinessVariable>]) => {
      v[entry[0]] = <BusinessVariable>Typed.value(entry[1])
    });
    return v;
  }

  unsetValue(key: string) {
    this.value = this.value.filter((entry: [string, Typed<BusinessVariable>]) => entry[0] !== key);
  }

  setValue(key: string, value: BusinessVariable) {
    this.unsetValue(key);
    this.value.push([key, Typed.of(value)]);
  }

  getValueByPath(path: VariablePath): Option<BusinessVariable> {
    if (path.isRoot() && !path.headIsIndexed()) {
      return this.valueFor(path.headName());
    } else if (path.isRoot() && path.headIsIndexed()) {
      throw new Error("Cannot get field value in object that is indexed")
    } else {
      const fieldValueOption = this.valueFor(path.headName());
      if (fieldValueOption.isDefined()) {
        const fieldValue = fieldValueOption.get();
        if (path.headIsIndexed()) {
          if (fieldValue.className() === ArrayVariable.className) {
            return (<ArrayVariable<BusinessVariable>>fieldValue).getValueByPath(path.headNameIndex(), path.tail());
          } else {
            throw new Error("Expected Array but found " + fieldValue.className());
          }
        } else {
          if (fieldValue.className() === ObjectVariable.className) {
            return (<ObjectVariable>fieldValue).getValueByPath(path.tail());
          } else {
            throw new Error("Expected Object but found " + fieldValue.className());
          }
        }
      } else {
        throw new Error("Cannot get value by path because part of path does not exist in object, [" + path.toString() + "]");
      }
    }
  }

  setValueByPath(path: VariablePath, value: Option<BusinessVariable>) {
    if (path.isRoot() && !path.headIsIndexed()) {
      if (value.isDefined()) {
        this.setValue(path.headName(), value.get());
      } else {
        this.clearValue(path.headName());
      }
    } else if (path.isRoot() && path.headIsIndexed()) {
      throw new Error("Cannot set field value in object that is indexed")
    } else {
      const fieldValueOption = this.valueFor(path.headName());
      if (fieldValueOption.isDefined()) {
        const fieldValue = fieldValueOption.get();
        if (path.headIsIndexed()) {
          if (fieldValue.className() === ArrayVariable.className) {
            (<ArrayVariable<BusinessVariable>>fieldValue).setValueByPath(path.headNameIndex(), path.tail(), value);
          } else {
            throw new Error("Expected Array but found " + fieldValue.className());
          }
        } else {
          if (fieldValue.className() === ObjectVariable.className) {
            (<ObjectVariable>fieldValue).setValueByPath(path.tail(), value);
          } else {
            throw new Error("Expected Object but found " + fieldValue.className());
          }
        }
      } else {
        if(path.tail().headIsIndexed()) {
          throw new Error("Cannot set value by path because part of path does not exist in object, [" + path.toString() + "]");
        } else {
          const subObject = new ObjectVariable({});
          this.setValue(path.headName(), subObject);
          subObject.setValueByPath(path.tail(), value);
        }
      }
    }
  }

  clearValue(key: string) {
    this.unsetValue(key);
  }

  valueFor(key: string): Option<BusinessVariable> {
    return Option.of(this.value.filter((entry: [string, Typed<BusinessVariable>]) => entry[0] === key)[0])
      .map((entry: [string, Typed<BusinessVariable>]) => Typed.value(entry[1]));
  }

  singleValue(): Typed<BusinessVariable> {
    if (this.value.length === 1) {
      return this.value[0][1];
    } else {
      throw new Error("Incorrect number of entries");
    }
  }

  isEqual(other: BusinessVariable | undefined): boolean {
    if (this === other) {
      return true;
    } else if (other instanceof ObjectVariable) {
      if (this.value.length === other.value.length) {
        const fields = this.value.map(v => v[0]);
        return __(fields).all(field => this.valueFor(field).equals(other.valueFor(field), (a, b) => a.isEqual(b)));
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  compare(other: BusinessVariable) {
    if (other == undefined) {
      return 1;
    } else {
      return this.valueToSortableString().localeCompare(other.valueToSortableString(), undefined, {
        numeric: true,
        sensitivity: 'base'
      });
    }
  }


  static copy(other: ObjectVariable) {
    const c = new ObjectVariable({}, other.id ? ObjectId.copy(other.id) : ObjectId.next());
    c.value = other.value.map((entry: [string, Typed<BusinessVariable>]) =>
      <[string, Typed<BusinessVariable>]>[entry[0], BusinessVariableFactory.copyTyped(entry[1])]);
    return c;
  }

  copy() {
    return ObjectVariable.copy(this);
  }

  static fromRootVariables(variables: Array<RootVariable<BusinessVariable>>): ObjectVariable {
    const obj = new ObjectVariable({});
    obj.value = variables.map(v => <[string, Typed<BusinessVariable>]>[v.name, v.variable]);
    return obj;
  }

  findObjectById(objectId: ObjectId): Option<[VariablePath, ObjectVariable]> {
    const found = __(this.value).find((e: [string, Typed<BusinessVariable>]) => Typed.className(e[1]) === ObjectVariable.className && (<ObjectVariable>Typed.value(e[1])).id.id == objectId.id);
    if (found.isDefined()) {
      return found.map(f => <[VariablePath, ObjectVariable]>[VariablePath.root(found.get()[0]), <ObjectVariable>Typed.value(found.get()[1])]);
    } else {
      const foundInSubObjects = this.findObjectByIdInSubObjects(objectId);
      if (foundInSubObjects.isDefined()) {
        return foundInSubObjects
      } else {
        return this.findObjectByIdInSubArrays(objectId);
      }
    }
  }

  findObjectByIdInSubObjects(objectId: ObjectId): Option<[VariablePath, ObjectVariable]> {
    const subObjects = this.value.filter(v => Typed.className(v[1]) === ObjectVariable.className);
    let found: Option<[VariablePath, ObjectVariable]> = None();
    let i = 0;
    while (found.isEmpty() && i < subObjects.length) {
      const subObject = subObjects[i];
      found = (<ObjectVariable>Typed.value(subObject[1])).findObjectById(objectId).map(f => <[VariablePath, ObjectVariable]>[VariablePath.root(subObject[0]).concat(f[0]), f[1]]);
      i++;
    }
    return found;
  }

  findObjectByIdInSubArrays(objectId: ObjectId): Option<[VariablePath, ObjectVariable]> {
    const subArrays = this.value.filter(v => Typed.className(v[1]) === ArrayVariable.className);
    let found: Option<[VariablePath, ObjectVariable]> = None();
    let i = 0;

    while (found.isEmpty() && i < subArrays.length) {
      const subObject = subArrays[i];
      i++;
      found = (<ArrayVariable<BusinessVariable>>Typed.value(subObject[1])).findObjectById(objectId).map(f => <[VariablePath, ObjectVariable]>[VariablePath.root(subObject[0]).concat(f[0]), f[1]])
    }

    return found;
  }

  changeFieldName(oldName: string, newName: string) {
    if (this.valueFor(oldName).isDefined()) {
      if (this.valueFor(newName).isEmpty()) {
        this.setValue(newName, this.valueFor(oldName).get());
        this.clearValue(oldName);
      } else {
        throw new Error("Field " + newName + " is already defined");
      }
    }
  }

  toStringVariable(): StringVariable {
    return new StringVariable(this.valueToString());
  }

  bytesSize(): number {
    return 4;// + ___(this.value).map((t: [string, Typed<BusinessVariable>]) => t[0].length + Typed.value(t[1]).bytesSize()).sum();
  }

  keys() {
    return this.value.map(v => v[0]);
  }

  /** Returns the number of entries in the object */
  size() {
    return this.value.length;
  }

  clear() {
    this.value = [];
  }

  contains(name: string) {
    return this.valueFor(name).isDefined();
  }

}


export class NullVariable implements BusinessVariable {
  static className = "NullVariable";

  static instance = new NullVariable();

  className() {
    return NullVariable.className
  }

  toSearchable(): string {
    return "";
  }

  simpleValueType() {
    return 'Null';
  }

  value: any;

  isEmpty(): boolean {
    return true;
  }

  toJsonString(): string {
    return "null";
  }

  toJsonValue(): any {
    return null;
  }

  valueToString(): string {
    return "null";
  }

  valueToSimpleString(): string {
    return this.valueToString();
  }

  valueToSortableString(): string {
    return this.valueToString();
  }


  constructor() {
    this.value = undefined;
  }

  isEqual(other: BusinessVariable | undefined) {
    if (this === other) {
      return true;
    } else if (other == undefined || other.className() !== this.className()) {
      return false;
    } else {
      return other.value === this.value;
    }
  }

  compare(other: BusinessVariable) {
    if (other == undefined) {
      return 1;
    } else if (other.className() !== this.className()) {
      return this.valueToSimpleString().localeCompare(other.valueToSimpleString(), undefined, {
        numeric: true,
        sensitivity: 'base'
      });
    } else {
      return 0;
    }
  }

  copy() {
    return new NullVariable();
  }

  toStringVariable(): StringVariable {
    return new StringVariable(this.valueToString());
  }


  bytesSize(): number {
    return 4;
  }
}

export class TaskVariable implements BusinessVariable {
  static className = "TaskVariable";

  className() {
    return TaskVariable.className
  }

  toSearchable(): string {
    return "";
  }

  simpleValueType() {
    return 'Case';
  }

  value: string;

  isEmpty(): boolean {
    return this.valueToSimpleString().length === 0;
  }

  valueToString(): string {
    return "Task#" + this.value;
  }

  valueToSimpleString(): string {
    return this.valueToString();
  }

  valueToSortableString(): string {
    return this.valueToString();
  }

  toJsonString(): string {
    return `"Task#${this.value}"`;
  }

  toJsonValue(): any {
    return this.valueToSimpleString();
  }

  constructor(value: string) {
    this.value = value;
  }

  isEqual(other: BusinessVariable | undefined): boolean {
    if (this === other) {
      return true;
    } else if (other == undefined || other.className() !== this.className()) {
      return false;
    } else {
      return (<TaskVariable>other).value === this.value;
    }
  }

  compare(other: BusinessVariable) {
    if (other == undefined) {
      return 1;
    } else if (other.className() !== this.className()) {
      return this.valueToSimpleString().localeCompare(other.valueToSimpleString(), undefined, {
        numeric: true,
        sensitivity: 'base'
      });
    } else {
      return this.value.localeCompare(other.value.id, undefined, {numeric: true, sensitivity: 'base'});
    }
  }

  copy() {
    return new TaskVariable(this.value);
  }

  toStringVariable(): StringVariable {
    return new StringVariable(this.valueToString());
  }

  bytesSize(): number {
    return 4 + 6;
  }

}


export class CaseVariable implements BusinessVariable {
  static className = "CaseVariable";

  className() {
    return CaseVariable.className
  }

  toSearchable(): string {
    return "";
  }

  simpleValueType() {
    return 'Case';
  }

  value: FlowId;

  isEmpty(): boolean {
    return this.valueToSimpleString().length === 0;
  }

  valueToString(): string {
    return "Case#" + this.value.id;
  }

  valueToSimpleString(): string {
    return this.valueToString();
  }

  valueToSortableString(): string {
    return this.valueToString();
  }

  toJsonString(): string {
    return `"Case#${this.value.id}"`;
  }

  toJsonValue(): any {
    return this.valueToSimpleString();
  }

  constructor(value: FlowId) {
    this.value = value;
  }

  isEqual(other: BusinessVariable | undefined): boolean {
    if (this === other) {
      return true;
    } else if (other == undefined || other.className() !== this.className()) {
      return false;
    } else {
      return (<CaseVariable>other).value.id === this.value.id;
    }
  }

  compare(other: BusinessVariable) {
    if (other == undefined) {
      return 1;
    } else if (other.className() !== this.className()) {
      return this.valueToSimpleString().localeCompare(other.valueToSimpleString(), undefined, {
        numeric: true,
        sensitivity: 'base'
      });
    } else {
      return this.value.id.localeCompare(other.value.id, undefined, {numeric: true, sensitivity: 'base'});
    }
  }

  copy() {
    return new CaseVariable(FlowId.copy(this.value));
  }

  toStringVariable(): StringVariable {
    return new StringVariable(this.valueToString());
  }

  bytesSize(): number {
    return 4 + 6;
  }

}


export class CaseCodeVariable implements BusinessVariable {
  static className = "CaseCodeVariable";

  className() {
    return CaseVariable.className
  }

  toSearchable(): string {
    return "";
  }

  simpleValueType() {
    return 'CaseCode';
  }

  value: string;

  isEmpty(): boolean {
    return this.valueToSimpleString().length === 0;
  }

  valueToString(): string {
    return "CaseCode#" + this.value;
  }

  valueToSimpleString(): string {
    return this.valueToString();
  }

  valueToSortableString(): string {
    return this.valueToString();
  }

  toJsonString(): string {
    return `"CaseCode#${this.value}"`;
  }


  toJsonValue(): any {
    return this.valueToSimpleString();
  }

  constructor(value: string) {
    this.value = value;
  }

  isEqual(other: BusinessVariable | undefined): boolean {
    if (this === other) {
      return true;
    } else if (other == undefined || other.className() !== this.className()) {
      return false;
    } else {
      return (<CaseCodeVariable>other).value === this.value;
    }
  }

  compare(other: BusinessVariable) {
    if (other == undefined) {
      return 1;
    } else if (other.className() !== this.className()) {
      return this.valueToSimpleString().localeCompare(other.valueToSimpleString(), undefined, {
        numeric: true,
        sensitivity: 'base'
      });
    } else {
      return this.value.localeCompare(other.value.id, undefined, {numeric: true, sensitivity: 'base'});
    }
  }

  copy() {
    return new CaseCodeVariable(this.value);
  }

  toStringVariable(): StringVariable {
    return new StringVariable(this.valueToString());
  }

  bytesSize(): number {
    return 4 + 6;
  }

}


export class InstanceVariable implements BusinessVariable {
  static className = "InstanceVariable";


  className() {
    return InstanceVariable.className
  }

  toSearchable(): string {
    return "";
  }

  simpleValueType() {
    return 'Instance';
  }

  value: AggregateId;

  isEmpty(): boolean {
    return this.valueToSimpleString().length === 0;
  }

  valueToString(): string {
    return "Instance#" + this.value.id;
  }

  toJsonString(): string {
    return this.toJsonValue();
  }

  toJsonValue(): any {
    return this.valueToSimpleString();
  }

  valueToSimpleString(): string {
    return this.valueToString();
  }

  valueToSortableString(): string {
    return this.valueToString();
  }

  constructor(value: AggregateId) {
    this.value = value;
  }

  isEqual(other: BusinessVariable | undefined): boolean {
    if (this === other) {
      return true;
    } else if (other == undefined || other.className() !== this.className()) {
      return false;
    } else {
      return (<InstanceVariable>other).value.id === this.value.id;
    }
  }

  compare(other: BusinessVariable) {
    if (other == undefined) {
      return 1;
    } else if (other.className() !== this.className()) {
      return this.valueToSimpleString().localeCompare(other.valueToSimpleString(), undefined, {
        numeric: true,
        sensitivity: 'base'
      });
    } else {
      return this.value.id.localeCompare(other.value.id, undefined, {numeric: true, sensitivity: 'base'});
    }
  }

  copy() {
    return new InstanceVariable(this.value);
  }

  toStringVariable(): StringVariable {
    return new StringVariable(this.valueToString());
  }

  bytesSize(): number {
    return 4 + 6;
  }

}

export class ProcessVariable implements BusinessVariable {
  static className = "ProcessVariable";


  className() {
    return ProcessVariable.className
  }

  toSearchable(): string {
    return "";
  }

  simpleValueType() {
    return 'Process';
  }

  value: AggregateId;

  isEmpty(): boolean {
    return this.valueToSimpleString().length === 0;
  }

  valueToString(): string {
    return "Process#" + this.value.id;
  }

  toJsonString(): string {
    return `"Process#${this.value.id}"`;
  }

  toJsonValue(): any {
    return this.valueToSimpleString();
  }

  valueToSimpleString(): string {
    return this.valueToString();
  }

  valueToSortableString(): string {
    return this.valueToString();
  }

  constructor(value: AggregateId) {
    this.value = value;
  }

  isEqual(other: BusinessVariable | undefined): boolean {
    if (this === other) {
      return true;
    } else if (other == undefined || other.className() !== this.className()) {
      return false;
    } else {
      return (<ProcessVariable>other).value.id === this.value.id;
    }
  }

  compare(other: BusinessVariable) {
    if (other == undefined) {
      return 1;
    } else if (other.className() !== this.className()) {
      return this.valueToSimpleString().localeCompare(other.valueToSimpleString(), undefined, {
        numeric: true,
        sensitivity: 'base'
      });
    } else {
      return this.value.id.localeCompare(other.value.id, undefined, {numeric: true, sensitivity: 'base'});
    }
  }

  copy() {
    return new ProcessVariable(this.value);
  }

  toStringVariable(): StringVariable {
    return new StringVariable(this.valueToString());
  }

  bytesSize(): number {
    return 4 + 6;
  }


}

export class ProcessVersionVariable implements BusinessVariable {
  static className = "ProcessVersionVariable";

  toJsonString(): string {
    return `"ProcessVersion#${this.value.id}"`;
  }

  className() {
    return ProcessVersionVariable.className
  }

  toSearchable(): string {
    return "";
  }

  simpleValueType() {
    return 'ProcessVersion';
  }

  value: AggregateId;

  isEmpty(): boolean {
    return this.valueToSimpleString().length === 0;
  }

  valueToString(): string {
    return "ProcessVersion#" + this.value.id;
  }


  toJsonValue(): any {
    return this.valueToSimpleString();
  }

  valueToSimpleString(): string {
    return this.valueToString();
  }

  valueToSortableString(): string {
    return this.valueToString();
  }

  constructor(value: AggregateId) {
    this.value = value;
  }

  isEqual(other: BusinessVariable | undefined): boolean {
    if (this === other) {
      return true;
    } else if (other == undefined || other.className() !== this.className()) {
      return false;
    } else {
      return (<ProcessVersionVariable>other).value.id === this.value.id;
    }
  }

  compare(other: BusinessVariable) {
    if (other == undefined) {
      return 1;
    } else if (other.className() !== this.className()) {
      return this.valueToSimpleString().localeCompare(other.valueToSimpleString(), undefined, {
        numeric: true,
        sensitivity: 'base'
      });
    } else {
      return this.value.id.localeCompare(other.value.id, undefined, {numeric: true, sensitivity: 'base'});
    }
  }

  copy() {
    return new ProcessVersionVariable(this.value);
  }

  toStringVariable(): StringVariable {
    return new StringVariable(this.valueToString());
  }

  bytesSize(): number {
    return 4 + 6;
  }


}

export class NodeVariable implements BusinessVariable {
  static className = "NodeVariable";

  className() {
    return NodeVariable.className
  }

  toSearchable(): string {
    return "";
  }

  simpleValueType() {
    return 'Node';
  }

  value: string; //ReleaseId encrypted pipe NodeId

  getReleaseId() {
    const hash = this.value.indexOf("#");
    const pipe = this.value.indexOf("|");
    return new AggregateId(this.value.substring(hash + 1, pipe));
  }

  getNodeId() {
    const pipe = this.value.indexOf("|");
    return parseInt(this.value.substring(pipe + 1));
  }

  isEmpty(): boolean {
    return this.valueToSimpleString().length === 0;
  }

  valueToString(): string {
    return "Node#" + this.value;
  }

  valueToSimpleString(): string {
    return this.valueToString();
  }

  valueToSortableString(): string {
    const id = this.getNodeId();
    if (id < 10) {
      return "000" + id;
    } else if (id < 100) {
      return "00" + id;
    } else if (id < 1000) {
      return "0" + id
    } else {
      return "" + id;
    }
  }

  toJsonString(): string {
    return `"Node#${this.value}"`;
  }

  toJsonValue(): any {
    return this.valueToSimpleString();
  }

  constructor(value: string) {
    this.value = value;
  }

  isEqual(other: BusinessVariable | undefined): boolean {
    if (this === other) {
      return true;
    } else if (other == undefined || other.className() !== this.className()) {
      return false;
    } else {
      return (<NodeVariable>other).value === this.value;
    }
  }

  compare(other: BusinessVariable): number {
    if (other == undefined) {
      return 1;
    } else if (other instanceof NodeVariable) {
      return compareNumbers(this.getNodeId(), other.getNodeId());
    } else {
      return this.valueToSortableString().localeCompare(other.valueToSortableString(), undefined, {
        numeric: true,
        sensitivity: 'base'
      });
    }
  }

  copy() {
    return new NodeVariable(this.value);
  }

  toStringVariable(): StringVariable {
    return new StringVariable(this.valueToString());
  }

  bytesSize(): number {
    return 4 + this.value.length;
  }

}


export class ProcessRoleVariable implements BusinessVariable {
  static className = "ProcessRoleVariable";

  className() {
    return ProcessRoleVariable.className
  }

  toSearchable(): string {
    return "";
  }

  simpleValueType() {
    return 'ProcessRole';
  }

  value: string; //ReleaseId encrypted pipe ProcessRoleId

  getReleaseId() {
    const hash = this.value.indexOf("#");
    const pipe = this.value.indexOf("|");
    return new AggregateId(this.value.substring(hash + 1, pipe));
  }

  getProcessRoleId() {
    const pipe = this.value.indexOf("|");
    return parseInt(this.value.substring(pipe + 1));
  }

  isEmpty(): boolean {
    return this.valueToSimpleString().length === 0;
  }

  valueToString(): string {
    return "ProcessRole#" + this.value;
  }

  valueToSimpleString(): string {
    return this.valueToString();
  }

  valueToSortableString(): string {
    const id = this.getProcessRoleId();
    if (id < 10) {
      return "000" + id;
    } else if (id < 100) {
      return "00" + id;
    } else if (id < 1000) {
      return "0" + id
    } else {
      return "" + id;
    }
  }

  toJsonString(): string {
    return `"ProcessRole#${this.value}"`;
  }

  toJsonValue(): any {
    return this.valueToSimpleString();
  }

  constructor(value: string) {
    this.value = value;
  }

  isEqual(other: BusinessVariable | undefined): boolean {
    if (this === other) {
      return true;
    } else if (other == undefined || other.className() !== this.className()) {
      return false;
    } else {
      return (<ProcessRoleVariable>other).value === this.value;
    }
  }

  compare(other: BusinessVariable): number {
    if (other == undefined) {
      return 1;
    } else if (other instanceof ProcessRoleVariable) {
      return compareNumbers(this.getProcessRoleId(), other.getProcessRoleId());
    } else {
      return this.valueToSortableString().localeCompare(other.valueToSortableString(), undefined, {
        numeric: true,
        sensitivity: 'base'
      });
    }
  }

  copy() {
    return new ProcessRoleVariable(this.value);
  }

  toStringVariable(): StringVariable {
    return new StringVariable(this.valueToString());
  }

  bytesSize(): number {
    return 4 + this.value.length;
  }

}

export class CaseStatusVariable implements BusinessVariable {
  static className = "CaseStatusVariable";

  className() {
    return CaseStatusVariable.className
  }

  toSearchable(): string {
    return "";
  }

  simpleValueType() {
    return 'CaseStatus';
  }

  value: FlowStatus;

  isEmpty(): boolean {
    return this.valueToSimpleString().length === 0;
  }

  valueToString(): string {
    return this.value.name;
  }

  valueToSimpleString(): string {
    return this.valueToString();
  }

  valueToSortableString(): string {
    return this.valueToString();
  }

  toJsonString(): string {
    return `"${this.value.name}"`;
  }

  toJsonValue(): any {
    return this.valueToSimpleString();
  }

  constructor(value: FlowStatus) {
    this.value = value;
  }

  isEqual(other: BusinessVariable | undefined): boolean {
    if (this === other) {
      return true;
    } else if (other == undefined || other.className() !== this.className()) {
      return false;
    } else {
      return (<CaseStatusVariable>other).value.name === this.value.name;
    }
  }

  compare(other: BusinessVariable): number {
    if (other == undefined) {
      return 1;
    } else {
      return FlowStatus.compare(this.value, other.value);
    }
  }

  copy() {
    return new CaseStatusVariable(this.value);
  }

  toStringVariable(): StringVariable {
    return new StringVariable(this.valueToString());
  }

  bytesSize(): number {
    return 4 + 6;
  }

}

export class YearWeek {

  static millisInWeek = 7 * 24 * 60 * 60 * 1000;
  constructor(readonly year: number, readonly week: number) {
  }

  static of(week: {year: number, week: number}) {
    return new YearWeek(week.year, week.week);
  }

  static compare(a: YearWeek, b: YearWeek): number {
    if (a.year === b.year) {

      if (a.week === b.week) {
        return 0;
      } else {
        return a.week > b.week ? 1 : -1;
      }

    } else {
      return a.year > b.year ? 1 : -1;
    }

  }

  asMillisApproximation() {
    return millisOfYear(this.year) + (this.week - 1) * YearWeek.millisInWeek;
  }
}

export class WeekVariable implements BusinessVariable {
  static className = "WeekVariable";


  className() {
    return WeekVariable.className
  }

  toSearchable(): string {
    return "";
  }

  simpleValueType() {
    return 'Week';
  }

  value: YearWeek;

  isEmpty(): boolean {
    return this.valueToSimpleString().length === 0;
  }

  valueToString(): string {
    return this.value.year + " " + i18n("common_week_short") + " " + this.value.week;
  }


  toJsonString(): string {
    return JSON.stringify(this.valueToString());
  }

  toJsonValue(): any {
    return this.valueToSimpleString();
  }

  valueToSimpleString(): string {
    return this.valueToString();
  }

  valueToSortableString(): string {
    return this.valueToString();
  }

  constructor(value: YearWeek) {
    this.value = value;
  }

  isEqual(other: BusinessVariable | undefined): boolean {
    if (this === other) {
      return true;
    } else if (other == undefined || other.className() !== this.className()) {
      return false;
    } else {
      return (<WeekVariable>other).value.year === this.value.year && (<WeekVariable>other).value.week === this.value.week;
    }
  }

  compare(other: BusinessVariable) {
    if (other == undefined) {
      return 1;
    } else {
      return YearWeek.compare(this.value, other.value);
    }
  }

  copy() {
    return new WeekVariable(this.value);
  }

  toStringVariable(): StringVariable {
    return new StringVariable(this.valueToString());
  }

  bytesSize(): number {
    return 4 + 8;
  }


}

export class YearMonth {
  constructor(readonly year: number, readonly month: number) {
  }

  static compare(a: YearMonth, b: YearMonth): number {
    if (a.year === b.year) {

      if (a.month === b.month) {
        return 0;
      } else {
        return a.month > b.month ? 1 : -1;
      }

    } else {
      return a.year > b.year ? 1 : -1;
    }

  }

  asMillisApproximation() {
    return millisOfYear(this.year) + (this.month - 1) * 30 * 24 * 60 * 60 * 1000;
  }
}

export class MonthVariable implements BusinessVariable {
  static className = "MonthVariable";

  className() {
    return MonthVariable.className
  }

  toSearchable(): string {
    return "";
  }

  simpleValueType() {
    return 'Month';
  }

  value: YearMonth;

  isEmpty(): boolean {
    return this.valueToSimpleString().length === 0;
  }

  valueToString(): string {
    return this.value.year + "-" + toDoubleDigit(this.value.month);
  }

  valueToSimpleString(): string {
    return this.valueToString();
  }

  valueToSortableString(): string {
    return this.valueToString();
  }

  toJsonString(): string {
    return JSON.stringify(this.valueToString());
  }

  toJsonValue(): any {
    return this.valueToSimpleString();
  }

  constructor(value: YearMonth) {
    this.value = value;
  }

  isEqual(other: BusinessVariable | undefined): boolean {
    if (this === other) {
      return true;
    } else if (other == undefined || other.className() !== this.className()) {
      return false;
    } else {
      return (<MonthVariable>other).value.year === this.value.year && (<MonthVariable>other).value.month === this.value.month;
    }
  }

  compare(other: BusinessVariable) {
    if (other == undefined) {
      return 1;
    } else {
      return YearMonth.compare(this.value, other.value);
    }
  }

  copy() {
    return new MonthVariable(this.value);
  }

  toStringVariable(): StringVariable {
    return new StringVariable(this.valueToString());
  }

  bytesSize(): number {
    return 4 + 8;
  }

}


export class YearQuarter {
  constructor(readonly year: number, readonly quarter: number) {
  }

  static compare(a: YearQuarter, b: YearQuarter): number {
    if (a.year === b.year) {

      if (a.quarter === b.quarter) {
        return 0;
      } else {
        return a.quarter > b.quarter ? 1 : -1;
      }

    } else {
      return a.year > b.year ? 1 : -1;
    }

  }

  asMillisApproximation() {
    return millisOfYear(this.year) + (this.quarter - 1) * 3 * 30 * 24 * 60 * 60 * 1000;
  }
}

export class QuarterVariable implements BusinessVariable {
  static className = "QuarterVariable";

  className() {
    return QuarterVariable.className
  }

  toSearchable(): string {
    return "";
  }

  simpleValueType() {
    return 'Quarter';
  }

  value: YearQuarter;

  isEmpty(): boolean {
    return this.valueToSimpleString().length === 0;
  }

  valueToString(): string {
    return this.value.year + " "+i18n("common_quarter_short") + " "+this.value.quarter;
  }

  valueToSimpleString(): string {
    return this.valueToString();
  }

  valueToSortableString(): string {
    return this.valueToString();
  }

  toJsonString(): string {
    return JSON.stringify(this.valueToString());
  }

  toJsonValue(): any {
    return this.valueToSimpleString();
  }

  constructor(value: YearQuarter) {
    this.value = value;
  }

  isEqual(other: BusinessVariable | undefined): boolean {
    if (this === other) {
      return true;
    } else if (other == undefined || other.className() !== this.className()) {
      return false;
    } else {
      return (<QuarterVariable>other).value.year === this.value.year && (<QuarterVariable>other).value.quarter === this.value.quarter;
    }
  }

  compare(other: BusinessVariable) {
    if (other == undefined) {
      return 1;
    } else {
      return YearQuarter.compare(this.value, other.value);
    }
  }

  copy() {
    return new QuarterVariable(this.value);
  }

  toStringVariable(): StringVariable {
    return new StringVariable(this.valueToString());
  }

  bytesSize(): number {
    return 4 + 8;
  }

}

export class DateHours {
  constructor(readonly date: LocalDate, readonly hours: number) {
  }

  static compare(a: DateHours, b: DateHours): number {
    if (a.date.isEqual(b.date)) {
      if (a.hours === b.hours) {
        return 0;
      } else {
        return a.hours > b.hours ? 1 : -1;
      }
    } else {
      return a.date.isAfter(b.date) ? 1 : -1;
    }
  }

  static copy(other: DateHours): DateHours {
    return new DateHours(LocalDate.copy(other.date), other.hours);
  }

  static fromDateTime(dateTime: LocalDateTime): DateHours {
    return new DateHours(dateTime.date, dateTime.time.hour);
  }

  toLocalDateTime() {
    return new LocalDateTime(this.date, new LocalTime(this.hours, 0, 0, 0));
  }
}

export class DateHoursVariable implements BusinessVariable {
  static className = "DateHoursVariable";

  className() {
    return DateHoursVariable.className
  }


  toSearchable(): string {
    return "";
  }

  simpleValueType() {
    return 'DateHours';
  }

  value: DateHours;

  isEmpty(): boolean {
    return this.valueToSimpleString().length === 0;
  }


  toJsonString(): string {
    return JSON.stringify(this.valueToString());
  }

  toJsonValue(): any {
    return this.valueToSimpleString();
  }

  valueToString(): string {
    return this.value.date.formatted() + " " + i18n("common_hour_short") + " " + toFixedLengthString(this.value.hours, 2);
  }

  valueToSimpleString(): string {
    return this.valueToString();
  }

  valueToSortableString(): string {
    return this.valueToString();
  }

  constructor(value: DateHours) {
    this.value = value;
  }

  isEqual(other: BusinessVariable | undefined): boolean {
    if (this === other) {
      return true;
    } else if (other == undefined || other.className() !== this.className()) {
      return false;
    } else {
      return (<DateHoursVariable>other).compare(this) === 0;
    }
  }

  compare(other: BusinessVariable) {
    if (other == undefined) {
      return 1;
    } else {
      return DateHours.compare(this.value, other.value);
    }
  }

  copy() {
    return new DateHoursVariable(this.value);
  }

  toStringVariable(): StringVariable {
    return new StringVariable(this.valueToString());
  }


  bytesSize(): number {
    return 4 + 12;
  }
}

export class DateMinutes {
  constructor(readonly date: LocalDate, readonly hours: number, readonly minutes: number) {
  }

  static compare(a: DateMinutes, b: DateMinutes): number {
    if (a.date.isEqual(b.date)) {
      if (a.hours === b.hours) {
        if (a.minutes === b.minutes) {
          return 0;
        } else {
          return a.minutes > b.minutes ? 1 : -1;
        }
      } else {
        return a.hours > b.hours ? 1 : -1;
      }
    } else {
      return a.date.isAfter(b.date) ? 1 : -1;
    }
  }

  static copy(other: DateMinutes): DateMinutes {
    return new DateMinutes(LocalDate.copy(other.date), other.hours, other.minutes);
  }

  static fromDateTime(dateTime: LocalDateTime): DateMinutes {
    return new DateMinutes(dateTime.date, dateTime.time.hour, dateTime.time.minute);
  }

  toLocalDateTime() {
    return new LocalDateTime(this.date, new LocalTime(this.hours, this.minutes, 0, 0));
  }
}

export class DateMinutesVariable implements BusinessVariable {
  static className = "DateMinutesVariable";


  className() {
    return DateMinutesVariable.className
  }

  toSearchable(): string {
    return "";
  }

  simpleValueType() {
    return 'DateMinutes';
  }

  value: DateMinutes;

  isEmpty(): boolean {
    return this.valueToSimpleString().length === 0;
  }


  toJsonString(): string {
    return JSON.stringify(this.valueToString());
  }

  toJsonValue(): any {
    return this.valueToSimpleString();
  }

  valueToString(): string {
    return this.value.date.formatted() + " " +
      toFixedLengthString(this.value.hours, 2) + ":" + toFixedLengthString(this.value.minutes, 2);
  }

  valueToSimpleString(): string {
    return this.valueToString();
  }

  valueToSortableString(): string {
    return this.valueToString();
  }

  constructor(value: DateMinutes) {
    this.value = value;
  }

  isEqual(other: BusinessVariable | undefined): boolean {
    if (this === other) {
      return true;
    } else if (other == undefined || other.className() !== this.className()) {
      return false;
    } else {
      return (<DateMinutesVariable>other).compare(this) === 0;
    }
  }

  compare(other: BusinessVariable) {
    if (other == undefined) {
      return 1;
    } else {
      return DateMinutes.compare(this.value, other.value);
    }
  }

  copy() {
    return new DateMinutesVariable(this.value);
  }

  toStringVariable(): StringVariable {
    return new StringVariable(this.valueToString());
  }

  bytesSize(): number {
    return 4 + 12;
  }
}

export class DateSeconds {
  constructor(readonly date: LocalDate, readonly hours: number, readonly minutes: number, readonly seconds: number) {
  }

  static compare(a: DateSeconds, b: DateSeconds): number {
    if (a.date.isEqual(b.date)) {
      if (a.hours === b.hours) {
        if (a.minutes === b.minutes) {
          if (a.seconds === b.seconds) {
            return 0;
          } else {
            return a.seconds > b.seconds ? 1 : -1;
          }
        } else {
          return a.minutes > b.minutes ? 1 : -1;
        }
      } else {
        return a.hours > b.hours ? 1 : -1;
      }
    } else {
      return a.date.isAfter(b.date) ? 1 : -1;
    }
  }

  static copy(other: DateSeconds): DateSeconds {
    return new DateSeconds(LocalDate.copy(other.date), other.hours, other.minutes, other.seconds);
  }

  static fromDateTime(dateTime: LocalDateTime): DateSeconds {
    return new DateSeconds(dateTime.date, dateTime.time.hour, dateTime.time.minute, dateTime.time.second);
  }

  toLocalDateTime() {
    return new LocalDateTime(this.date, new LocalTime(this.hours, this.minutes, this.seconds, 0));
  }
}

export class DateSecondsVariable implements BusinessVariable {
  static className = "DateSecondsVariable";

  className() {
    return DateSecondsVariable.className
  }

  toSearchable(): string {
    return "";
  }

  simpleValueType() {
    return 'DateSeconds';
  }

  value: DateSeconds;

  isEmpty(): boolean {
    return this.valueToSimpleString().length === 0;
  }

  valueToString(): string {
    return this.value.date.formatted() + " " + toFixedLengthString(this.value.hours, 2) + ":" +
      toFixedLengthString(this.value.minutes, 2) + ":" + toFixedLengthString(this.value.seconds, 2);
  }

  valueToSimpleString(): string {
    return this.valueToString();
  }

  valueToSortableString(): string {
    return this.valueToString();
  }

  toJsonString(): string {
    return JSON.stringify(this.valueToString());
  }


  toJsonValue(): any {
    return this.valueToSimpleString();
  }

  constructor(value: DateSeconds) {
    this.value = value;
  }

  isEqual(other: BusinessVariable | undefined): boolean {
    if (this === other) {
      return true;
    } else if (other == undefined || other.className() !== this.className()) {
      return false;
    } else {
      return (<DateSecondsVariable>other).compare(this) === 0;
    }
  }

  compare(other: BusinessVariable) {
    if (other == undefined) {
      return 1;
    } else {
      return DateSeconds.compare(this.value, other.value);
    }
  }

  copy() {
    return new DateSecondsVariable(this.value);
  }

  toStringVariable(): StringVariable {
    return new StringVariable(this.valueToString());
  }

  bytesSize(): number {
    return 4 + 12;
  }
}


export class EmailVariable implements BusinessVariable {
  static className = "EmailVariable";


  className() {
    return EmailVariable.className
  }

  toSearchable(): string {
    return "";
  }

  simpleValueType() {
    return 'Email';
  }

  value: FileUri;

  isEmpty(): boolean {
    return this.valueToSimpleString().length === 0;
  }

  valueToString(): string {
    if (this.value == null) {
      return ""
    } else {
      return "Email#" + this.value.serialize();
    }
  }

  toJsonString(): string {
    return JSON.stringify(this.valueToString());
  }

  toJsonValue(): any {
    return this.valueToSimpleString();
  }

  valueToSimpleString(): string {
    return this.valueToString();
  }

  valueToSortableString(): string {
    return this.valueToString();
  }

  constructor(value: FileUri) {
    this.value = value;
  }

  isEqual(other: BusinessVariable | undefined): boolean {
    if (this === other) {
      return true;
    } else if (other == undefined || other.className() !== this.className()) {
      return false;
    } else {
      return this.value.isEqual(other.value);
    }
  }

  compare(other: BusinessVariable) {
    if (other == undefined) {
      return 1;
    } else if (other.className() !== this.className()) {
      return this.valueToSimpleString().localeCompare(other.valueToSimpleString(), undefined, {
        numeric: true,
        sensitivity: 'base'
      });
    } else {
      return (this.value.protocol.name + "://" + this.value.path).localeCompare(other.value.protocol.name + "://" + other.value.path, undefined, {
        numeric: true,
        sensitivity: 'base'
      });
    }
  }

  copy() {
    return new EmailVariable(FileUri.copy(this.value));
  }

  toStringVariable(): StringVariable {
    return new StringVariable(this.valueToString());
  }

  bytesSize(): number {
    return 4 + 6;
  }


}


export class BusinessVariableFactory {
  static copy(variable: BusinessVariable): BusinessVariable {
    return BusinessVariableFactory.copyByType(variable, variable.className());
  }

  static copyTyped(variable: Typed<BusinessVariable>): Typed<BusinessVariable> {
    return Typed.of(BusinessVariableFactory.copyByType(Typed.value(variable), Typed.className(variable)));
  }

  static copyByType(variable: BusinessVariable, className: string): BusinessVariable {
    if (className.substr(className.length - 2) === "V1") {
      // FIXME ugly workaround - sometimes we receive versioned frozen events from server
      className = className.substr(0, className.length - 2);
    }
    switch (className) {
      case StringVariable.className:
        return new StringVariable(<string>variable.value);
      case PasswordVariable.className:
        return new PasswordVariable(new PasswordValue((<PasswordVariable>variable).value.password));
      case I18nTextVariable.className:
        return new I18nTextVariable(I18nText.copy(<I18nText>variable.value));
      case NumberVariable.className:
        return new NumberVariable(<number>variable.value);
      case BooleanVariable.className:
        return new BooleanVariable(<boolean>variable.value);
      case DateVariable.className:
        return new DateVariable(LocalDate.copy(<LocalDate>variable.value));
      case DateTimeVariable.className:
        return new DateTimeVariable(LocalDateTime.copy(<LocalDateTime>variable.value));
      case TimeVariable.className:
        return new TimeVariable(LocalTime.copy(<LocalTime>variable.value));
      case IntervalVariable.className:
        return new IntervalVariable(Duration.copy(<Duration>variable.value));
      case GeoCoordinateVariable.className:
        return new GeoCoordinateVariable(GeoCoordinate.copy(<GeoCoordinate>variable.value));
      case FileVariableV2.className:
        return new FileVariableV2(FileUri.copy(<FileUri>variable.value));
      case PersonVariable.className:
        return PersonVariable.copy(<PersonVariable>variable);
      case DepartmentVariable.className:
        return new DepartmentVariable(DepartmentId.copy(variable.value));
      case GroupVariable.className:
        return new GroupVariable(GroupId.copy(variable.value));
      case ArrayVariable.className:
        return ArrayVariable.copy(<ArrayVariable<BusinessVariable>>variable);
      case ObjectVariable.className:
        return ObjectVariable.copy(<ObjectVariable>variable);
      case NullVariable.className:
        return new NullVariable();
      case TaskVariable.className:
        return new TaskVariable(<string>variable.value);
      case CaseVariable.className:
        return new CaseVariable(FlowId.copy(variable.value));
      case CaseCodeVariable.className:
        return new CaseCodeVariable(variable.value);
      case InstanceVariable.className:
        return new InstanceVariable(AggregateId.copy(variable.value));
      case ProcessVariable.className:
        return new ProcessVariable(AggregateId.copy(variable.value));
      case ProcessVersionVariable.className:
        return new ProcessVersionVariable(AggregateId.copy(variable.value));
      case NodeVariable.className:
        return new NodeVariable(<string>variable.value);
      case CaseStatusVariable.className:
        return new CaseStatusVariable(FlowStatus.copy(variable.value));
      case WeekVariable.className:
        return new WeekVariable(<YearWeek>variable.value);
      case MonthVariable.className:
        return new MonthVariable(<YearMonth>variable.value);
      case DateHoursVariable.className:
        return new DateHoursVariable(DateHours.copy(variable.value));
      case DateMinutesVariable.className:
        return new DateMinutesVariable(DateMinutes.copy(variable.value));
      case DateSecondsVariable.className:
        return new DateSecondsVariable(DateSeconds.copy(variable.value));
      case LinkVariable.className:
        return new LinkVariable(Link.copy(variable.value));
      case EmailVariable.className:
        return new EmailVariable(FileUri.copy(<FileUri>variable.value));
      case BusinessEntityVariable.className:
        return new BusinessEntityVariable(BusinessEntityIdWithType.copy(variable.value))
      case BusinessEntityVersionVariable.className:
        return new BusinessEntityVersionVariable(AggregateId.copy(variable.value))
      default:
        throw new Error("Unsupported variable class: " + className + ", variable: " + JSON.stringify(variable));
    }
  }
}


export class RootVariable<V extends BusinessVariable> {

  constructor(readonly name: string,
              readonly variable: Typed<V>) {
  }

  static copy(other: RootVariable<any>) {
    return new RootVariable(other.name, BusinessVariableFactory.copyTyped(other.variable));
  }

  unwrappedVariable(): V {
    return Typed.value<V>(this.variable);
  }
}


export class ContextVariable<V extends BusinessVariable> {

  constructor(readonly context: Option<ObjectId>,
              readonly location: VariablePath,
              readonly variable: Typed<V>) {
  }

  static copy(other: ContextVariable<any>) {
    return new ContextVariable(Option.copy(other.context), VariablePath.copy(other.location), BusinessVariableFactory.copyTyped(other.variable))
  }

  unwrappedVariable(): V {
    return Typed.value<V>(this.variable);
  }

  isRootVariable() {
    return this.context.isEmpty() && this.location.isRoot();
  }

  nonRootVariable() {
    return this.context.isDefined() || this.location.nonRoot();
  }

  toContextPath() {
    return new ContextPath(this.context, this.location);
  }
}

export class ContextVariableType<T extends BusinessVariableType> {

  constructor(readonly location: VariableTypePath,
              readonly variableType: Typed<T>) {
  }

  static copy(other: ContextVariableType<any>) {
    return new ContextVariableType(VariableTypePath.copy(other.location), BusinessVariableTypeFactory.copyTyped(other.variableType));
  }

  unwrappedType(): T {
    return Typed.value<T>(this.variableType);
  }

  isRootVariableType() {
    return this.location.path.length === 1;
  }

  nonRootVariable() {
    return this.location.path.length > 1;
  }
}

export class RootVariableType<T extends BusinessVariableType> {

  constructor(public name: string,
              public variableType: Typed<T>) {
  }

  unwrappedVariableType(): T {
    return Typed.value<T>(this.variableType);
  }

  variableClassName(): string {
    return Typed.className(this.variableType);
  }

  variableTypeName(): string {
    return Typed.value<BusinessVariableType>(this.variableType).typeName();
  }

  static copy(other: RootVariableType<any>) {
    return new RootVariableType(other.name, BusinessVariableTypeFactory.copyTyped(other.variableType));
  }
}

export class RootVariableWithType<V extends BusinessVariable, T extends BusinessVariableType> {
  constructor(readonly name: string,
              public variable: Option<Typed<V>>,
              readonly variableType: Typed<T>) {
  }

  unwrappedVariableOption(): Option<V> {
    return this.variable.map(v => Typed.value<V>(v));
  }

  unwrappedVariableType(): T {
    return Typed.value<T>(this.variableType);
  }

  setVariable(variable: Option<V>): void {
    this.variable = variable.map(v => Typed.of(v));
  }

  static copy(other: RootVariableWithType<BusinessVariable, BusinessVariableType>) {
    return new RootVariableWithType(other.name, Option.copy(other.variable).map(v => BusinessVariableFactory.copyTyped(v)), BusinessVariableTypeFactory.copyTyped(other.variableType));
  }

  static mergeVariables(variablesTypes: RootVariableType<BusinessVariableType>[], variables: RootVariable<BusinessVariable>[]) {
    return variablesTypes.map(vt => new RootVariableWithType(vt.name, Option.of(variables.filter(v => v.name === vt.name)[0]).map(v => v.variable), vt.variableType));
  }
}

export type BusinessVariableTypeName = "String"|"I18nText"|"Password"|"Number"|"Boolean"|"Date"|"DateTime"|"Time"|"Interval"|"GeoCoordinate"|"Link"|"File"|
                                       "OrganizationNode"|"Person"|"Department"|"Group"|"Array"|"Object"|"Task"|"BusinessEntity"|"Case"|"CaseCode"|
                                       "CaseStatus"|"Instance"|"Process"|"ProcessVersion"|"Node"|"Email"|"ProcessRole";

export abstract class BusinessVariableType {
  abstract className(): string;

  abstract typeName(): BusinessVariableTypeName;

  abstract simpleFriendlyTypeName(): string;

  friendlyTypeName(): string {
    return this.simpleFriendlyTypeName();
  }

  abstract defaultValue(): BusinessVariable|null;

  /** Compares everything */
  abstract isEqual(value: BusinessVariableType): boolean;

  /** Compares element type, Object fields, but not constraints */
  abstract typeEqual(other: BusinessVariableType): boolean;

  isObject() {
    return false;
  }

  isArray() {
    return false;
  }

  isBusinessEntity() {
    return false;
  }

  asArray(): ArrayVariableType<BusinessVariableType> {
    throw new Error("Not an array");
  }

  asObject(): ObjectVariableType {
    throw new Error("Not an object");
  }

  asBusinessEntity(): BusinessEntityVariableType {
    throw new Error("Not a business object");
  }
}

export class StringVariableType extends BusinessVariableType {

  static className = "StringVariableType";

  className() {
    return StringVariableType.className
  }

  defaultValue() {
    return new StringVariable("");
  }

  static typeName: BusinessVariableTypeName = "String";

  typeName() {
    return StringVariableType.typeName;
  }

  simpleFriendlyTypeName() {
    return i18n("business_model_variable_String");
  }

  isEqual(other: BusinessVariableType): boolean {
    if (other.className() === StringVariableType.className) {
      return true
    } else {
      return false;
    }
  }

  typeEqual(other: BusinessVariableType): boolean {
    return other.className() === StringVariableType.className;
  }

  static empty() {
    return new StringVariableType();
  }
}


export class I18nTextVariableType extends BusinessVariableType {

  static className = "I18nTextVariableType";

  className() {
    return I18nTextVariableType.className
  }

  defaultValue() {
    return new I18nTextVariable(I18nText.empty());
  }

  static typeName: BusinessVariableTypeName = "I18nText";

  typeName() {
    return I18nTextVariableType.typeName;
  }

  simpleFriendlyTypeName() {
    return i18n("business_model_variable_I18nText");
  }



  isEqual(other: BusinessVariableType): boolean {
    if (other.className() === I18nTextVariableType.className) {
      return true
    } else {
      return false;
    }
  }

  typeEqual(other: BusinessVariableType): boolean {
    return other.className() === I18nTextVariableType.className;
  }

  static empty() {
    return new I18nTextVariableType();
  }
}


export class PasswordVariableType extends BusinessVariableType {

  static className = "PasswordVariableType";

  className() {
    return PasswordVariableType.className
  }

  defaultValue() {
    return new PasswordVariable(new PasswordValue(""));
  }

  static typeName: BusinessVariableTypeName = "Password";

  typeName() {
    return PasswordVariableType.typeName;
  }

  simpleFriendlyTypeName() {
    return i18n("business_model_variable_Password");
  }

  isEqual(other: BusinessVariableType): boolean {
    if (other.className() === PasswordVariableType.className) {
      return true
    } else {
      return false;
    }
  }

  typeEqual(other: BusinessVariableType): boolean {
    return other.className() === PasswordVariableType.className;
  }

  static empty() {
    return new PasswordVariableType();
  }
}


export class NumberVariableType extends BusinessVariableType {
  static className = "NumberVariableType";

  className() {
    return NumberVariableType.className
  }

  percent: boolean;
  precision: number;
  unit: Option<string>;

  constructor(percent: boolean = false, precision: number = 0, unit: Option<string> = None()) {
    super();
    this.percent = percent;
    this.precision = precision;
    this.unit = Option.copy(unit);
  }

  defaultValue() {
    return new NumberVariable(0);
  }

  static typeName: BusinessVariableTypeName = "Number";

  typeName() {
    return NumberVariableType.typeName;
  }

  simpleFriendlyTypeName() {
    return i18n("business_model_variable_Number");
  }

  private countDecimalPlaces(value: number) {
    const str = "" + value;
    const index = str.indexOf('.');
    if (index >= 0) {
      return str.length - index - 1;
    } else {
      return 0;
    }
  }

  isEqual(other: BusinessVariableType): boolean {
    if (other.className() === NumberVariableType.className) {
      const otherV = <NumberVariableType>other;
      return otherV.percent === this.percent &&
        otherV.precision === this.precision &&
        otherV.unit.equals(this.unit);
    } else {
      return false;
    }
  }

  typeEqual(other: BusinessVariableType): boolean {
    return other.className() === NumberVariableType.className;
  }

  static empty() {
    return new NumberVariableType();
  }

}


export class BooleanVariableType extends BusinessVariableType {
  static className = "BooleanVariableType";

  className() {
    return BooleanVariableType.className
  }

  defaultValue() {
    return new BooleanVariable(false);
  }

  static empty() {
    return new BooleanVariableType();
  }

  static typeName: BusinessVariableTypeName = "Boolean";

  typeName() {
    return BooleanVariableType.typeName;
  }

  simpleFriendlyTypeName() {
    return i18n("business_model_variable_Boolean");
  }

  isEqual(other: BusinessVariableType): boolean {
    if (other.className() === BooleanVariableType.className) {
      const otherV = <BooleanVariableType>other;
      return true;
    } else {
      return false;
    }
  }

  typeEqual(other: BusinessVariableType): boolean {
    return other.className() === BooleanVariableType.className;
  }

}

export class DateVariableType extends BusinessVariableType {
  static className = "DateVariableType";

  className() {
    return DateVariableType.className
  }

  defaultValue() {
    return new DateVariable(new LocalDate(2000, 1, 1));
  }

  static empty(): DateVariableType {
    return new DateVariableType();
  }

  static typeName: BusinessVariableTypeName = "Date";

  typeName() {
    return DateVariableType.typeName;
  }

  simpleFriendlyTypeName() {
    return i18n("business_model_variable_Date");
  }

  isEqual(other: BusinessVariableType): boolean {
    if (other.className() === DateVariableType.className) {
      return true
    } else {
      return false;
    }
  }


  typeEqual(other: BusinessVariableType): boolean {
    return other.className() === DateVariableType.className;
  }

}

export class DateTimeVariableType extends BusinessVariableType {
  static className = "DateTimeVariableType";

  className() {
    return DateTimeVariableType.className
  }

  defaultValue() {
    return new DateTimeVariable(new LocalDateTime(new LocalDate(2000, 1, 1), new LocalTime(12, 0, 0, 0)));
  }

  static typeName: BusinessVariableTypeName = "DateTime";

  typeName() {
    return DateTimeVariableType.typeName;
  }

  simpleFriendlyTypeName() {
    return i18n("business_model_variable_DateTime");
  }

  static empty(): DateTimeVariableType {
    return new DateTimeVariableType();
  }

  isEqual(other: BusinessVariableType): boolean {
    if (other.className() === DateTimeVariableType.className) {
      return true
    } else {
      return false;
    }
  }

  typeEqual(other: BusinessVariableType): boolean {
    return other.className() === DateTimeVariableType.className;
  }

}

export class TimeVariableType extends BusinessVariableType {
  static className = "TimeVariableType";

  className() {
    return TimeVariableType.className
  }

  defaultValue() {
    return new TimeVariable(new LocalTime(12, 0, 0, 0));
  }

  static empty() {
    return new TimeVariableType();
  }

  static typeName: BusinessVariableTypeName = "Time";

  typeName() {
    return TimeVariableType.typeName;
  }

  simpleFriendlyTypeName() {
    return i18n("business_model_variable_Time");
  }

  isEqual(other: BusinessVariableType): boolean {
    if (other.className() === TimeVariableType.className) {
      const otherV = <TimeVariableType>other;
      return true;
    } else {
      return false;
    }
  }

  typeEqual(other: BusinessVariableType): boolean {
    return other.className() === TimeVariableType.className;
  }

}

export class IntervalVariableType extends BusinessVariableType {
  static className = "IntervalVariableType";

  className() {
    return IntervalVariableType.className
  }

  defaultValue() {
    return new IntervalVariable(new Duration(0, 0));
  }

  static typeName: BusinessVariableTypeName = "Interval";

  typeName() {
    return IntervalVariableType.typeName;
  }

  simpleFriendlyTypeName() {
    return i18n("business_model_variable_Interval");
  }


  isEqual(other: BusinessVariableType): boolean {
    if (other.className() === IntervalVariableType.className) {
      const otherV = <IntervalVariableType>other;
      return true;
    } else {
      return false;
    }
  }

  typeEqual(other: BusinessVariableType): boolean {
    return other.className() === IntervalVariableType.className;
  }

}

export class GeoCoordinateVariableType extends BusinessVariableType {
  static className = "GeoCoordinateVariableType";

  className() {
    return GeoCoordinateVariableType.className
  }

  defaultValue() {
    return new GeoCoordinateVariable(new GeoCoordinate(0, 0));
  }

  static typeName: BusinessVariableTypeName = "GeoCoordinate";

  typeName() {
    return GeoCoordinateVariableType.typeName;
  }

  simpleFriendlyTypeName() {
    return i18n("business_model_variable_GeoCoordinate");
  }

  isEqual(other: GeoCoordinateVariableType): boolean {
    if (other.className() === GeoCoordinateVariableType.className) {
      const otherV = <GeoCoordinateVariableType>other;
      return true;
    } else {
      return false;
    }
  }

  typeEqual(other: BusinessVariableType): boolean {
    return other.className() === GeoCoordinateVariableType.className;
  }

}

export class LinkVariableType extends BusinessVariableType {
  static className = "LinkVariableType";

  className() {
    return LinkVariableType.className
  }

  defaultValue() {
    return new LinkVariable(new Link("", ""));
  }

  static typeName: BusinessVariableTypeName = "Link";

  typeName() {
    return LinkVariableType.typeName;
  }

  simpleFriendlyTypeName() {
    return i18n("business_model_variable_Link");
  }

  isEqual(other: LinkVariableType): boolean {
    if (other.className() === LinkVariableType.className) {
      const otherV = <LinkVariableType>other;
      return true;
    } else {
      return false;
    }
  }

  typeEqual(other: BusinessVariableType): boolean {
    return other.className() === LinkVariableType.className;
  }

}


export class FileVariableType extends BusinessVariableType {
  static className = "FileVariableType";

  className() {
    return FileVariableType.className
  }

  defaultValue(): FileVariableV2|null {
    return null;
  }

  static empty() {
    return new FileVariableType();
  }

  static typeName: BusinessVariableTypeName = "File";

  typeName() {
    return FileVariableType.typeName;
  }

  simpleFriendlyTypeName() {
    return i18n("business_model_variable_File");
  }

  isEqual(other: BusinessVariableType): boolean {
    if (other.className() === FileVariableType.className) {
      return true;
    } else {
      return false;
    }
  }

  typeEqual(other: BusinessVariableType): boolean {
    return other.className() === FileVariableType.className;
  }

}

export class OrganizationNodeVariableType extends BusinessVariableType {
  static className = "OrganizationNodeVariableType";

  className() {
    return OrganizationNodeVariableType.className
  }

  defaultValue(): OrganizationNodeVariable|null {
    return null;
  }

  static empty() {
    return new OrganizationNodeVariableType();
  }

  static typeName: BusinessVariableTypeName = "OrganizationNode";

  typeName() {
    return OrganizationNodeVariableType.typeName;
  }

  simpleFriendlyTypeName() {
    return i18n("business_model_variable_OrganizationNode");
  }

  isEqual(other: BusinessVariableType): boolean {
    return other.className() === OrganizationNodeVariableType.className;
  }

  typeEqual(other: BusinessVariableType): boolean {
    return other.className() === OrganizationNodeVariableType.className;
  }

}

export class PersonVariableType extends BusinessVariableType {
  static className = "PersonVariableType";

  className() {
    return PersonVariableType.className
  }

  defaultValue(): PersonVariable|null {
    return  null;
  }

  static empty() {
    return new PersonVariableType();
  }

  static typeName: BusinessVariableTypeName = "Person";

  typeName() {
    return PersonVariableType.typeName;
  }

  simpleFriendlyTypeName() {
    return i18n("business_model_variable_Person");
  }

  isEqual(other: BusinessVariableType): boolean {
    return other.className() === PersonVariableType.className;
  }

  typeEqual(other: BusinessVariableType): boolean {
    return other.className() === PersonVariableType.className;
  }

}

export class DepartmentVariableType extends BusinessVariableType {
  static className = "DepartmentVariableType";

  className() {
    return DepartmentVariableType.className
  }

  defaultValue(): DepartmentVariable|null {
    return null;
  }

  static empty() {
    return new DepartmentVariableType();
  }

  static typeName: BusinessVariableTypeName = "Department";

  typeName() {
    return DepartmentVariableType.typeName;
  }
  simpleFriendlyTypeName() {
    return i18n("business_model_variable_Department");
  }

  isEqual(other: BusinessVariableType): boolean {
    return other.className() === DepartmentVariableType.className;
  }

  typeEqual(other: BusinessVariableType): boolean {
    return other.className() === DepartmentVariableType.className;
  }

}

export class GroupVariableType extends BusinessVariableType {
  static className = "GroupVariableType";

  className() {
    return GroupVariableType.className
  }

  defaultValue(): GroupVariable|null {
    return null;
  }

  static empty() {
    return new GroupVariableType();
  }

  static typeName: BusinessVariableTypeName = "Group";

  typeName() {
    return GroupVariableType.typeName;
  }

  simpleFriendlyTypeName() {
    return i18n("business_model_variable_Group");
  }

  isEqual(other: BusinessVariableType): boolean {
    return other.className() === GroupVariableType.className;
  }

  typeEqual(other: BusinessVariableType): boolean {
    return other.className() === GroupVariableType.className;
  }

}


export class ArrayVariableType<T extends BusinessVariableType> extends BusinessVariableType {
  static className = "ArrayVariableType";

  className() {
    return ArrayVariableType.className
  }

  subType: Typed<T>;

  constructor(subType: Typed<T>) {
    super();
    this.subType = subType;
  }

  static of<T extends BusinessVariableType>(subType: T): ArrayVariableType<T> {
    return new ArrayVariableType(Typed.of(subType));
  }

  defaultValue() {
    return new ArrayVariable([]);
  }

  subtypeUnwrapped(): T {
    return Typed.value<T>(this.subType);
  }

  static typeName: BusinessVariableTypeName = "Array";

  typeName() {
    return ArrayVariableType.typeName;
  }

  simpleFriendlyTypeName() {
    return i18n("business_model_variable_Array");
  }

  override friendlyTypeName(): string {
    return i18n("business_model_variable_Array_of", {"type": Typed.value(this.subType).friendlyTypeName()});
  }

  override isArray(): boolean {
    return true;
  }

  override asArray(): ArrayVariableType<BusinessVariableType> {
    return this;
  }

  isEqual(other: BusinessVariableType): boolean {
    return this.typeEqual(other);
  }

  typeEqual(other: BusinessVariableType): boolean {
    return other.className() === ArrayVariableType.className && (<ArrayVariableType<BusinessVariableType>>other).subtypeUnwrapped().typeEqual(this.subtypeUnwrapped());
  }


  addFieldByPath(path: VariableTypePath, fieldType: BusinessVariableType): number {
    if (this.subtypeUnwrapped().className() === ArrayVariableType.className) {
      return (<ArrayVariableType<BusinessVariableType>><any>this.subtypeUnwrapped()).addFieldByPath(path, fieldType);
    } else if (this.subtypeUnwrapped().className() === ObjectVariableType.className) {
      return (<ObjectVariableType><any>this.subtypeUnwrapped()).addFieldByPath(path, fieldType);
    } else {
      return -1;
    }
  }
}

export class ObjectVariableSubType {
  constructor(public dataType: Typed<BusinessVariableType>,
              public name: I18nText) {}

  static copy(other: ObjectVariableSubType) {
    return new ObjectVariableSubType(BusinessVariableTypeFactory.copyTyped(other.dataType), I18nText.copy(other.name));
  }

  dataTypeUnwrapped() {
    return Typed.value(this.dataType);
  }

  static of(fieldType: BusinessVariableType): ObjectVariableSubType {
    return new ObjectVariableSubType(Typed.of(fieldType), I18nText.empty());
  }
}

export class NamedField {
  constructor(readonly identifier: string,
              readonly name: I18nText,
              readonly dataType: BusinessVariableType) {}
}

export class ObjectVariableType extends BusinessVariableType {
  static className = "ObjectVariableType";

  className() {
    return ObjectVariableType.className
  }

  fields: Array<[string, ObjectVariableSubType]>;

  constructor(fields: Array<[string, ObjectVariableSubType]>) {
    super();
    this.fields = fields;
  }

  static copy(other: ObjectVariableType): ObjectVariableType {
    return new ObjectVariableType(other.fields.map((s: [string, ObjectVariableSubType]) => <[string, ObjectVariableSubType]>[s[0], ObjectVariableSubType.copy(s[1])]))
  }

  override asObject() {
    return this;
  }

  defaultValue() {
    return new ObjectVariable({});
  }

  fieldTypeByName(fieldName: string): Option<BusinessVariableType> {
    return Option.of(this.fields.filter(s => s[0] === fieldName)[0]).map(r => r[1].dataTypeUnwrapped());
  }

  fieldTypeByPath(path: VariableTypePath): Option<BusinessVariableType> {
    const variableAtHead = __(this.fields).find(field => field[0] === path.head());
    if (path.isRoot()) {
      return variableAtHead.map(tuple => tuple[1].dataTypeUnwrapped());
    } else {
      return variableAtHead.flatMap(tuple => {
        if (Typed.className(tuple[1].dataType) === ObjectVariableType.className) {
          return (<ObjectVariableType>tuple[1].dataTypeUnwrapped()).fieldTypeByPath(path.tail());
        } else {
          return None()
        }
      })
    }
  }


  override isObject(): boolean {
    return true;
  }

  static typeName: BusinessVariableTypeName = "Object";

  typeName() {
    return ObjectVariableType.typeName;
  }

  simpleFriendlyTypeName() {
    return i18n("business_model_variable_Object");
  }

  isEqual(other: BusinessVariableType): boolean {
    return this.typeEqual(other);
  }

  typeEqual(other: BusinessVariableType): boolean {
    return other.className() === ObjectVariableType.className && this.fieldsTypesEqual(<ObjectVariableType>other);
  }

  addField(fieldName: string, fieldType: BusinessVariableType): number {
    const newField: [string, ObjectVariableSubType] = [fieldName, ObjectVariableSubType.of(fieldType)];
    this.fields = this.fields.filter(type => type[0] !== fieldName);
    this.fields.push(newField);
    return this.fields.length - 1;
  }

  addFieldByPath(path: VariableTypePath, fieldType: BusinessVariableType): number {
    if (path.isRoot()) {
      return this.addField(path.head(), fieldType)
    } else if (!path.isEmpty()) {
      return __(this.fields).find(entry => entry[0] === path.head()).map(entry => {
        const dataType = entry[1].dataType;
        if (Typed.className(dataType) === ObjectVariableType.className) {
          return (<ObjectVariableType>Typed.value(dataType)).addFieldByPath(path.tail(), fieldType);
        } else if (Typed.className(dataType) === ArrayVariableType.className) {
          return (<ArrayVariableType<BusinessVariableType>>Typed.value(dataType)).addFieldByPath(path.tail(), fieldType);
        } else {
          return -1;
        }
      }).getOrElse(-1);
    }
    return -1;
  }

  addFieldAtIndex(fieldName: string, fieldType: BusinessVariableType, index: number) {
    const newField: [string, ObjectVariableSubType] = [fieldName, ObjectVariableSubType.of(fieldType)];
    this.fields.splice(index, 0, newField);
  }

  addFieldByPathAtIndexAtPath(path: VariableTypePath, fieldType: BusinessVariableType, index: number) {
    if (path.isRoot()) {
      return this.addFieldAtIndex(path.head(), fieldType, index)
    } else if (!path.isEmpty()) {
      __(this.fields).find(entry => entry[0] === path.head()).forEach(entry => {
        const dataType = entry[1].dataType;
        if (Typed.className(dataType) === ObjectVariableType.className) {
          (<ObjectVariableType>Typed.value(dataType)).addFieldByPathAtIndexAtPath(path.tail(), fieldType, index)
        }
      });
    }
  }

  moveField(fromIndex: number, toIndex: number) {
    arrayMove(this.fields, fromIndex, toIndex);
  }

  removeField(fieldName: string) {
    const index = __(this.fields).findIndexOf(entry => entry[0] === fieldName);
    index.forEach(idx => this.fields.splice(idx, 1));
  }

  removeFieldAtIndex(index: number) {
    this.fields.splice(index, 1);
  }

  removeFieldByPath(path: VariableTypePath) {
    if (path.isRoot()) {
      this.removeField(path.head())
    } else if (!path.isEmpty()) {
      __(this.fields).find(entry => entry[0] === path.head()).forEach(entry => {
        const dataType = entry[1].dataTypeUnwrapped();
        if (dataType instanceof ObjectVariableType) {
          dataType.removeFieldByPath(path.tail());
        } else if (dataType instanceof ArrayVariableType && dataType.subtypeUnwrapped() instanceof ObjectVariableType) {
          dataType.subtypeUnwrapped().removeFieldByPath(path.tail());
        }
      })
    }
  }

  removeFieldBydPathAtIndex(path: VariableTypePath, index: number) {
    if (path.isEmpty()) {
      this.removeFieldAtIndex(index)
    } else if (!path.isEmpty()) {
      __(this.fields).find(entry => entry[0] === path.head()).forEach(entry => {
        const dataType = entry[1].dataType;
        if (Typed.className(dataType) === ObjectVariableType.className) {
          (<ObjectVariableType>Typed.value(dataType)).removeFieldBydPathAtIndex(path.tail(), index)
        }
      })
    }
  }

  renameField(oldName: string, newName: string) {
    __(this.fields).find(entry => entry[0] === oldName).forEach(entry => entry[0] = newName);
  }

  renameFieldByPath(path: VariableTypePath, newName: string) {
    if (path.isRoot()) {
      this.renameField(path.head(), newName)
    } else if (!path.isEmpty()) {
      __(this.fields).find(entry => entry[0] === path.head()).forEach(entry => {
        const dataType = entry[1].dataType;
        if (Typed.className(dataType) === ObjectVariableType.className) {
          (<ObjectVariableType>Typed.value(dataType)).renameFieldByPath(path.tail(), newName)
        }
      });
    }
  }

  changeFieldType(name: string, newType: Typed<BusinessVariableType>) {
    __(this.fields).find(entry => entry[0] === name).forEach(entry => entry[1].dataType = newType);
  }

  changeFieldTypeByPath(path: VariableTypePath, newType: Typed<BusinessVariableType>) {
    if (path.isRoot()) {
      this.changeFieldType(path.head(), newType)
    } else if (!path.isEmpty()) {
      __(this.fields).find(entry => entry[0] === path.head()).forEach(entry => {
        const dataType = entry[1].dataType;
        if (Typed.className(dataType) === ObjectVariableType.className) {
          (<ObjectVariableType>Typed.value(dataType)).changeFieldTypeByPath(path.tail(), newType)
        }
      });
    }
  }

  findIndexByPath(path: VariableTypePath): Option<number> {
    if (path.isRoot()) {
      return __(this.fields).findIndexOf(field => field[0] === path.head())
    } else {
      return __(this.fields).find(entry => entry[0] === path.head()).flatMap(entry => {
        if (Typed.className(entry[1].dataType) === ObjectVariableType.className) {
          return (<ObjectVariableType>Typed.value(entry[1].dataType)).findIndexByPath(path.tail())
        } else {
          return None();
        }
      });
    }
  }

  private fieldsTypesEqual(other: ObjectVariableType): boolean {
    const thisFields = this.fields.map(s => s[0]).sort();
    const otherFields = this.fields.map(s => s[0]).sort();

    // const sameFieldsNames = thisFields.length === otherFields.length && _.intersection(thisFields, otherFields).length === otherFields.length;

    // return sameFieldsNames && _.every(thisFields, (field: string) => this.fieldTypeByName(field).value.typeEqual(other.fieldTypeByName(field).value));
    return true;
  }

  fieldsFlatView(): Array<NamedField> {
    return this.fields.map(f => new NamedField(f[0], f[1].name, f[1].dataTypeUnwrapped()));
  }

  fieldByIdentifier(identifier: string): ObjectVariableSubType|undefined {
    const field = this.fields.find(f => f[0] === identifier);
    return field ? field[1] : undefined;
  }
}

export class TaskVariableType extends BusinessVariableType {
  static className = "TaskVariableType";

  className() {
    return TaskVariableType.className
  }

  static typeName: BusinessVariableTypeName = "Task";

  typeName() {
    return TaskVariableType.typeName;
  }

  simpleFriendlyTypeName() {
    return i18n("business_model_variable_Task");
  }

  defaultValue(): TaskVariable|null {
    return null;
  }

  isEqual(other: BusinessVariableType): boolean {
    return other.className() === TaskVariableType.className;
  }

  typeEqual(other: BusinessVariableType): boolean {
    return other.className() === TaskVariableType.className;
  }

  static empty(): TaskVariableType {
    return new TaskVariableType();
  }

}

export class BusinessEntityVariableType extends BusinessVariableType {
  static className = "BusinessEntityVariableType";
  static typeName: BusinessVariableTypeName = "BusinessEntity";

  className() {
    return BusinessEntityVariableType.className
  }

  constructor(readonly businessVariableTypeId: BusinessEntityTypeId) {
    super();
  }

  typeName() {
    return BusinessEntityVariableType.typeName;
  }

  simpleFriendlyTypeName(): string {
    return i18n("business_model_variable_BusinessEntity");
  }

  defaultValue(): CaseVariable|null {
    return null;
  }

  isEqual(other: BusinessVariableType): boolean {
    return other.className() === BusinessEntityVariableType.className && this.businessVariableTypeId.id === other.asBusinessEntity().businessVariableTypeId.id;
  }

  typeEqual(other: BusinessVariableType): boolean {
    return other.className() === BusinessEntityVariableType.className && this.businessVariableTypeId.id === other.asBusinessEntity().businessVariableTypeId.id;
  }

  static copy(other: BusinessEntityVariableType) {
    return new BusinessEntityVariableType(BusinessEntityTypeId.copy(other.businessVariableTypeId));
  }


  override isBusinessEntity(): boolean {
    return true;
  }

  override asBusinessEntity(): BusinessEntityVariableType {
    return this;
  }
}

export class CaseVariableType extends BusinessVariableType {
  static className = "CaseVariableType";

  className() {
    return CaseVariableType.className
  }

  static typeName: BusinessVariableTypeName = "Case";

  typeName() {
    return CaseVariableType.typeName;
  }

  simpleFriendlyTypeName(): string {
    return i18n("business_model_variable_Case");
  }

  defaultValue(): CaseVariable|null {
    return null;
  }

  isEqual(other: BusinessVariableType): boolean {
    return other.className() === CaseVariableType.className;
  }

  typeEqual(other: BusinessVariableType): boolean {
    return other.className() === CaseVariableType.className;
  }

  static empty(): CaseVariableType {
    return new CaseVariableType();
  }

}


export class CaseCodeVariableType extends BusinessVariableType {
  static className = "CaseCodeVariableType";

  className() {
    return CaseCodeVariableType.className
  }

  static typeName: BusinessVariableTypeName = "CaseCode";

  typeName() {
    return CaseCodeVariableType.typeName;
  }

  simpleFriendlyTypeName() {
    return i18n("business_model_variable_CaseCode");
  }

  defaultValue(): CaseVariable|null {
    return null;
  }

  isEqual(other: BusinessVariableType): boolean {
    return other.className() === CaseCodeVariableType.className;
  }

  typeEqual(other: BusinessVariableType): boolean {
    return other.className() === CaseCodeVariableType.className;
  }

  static empty(): CaseCodeVariableType {
    return new CaseCodeVariableType();
  }

}

export class CaseStatusVariableType extends BusinessVariableType {
  static className = "CaseStatusVariableType";

  className() {
    return CaseStatusVariableType.className
  }

  static typeName: BusinessVariableTypeName = "CaseStatus";

  typeName() {
    return CaseStatusVariableType.typeName;
  }

  simpleFriendlyTypeName() {
    return i18n("business_model_variable_CaseStatus");
  }

  defaultValue(): CaseStatusVariable|null {
    return null;
  }

  isEqual(other: BusinessVariableType): boolean {
    return other.className() === CaseStatusVariableType.className;
  }

  typeEqual(other: BusinessVariableType): boolean {
    return other.className() === CaseStatusVariableType.className;
  }

}


export class InstanceVariableType extends BusinessVariableType {
  static className = "InstanceVariableType";

  className() {
    return InstanceVariableType.className
  }

  static typeName: BusinessVariableTypeName = "Instance";

  typeName() {
    return InstanceVariableType.typeName;
  }

  simpleFriendlyTypeName() {
    return i18n("business_model_variable_Instance");
  }

  defaultValue(): InstanceVariable|null {
    return null;
  }

  isEqual(other: BusinessVariableType): boolean {
    return other.className() === InstanceVariableType.className;
  }

  typeEqual(other: BusinessVariableType): boolean {
    return other.className() === InstanceVariableType.className;
  }

}

export class ProcessVariableType extends BusinessVariableType {
  static className = "ProcessVariableType";

  className() {
    return ProcessVariableType.className
  }

  static typeName: BusinessVariableTypeName = "Process";

  typeName() {
    return ProcessVariableType.typeName;
  }

  simpleFriendlyTypeName() {
    return i18n("business_model_variable_Process");
  }

  defaultValue(): ProcessVariable|null {
    return null;
  }

  isEqual(other: BusinessVariableType): boolean {
    return other.className() === ProcessVariableType.className;
  }

  typeEqual(other: BusinessVariableType): boolean {
    return other.className() === ProcessVariableType.className;
  }

}

export class ProcessVersionVariableType extends BusinessVariableType {
  static className = "ProcessVersionVariableType";

  className() {
    return ProcessVersionVariableType.className
  }

  static typeName: BusinessVariableTypeName = "ProcessVersion";

  typeName() {
    return ProcessVersionVariableType.typeName;
  }


  simpleFriendlyTypeName() {
    return i18n("business_model_variable_ProcessVersion");
  }

  defaultValue(): ProcessVersionVariable|null {
    return null;
  }

  isEqual(other: BusinessVariableType): boolean {
    return other.className() === ProcessVersionVariableType.className;
  }

  typeEqual(other: BusinessVariableType): boolean {
    return other.className() === ProcessVersionVariableType.className;
  }
}

export class NodeVariableType extends BusinessVariableType {
  static className = "NodeVariableType";

  className() {
    return NodeVariableType.className
  }

  static typeName: BusinessVariableTypeName = "Node";

  typeName() {
    return NodeVariableType.typeName;
  }

  simpleFriendlyTypeName() {
    return i18n("business_model_variable_Node");
  }


  defaultValue(): NodeVariable|null {
    return null;
  }

  isEqual(other: BusinessVariableType): boolean {
    return other.className() === NodeVariableType.className;
  }

  typeEqual(other: BusinessVariableType): boolean {
    return other.className() === NodeVariableType.className;
  }

}

export class ProcessRoleVariableType extends BusinessVariableType {
  static className = "ProcessRoleVariableType";

  className() {
    return ProcessRoleVariableType.className
  }

  static typeName: BusinessVariableTypeName = "ProcessRole";

  typeName() {
    return ProcessRoleVariableType.typeName;
  }

  simpleFriendlyTypeName() {
    return i18n("business_model_variable_Role");
  }


  defaultValue(): ProcessRoleVariable|null {
    return null;
  }

  isEqual(other: BusinessVariableType): boolean {
    return other.className() === ProcessRoleVariableType.className;
  }

  typeEqual(other: BusinessVariableType): boolean {
    return other.className() === ProcessRoleVariableType.className;
  }

}

export class EmailVariableType extends BusinessVariableType {
  static className = "EmailVariableType";

  className() {
    return EmailVariableType.className
  }

  static typeName: BusinessVariableTypeName = "Email";

  typeName() {
    return EmailVariableType.typeName;
  }

  simpleFriendlyTypeName(): string {
    return i18n("business_model_variable_Email");
  }



  defaultValue(): EmailVariable|null {
    return null;
  }

  isEqual(other: BusinessVariableType): boolean {
    return other.className() === EmailVariableType.className;
  }

  typeEqual(other: BusinessVariableType): boolean {
    return other.className() === EmailVariableType.className;
  }

}

export class BusinessVariableTypeFactory {
  static copy(variable: BusinessVariableType): BusinessVariableType {
    return BusinessVariableTypeFactory.copyByType(variable, variable.className());
  }

  static copyTyped(variable: Typed<BusinessVariableType>): Typed<BusinessVariableType> {
    return Typed.of(BusinessVariableTypeFactory.copyByType(Typed.value(variable), Typed.className(variable)));
  }

  static copyByType(variable: BusinessVariableType, className: string): BusinessVariableType {
    switch (className) {
      case StringVariableType.className:
        return new StringVariableType();
      case PasswordVariableType.className:
        return new PasswordVariableType();
      case I18nTextVariableType.className:
        return new I18nTextVariableType();
      case NumberVariableType.className: {
        const v: NumberVariableType = <NumberVariableType>variable;
        return new NumberVariableType(v.percent, v.precision, v.unit);
      }
      case BooleanVariableType.className:
        return new BooleanVariableType();
      case DateVariableType.className:
        return new DateVariableType();
      case DateTimeVariableType.className:
        return new DateTimeVariableType();
      case TimeVariableType.className:
        return new TimeVariableType();
      case IntervalVariableType.className:
        return new IntervalVariableType();
      case GeoCoordinateVariableType.className:
        return new GeoCoordinateVariableType();
      case FileVariableType.className:
        return new FileVariableType();
      case PersonVariableType.className:
        return new PersonVariableType();
      case DepartmentVariableType.className:
        return new DepartmentVariableType();
      case GroupVariableType.className:
        return new GroupVariableType();
      case OrganizationNodeVariableType.className:
        return new OrganizationNodeVariableType();
      case ArrayVariableType.className: {
        const v: ArrayVariableType<any> = <ArrayVariableType<any>>variable;
        return new ArrayVariableType<any>(BusinessVariableTypeFactory.copyTyped(v.subType));
      }
      case ObjectVariableType.className: {
        const v: ObjectVariableType = <ObjectVariableType>variable;
        return ObjectVariableType.copy(v);
      }
      case TaskVariableType.className:
        return new TaskVariableType();
      case CaseVariableType.className:
        return new CaseVariableType();
      case CaseCodeVariableType.className:
        return new CaseCodeVariableType();
      case CaseStatusVariableType.className:
        return new CaseStatusVariableType();
      case InstanceVariableType.className:
        return new InstanceVariableType();
      case ProcessVariableType.className:
        return new ProcessVariableType();
      case ProcessVersionVariableType.className:
        return new ProcessVersionVariableType();
      case NodeVariableType.className:
        return new NodeVariableType();
      case ProcessRoleVariableType.className:
        return new ProcessRoleVariableType();
      case LinkVariableType.className:
        return new LinkVariableType();
      case EmailVariableType.className:
        return new EmailVariableType();
      case BusinessEntityVariableType.className: {
        const v: BusinessEntityVariableType = <BusinessEntityVariableType>variable;
        return BusinessEntityVariableType.copy(v);
      }
      default:
        throw new Error("Unsupported variable: " + JSON.stringify(variable));
    }
  }


  static createFromVariable(variable: BusinessVariable): BusinessVariableType {
    switch (variable.className()) {
      case StringVariable.className: {
        return new StringVariableType();
      }
      case I18nTextVariable.className: {
        return new I18nTextVariableType();
      }
      case PasswordVariable.className: {
        return new PasswordVariableType();
      }
      case NumberVariable.className: {
        return new NumberVariableType();
      }
      case BooleanVariable.className:
        return new BooleanVariableType();
      case DateVariable.className: {
        return new DateVariableType();
      }
      case DateTimeVariable.className: {
        return new DateTimeVariableType();
      }
      case TimeVariable.className:
        return new TimeVariableType();
      case IntervalVariable.className:
        return new IntervalVariableType();
      case GeoCoordinateVariable.className:
        return new GeoCoordinateVariableType();
      case FileVariableV2.className: {
        return new FileVariableType();
      }
      case PersonVariable.className:
        return new PersonVariableType();
      case DepartmentVariable.className:
        return new DepartmentVariableType();
      case GroupVariable.className:
        return new GroupVariableType();
      case ArrayVariable.className: {
        let variables = (<ArrayVariable<BusinessVariable>>variable).unwrappedValue();
        return ArrayVariableType.of(variables.length > 0 ? BusinessVariableTypeFactory.createFromVariable(variables[0]) : new StringVariableType());
      }
      case ObjectVariable.className: {
        let obj = <ObjectVariable>variable;
        return new ObjectVariableType(obj.value.map(v => [v[0], ObjectVariableSubType.of(BusinessVariableTypeFactory.createFromVariable(Typed.value(v[1])))]));
      }
      case TaskVariable.className:
        return new TaskVariableType();
      case CaseVariable.className:
        return new CaseVariableType();
      case CaseCodeVariable.className:
        return new CaseCodeVariableType();
      case CaseStatusVariable.className:
        return new CaseStatusVariableType();
      case InstanceVariable.className:
        return new InstanceVariableType();
      case ProcessVariable.className:
        return new ProcessVariableType();
      case ProcessVersionVariable.className:
        return new ProcessVersionVariableType();
      case NodeVariable.className:
        return new NodeVariableType();
      case ProcessRoleVariable.className:
        return new ProcessRoleVariableType();
      case LinkVariable.className:
        return new LinkVariableType();
      case EmailVariable.className:
        return new EmailVariableType();
      case BusinessEntityVariable.className:
        return new BusinessEntityVariableType(new BusinessEntityTypeId((<BusinessEntityVariable>variable).value.typeId));
      default:
        throw new Error("Unsupported variable: " + JSON.stringify(variable));
    }
  }

}
