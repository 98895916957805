import {AggregateId, AnyFlowId, DirectoryId, EmailId, encodeFilePath, FileId, FlowId, None, Option, Some} from "@utils";
import {ProcessNodeId} from "@shared-model";

export class UriTaskFormFieldInfo {
  constructor(readonly flowId: AnyFlowId,
              readonly nodeId: ProcessNodeId,
              readonly uri: FileUri,
              readonly formSectionRefId: number,
              readonly fieldRefId: number,
              readonly contextObjectId: Option<string>) {
  }
}

export class UriFlowInfo {

  constructor(readonly flowId: AnyFlowId,
              readonly uri: FileUri) {
  }
}

export class FileProtocol {
  constructor(readonly name: string) {
  }

  static InternalFile = new FileProtocol("internal");
  static RepositoryFile = new FileProtocol("file");
  static RepositoryFileId = new FileProtocol("fileId");
  static RepositoryDir = new FileProtocol("dir");
  static RepositoryDirId = new FileProtocol("dirId");
  static Https = new FileProtocol("https");
  static Http = new FileProtocol("http");
  static EmailId = new FileProtocol("emailId");
  static EmailAttachment = new FileProtocol("emailAttachment");

  // Authorization providers
  static Report = new FileProtocol("report");
  static Flow = new FileProtocol("flow");
  static TaskFormField = new FileProtocol("taskFormField");
  static Mailbox = new FileProtocol("mailbox");

  static copy(other: FileProtocol) {
    switch (other.name) {
      case FileProtocol.InternalFile.name:
        return FileProtocol.InternalFile;
      case FileProtocol.RepositoryFile.name:
        return FileProtocol.RepositoryFile;
      case FileProtocol.RepositoryDir.name:
        return FileProtocol.RepositoryDir;
      case FileProtocol.RepositoryFileId.name:
        return FileProtocol.RepositoryFileId;
      case FileProtocol.RepositoryDirId.name:
        return FileProtocol.RepositoryDirId;
      case FileProtocol.Https.name:
        return FileProtocol.Https;
      case FileProtocol.Http.name:
        return FileProtocol.Http;
      case FileProtocol.Report.name:
        return FileProtocol.Report;
      case FileProtocol.Flow.name:
        return FileProtocol.Flow;
      case FileProtocol.TaskFormField.name:
        return FileProtocol.TaskFormField;
      case FileProtocol.Mailbox.name:
        return FileProtocol.Mailbox;
      case FileProtocol.EmailId.name:
        return FileProtocol.EmailId;
      case FileProtocol.EmailAttachment.name:
        return FileProtocol.EmailAttachment;
      default:
        throw new Error("Unsupported file variable protocol [" + other.name + "]");
    }
  }

  static parse(protocol: string) {
    return FileProtocol.copy(new FileProtocol(protocol));
  }
}

export class FileUri {
  constructor(readonly protocol: FileProtocol, readonly path: string) {
  }


  internalFileId() {
    if (this.protocol.name === FileProtocol.InternalFile.name) {
      return parseInt(this.path);
    } else {
      throw new Error("No fileId for [" + this.protocol.name + "]");
    }
  }

  isAnyFile(): boolean {
    return this.isInternalFile() || this.isRepositoryFile() || this.isRepositoryFileId() || this.isReport() || this.isFlow() || this.isTaskFormField() || this.isEmailAttachment();
  }

  isAnyDir(): boolean {
    return this.isRepositoryDir() || this.isRepositoryDirId();
  }

  isInternalFile(): boolean {
    return this.protocol.name === FileProtocol.InternalFile.name;
  }

  isRepositoryFile(): boolean {
    return this.protocol.name === FileProtocol.RepositoryFile.name;
  }

  isRepositoryFileId(): boolean {
    return this.protocol.name === FileProtocol.RepositoryFileId.name;
  }

  isRepositoryDir(): boolean {
    return this.protocol.name === FileProtocol.RepositoryDir.name;
  }

  isRepositoryDirId(): boolean {
    return this.protocol.name === FileProtocol.RepositoryDirId.name;
  }


  isReport(): boolean {
    return this.protocol.name === FileProtocol.Report.name;
  }


  isTaskFormField(): boolean {
    return this.protocol.name === FileProtocol.TaskFormField.name;
  }

  isMailbox(): boolean {
    return this.protocol.name === FileProtocol.Mailbox.name;
  }

  isFlow(): boolean {
    return this.protocol.name === FileProtocol.Flow.name;
  }

  isEmail() {
    return this.protocol.name === FileProtocol.EmailId.name;
  }

  isEmailAttachment() {
    return this.protocol.name === FileProtocol.EmailAttachment.name;
  }

  isHttpFile() {
    return this.protocol.name === FileProtocol.Http.name;
  }

  isHttpsFile() {
    return this.protocol.name === FileProtocol.Https.name;
  }

  static copy(other: FileUri) {
    return new FileUri(FileProtocol.copy(other.protocol), other.path);
  }

  isEqual(other: FileUri) {
    return this.protocol === other.protocol &&
      this.path === other.path;
  }

  serialize() {
    return this.protocol.name + "://" + this.path;
  }

  static fromEmail(id: EmailId): FileUri {
    return new FileUri(FileProtocol.EmailId, id.id);
  }

  static fromEmailAttachment(id: EmailId, attachmentId: number): FileUri {
    return new FileUri(FileProtocol.EmailAttachment, id.id + "/" + attachmentId);
  }

  static fromDirectoryId(id: DirectoryId): FileUri {
    return new FileUri(FileProtocol.RepositoryDirId, id.id.id);
  }

  static fromFileId(id: FileId): FileUri {
    return new FileUri(FileProtocol.RepositoryFileId, id.id.id);
  }

  static fromDirectoryPath(path: string): FileUri {
    if(path.startsWith("/@")) {
      const remaining = path.substring(2);
      if(remaining.indexOf("/") < 0) {
        return new FileUri(FileProtocol.RepositoryDirId, remaining.substring(1));
      } else {
        throw new Error("Invalid directory id path [" + remaining + "]");
      }
    } else {
      return new FileUri(FileProtocol.RepositoryDir, path);
    }
  }

  static fromFilePath(path: string): FileUri {
    return new FileUri(FileProtocol.RepositoryFile, path);
  }

  static fromEmailId(id: EmailId): FileUri {
    return new FileUri(FileProtocol.EmailId, id.id);
  }

  static taskFormField(uri: FileUri, flowId: AnyFlowId, nodeId: ProcessNodeId, formSectionRefId: number, fieldRefId: number, contextObjectId: Option<string>): FileUri {
    return new FileUri(FileProtocol.TaskFormField, flowId.urlSerialized() + "/" + nodeId + "/" + formSectionRefId + "/" + fieldRefId + "/" + contextObjectId.getOrElse("_") + "/" + uri.protocol.name + "/" + uri.path);
  }

  static flow(uri: FileUri, flowId: AnyFlowId): FileUri {
    return new FileUri(FileProtocol.Flow, flowId.urlSerialized() + "/" + uri.protocol.name + "/" + uri.path);
  }

  static mailbox(uri: FileUri, mailbox: AggregateId): FileUri {
    return new FileUri(FileProtocol.Mailbox, mailbox.id + "/" + uri.protocol.name + "/" + uri.path);
  }

  static parse(uri: string) {
    const uriSplitted = uri.split("://").filter(s => s.trim().length > 0);
    if (uriSplitted.length == 2) {
      return new FileUri(FileProtocol.parse(uriSplitted[0]), uriSplitted[1])
    } else {
      throw new Error("Incorrect file Uri [" + uri + "]");
    }
  }

  urlSerialized() {
    if (this.path.indexOf("/") == 0) {
      return this.protocol.name + "/" + encodeFilePath(this.path.substring(1));
    } else {
      return this.protocol.name + "/" + encodeFilePath(this.path);
    }

  }

  getTaskFormFieldInfo() {
    if (this.isTaskFormField()) {
      const first = this.path.indexOf("/");
      const second = this.path.substring(first + 1).indexOf("/") + first + 1;
      const third = this.path.substring(second + 1).indexOf("/") + second + 1;
      const forth = this.path.substring(third + 1).indexOf("/") + third + 1;
      const fifth = this.path.substring(forth + 1).indexOf("/") + forth + 1;
      const sixth = this.path.substring(fifth + 1).indexOf("/") + fifth + 1;
      const flowId = FlowId.parseFromUri(this.path.substring(0, first));
      const nodeId = parseInt(this.path.substring(first + 1, second));

      const sectionRefId = parseInt(this.path.substring(second + 1, third));
      const fieldRefId = parseInt(this.path.substring(third + 1, forth));
      const contextObjectId = this.path.substring(forth + 1, fifth) == "_" ? None() : Some(this.path.substring(forth + 1, fifth));

      const subProtocol = this.path.substring(fifth + 1, sixth)
      const uri = new FileUri(FileProtocol.copy(new FileProtocol(subProtocol)), this.path.substring(sixth + 1))

      return new UriTaskFormFieldInfo(flowId, nodeId, uri, sectionRefId, fieldRefId, contextObjectId)
    } else {
      throw new Error("Not a task uri")
    }
  }

  getFlowInfo() {
    if (this.isFlow()) {
      const first = this.path.indexOf("/");
      const second = this.path.substring(first + 1).indexOf("/") + first + 1;
      const flowId = new FlowId(this.path.substring(0, first));
      const subProtocol = this.path.substring(first + 1, second);
      const uri = new FileUri(FileProtocol.copy(new FileProtocol(subProtocol)), this.path.substring(second + 1));
      return new UriFlowInfo(flowId, uri)
    } else {
      throw new Error("Not a flow uri")
    }
  }
}
